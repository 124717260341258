import { capitalize, groupBy, sortBy, truncate } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import {
  Bar,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { Timeframe } from '../../../domainTypes/analytics';
import {
  AnalyticsIntervalUnit,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import { getTimeKeyRangeWithInterval } from '../../../services/analytics';
import { AnalyticsGroupContainer } from '../../../services/analyticsV2/groups';
import { formatMetric } from '../../../services/analyticsV2/metrics';
import { COLOR_UNKNOWN } from '../../../services/color';
import { getRollingAverage } from '../../../services/math';
import {
  intervalToTk,
  timeframeToMomentRange,
  tkToIntervalLabel,
  tkToIntervalTick
} from '../../../services/time';
import { CustomTooltip } from '../CustomTooltip';
import { ChartMode } from '../EarningsChartCard/ChartModeSelector';
import { EarningsBarChartCardMetricV2 } from '../EarningsChartCardV2';
import { formatChartNumber, WithShape } from '../Util';

const ROLLING_AVERAGE_COLOR = '#b3b3b3';
const CLICKS_COLOR = 'black';

type BarEl = {
  timeKey: string;
  total: number;
  rollingAverage30d: number;
  clicks?: number;
  containers: { [key: string]: number };
};

const tooltipFormatter = (
  value: number,
  metric: EarningsBarChartMetric,
  name: string,
  containers: AnalyticsGroupContainer[],
  currency: CurrencyCode,
  isSmall: boolean
) => {
  const v = formatMetric(value, metric, currency);

  if (name === 'rollingAverage30d') {
    return [
      v,
      <WithShape color={ROLLING_AVERAGE_COLOR} small={isSmall}>
        Rolling Average
      </WithShape>
    ];
  }

  if (name === 'clicks') {
    return [
      value,
      <WithShape color={CLICKS_COLOR} small={isSmall}>
        Clicks
      </WithShape>,
      { marginTop: 8 }
    ];
  }

  if (name === 'total') {
    return [
      v,
      <WithShape color="none" small={isSmall}>
        <strong>Total</strong>
      </WithShape>,
      { marginTop: 8 }
    ];
  }

  const key = name.substr('containers.'.length, name.length - 1);
  const deserializedKey = deserializeKey(key);
  const container = containers.find((p) => p.key === deserializedKey);
  const containerName = container
    ? container.label || 'Unknown'
    : capitalize(key);

  return [
    v,
    <WithShape color={container?.color || 'none'} small={isSmall}>
      {truncate(containerName, { length: 23 })}
    </WithShape>
  ];
};

const addRollingAverages = (els: BarEl[]): void => {
  const averages = getRollingAverage(
    els.map((el) => el.total),
    30
  );
  averages.forEach((avg, i) => {
    const el = els[i];
    if (el) {
      el.rollingAverage30d = avg;
    }
  });
};

export type TimeseriesData = {
  tk: string;
  c?: number;
  commission_sum_net?: number;
  gmv_sum_net?: number;
  // quantity_net?: number;
  // order_count_net?: number;
};

export type Data = {
  container: AnalyticsGroupContainer;
  timeseries: TimeseriesData[];
};

const sort = (data: Data[], metric: EarningsBarChartCardMetricV2) =>
  sortBy(data, (d) => -d.timeseries.reduce((m, e) => (e[metric] || 0) + m, 0));

export const limitAndSort = (
  data: Data[],
  metric: EarningsBarChartCardMetricV2,
  limit?: number
): Data[] => {
  if (!data.length) {
    return [];
  }
  const sorted = sort(data, metric);
  if (!limit) {
    return sorted;
  }
  const named = sorted.slice(0, limit);
  const others = sorted.slice(limit);
  if (!others.length) {
    return named;
  }

  const otherTimeSeries = others.reduce<{
    [timeKey: string]: TimeseriesData;
  }>((m, s) => {
    s.timeseries.forEach((e) => {
      const x = (m[e.tk] = m[e.tk] || {
        tk: e.tk,
        commission_sum_net: 0,
        gmv_sum_net: 0,
        quantity_net: 0,
        order_count_net: 0
      });
      x.commission_sum_net =
        (x.commission_sum_net || 0) + (e.commission_sum_net || 0);
      x.gmv_sum_net = (x.gmv_sum_net || 0) + (e.gmv_sum_net || 0);
      x.c = (x.c || 0) + (e.c || 0);
      // x.quantity_net = (x.quantity_net || 0) + (e.quantity_net || 0);
      // x.order_count_net = (x.order_count_net || 0) + (e.order_count_net || 0);
      return m;
    });
    return m;
  }, {});

  return [
    ...named,
    {
      container: {
        key: 'OTHER',
        label: 'Others',
        color: COLOR_UNKNOWN
      },
      timeseries: Object.values(otherTimeSeries)
    }
  ];
};

export type EarningsBarChartMetric =
  | 'c'
  | 'commission_sum_net'
  | 'gmv_sum_net'
  | 'quantity_net'
  | 'order_count_net';

const EMPTY_BAR_EL = (timeKey: string): BarEl => ({
  timeKey: timeKey,
  containers: {},
  total: 0,
  rollingAverage30d: 0
});

const serializeKey = (key: string) => {
  return key.replace(/\./g, '_');
};

const deserializeKey = (key: string) => {
  return key.replace(/_/g, '.');
};

export const EarningsBarChartV2 = ({
  data,
  currency,
  size = 'normal',
  yAxisOrientation = 'left',
  height = 'auto',
  timeframe,
  metric = 'commission_sum_net',
  clickData,
  chartMode = 'barChart',
  intervalUnit = 'day'
}: {
  data: Data[];
  clickData?: {
    tk: string;
    clicks: number;
  }[];
  currency: CurrencyCode;
  size?: 'small' | 'normal';
  yAxisOrientation?: 'left' | 'right';
  metric?: EarningsBarChartCardMetricV2;
  height?: number | string;
  timeframe?: Timeframe; // optionally pass this to guarantee a minimum range on the xAxis
  chartMode?: ChartMode;
  intervalUnit: AnalyticsIntervalUnit;
}) => {
  const containers = data.map((d) => d.container);
  const isSmall = size === 'small';

  const tfStart = timeframe?.start;
  const tfEnd = timeframe?.end;
  const tfTz = timeframe?.tz;

  const startsInCurrentYear = useMemo(
    () =>
      tfStart
        ? moment(tfStart).format('YYYY') === moment().format('YYYY')
        : false,
    [tfStart]
  );

  const counts = useMemo(() => {
    const byTimeKey = data.reduce<{ [timeKey: string]: BarEl }>(
      (result, el) => {
        const { container, timeseries } = el;
        timeseries.forEach((e) => {
          const item: BarEl = (result[e.tk] =
            result[e.tk] || EMPTY_BAR_EL(e.tk));

          const count = (() => {
            switch (metric) {
              case 'c':
                return e.c || 0;
              case 'commission_sum_net':
                return e.commission_sum_net || 0;
              case 'gmv_sum_net':
                return e.gmv_sum_net || 0;
              // case 'quantity_net':
              //   return e.quantity_net || 0;
              // case 'order_count_net':
              //   return e.order_count_net || 0;
            }
          })();

          // Handle keys with dots in them
          const serializedKey = serializeKey(container.key);
          item.containers[serializedKey] = count;
          item.total += count;
        });
        return result;
      },
      {}
    );
    if (tfStart && tfEnd && tfTz) {
      const moments = timeframeToMomentRange({
        start: tfStart,
        end: tfEnd,
        tz: tfTz
      });
      const tkRange = getTimeKeyRangeWithInterval(
        moments.start,
        moments.end,
        intervalUnit
      );
      tkRange.forEach(
        (tk) => (byTimeKey[tk] = byTimeKey[tk] || EMPTY_BAR_EL(tk))
      );
    }

    if (clickData) {
      clickData.forEach((c) => {
        const tk = c.tk;
        const container = (byTimeKey[tk] = byTimeKey[tk] || EMPTY_BAR_EL(tk));
        container.clicks = c.clicks;
      });
    }

    const els = Object.values(byTimeKey);
    addRollingAverages(els);
    return els;
  }, [data, clickData, tfStart, tfEnd, tfTz, metric, intervalUnit]);

  const axisFontSize = isSmall ? 12 : 14;
  const xAxisTickGap = useMemo(() => {
    switch (intervalUnit) {
      case 'day':
        return 24;
      case 'week':
        return 50;
      case 'month':
        return 1;
      case 'quarter':
        return 50;
      case 'year':
        return 1;
    }
  }, [intervalUnit]);

  return (
    <ResponsiveContainer
      height={height}
      width="99%"
      aspect={height === 'auto' ? 1.8 : undefined}
    >
      <ComposedChart
        data={counts}
        layout="horizontal"
        stackOffset="sign"
        maxBarSize={48}
      >
        <XAxis
          dataKey="timeKey"
          tickFormatter={(tk) => {
            return tkToIntervalTick(tk, intervalUnit, startsInCurrentYear);
          }}
          tick={{
            fill: '#9b9b9b',
            transform: 'translate(0, 8)',
            fontSize: axisFontSize
          }}
          textAnchor="middle"
          tickSize={0}
          minTickGap={xAxisTickGap}
          stroke="cwBBB"
          hide={isSmall}
        />
        <YAxis
          width={isSmall ? 40 : undefined}
          yAxisId="timeseries"
          tick={{
            fill: '#9b9b9b',
            transform: `translate(${yAxisOrientation === 'left' ? -8 : 8}, 0)`,
            fontSize: axisFontSize
          }}
          domain={[
            (min) => {
              if (chartMode === 'barChart') {
                return Math.min(min, 0);
              }
              // getting smallest value from all containers to set yAxis length accordingly
              const minValue = Math.min(
                ...counts.map((count) =>
                  Math.min(...Object.values(count.containers))
                )
              );
              return minValue;
            },
            (max) => {
              if (chartMode === 'barChart') {
                return Math.max(max, 0);
              }
              // getting largest value from all containers to set yAxis length accordingly
              const maxValue = Math.max(
                ...counts.map((count) =>
                  Math.max(...Object.values(count.containers))
                )
              );
              return maxValue;
            }
          ]}
          allowDecimals={false}
          stroke="none"
          tickSize={0}
          orientation={yAxisOrientation}
          tickFormatter={(value: number) =>
            formatMetric(value, metric, currency)
          }
        />

        {clickData && (
          <YAxis
            width={isSmall ? 40 : undefined}
            yAxisId="clicks"
            tick={{
              fill: '#9b9b9b',
              transform: `translate(${
                yAxisOrientation === 'left' ? 8 : -8
              }, 0)`,
              fontSize: axisFontSize
            }}
            allowDecimals={false}
            stroke="none"
            tickSize={0}
            tickFormatter={(v: number) => formatChartNumber(v)}
            orientation={yAxisOrientation === 'left' ? 'right' : 'left'}
          />
        )}
        {chartMode === 'barChart'
          ? data.map((d) => (
              <Bar
                key={serializeKey(d.container.key)}
                dataKey={`containers.${serializeKey(d.container.key)}`}
                stackId="a"
                yAxisId="timeseries"
                fill={d.container.color}
                isAnimationActive={false}
              />
            ))
          : data.map((d) => (
              <Line
                key={serializeKey(d.container.key)}
                dataKey={`containers.${serializeKey(d.container.key)}`}
                yAxisId="timeseries"
                stroke={d.container.color}
                isAnimationActive={false}
                dot={false}
                strokeWidth={1.3}
                type="monotone"
              />
            ))}
        <Line
          stroke="none"
          yAxisId="timeseries"
          dataKey="total"
          dot={false}
          activeDot={false}
        />
        {clickData && (
          <Line
            type="monotone"
            dataKey="clicks"
            yAxisId="clicks"
            dot={false}
            stroke={CLICKS_COLOR}
            strokeWidth={isSmall ? 1 : 3}
            isAnimationActive={false}
          />
        )}
        <Tooltip
          cursor={false}
          separator=": "
          content={<CustomTooltip size={isSmall ? 'small' : 'normal'} />}
          labelFormatter={(label: TooltipProps['label']) => {
            if (!label || typeof label !== 'string') {
              return '';
            }
            return tkToIntervalLabel(label, intervalUnit, startsInCurrentYear);
          }}
          formatter={(value, name) =>
            tooltipFormatter(
              value as number,
              metric,
              name,
              containers,
              currency,
              isSmall
            )
          }
        />

        {!isSmall && (
          <ReferenceLine y={0} yAxisId={'timeseries'} stroke="#bbb" />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const transformAnalyticsResponseToEarningsChartData = (
  res: AnalyticsResponse,
  toGroupKey: (r: AnalyticsResponseRowWithComparison) => string,
  groupKeyToContainer: (key: string) => AnalyticsGroupContainer,
  tz: string,
  metric: EarningsBarChartCardMetricV2,
  limit: number = 10,
  filterRows: (
    rows: AnalyticsResponseRowWithComparison[]
  ) => AnalyticsResponseRowWithComparison[] = (t) => t
): Data[] => {
  const grouped = groupBy(filterRows(res.rows), toGroupKey);

  const data: Data[] = Object.entries(grouped).map(([g, rows]) => {
    const container = groupKeyToContainer(g);
    const timeseries: TimeseriesData[] = rows.map((r) => {
      return {
        // check res.q.interval?.unit to format the interval correct.
        tk: intervalToTk(r.group['interval'], tz, res.q.interval?.unit),
        [metric]: r.data[metric]?.curr || 0
      };
    });
    return { container, timeseries };
  });

  return limitAndSort(data, metric, limit);
};
