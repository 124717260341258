import React from 'react';
import { Helmet } from 'react-helmet';
import { ChartCard } from '../../../../components/Charts/ChartCard';
import { CountryChart } from '../../../../components/Charts/CountryChart';
import { DeviceChart } from '../../../../components/Charts/DeviceChart';
import { Timeframe } from '../../../../domainTypes/analytics';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import { styled } from '../../../../emotion';
import { PageBody } from '../../../../layout/PageBody';
import {
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { useRoutes } from '../../../../routes';
import {
  useCountryClickCountsForPageInTimeframePg,
  useDeviceClickCountsForPageInTimeframePg
} from '../../../../services/analytics';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePageOrCreate } from '../../../../services/page';
import { getPathname } from '../../../../services/url';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import {
  SelectorsDense,
  useStandardSelectorStateFromUrl
} from '../../components/Selectors';
import { sideNavProps } from '../../services/detailsSideNav';
import { useExperimentalContext } from '../../../../services/experimental';
import { DetailsAudienceBody } from './DetailsAudienceBody';
import { NoPermissions } from '../../../../components/NoPermissions';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  }
`;

type Props = {
  spaceId: string;
  url: string;
  timeframe: Timeframe;
};

const DevicesPg = ({ spaceId, url, timeframe }: Props) => {
  const [counts, loading] = useDeviceClickCountsForPageInTimeframePg(
    spaceId,
    url,
    timeframe
  );
  return (
    <ChartCard
      heading="Devices"
      subheading="Devices your audience uses when they click your affiliate links"
    >
      <DeviceChart counts={counts} loading={loading} />
    </ChartCard>
  );
};

const CountriesPg = ({ spaceId, url, timeframe }: Props) => {
  const [counts, loading] = useCountryClickCountsForPageInTimeframePg(
    spaceId,
    url,
    timeframe,
    false
  );
  return (
    <ChartCard
      heading="Countries"
      subheading="Countries your audience is in when they click your affiliate links"
    >
      <CountryChart counts={counts} loading={loading} />
    </ChartCard>
  );
};

const DetailsAudienceBodyPg = ({ url }: { url: string }) => {
  const { ROUTES } = useRoutes();
  const sideNav = sideNavProps(ROUTES, url);

  const { space } = useCurrentUser();
  const [metadata] = usePageOrCreate(space.id, url);
  const { value, onChange, options } = useStandardSelectorStateFromUrl(
    space,
    metadata ? metadata.data.revisions : EMPTY_ARR
  );
  const { timeframe } = value;
  useTrackMixpanelView('view_content_details_audience', { url });

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      <PageToolbar>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <SelectorsDense value={value} onChange={onChange} options={options} />
        </PageToolbarSection>
      </PageToolbar>
      <Grid>
        <DevicesPg spaceId={space.id} url={url} timeframe={timeframe} />
        <CountriesPg spaceId={space.id} url={url} timeframe={timeframe} />
      </Grid>
    </PageBody>
  );
};

export const PageContentDetailsAudience = ({ url }: { url: string }) => {
  const [isExperiment] = useExperimentalContext();
  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return isExperiment ? (
    <DetailsAudienceBodyPg url={url} />
  ) : (
    <DetailsAudienceBody url={url} />
  );
};
