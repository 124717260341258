import React, { useMemo, useState } from 'react';
import { Truncated } from '../../../../../components/Truncated';
import {
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useRoutes } from '../../../../../routes';
import {
  dbColumn,
  utmParameterTitle,
  UtmParameterType
} from '../../../../Content/pages/Traffic/Utm/service/utm';
import {
  useRealtimeFilters,
  useRealtimeMetric,
  useSmallTableMetrics
} from '../service';
import {
  comparePeriodRange,
  usePeriodRangeToTimeframe
} from './PeriodSelector';
import {
  createNameColumn,
  TopFooter,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';
import { SelectUtm } from '../../../../../components/SelectUtm';

const cell = (utmParam: UtmParameterType) => (
  p: AnalyticsResponseRowWithComparison
) => <Truncated title={p.group[dbColumn(utmParam)]} />;

export const TopSourcesTable = ({
  range,
  color
}: {
  range: ISOTimeRange;
  color: string;
}) => {
  const [utmParam, setUtmParam] = useState<UtmParameterType>('campaign');
  const [metric] = useRealtimeMetric();
  const { filters, columnTransformers } = useRealtimeFilters();
  const metrics = useSmallTableMetrics();
  const { ROUTES } = useRoutes();
  const timeframe = usePeriodRangeToTimeframe();
  const title = utmParameterTitle(utmParam);
  const sourceColumn = useMemo(
    () => createNameColumn(cell(utmParam), title, color, metric),
    [color, metric, title, utmParam]
  );
  return (
    <TopPaper>
      <TopHeader
        title={`Top ${title.toLowerCase()}`}
        description="Engagement and performance by traffic containing UTM parameters."
      >
        <SelectUtm value={utmParam} onChange={setUtmParam} />
      </TopHeader>
      <TopTable
        metrics={metrics}
        metric={metric}
        filters={filters}
        columnTransformers={columnTransformers}
        nameColumn={sourceColumn}
        range={range}
        compareRange={comparePeriodRange(range)}
        groupField={dbColumn(utmParam)}
      />
      <TopFooter
        route={ROUTES.content.utm.details.url(utmParam, { timeframe })}
      />
    </TopPaper>
  );
};
