import { memoize } from 'lodash';
import * as mp from 'mixpanel-browser';
import { useEffect, useMemo } from 'react';
import { MixpanelEventName } from '../domainTypes/mixpanel';
import * as ENVS from '../env.json';
import { useCurrentUser } from './currentUser';

export const mixpanel = memoize(() => {
  mp.init(ENVS.mixpanel.projectToken, {
    debug: true,
    ignore_dnt: true,
    api_host: 'https://api-eu.mixpanel.com'
  });
  return mp;
});

type Dict = {
  [key: string]: any;
};

const isInternalUser = (email: string | null | undefined) => {
  if (!email) {
    return false;
  }
  return (
    email.indexOf('affilimate.com') !== -1 ||
    email.indexOf('affilimate.io') !== -1 ||
    email.indexOf('evergreensupport.co') !== -1
  );
};

export const useMixpanel = () => {
  const { space, email } = useCurrentUser();
  const spaceId = space.id;

  return useMemo(() => {
    const m = mixpanel();
    const track = (name: MixpanelEventName, properties: Dict = {}) => {
      if (isInternalUser(email)) {
        return;
      }

      m.track(name, { ...properties, spaces: [spaceId] });
    };
    const identify = m.identify;
    const register = m.register;
    return { track, identify, register };
  }, [spaceId, email]);
};

export const useTrackMixpanelView = (
  eventName: MixpanelEventName,
  properties?: Dict
) => {
  // Also uses the mixpanel queue via the hook above
  const mixpanel = useMixpanel();
  return useEffect(() => {
    const props = properties || {};
    mixpanel.track(eventName, props);
  }, [mixpanel, eventName, properties]);
};
