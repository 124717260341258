import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { PagePerformanceChannelsContent } from './PagePerformanceChannelsContent';
import { PagePerformanceChannelsContentV2 } from './PagePerformanceChannelsContentV2';

export const PagePerformanceChannels = () => {
  useTrackMixpanelView('view_channels');

  const [canView] = useHasCurrentUserRequiredScopes(['reports.channels.view']);
  const [experimental] = useExperimentalContext();

  return (
    <>
      <Helmet>
        <title>Channels | Affilimate</title>
      </Helmet>
      {canView ? (
        experimental ? (
          <PagePerformanceChannelsContent />
        ) : (
          <PagePerformanceChannelsContentV2 />
        )
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
