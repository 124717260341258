import { sortBy, uniq } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip
} from '../../../../components/MultiSelector';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { ModeSelector } from '../../../../components/Table/ModeSelector';
import { DEFAULT_CURRENCY } from '../../../../domainTypes/space';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useLoadingValueTimer } from '../../../../services/db';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePageOrCreate } from '../../../../services/page';
import {
  aggregatePartnerListFromKeys,
  PartnerListItem
} from '../../../../services/partner';
import {
  getProductsByIdPg,
  useProductsWithCountsAndSalesOnPageWithLazyProductRetrieval
} from '../../../../services/products';
import { usePartnersQueryParam } from '../../../Links/pages/Overview';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import {
  SelectorsDense,
  useStandardSelectorStateFromUrl
} from '../../components/Selectors';
import { sideNavProps } from '../../services/detailsSideNav';
import { COLUMNS, DEFAULT_COLUMNS, SORTERS, Table } from './Table';
import { useExperimentalContext } from '../../../../services/experimental';
import { PageContentDetailsLinksBody } from './PageContentDetailsLinksBody';
import { Mode } from '../../../../domainTypes/analytics';
import { NoPermissions } from '../../../../components/NoPermissions';

export const PartnerSelector = ({
  partners,
  value,
  onChange
}: {
  partners: PartnerListItem[];
  value: Set<string> | null;
  onChange: (nextValue: Set<string>) => void;
}) => {
  const fullSet = useMemo(() => new Set(partners.map((p) => p.partner.key)), [
    partners
  ]);
  const v = value || fullSet;
  const isApplied = partners.length !== v.size;

  return (
    <MultiSelector
      value={v}
      onChange={onChange}
      legend="Partners"
      options={sortBy(partners, (p) => p.partner.name).map((p) => ({
        label: `${p.partner.name} (${p.counts.links})`,
        value: p.partner.key
      }))}
      allOption={<strong>All partners</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() => onChange(fullSet)}
        label="Partners"
        appliedLabel={getAppliedLabel(
          'partner',
          isApplied
            ? partners
                .filter((p) => v.has(p.partner.key))
                .map((p) => p.partner.name)
            : []
        )}
      />
    </MultiSelector>
  );
};

// How to retrieve this data via Postgres
// - make a request for the page in a given timeframe
// - receive a container back that overfetched - more pages will be present and more tks
// - pump these into a cache, after the timeframe has been padded. Cached is keyed by space, page and tk
// - when another request is made, check the cache first
// - collect all cached results. Check for which tks overlap for this page
// - make a request for all non-overlapping ranges. Need to determine whether this should be made as two requests
//   (if something is missing at the start and at the end), or just say fuck it and retrieve the whole tf again
//   (requests are fast enough to take the larger window)
// - how to implement the simpler version: expand the requests timeframe
// - remove all keys that are present in the cache.
// - take the start and the end of the remaining range and make the request
// - or just return the cached result
//
// - The same plan applies to products
//
// - For everything product related a second step is required to resolve
//   the product name and the url. Joins would be possible, but are too complicated
//   for this particular data. Overall speaking, we could always perform this merge
//   on the client. This allows us to progressively build up this data, so that we are
//   more and more likely to have it around whenever someone asks for this data.
// - In an ideal world we could make these requests in a batched fashion, so that we don't have
//   to ask for them row by row, which is needlessly inefficient.

const PageContentDetailsLinksPg = ({ url }: { url: string }) => {
  const { ROUTES } = useRoutes();
  const sideNav = sideNavProps(ROUTES, url);

  const { space } = useCurrentUser();
  const spaceId = space.id;
  const currency = space.config.currency || DEFAULT_CURRENCY;

  const [pageMetadata] = usePageOrCreate(space.id, url);
  const { value, onChange, options } = useStandardSelectorStateFromUrl(
    space,
    pageMetadata ? pageMetadata.data.revisions : []
  );

  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const [mode, setMode] = useTypedStringQueryParam<Mode>(
    'table-mode',
    'absolute-numbers',
    true
  );

  const sorters = SORTERS[mode];
  const [[sorter, direction], setSort] = useSortQueryParam('sort', sorters);

  const [
    products,
    loading,
    error
  ] = useProductsWithCountsAndSalesOnPageWithLazyProductRetrieval(
    url,
    value.timeframe,
    true,
    currency
  );
  useLoadingValueTimer('lazy products', [products, loading, error]);

  useEffect(() => {
    // Pre-loading the cache.
    // All these products will request one product per row.
    // They will all hit a deduplicated cached entry.
    // This is more efficient as this way we can batch the request.
    if (products) {
      getProductsByIdPg(
        spaceId,
        products.map((p) => p.id)
      );
    }
  }, [spaceId, products]);

  useErrorLogger(error);

  const allPartners = useMemo(
    () =>
      aggregatePartnerListFromKeys(
        uniq((products || []).map((p) => p.partner_key))
      ),
    [products]
  );

  const [partners, setPartners] = usePartnersQueryParam(
    'partners',
    allPartners
  );

  const ds = useMemo(() => {
    if (!products) {
      return [];
    }

    if (!partners) {
      return products;
    }

    return products.filter((p) => {
      const matchesPartners = partners ? partners.has(p.partner_key) : true;

      return matchesPartners;
    });
  }, [products, partners]);

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
          <PageToolbarSection flex={1}>
            <PartnerSelector
              partners={allPartners}
              value={partners}
              onChange={setPartners}
            />
            <ColumnSelector
              value={columns}
              onChange={setColumns}
              columns={COLUMNS}
            />
            <ModeSelector value={mode} onChange={setMode} />
          </PageToolbarSection>
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <SelectorsDense value={value} onChange={onChange} options={options} />
        </PageToolbarSection>
      </PageToolbar>
      <Table
        ds={ds}
        loading={loading}
        sorter={sorter || sorters.clicked}
        sortDirection={direction}
        onSort={(k, dir) => setSort([sorters[k] || null, dir])}
        columns={columns}
        compare={true}
        rowToHref={(d) => ROUTES.links.details.overview.url(d.id)}
        mode={mode}
        currency={currency}
      />
    </PageBody>
  );
};

export const PageContentDetailsLinks = ({ url }: { url: string }) => {
  const [isExperimental] = useExperimentalContext();
  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  useTrackMixpanelView('view_content_details_links', { url });

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return isExperimental ? (
    <PageContentDetailsLinksPg url={url} />
  ) : (
    <PageContentDetailsLinksBody url={url} />
  );
};
