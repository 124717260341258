import React, { useMemo } from 'react';
import { Truncated } from '../../../../../components/Truncated';
import {
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useRoutes } from '../../../../../routes';
import { getPathName } from '../../../../../services/pages';
import { useRealtimeFilters, useRealtimeMetric } from '../service';
import {
  comparePeriodRange,
  usePeriodRangeToTimeframe
} from './PeriodSelector';
import {
  createNameColumn,
  TopFooter,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';

const cell = (p: AnalyticsResponseRowWithComparison) => {
  const href = p.group.page_url;
  const pathName = getPathName(href);
  return <Truncated title={href}>{pathName}</Truncated>;
};

const metrics = [
  'p',
  'c',
  'ctr',
  'rpm_net',
  'epc_net',
  'commission_sum_net',
  'gmv_sum_net'
] as const;

export const TopEarningsTable = ({
  range,
  color
}: {
  range: ISOTimeRange;
  color: string;
}) => {
  const [metric] = useRealtimeMetric();
  const { ROUTES } = useRoutes();
  const timeframe = usePeriodRangeToTimeframe();
  const { filters, columnTransformers } = useRealtimeFilters();
  const contentColumn = useMemo(() => {
    return createNameColumn(cell, 'Page URL', color, metric);
  }, [color, metric]);
  return (
    <TopPaper>
      <TopHeader
        title="Top content"
        description="Your top content based on the selected sorting metric. You can filter this using the search box."
      ></TopHeader>
      <TopTable
        metrics={metrics}
        metric={metric}
        range={range}
        compareRange={comparePeriodRange(range)}
        groupField={'page_url'}
        nameColumn={contentColumn}
        filters={filters}
        columnTransformers={columnTransformers}
      />
      <TopFooter route={ROUTES.content.overview_v2.url(undefined, timeframe)} />
    </TopPaper>
  );
};
