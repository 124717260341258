import { ButtonBase, Popover } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { FilterChip } from '../Chip';
import { Dimension } from '../filters';
import { FilterMenuBody } from './FilterMenuBody';
import { ISOTimeRange } from '../../../../domainTypes/analytics_v2';
import { FilterDrawerRenderContext } from './types';

interface FilterControlProps {
  definition: FilterDefinition;
  onChange: (definition: FilterDefinition) => void;
  onDelete: (dimension: Dimension) => void;
  range: ISOTimeRange;
  orderBy: Metric;
  renderContext?: FilterDrawerRenderContext;
}

export const FilterControl: React.FC<FilterControlProps> = ({
  onDelete,
  onChange,
  definition,
  range,
  orderBy,
  renderContext
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const toggle = useCallback(() => setOpen((x) => !x), []);
  const close = useCallback(() => setOpen(false), []);
  const onSave = useCallback(
    (definition: FilterDefinition) => {
      onChange(definition);
      close();
    },
    [close, onChange]
  );

  return (
    <>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ marginTop: 10 }}
      >
        <FilterMenuBody
          initialDefinition={definition}
          onSave={onSave}
          orderBy={orderBy}
          range={range}
          renderContext={renderContext}
        />
      </Popover>
      <ButtonBase ref={ref}>
        <FilterChip
          definition={definition}
          onClick={toggle}
          onDelete={onDelete}
        />
      </ButtonBase>
    </>
  );
};
