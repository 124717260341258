import React, { useCallback, useMemo } from 'react';
import { ALL_DIMENSIONS, Dimension } from '../filters';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { Badge, Button, Collapse, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Filter } from 'react-feather';
import { css, styled } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';
import { useLocalJsonStorage } from '../../../../services/localJsonStorage';
import { useMixpanel } from '../../../../services/mixpanel';
import { ISOTimeRange } from '../../../../domainTypes/analytics_v2';
import { useTimeframe } from '../../Timeframe';
import { useFilters } from '../hooks';
import { AddFilterMenu } from './AddFilterMenu';
import { ClearAll } from './ClearAll';
import { useFiltersState } from './filters-state';
import { FilterList } from './FiltersList';
import { FilterDrawerRenderContext } from './types';

const Row: React.FC = ({ children }) => (
  <FlexContainer
    direction="row"
    alignItems="center"
    wrap="wrap"
    className={css((t) => ({
      width: '100%',
      rowGap: t.spacing(1),
      columnGap: t.spacing(2)
    }))}
  >
    {children}
  </FlexContainer>
);

const Container: React.FC<{ spacing: number; marginTop?: number }> = ({
  children,
  spacing,
  marginTop
}) => (
  <Paper
    className={css((t) => ({
      padding: `${t.spacing(1)}px ${t.spacing(2)}px`,
      marginTop: t.spacing(marginTop || 0)
    }))}
  >
    <FlexContainer direction="column" alignItems="start" spacing={spacing}>
      {children}
    </FlexContainer>
  </Paper>
);

interface FiltersDrawerProps {
  isOpen: boolean;
  availableDimensions?: Dimension[];
  title?: string;
  orderBy?: Metric; // TODO: name better, maybe move into more meaningful options object
  range?: ISOTimeRange;
  marginTop?: number; // multiplier for spacing
  renderContext?: FilterDrawerRenderContext;
}

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  isOpen,
  availableDimensions = ALL_DIMENSIONS,
  title = 'Filter',
  orderBy = 'c',
  range,
  marginTop = 0,
  renderContext
}) => {
  const { range: tfRange } = useTimeframe();
  const effectiveRange = useMemo<ISOTimeRange>(() => range ?? tfRange, [
    range,
    tfRange
  ]);
  const {
    removeFilter,
    updateFilter,
    filters,
    placeholderDimension,
    addFilter,
    clearPlaceholder,
    setPlaceholderDimension
  } = useFiltersState();
  const dimensions = availableDimensions.filter(
    (d) => !filters.some((f) => f.k === d)
  );

  // NOTE: We need to render FilterList and in result second Row ahead of time so Placeholder popover has a reference to anchor.
  const hasSomethingInSecondRow =
    filters.length > 0 || placeholderDimension !== null;
  const spacing = hasSomethingInSecondRow ? 1 : 0;
  return (
    <Collapse in={isOpen}>
      <Container spacing={spacing} marginTop={marginTop}>
        <Row>
          <Typography
            variant="caption"
            color="textSecondary"
            component="p"
            className={css(() => ({
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }))}
          >
            {title}
          </Typography>
          <AddFilterMenu
            selectDimension={setPlaceholderDimension}
            availableDimensions={dimensions}
            renderContext={renderContext}
          />
          <ClearAll />
        </Row>
        <Row>
          <FilterList
            filters={filters}
            orderBy={orderBy}
            range={effectiveRange}
            placeHolderDimension={placeholderDimension}
            onChange={updateFilter}
            onDelete={removeFilter}
            addFilter={addFilter}
            clearPlaceholder={clearPlaceholder}
            renderContext={renderContext}
          />
        </Row>
      </Container>
    </Collapse>
  );
};

interface FiltersIconProps {
  isOpen: boolean;
  toggle: () => void;
}
const SmallBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    padding: '0 4px',
    right: -12,
    height: 16,
    minWidth: 16
  }
}));

const ToggleButton = styled(Button)(() => ({
  paddingRight: '0'
}));

export const FiltersIcon: React.FC<FiltersIconProps> = ({ isOpen, toggle }) => {
  const filters = useFilters();
  return (
    <ToggleButton
      startIcon={<Filter size={16} color="rgba(0, 0, 0, 0.54)" />}
      onClick={toggle}
      className={css((t) => ({
        paddingRight:
          filters.length > 0 ? `${t.spacing(3)}px` : `${t.spacing(1)}px`
      }))}
    >
      <Typography variant="body2" color="textSecondary">
        {isOpen ? 'Hide filters' : 'Filters'}
      </Typography>
      <SmallBadge
        badgeContent={filters.length}
        color="error"
        invisible={filters.length === 0}
      />
    </ToggleButton>
  );
};

export const useFilterDrawerState = (
  initialState: boolean,
  { localStorageKey }: { localStorageKey: string }
) => {
  const mixpanel = useMixpanel();
  const [isOpen, setOpen] = useLocalJsonStorage(localStorageKey, initialState);
  const toggle = useCallback(() => {
    if (isOpen) {
      mixpanel.track('filters_hide');
      setOpen(false);
    } else {
      mixpanel.track('filters_show');
      setOpen(true);
    }
  }, [isOpen, mixpanel, setOpen]);
  return {
    isOpen,
    toggle
  };
};
