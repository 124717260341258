import { Doc, toDoc } from '../../../domainTypes/document';
import firebase from 'firebase/app';
import { useState, useEffect } from 'react';
import { EMPTY_OBJ, NOOP } from '../../../domainTypes/emptyConstants';

type Listener<T> = (self: CollectionListener<T>) => void;
type CollectionListenerOptions = { ignoreInitialCachedResults?: boolean };

export class CollectionListener<T> {
  initialising: boolean = true;
  value: Doc<T>[] | void = undefined;
  error: any | undefined;
  detach: () => void = NOOP;

  private listeners: Listener<T>[] = [];

  constructor(
    query: firebase.firestore.Query,
    normalize: (s: firebase.firestore.QueryDocumentSnapshot) => Doc<T> = (t) =>
      toDoc<T>(t),
    options: CollectionListenerOptions = EMPTY_OBJ
  ) {
    this.init(query, normalize, options);
  }

  protected init(
    query: firebase.firestore.Query,
    normalize: (s: firebase.firestore.QueryDocumentSnapshot) => Doc<T> = (t) =>
      toDoc<T>(t),

    options: CollectionListenerOptions
  ) {
    this.detach = query.onSnapshot(
      (snapshot) => {
        if (options.ignoreInitialCachedResults && snapshot.metadata.fromCache) {
          return;
        }
        this.value = snapshot.docs.map(normalize);
        this.error = undefined;
        this.initialising = false;
        this.notify();
      },
      (error) => {
        this.value = undefined;
        this.error = error;
        this.initialising = false;
        this.notify();
      }
    );
  }

  protected notify = () => this.listeners.forEach((l) => l(this));

  listen = (listener: Listener<T>) => {
    this.listeners.push(listener);
    return () => {
      const i = this.listeners.indexOf(listener);
      if (i !== -1) {
        this.listeners.splice(i, 1);
      }
    };
  };

  get = () => {
    return new Promise<Doc<T>[]>((resolve, reject) => {
      const toResult = () => {
        if (this.error) {
          reject(this.error);
          return;
        }
        if (this.value) {
          resolve(this.value);
        }
      };
      if (!this.initialising) {
        toResult();
        return;
      }
      const unlisten = this.listen(() => {
        unlisten();
        toResult();
      });
    });
  };

  asTuple = () => {
    const { value, initialising, error } = this;
    return [value, initialising, error] as [
      typeof value,
      typeof initialising,
      typeof error
    ];
  };
}

export const listenToCollections = <T>(
  listeners: CollectionListener<T>[],
  onValue: (docs: Doc<T>[]) => void,
  onError: (err: any) => void
) => {
  const check = () => {
    const error = listeners.find((l) => l.error);
    if (error) {
      onError(error);
      return;
    }
    const values = listeners.map((l) => l.value);
    if (values.every((t) => t)) {
      onValue(values.reduce<Doc<T>[]>((m, v) => m.concat(v || []), []));
    }
  };

  // check NEEDS to be wrapped in an own error function - every listener should
  // have a unique check function, so that deatchment works fine.
  const detachers = listeners.map((l) => l.listen(() => check()));
  check();
  return () => detachers.forEach((detach) => detach());
};

export const createCollectionListenerStore = <T>(
  createListener: (key: string) => CollectionListener<T>
) => {
  const store: { [key: string]: CollectionListener<T> } = {};
  return (key: string): CollectionListener<T> => {
    return (store[key] = store[key] || createListener(key));
  };
};

export const createSingleCollectionListenerStore = <T>(
  createListener: () => CollectionListener<T>
) => {
  let listener: CollectionListener<T> | null = null;
  return (): CollectionListener<T> => {
    if (!listener) {
      listener = createListener();
    }
    return listener;
  };
};

export const useCollectionListener = <T>(c: CollectionListener<T>) => {
  const [state, setState] = useState<ReturnType<typeof c.asTuple>>(c.asTuple());
  useEffect(() => c.listen((x) => setState(x.asTuple())), [c]);
  return state;
};
