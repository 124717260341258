import React, { useMemo } from 'react';
import {
  formatCurrency,
  formatNumber,
  toPercent
} from '../../../../../components/Number';
import { ICounter } from '../../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { IEarning } from '../../../../../domainTypes/performance';
import { StatChip } from '../StatChip';

export const AverageCommissionChip = ({
  d,
  loading,
  currency
}: {
  d: { prev: IEarning | null; curr: IEarning } | void;
  loading: boolean;
  currency: CurrencyCode;
}) => {
  const values = useMemo(() => {
    if (!d) {
      return { value: '', diff: '' };
    }

    const { prev, curr } = d;

    const avgCommissionPrev =
      !prev || prev.salesCount === 0
        ? 0
        : (prev.final + prev.pending) / prev.salesCount;
    const avgCommissionCurr =
      curr.salesCount === 0 ? 0 : (curr.final + curr.pending) / curr.salesCount;

    // d.prev might be null, if no data for the comparison timeframe is available,
    // e.g. new accounts will frequently run into this
    return {
      value: formatCurrency(avgCommissionCurr, currency),
      diffFormatted: d.prev
        ? formatCurrency(avgCommissionCurr - avgCommissionPrev, currency, true)
        : '',
      diff: d.prev ? avgCommissionCurr - avgCommissionPrev : 0
    };
  }, [d, currency]);
  return (
    <StatChip
      label="AVC"
      value={values.value}
      diff={values.diffFormatted}
      tooltip="Average Commission: How much each product you're selling earns you on average in commissions"
      color={values.diff === 0 ? 'gray' : values.diff > 0 ? 'green' : 'yellow'}
      loading={loading}
    />
  );
};

export const SaleRateChip = ({
  d,
  loading
}: {
  d: {
    prev: IEarning | null;
    curr: IEarning;
    traffic: {
      prev: ICounter;
      curr: ICounter;
    };
  } | void;
  loading: boolean;
}) => {
  const values = useMemo(() => {
    if (!d) {
      return { value: '', diff: '' };
    }

    const prevSaleRate = d.prev
      ? toPercent(d.prev.salesCount, d.traffic.prev.clicked)
      : 0;
    const currSaleRate = toPercent(d.curr.salesCount, d.traffic.curr.clicked);

    // d.prev might be null, if no data for the comparison timeframe is available,
    // e.g. new accounts will frequently run into this
    return {
      value: formatNumber({
        n: currSaleRate,
        format: 'percent',
        digits: 2
      }),
      diffFormatted: d.prev
        ? formatNumber({
            n: currSaleRate - prevSaleRate,
            format: 'percent',
            digits: 2,
            plusMinus: true
          })
        : '',
      diff: d.prev ? currSaleRate - prevSaleRate : 0
    };
  }, [d]);

  return (
    <StatChip
      label="SR"
      value={values.value}
      diff={values.diffFormatted}
      tooltip="Sale Rate: The ratio of your clicks to sales"
      color={values.diff === 0 ? 'gray' : values.diff > 0 ? 'green' : 'yellow'}
      loading={loading}
    />
  );
};
