import { unitOfTime } from 'moment-timezone';

export type PeriodDefinition =
  | {
      type: 'last';
      duration: string; // ISODuration
    }
  | {
      type: 'latest';
      duration: string; // ISODuration
    }
  | {
      type: 'previous';
      unit: unitOfTime.DurationConstructor;
    }
  | {
      type: 'current';
      unit: unitOfTime.StartOf;
    };

export type TimeframeComparisonDefinition =
  | {
      kind: 'custom';
      start: string; // ISOString
      end: string; // ISOString
    }
  | {
      kind: 'previous';
    }
  | {
      kind: 'disabled';
    }
  | {
      kind: 'timeshift';
      duration: string; // ISODuration like P1D
    };

export type TimeframeRangeDefinition =
  | {
      kind: 'custom';
      start: string; // ISOString
      end: string; // ISOString
    }
  | {
      kind: 'period';
      value: PeriodDefinition;
    };

export type TimeframeDefinition = {
  range: TimeframeRangeDefinition;
  comparison: TimeframeComparisonDefinition;
};

export const readTimeframe = (query: string): TimeframeDefinition | null => {
  return JSON.parse(decodeURIComponent(query));
};

export const printTimeframe = (timeframe: TimeframeDefinition) =>
  encodeURIComponent(JSON.stringify(timeframe));
