import { Paper as MuiPaper } from '@material-ui/core';
import React, { useMemo } from 'react';
import { KnowledgeBasePageBody } from './components/KnowledgeBasePageBody';
import { styled } from '../../../../emotion';
import { usePromise } from '../../../../hooks/usePromise';
import { useCurrentUser } from '../../../../services/currentUser';
import { getArticlesInCategory } from '../../../../services/docs';
import { HelpScoutArticlePreview, groupArticlesInCategory } from './service';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Centered } from '../../../../layout/Centered';
import { Loader } from '../../../../components/Loader';
import { CategoryList } from './components/CategoryList';
import { Article } from './components/Article';
import { DEFAULT_OFFSET, PageToolbar } from '../../../../layout/PageToolbar';
import { KnowledgeBaseSearch } from './components/KnowledgeBaseSearch';
import { useBooleanQueryParam } from '../../../../routes';

const Paper = styled(MuiPaper)`
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(3)}px;
`;

export const PageDocsKnowledgeBase = ({ slug }: { slug?: string }) => {
  const docsSlug = useMemo(() => slug || 'getting-started', [slug]);
  const { space } = useCurrentUser();
  const [includeDrafts] = useBooleanQueryParam('drafts', false);

  const [docsInCategory, loadingDocs] = usePromise<{
    type: 'article' | 'category';
    groupNames?: string[];
    groups?: { [slug: string]: HelpScoutArticlePreview[] };
    category?: { name: string; description: string; slug: string };
    articles?: HelpScoutArticlePreview[];
  }>(async () => {
    const { articles, category, type } = await getArticlesInCategory({
      includeDrafts,
      slug: docsSlug,
      spaceId: space.id
    });
    if (type === 'category') {
      return { ...groupArticlesInCategory(articles), category, type };
    } else {
      return { articles, type };
    }
  }, [docsSlug, includeDrafts]);

  const relatedArticles = useMemo(() => {
    if (!docsInCategory || loadingDocs) {
      return;
    }
    if (docsInCategory.type === 'article' && docsInCategory.articles) {
      const related = (docsInCategory.articles[0] as any).related;
      return related && related.length ? related : undefined;
    }
  }, [docsInCategory, loadingDocs]);

  const selectedArticle = useMemo(() => {
    if (
      !docsInCategory ||
      !docsInCategory.articles ||
      loadingDocs ||
      docsInCategory.type === 'category'
    ) {
      return;
    }
    return docsInCategory.articles[0];
  }, [docsInCategory, loadingDocs]);

  return (
    <KnowledgeBasePageBody
      selectedArticle={selectedArticle}
      relatedArticles={relatedArticles}
    >
      {loadingDocs ? (
        <LimitedWidth width={750}>
          <PageToolbar sticky offset={DEFAULT_OFFSET}>
            <KnowledgeBaseSearch />
          </PageToolbar>
          <Paper>
            <Centered height={750}>
              <Loader height={24} />
            </Centered>
          </Paper>
        </LimitedWidth>
      ) : docsInCategory ? (
        <LimitedWidth width={750}>
          <PageToolbar sticky offset={DEFAULT_OFFSET}>
            <KnowledgeBaseSearch />
          </PageToolbar>
          <Paper>
            {docsInCategory.category &&
            docsInCategory.groupNames &&
            docsInCategory.type === 'category' &&
            docsInCategory.groups ? (
              <CategoryList
                slug={slug}
                groupNames={docsInCategory.groupNames}
                category={docsInCategory.category}
                groups={docsInCategory.groups}
              />
            ) : selectedArticle ? (
              <Article article={selectedArticle} />
            ) : null}
          </Paper>
        </LimitedWidth>
      ) : (
        <Paper>No docs</Paper>
      )}
    </KnowledgeBasePageBody>
  );
};
