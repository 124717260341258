import { ButtonBase } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';
import { FileText } from 'react-feather';
import {
  Bar,
  ComposedChart,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { LegendLabel } from '../../../../../../components/Charts/Util';
import {
  formatCurrency,
  formatNumber
} from '../../../../../../components/Number';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { styled } from '../../../../../../emotion';
import { formatTimeKey } from '../../../../../../services/analytics';
import { useTheme } from '../../../../../../themes';
import { getAdvertiserColor } from '../../../../../../components/AdvertiserWithColor';
import { COLORS } from '../../../../../../domainTypes/colors';
import { ISOTimeRange } from '../../../../../../domainTypes/analytics_v2';
import { FreeFormTooltip } from '../../../../../../components/Charts/CustomTooltip';
import { ChartTooltip } from './Tooltip2';

export const REVISIONS_COLOR = COLORS.red.red5;
export const SERIES_COLOR = 'black';
export const COMPARE_SERIES_COLOR = '#999';

type Props = {
  timeseries: ChartData[];
  advertisers: string[];
  timeRange: ISOTimeRange;
  currency: CurrencyCode;
  mode: [LeftMode, RightMode];
  onModeChange: (nextMode: [LeftMode, RightMode]) => void;
  height: string | number;
};

export type ChartData = {
  tk: string;
  clicks: {
    prev: number;
    curr: number;
  };
  pageviews: {
    prev: number;
    curr: number;
  };
  ctr: {
    prev: number;
    curr: number;
  };
  earnings: Record<
    string,
    {
      partnerKey: string;
      advertiserName: string;
      earnings: number;
    }
  >;
  revisions: Array<{ index: number; note?: string; timestamp: Moment }>;
};

export type LeftMode = 'clicks' | 'pageviews' | 'ctr';
export type RightMode = 'earnings';

const CustomLegendContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f2f2f2;
  padding: 0 ${(p) => p.theme.spacing(1)}px;
  padding-top: ${(p) => p.theme.spacing(2)}px;
  margin-top: ${(p) => p.theme.spacing(2)}px;
`;

const CustomLegendSectionL = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: ${(p) => p.theme.spacing(2)}px;
`;

const CustomLegendSectionR = styled(CustomLegendSectionL)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Square = styled<'div', { color: string; active: boolean }>('div')`
  width: 12px;
  height: 12px;
  border: 3px solid ${(p) => p.color};
  background: ${(p) => (p.active ? p.color : 'none')};
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const Circle = styled<typeof Square, { color: string; active: boolean }>(
  Square
)`
  border-radius: 50%;
`;

const LegendElement = ({
  color,
  active,
  onClick,
  label,
  shape = 'circle'
}: {
  shape?: 'square' | 'circle';
  color: string;
  active: boolean;
  onClick?: () => void;
  label: string;
}) => {
  return (
    <ButtonBase onClick={onClick} disabled={!onClick}>
      {shape === 'circle' && <Circle color={color} active={active} />}
      {shape === 'square' && <Square color={color} active={active} />}
      <LegendLabel>{label}</LegendLabel>
    </ButtonBase>
  );
};

const CustomLegend = ({
  value,
  onChange
}: {
  value: [LeftMode, RightMode];
  onChange: (nextValue: [LeftMode, RightMode]) => void;
}) => {
  const [left, right] = value;
  return (
    <CustomLegendContainer>
      <CustomLegendSectionL>
        <LegendElement
          color={SERIES_COLOR}
          active={left === 'clicks'}
          onClick={() => onChange(['clicks', right])}
          label="Clicks"
        />
        <LegendElement
          color={SERIES_COLOR}
          active={left === 'pageviews'}
          onClick={() => onChange(['pageviews', right])}
          label="Pageviews"
        />
        <LegendElement
          color={SERIES_COLOR}
          active={left === 'ctr'}
          onClick={() => onChange(['ctr', right])}
          label="Page CTR"
        />
      </CustomLegendSectionL>
      <CustomLegendSectionR>
        <LegendElement
          color={REVISIONS_COLOR}
          active={true}
          label="Revisions"
        />
        <LegendElement
          shape="square"
          color={SERIES_COLOR}
          active={right === 'earnings'}
          onClick={() => onChange([left, 'earnings'])}
          label="Earnings"
        />
      </CustomLegendSectionR>
    </CustomLegendContainer>
  );
};

export const formatters = {
  clicks: (t: number) =>
    formatNumber({
      n: t,
      format: 'decimal',
      digits: 0
    }),
  pageviews: (t: number) =>
    formatNumber({
      n: t,
      format: 'decimal',
      digits: 0
    }),
  ctr: (t: number) => `${(Math.round(t * 10 * 100) / 10).toString()}%`,
  earnings: formatCurrency
};

export const earningsColor = (key: string) => {
  const [advertiserName, partnerKey] = key.split('---');
  return getAdvertiserColor(advertiserName, partnerKey);
};

export const Chart = ({
  timeseries,
  advertisers,
  timeRange,
  currency,
  mode,
  onModeChange,
  height
}: Props) => {
  const [leftMode, rightMode] = mode;

  const startsInCurrentYear = useMemo(
    () =>
      Boolean(
        timeRange.start &&
          moment(timeRange.start).format('YYYY') === moment().format('YYYY')
      ),
    [timeRange.start]
  );

  const theme = useTheme();
  const tickStyle = {
    fill: '#9b9b9b',
    fontSize: theme.custom.fontSize.m
  };

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer
        width="99%"
        height={height}
        aspect={height === 'auto' ? 2.2 : undefined}
      >
        <ComposedChart
          data={timeseries}
          layout="horizontal"
          maxBarSize={12}
          barGap={0}
        >
          <XAxis
            dataKey="tk"
            tickFormatter={(tk) =>
              formatTimeKey(
                tk || '',
                startsInCurrentYear ? 'MMM DD' : "MMM DD, 'YY"
              )
            }
            tick={{
              ...tickStyle,
              transform: 'translate(0, 8)'
            }}
            xAxisId="time"
            textAnchor="end"
            tickSize={0}
            minTickGap={60}
            stroke={'none'}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(-8, 0)`
            }}
            hide={leftMode !== 'clicks'}
            yAxisId="clicks"
            stroke="none"
            orientation="left"
            domain={[
              (min) => Math.max(Math.floor(min / 10) * 10 - 30, 0),
              (max) => Math.max(Math.floor(max / 10) * 10 + 10, 0)
            ]}
            tickFormatter={formatters.clicks}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(-8, 0)`
            }}
            hide={leftMode !== 'pageviews'}
            yAxisId="pageviews"
            stroke="none"
            orientation="left"
            domain={[
              (min) => Math.max(Math.floor(min / 10) * 10 - 100, 0),
              (max) => Math.max(Math.floor(max / 10) * 10 + 10, 0)
            ]}
            tickFormatter={formatters.pageviews}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(-8, 0)`
            }}
            hide={leftMode !== 'ctr'}
            yAxisId="ctr"
            stroke="none"
            orientation="left"
            tickFormatter={formatters.ctr}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(8, 0)`
            }}
            domain={[
              (min) => (min < 0 ? Math.floor(min / 10) * 10 : min),
              (max) => {
                const m = max > 0 ? Math.ceil(max / 10) * 10 : max;
                return m + m / 2;
              }
            ]}
            allowDecimals={false}
            yAxisId="earnings"
            stroke="none"
            tickSize={0}
            orientation={'right'}
            tickFormatter={(v) => formatters.earnings(v, currency)}
          />
          {advertisers.map((advertiserKey) => (
            <Bar
              yAxisId="earnings"
              xAxisId="time"
              stackId="1"
              name={advertiserKey}
              dataKey={(e: ChartData) => {
                return e.earnings[advertiserKey]?.earnings ?? 0;
              }}
              fill={earningsColor(advertiserKey)}
              isAnimationActive={false}
            />
          ))}
          {leftMode === 'pageviews' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="pageviews.prev"
              yAxisId="pageviews"
              xAxisId="time"
              stroke={COMPARE_SERIES_COLOR}
              strokeWidth={1.4}
              strokeDasharray="4, 2"
              dot={false}
            />
          )}
          {leftMode === 'pageviews' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="pageviews.curr"
              yAxisId="pageviews"
              xAxisId="time"
              stroke={SERIES_COLOR}
              strokeWidth={1.6}
              dot={false}
            />
          )}
          {leftMode === 'clicks' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="clicks.prev"
              yAxisId="clicks"
              xAxisId="time"
              stroke={COMPARE_SERIES_COLOR}
              strokeWidth={1.4}
              strokeDasharray="4, 2"
              dot={false}
            />
          )}
          {leftMode === 'clicks' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="clicks.curr"
              yAxisId="clicks"
              xAxisId="time"
              stroke={SERIES_COLOR}
              strokeWidth={1.6}
              dot={false}
            />
          )}
          {leftMode === 'ctr' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="ctr.prev"
              yAxisId="ctr"
              xAxisId="time"
              stroke={COMPARE_SERIES_COLOR}
              strokeWidth={1.4}
              strokeDasharray="4, 2"
              dot={false}
            />
          )}
          {leftMode === 'ctr' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="ctr.curr"
              yAxisId="ctr"
              xAxisId="time"
              stroke={SERIES_COLOR}
              strokeWidth={1.6}
              dot={false}
            />
          )}
          <ReferenceLine
            y={0}
            xAxisId="time"
            yAxisId={rightMode}
            stroke={tickStyle.fill}
          />
          {timeseries.map(
            (d) =>
              !!d.revisions.length && (
                <ReferenceDot
                  key={d.tk}
                  fill={REVISIONS_COLOR}
                  stroke="#FFF"
                  strokeWidth={2}
                  r={6}
                  yAxisId={leftMode}
                  xAxisId="time"
                  isFront={true}
                  y={d[leftMode].curr}
                  x={d.tk}
                  label={(p) => {
                    return (
                      <FileText
                        size={20}
                        strokeWidth={1.5}
                        x={p.viewBox.x + 2}
                        y={p.viewBox.y - 24}
                        fill="#FFF"
                      />
                    );
                  }}
                />
              )
          )}
          <Tooltip
            cursor={false}
            content={
              <FreeFormTooltip>
                {({ x }) => {
                  if (!x) {
                    return null;
                  }
                  const tk = `${x}`;
                  const data = timeseries.find((d) => d.tk === tk);
                  if (!data) {
                    return null;
                  }
                  return (
                    <ChartTooltip
                      startsInCurrentYear={startsInCurrentYear}
                      data={data}
                      trafficMetric={leftMode}
                    />
                  );
                }}
              </FreeFormTooltip>
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegend value={mode} onChange={onModeChange} />
    </div>
  );
};
