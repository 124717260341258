import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { omit } from 'lodash';
import React, { useMemo } from 'react';
import { DownloadCloud } from 'react-feather';
import { AnalyticsQuery } from '../../domainTypes/analytics_v2';
import { ExportReportType } from '../../domainTypes/export';
import { css, styled } from '../../emotion';
import { useDialogState } from '../../hooks/useDialogState';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useCurrentUser } from '../../services/currentUser';
import { callFirebaseFunction } from '../../services/firebaseFunctions';
import { CF } from '../../versions';
import { ButtonWithPromise } from '../ButtonWithPromise';

const AlertImg = styled('img')`
  max-width: 80%;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing(4)}px;
`;

interface ExportQueryDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  reportType: ExportReportType;
  query: AnalyticsQuery;
}

export const ExportQueryDialog: React.FC<ExportQueryDialogProps> = ({
  open,
  onClose,
  title,
  query,
  reportType,
  children
}) => {
  const { space } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="p" paragraph>
          The data export will export all rows that match the currently selected
          filters and timeframe, along with selected fields.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Exporting data can take anywhere from a few seconds to several
          minutes, depending on the number of rows in the export. You will
          receive an <strong>Alert</strong> when the export is ready, like this:
        </Typography>
        <AlertImg src="/images/alert-example.png" alt="Alert Example" />
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <ButtonWithPromise
          pending="Triggering export..."
          variant="contained"
          color="primary"
          onClick={async () => {
            await callFirebaseFunction(CF.files.exportQuery, {
              spaceId: space.id,
              query,
              reportType
            });
            enqueueSnackbar(
              'Export triggered successfully. Watch for the download alert.',
              {
                variant: 'success'
              }
            );
            onClose();
          }}
        >
          Trigger export
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

interface ExportQueryButtonProps {
  title: string;
  reportType: ExportReportType;
  query: AnalyticsQuery;
}

export const ExportQueryButton: React.FC<ExportQueryButtonProps> = ({
  query,
  reportType,
  title,
  children
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  return (
    <>
      <Tooltip title={title} placement="top">
        <IconButton
          onClick={openDialog}
          className={css((t) => ({
            padding: t.spacing(1)
          }))}
        >
          <DownloadCloud size={18} />
        </IconButton>
      </Tooltip>
      <ExportQueryDialog
        query={query}
        onClose={closeDialog}
        title={title}
        reportType={reportType}
        open={dialogOpen}
      >
        {children}
      </ExportQueryDialog>
    </>
  );
};

export const useExportQuery = (q: AnalyticsQuery) =>
  useMemo<AnalyticsQuery>(() => {
    return omit(q, ['paginate', 'compare']);
  }, [q]);
