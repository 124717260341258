import {
  queryParamToList,
  setToQueryParam,
  useQueryParam
} from '../../../routes';
import { MultiSelector } from '../../MultiSelector';
import React, { useCallback, useMemo } from 'react';
import { PlatformWithColor } from '../../PlatformWithColor';
import { AnalyticsFilter } from '../../../domainTypes/analytics_v2';
import { usePlatformsWithLinkCounts } from '../service/platform';
import { isEmpty } from 'lodash';
import { useTimeframe } from '../Timeframe';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { Typography } from '@material-ui/core';
import { ChevronDown } from 'react-feather';

const unknownPlatformKey = '__UNKNOWN__';

const normalizePlatformKeys = (platforms: string[]) =>
  platforms.map((p) => (p === unknownPlatformKey ? '' : p));

const paramName = 'platform';

const usePlatformSelectorState = (paramName: string) => {
  return useQueryParam<Set<string>>(
    paramName,
    (p) => new Set(p ? queryParamToList(p) : []),
    setToQueryParam
  );
};

export const PlatformSelector = ({
  filters = EMPTY_ARR
}: {
  filters?: AnalyticsFilter[];
}) => {
  const { range } = useTimeframe();
  const [platforms, setPlatforms] = usePlatformSelectorState(paramName);
  const [allPlatforms = []] = usePlatformsWithLinkCounts(filters, range);
  const options = useMemo(() => {
    return allPlatforms.map((platform) => ({
      label: <PlatformWithColor partner={platform} />,
      value: isEmpty(platform.key) ? unknownPlatformKey : platform.key
    }));
  }, [allPlatforms]);

  const reset = useCallback(() => {
    setPlatforms(new Set());
  }, [setPlatforms]);

  const onChange = useCallback(
    (next: Set<string>) => {
      if (next.size === allPlatforms.length) {
        reset();
      } else {
        setPlatforms(next);
      }
    },
    [allPlatforms.length, reset, setPlatforms]
  );

  const effectiveSet = useMemo(
    () =>
      platforms.size === 0
        ? new Set(allPlatforms.map((p) => p.key))
        : platforms,
    [allPlatforms, platforms]
  );

  if (allPlatforms.length === 0) {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        Platforms
        <ChevronDown size={18} />
      </Typography>
    );
  }

  return (
    <MultiSelector
      allOption={<strong>All platforms</strong>}
      allowFocusing
      value={effectiveSet}
      onChange={onChange}
      options={options}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        Platforms{' '}
        <strong>
          {effectiveSet.size} of {options.length}
        </strong>
        <ChevronDown size={18} />
      </Typography>
    </MultiSelector>
  );
};

export const usePlatformFilter = (): AnalyticsFilter | null => {
  const [platforms] = usePlatformSelectorState(paramName);
  return useMemo(() => {
    if (platforms.size === 0) return null;
    return {
      field: 'pk',
      values: normalizePlatformKeys([...platforms]),
      condition: 'in'
    };
  }, [platforms]);
};
