import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../../services/features';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { TopBar } from './components/TopBar';
import { ContentWrapper } from './layout';
import {
  useReferrerGroupTimeseries,
  useReferrerGroupTotals
} from './services/referrer-groups';
import { useTrackMixpanelView } from '../../../../../services/mixpanel';
import { TotalsBar } from './components/TotalsBar';
import { TotalNumbers } from './components/TotalNumbers';
import { GroupsTable } from './components/GroupsTable';
import { GroupsTimeseriesChart } from './components/GroupsTimeseriesChart';
import { useReportMetric } from './services/report-metric';
import { TrafficSourcesUpgradePrompt } from '../components/TrafficSourcesUpgradePrompt';

const ReferrersBody = () => {
  const [metric] = useReportMetric();

  const totals = useReferrerGroupTotals(metric);
  const timeseries = useReferrerGroupTimeseries(metric);

  return (
    <ContentWrapper>
      <TotalsBar data={totals} />
      <TotalNumbers totals={totals} />
      <GroupsTimeseriesChart data={timeseries} />
      <GroupsTable />
    </ContentWrapper>
  );
};

export const ReferrersOverview = () => {
  useTrackMixpanelView('view_referrers', { section: 'overview' });
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.traffic_sources.view'
  ]);
  // Eventually we will have a third state, which is an "upgrade" CTA
  // for users who have permissions for the report, but not the feature toggle on
  const hasTrafficSourcesEnabled = useFeatureEnabled('REFERRER_REPORTS_V1');

  return (
    <>
      <Helmet>
        <title>Referrers | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        {canView && hasTrafficSourcesEnabled ? (
          <>
            <TopBar breadcrumbs={[{ title: 'Referrer groups' }]} />
            <ReferrersBody />
          </>
        ) : !hasTrafficSourcesEnabled ? (
          <TrafficSourcesUpgradePrompt />
        ) : (
          <NoPermissions />
        )}
      </ContentPageBody>
    </>
  );
};
