import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ALL_USER_LEVEL_SCOPES,
  analyzeUserLevelScopes,
  collectUserLevelScopesForUser,
  PermissionStatus,
  UserLevelScope
} from '../../domainTypes/permissions';
import { ICurrentUser } from '../../domainTypes/user';
import { useAdminOrImpersonatorClaim } from '../auth';

let currentUser: ICurrentUser | null = null;
type CurrentUserListener = (nextCurrentUser: ICurrentUser | null) => void;

const listeners: CurrentUserListener[] = [];

export const setCurrentUser = (nextCurrentUser: ICurrentUser | null) => {
  currentUser = nextCurrentUser;
  listeners.forEach((listener) => listener(nextCurrentUser));
};

// This should only be called after setCurrentUser was called with a non-null value!
export const getCurrentUser = () => {
  return currentUser!;
};

export const getCurrentSpace = () => {
  return currentUser!.space;
};

const listenToCurrentUserChanges = (
  listener: CurrentUserListener
): (() => void) => {
  listeners.push(listener);
  return () => {
    const index = listeners.indexOf(listener);
    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };
};

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(
    getCurrentUser()
  );

  useEffect(() => listenToCurrentUserChanges(setCurrentUser), []);
  // Can never be null - as this is just used inside the app,
  // if it ever IS null, then we would immediately move to the Login page anyway
  return currentUser!;
};

// Returns a tuple, where the first element is a simple boolean to indicate
// whether access is given. The second element can be used to provide
// additional information if need be - e.g. tell the user which scopes are missing etc.
export const useHasCurrentUserRequiredScopes = (
  requiredScopes: UserLevelScope[]
): [
  boolean,
  {
    status: PermissionStatus;
    present: UserLevelScope[];
    missing: UserLevelScope[];
  }
] => {
  const currentUser = useCurrentUser();
  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();
  const prev = useRef<UserLevelScope[]>([]);

  // As this is called in so many places we're doing some crafy stuff here
  // to avoid this useMemo being triggered all the times.
  // - We're checking the passed in requiredScopes with isEqual - as they are arrays
  //   they will frequently be unstable, when in practice they never really change.

  const haveScopesChanged = isEqual(prev.current, requiredScopes);
  prev.current = requiredScopes;

  return useMemo(() => {
    // Inside the admin app
    if (!currentUser) {
      return [true] as any;
    }
    const result = analyzeUserLevelScopes(
      currentUser.space.permissionsV2,
      currentUser.id,
      requiredScopes
    );
    if (isAdminOrImpersonator) {
      return [
        true,
        {
          status: PermissionStatus.ALLOWED,
          present: ALL_USER_LEVEL_SCOPES,
          missing: []
        }
      ];
    }
    return [result.status === PermissionStatus.ALLOWED, result];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveScopesChanged, currentUser, isAdminOrImpersonator]);
};

// While it's generally useful to use useHasCurrentUserRequiredScopes
// there might be use cases where we need want to much more generally ask
// what scopes are present - for this case all given scopes can be returned
// as a simple Set
export const useCurrentUserScopes = () => {
  const currentUser = useCurrentUser();
  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();
  const currentUserId = currentUser.id;
  const permissions = currentUser.space.permissionsV2;
  return useMemo(() => {
    if (isAdminOrImpersonator) {
      return new Set(ALL_USER_LEVEL_SCOPES);
    }
    const givenScopes = isAdminOrImpersonator
      ? ALL_USER_LEVEL_SCOPES
      : collectUserLevelScopesForUser(permissions, currentUserId);
    return new Set(givenScopes);
  }, [currentUserId, permissions, isAdminOrImpersonator]);
};
