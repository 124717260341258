import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { PlatformFilterDef } from '../../../../domainTypes/filters';
import { useMappedLoadingValue } from '../../../../services/db';
import { PlatformWithColor } from '../../../PlatformWithColor';
import { toSearchRegexp } from '../../../SearchInput';
import {
  usePlatformsWithCommission,
  usePlatformsWithLinkCounts
} from '../../service/platform';
import {
  createPlatformFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { OptionsList, SelectorLoader, SelectorShell, toggle } from './Selector';

interface PlatformMenuBodyProps {
  onChange: (value: Array<string>) => void;
  onSave: (v: PlatformFilterDef) => void;
  filters: AnalyticsFilter[];
  value: Array<string>;
  range: ISOTimeRange;
}

const PlatformMenuBody: React.FC<PlatformMenuBodyProps> = ({
  onChange,
  filters,
  range,
  onSave,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useMappedLoadingValue(
    usePlatformsWithLinkCounts(filters, range),
    (platforms) =>
      platforms.map((platform) => ({
        label: <PlatformWithColor partner={platform} />,
        value: platform.key,
        searchValue: platform.name
      }))
  );

  const _toggle = useCallback(
    (platform: string) => onChange(toggle(value, platform)),
    [onChange, value]
  );

  const focus = useCallback(
    (platform: string) => onSave(createPlatformFilterDefinition([platform])),
    [onSave]
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  return (
    <SelectorShell label="Platforms" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          toggle={_toggle}
          focus={focus}
        />
      )}
    </SelectorShell>
  );
};

const PlatformMenuBodyForTransactions: React.FC<PlatformMenuBodyProps> = ({
  onChange,
  filters,
  onSave,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useMappedLoadingValue(
    usePlatformsWithCommission(filters),
    (platforms) =>
      platforms.map((platform) => ({
        label: <PlatformWithColor partner={platform} />,
        value: platform.key,
        searchValue: platform.name
      }))
  );

  const _toggle = useCallback(
    (platform: string) => onChange(toggle(value, platform)),
    [onChange, value]
  );

  const focus = useCallback(
    (platform: string) => onSave(createPlatformFilterDefinition([platform])),
    [onSave]
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  return (
    <SelectorShell label="Platforms" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          toggle={_toggle}
          focus={focus}
          caption={`Top platforms by earnings`}
        />
      )}
    </SelectorShell>
  );
};

export const PlatformMenu: DimensionMenuComponent<PlatformFilterDef> = ({
  definition,
  onSave,
  filters,
  range,
  renderContext
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<PlatformFilterDef>(
    () => createPlatformFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  console.log('RENDER CONTEXT', renderContext);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="platform">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        {renderContext?.page === 'TRANSACTIONS' ? (
          <PlatformMenuBodyForTransactions
            filters={filters}
            onSave={onSave}
            onChange={setValue}
            value={value}
            range={range}
          />
        ) : (
          <PlatformMenuBody
            filters={filters}
            onSave={onSave}
            onChange={setValue}
            value={value}
            range={range}
          />
        )}
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('platform', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
