import { compact, isEqual, uniqBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange,
  AnalyticsQuery
} from '../../../../domainTypes/analytics_v2';
import { AdvertiserFilterDefinition } from '../../../../domainTypes/filters';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { AdvertiserWithColor } from '../../../AdvertiserWithColor';
import {
  createAdvertiserFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { OptionsList, SelectorLoader, SelectorShell, toggle } from './Selector';

interface AdvertiserMenuBodyProps {
  onChange: (value: Array<string>) => void;
  onSave: (v: AdvertiserFilterDefinition) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  value: Array<string>;
}

const LIMIT = 50;

const useAdvertisers = (
  filters: AnalyticsFilter[],
  range: ISOTimeRange,
  search: string
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['commission_sum_net'], // only present in sales table
      groupBy: ['pk', 'advertiser_name'],
      filters: compact([
        ...filters,
        search && {
          field: 'advertiser_name',
          condition: 'ilike',
          pattern: `%${search}%`
        }
      ]),
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
      paginate: {
        page: 1,
        limit: LIMIT
      }
    };
  }, [filters, range, search]);

  return useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (res) => {
    // Behold the uniqBy
    // advertiser_name is not unique across pks.
    // The filter does ONLY filter by advertiser_name though, so we just
    // ignore this fact in this case.
    // This is fine, as long as we don't start showing any numbers next to the individial
    // options which then would be misleading.
    return uniqBy(
      res.rows.map((row) => {
        const advertiserName = row.group.advertiser_name;
        const pk = row.group.pk;
        return {
          label: (
            <AdvertiserWithColor
              partnerKey={pk}
              advertiserName={advertiserName}
            />
          ),
          value: advertiserName || 'Unknown',
          searchValue: advertiserName
        };
      }),
      (x) => x.value
    );
  });
};

const AdvertiserMenuBody: React.FC<AdvertiserMenuBodyProps> = ({
  range,
  onChange,
  filters,
  onSave,
  value
}) => {
  const [search, setSearch] = useState('');
  const [options, loading] = useAdvertisers(filters, range, search);

  const _toggle = useCallback(
    (platform: string) => onChange(toggle(value, platform)),
    [onChange, value]
  );

  const focus = useCallback(
    (advertiser: string) =>
      onSave(createAdvertiserFilterDefinition([advertiser])),
    [onSave]
  );

  // const maybeHasMore = options.length === LIMIT;

  return (
    <SelectorShell label="Advertisers" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={options}
          selectedValues={value}
          toggle={_toggle}
          focus={focus}
          caption={`Top advertisers by earnings`}
        />
      )}
    </SelectorShell>
  );
};

export const AdvertiserMenu: DimensionMenuComponent<AdvertiserFilterDefinition> = ({
  definition,
  onSave,
  filters,
  range
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<AdvertiserFilterDefinition>(
    () => createAdvertiserFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="advertiser">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <AdvertiserMenuBody
          filters={filters}
          range={range}
          onSave={onSave}
          onChange={setValue}
          value={value}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('advertiser', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
