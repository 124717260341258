import React, { ReactNode } from 'react';
import {
  Bar,
  BarChart,
  TickFormatterFunction,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { CustomTooltip } from '../../../../../../components/Charts/CustomTooltip';
import { EarningMetric } from '../../service';
import { WithShape } from '../../../../../../components/Charts/Util';
import { CHART_HEIGHT, EARNINGS_COLOR } from './index';
import { ChartData } from './chart-data';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { formatMetric } from '../../../../../../services/analyticsV2/metrics';

interface ChartProps {
  data: Array<ChartData>;
  labelFormatter: (
    label: TooltipProps['label'],
    metric: EarningMetric
  ) => ReactNode;
  xAxisTickFormatter: TickFormatterFunction;
  currency: CurrencyCode;
  metric: EarningMetric;
}

export const realtimeEarningsChart = ({
  data,
  labelFormatter,
  xAxisTickFormatter,
  metric,
  currency
}: ChartProps) => (
  <BarChart
    width={730}
    syncId="realtimeChartId"
    height={CHART_HEIGHT}
    data={data}
  >
    <Tooltip
      cursor={false}
      content={<CustomTooltip />}
      formatter={(value) => {
        return [
          formatMetric(value as number, metric, currency),
          <WithShape color={EARNINGS_COLOR} small>
            All sites
          </WithShape>
        ];
      }}
      labelFormatter={(label) => labelFormatter(label, metric)}
    />
    <XAxis
      dataKey="timestamp"
      minTickGap={120}
      tickLine={false}
      tickFormatter={xAxisTickFormatter}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(0, 8)',
        fontSize: 14
      }}
      textAnchor="end"
      tickSize={0}
      stroke="cwBBB"
    />
    <YAxis
      yAxisId="earnings"
      tickLine={false}
      tickFormatter={(d) => formatMetric(d, metric, currency)}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(8, 0)',
        fontSize: 14
      }}
      tickSize={0}
      orientation="right"
      stroke="cwBBB"
    />

    <Bar
      dataKey="earnings"
      yAxisId="earnings"
      fill={EARNINGS_COLOR}
      isAnimationActive={false}
    />
  </BarChart>
);
