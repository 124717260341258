import { useQueryParam } from '../../routes';
import { useCallback, useMemo } from 'react';
import { useCurrentUser } from '../../services/currentUser';
import { defaultTimeframe } from '../../components/TimeframePicker/service/options';
import { getTimeframeMoments, toMoments } from './toMoments';
import { parseTimeframe } from './parseTimeframe';
import { Timeframe } from '../../domainTypes/analytics';
import moment from 'moment-timezone';
import { Query } from '../../domainTypes/routes';
import {
  printTimeframe,
  TimeframeDefinition
} from '../../domainTypes/timeframe';
import { AnalyticsRanges } from '../../components/analytics_v2/Timeframe';

const DEFAULT_PARAM_NAME = 'timeframe';

export const useTimeframeDefinitionFromUrl = (
  paramName: string = DEFAULT_PARAM_NAME
) => {
  return useQueryParam<TimeframeDefinition>(
    paramName,
    (p) => parseTimeframe(p) || defaultTimeframe,
    printTimeframe
  );
};

export const toTimeRanges = (
  timeframe: TimeframeDefinition,
  tz: string
): AnalyticsRanges => {
  const { start, end, compare } = toMoments(timeframe, tz);
  return {
    range: {
      start: start.toISOString(),
      end: end.toISOString()
    },
    compare: compare
      ? {
          range: {
            start: compare.start.toISOString(),
            end: compare.end.toISOString()
          }
        }
      : undefined
  };
};

export const useTimeRanges = (
  paramName: string = DEFAULT_PARAM_NAME
): AnalyticsRanges => {
  const [timeframe] = useTimeframeDefinitionFromUrl(paramName);
  const { tz } = useCurrentUser();
  return useMemo(() => toTimeRanges(timeframe, tz), [timeframe, tz]);
};

export const useHasComparison = () => {
  const [timeframe] = useTimeframeDefinitionFromUrl();
  return useMemo(() => timeframe.comparison.kind !== 'disabled', [
    timeframe.comparison
  ]);
};

const fromLegacyTf = (tf: Timeframe): TimeframeDefinition => {
  const start = moment(tf.start).tz(tf.tz).startOf('day').toISOString();
  const end = moment(tf.end).tz(tf.tz).endOf('day').toISOString();
  return {
    range: {
      kind: 'custom',
      start: start,
      end: end
    },
    comparison: {
      kind: 'previous'
    }
  };
};

/*
 * This hook is used to convert the legacy timeframe object to the new timeframe object.
 */

export const useLegacyTimeframe = (
  paramName: string = DEFAULT_PARAM_NAME
): [Timeframe, (tf: Timeframe) => void, (tf: Timeframe) => Query] => {
  const { tz } = useCurrentUser();
  const [timeframe, setTimeframe] = useTimeframeDefinitionFromUrl(paramName);

  const legacyTimeframe = useMemo(() => {
    const moments = getTimeframeMoments(timeframe.range, tz);
    return {
      start: moments.start.format('YYYY-MM-DD'),
      end: moments.end.format('YYYY-MM-DD'),
      tz
    };
  }, [timeframe, tz]);

  const setCustomTimeframe = useCallback(
    (tf: Timeframe) => {
      setTimeframe(fromLegacyTf(tf));
    },
    [setTimeframe]
  );

  const toQueryParams = useCallback(
    (tf: Timeframe) => ({
      [paramName]: printTimeframe(fromLegacyTf(tf))
    }),
    [paramName]
  );

  return [legacyTimeframe, setCustomTimeframe, toQueryParams];
};
