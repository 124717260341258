import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { some } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { TooltipProps } from 'recharts';
import { Loader } from '../../../../../../components/Loader';
import {
  AnalyticsInterval,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { metricTitle } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { COLOR_SCALE } from '../../../../../../services/color';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { RealtimeMetric, useRealtimeFilters } from '../../service';
import { formatChartData } from './chart-data';
import { useChartEarningsMetric, useChartTrafficMetric } from './metrics';
import { RealtimeChartCard } from './RealtimeChartCard';
import { realtimeEarningsChart } from './RealtimeEarningsChart';
import { RealtimeEarningsChartDetails } from './RealtimeEarningsChartDetails';
import { realtimeTrafficChart } from './RealtimeTrafficChart';
import { RealtimeTrafficChartDetails } from './RealtimeTrafficChartDetails';
import { useSpaceChannels } from '../../../../../../services/channels/channels';
import { useChannelIdGrouper } from '../../../../../../services/analyticsV2/groups';

export const EARNINGS_COLOR = COLOR_SCALE[8];
export const CHART_HEIGHT = 250;

const LOADER_HEIGHT = 400;

const useTimeseries = (range: ISOTimeRange, interval: AnalyticsInterval) => {
  const { space } = useCurrentUser();
  const [trafficMetric] = useChartTrafficMetric();
  const [earningsMetric] = useChartEarningsMetric();
  const { filters } = useRealtimeFilters();
  const { groupBy, columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      interval,
      filters,
      select: [trafficMetric, earningsMetric],
      groupBy: groupBy,
      orderBy: [
        {
          field: 'interval',
          direction: 'ASC'
        }
      ],
      columnTransformers: columnTransformers(space)
    }),
    [
      range,
      interval,
      filters,
      trafficMetric,
      earningsMetric,
      groupBy,
      columnTransformers,
      space
    ]
  );
  return useAnalyticsQueryV2(space.id, query);
};

export const RealtimeChart = ({ range }: { range: ISOTimeRange }) => {
  const currency = useSpaceCurrency();
  const [trafficMetric] = useChartTrafficMetric();
  const [earningsMetric] = useChartEarningsMetric();
  const userTimezone = useMemo(() => moment.tz.guess(), []);

  const interval = useMemo(() => {
    const diffH = Math.abs(moment(range.start).diff(moment(range.end), 'h'));
    return {
      unit: 'minute' as const,
      value: diffH === 1 ? 5 : diffH <= 12 /* or 6? */ ? 10 : 30,
      tz: userTimezone
    };
  }, [range, userTimezone]);

  const labelFormatter = useCallback(
    (label: TooltipProps['label'], metric: RealtimeMetric) => {
      const m = moment(new Date(label as number));
      return (
        <div>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {m.format('ddd MMM DD, LT')} -{' '}
            {m.clone().add(interval.value, interval.unit).format('LT')}
          </Typography>
          <Typography variant="body2">{metricTitle(metric)}</Typography>
        </div>
      );
    },
    [interval.unit, interval.value]
  );

  const xAxisTickFormatter = useCallback(
    (d) => {
      return interval.value === 1
        ? moment(new Date(d)).format('HH:mm')
        : moment(new Date(d)).format('ha');
    },
    [interval.value]
  );

  const [timeseries, loadingTimeseries, timeseriesError] = useTimeseries(
    range,
    interval
  );
  const [channels, loadingChannels, channelsError] = useSpaceChannels();

  if (timeseriesError || channelsError) {
    return <Card>An error occurred</Card>;
  }

  if ((loadingTimeseries && !timeseries) || (loadingChannels && !channels)) {
    return (
      <Card>
        <Loader height={LOADER_HEIGHT} size={24} />
      </Card>
    );
  }

  if (!timeseries || !channels) {
    // cannot be reached
    return null;
  }

  const chartData = formatChartData(
    timeseries.rows,
    trafficMetric,
    earningsMetric
  );

  /*
   NOTE:
   It is possible that some untracked origin has sales data but no traffic data.
   Let's remove those data points from traffic chart series, so they don't come up on this chart as lines ath chart bottom.
  */
  const channelIdsWithAnyTrafficData = channels.filter((c) =>
    some(chartData, (d) => !!d.traffic[c.id])
  );

  return (
    <>
      <RealtimeChartCard
        chart={realtimeTrafficChart({
          currency,
          metric: trafficMetric,
          data: chartData,
          labelFormatter: labelFormatter,
          xAxisTickFormatter: xAxisTickFormatter,
          channels: channelIdsWithAnyTrafficData
        })}
        details={
          <RealtimeTrafficChartDetails
            channels={channelIdsWithAnyTrafficData}
          />
        }
      />
      <br />
      <RealtimeChartCard
        chart={realtimeEarningsChart({
          metric: earningsMetric,
          currency,
          data: chartData,
          labelFormatter: labelFormatter,
          xAxisTickFormatter: xAxisTickFormatter
        })}
        details={<RealtimeEarningsChartDetails />}
      />
    </>
  );
};
