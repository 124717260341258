import isPropValid from '@emotion/is-prop-valid';
import { Card } from '@material-ui/core';
import { styled } from '../../../emotion';

export const OverflowingCard = styled(Card, {
  shouldForwardProp: (props) => isPropValid(props)
})<{ highlight?: boolean }>`
  && {
    overflow: visible;
    background-color: ${(p) => (p.highlight ? `#f5fbff` : '#FFF')};
    border: ${(p) => (p.highlight ? `1px solid #d3e6f8` : 'none')};
  }
`;
