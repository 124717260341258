import Typography from '@material-ui/core/Typography';
import { ILegendItem, Legend } from '../../../../../../components/Charts/Util';
import React from 'react';
import { useChartTrafficMetric } from './metrics';
import { trafficMetrics } from '../../service';
import { FlexContainer } from '../../../../../../layout/Flex';
import Tooltip from '@material-ui/core/Tooltip';
import { metricTitle } from '../../../../../../services/analyticsV2/metrics';
import { Channel } from '../../../../../../services/channels/channels';
import { ChannelLabel } from '../../../../../../services/channels/ChannelLabel';

const TrafficMetricPicker = () => {
  const [trafficMetric, selectTrafficMetric] = useChartTrafficMetric();
  const items: ILegendItem[] = trafficMetrics.map((metric) => ({
    label: metricTitle(metric),
    shape: 'circle',
    color: '#000',
    active: trafficMetric === metric,
    onClick: () => selectTrafficMetric(metric)
  }));

  return (
    <div style={{ marginLeft: '-6px' }}>
      <Legend items={items} />
    </div>
  );
};

const channelLabel = (channel: Channel) => (
  <ChannelLabel key={channel.id} channel={channel} />
);

const ChannelsLegend = ({ channels }: { channels: Channel[] }) => {
  const limit = 5;
  const overLimit = channels.length > limit;
  const visibleChannels = overLimit ? channels.slice(0, limit - 1) : channels;
  return (
    <div>
      <div>
        {visibleChannels.map(channelLabel)}
        {overLimit && (
          <Tooltip title={channels.slice(limit - 1).map(channelLabel)}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ cursor: 'pointer' }}
            >
              ...and {channels.length - limit + 1} more
            </Typography>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const RealtimeTrafficChartDetails = ({
  channels
}: {
  channels: Channel[];
}) => (
  <FlexContainer
    direction="column"
    justifyContent="space-between"
    alignItems="flex-start"
  >
    <div>
      <Typography variant="body1" component="p">
        <strong>Realtime engagement</strong>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        Engagement across your sites' affiliate content and links.
      </Typography>
    </div>
    <div>
      <ChannelsLegend channels={channels} />
    </div>
    <div>
      <strong>Engagement by</strong>
      <TrafficMetricPicker />
    </div>
  </FlexContainer>
);
