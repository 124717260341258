import { Card, IconButton, Tooltip } from '@material-ui/core';
import { scaleLinear } from 'd3-scale';
import { compact, keyBy, max, padStart, truncate } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  Divide,
  DollarSign,
  ExternalLink as IconOpenNewWindow,
  Layout as AllDevices,
  Monitor,
  MousePointer,
  ShoppingCart,
  Smartphone,
  Trash2
} from 'react-feather';
import { Ctr } from '../../../../../../components/Ctr';
import {
  IAnnotation,
  IAnnotationProps,
  IImageInfo,
  ImageWithAnnotations
} from '../../../../../../components/ImageWithAnnotations';
import { Currency, Number } from '../../../../../../components/Number';
import {
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  SelectableField
} from '../../../../../../domainTypes/analytics_v2';
import { Doc } from '../../../../../../domainTypes/document';
import { IPageScreenshot } from '../../../../../../domainTypes/page';
import { toCleanHref } from '../../../../../../domainTypes/performance-minimal';
import {
  INarrowProductWithCountsAndTrendsAndOccurrencesAndSales,
  IPostgresProduct
} from '../../../../../../domainTypes/product';
import { ISpace } from '../../../../../../domainTypes/space';
import { ProductLinkPosition } from '../../../../../../domainTypes/tracking';
import { css, styled } from '../../../../../../emotion';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import { usePromise } from '../../../../../../hooks/usePromise';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT_LARGE
} from '../../../../../../layout/PageToolbar';
import {
  getClickRatio,
  getEpc,
  getViewRatio
} from '../../../../../../services/analytics';
import { queryAnalyticsV2 } from '../../../../../../services/analyticsV2/query';
import { useHasCurrentUserRequiredScopes } from '../../../../../../services/currentUser';
import { pluralize } from '../../../../../../services/pluralize';
import { getProductsByIdPg } from '../../../../../../services/products';
import { ISOTimeRange, Timestamp } from '../../../../../../services/time';
import { withoutTrailingSlashAtAnyPosition } from '../../../../../../services/url';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { RevisionCreateIconButton } from '../../../../components/RevisionCreateDialog';
import { RegenerateScreenshotDialog } from './RegenerateScreenshotDialog';

export type IHeatmapAnnotationDimension = Extract<
  SelectableField,
  'c' | 'epc_net' | 'commission_sum_net' | 'gmv_sum_net'
>;

const HighlightedMetric = styled<
  'div',
  { selectedDimension: boolean; hasSales: boolean }
>('div')`
  background-color: ${(p) =>
    p.selectedDimension && p.hasSales
      ? '#ff4d4f87'
      : p.selectedDimension && !p.hasSales
      ? '#1890ff9e'
      : 'transparent'};
  padding: 2px 6px 2px 3px;
  border-radius: 8px;
`;

const getCenter = <
  T extends { top: number; left: number; height: number; width: number }
>(
  position: T
): { x: number; y: number } => {
  return {
    x: position.left + position.width / 2,
    y: position.top + position.height / 2
  };
};

const DARK_BG = '#18202c';

type ProductMap = {
  [productId: string]: INarrowProductWithCountsAndTrendsAndOccurrencesAndSales;
};

const Circle = styled<'div', { selected: boolean; hasSales: boolean }>('div')`
  color: ${(p) => p.theme.custom.colors.secondary.contrastText};
  opacity: ${(p) => {
    return p.selected ? 0.7 : 0.5;
  }};
  border: ${(p) => {
    return p.selected ? `3px solid ${DARK_BG}` : 'none';
  }};
  background-color: ${(p) => (p.hasSales ? '#ff4d4f' : '#1890ff')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BoldNumber = styled('div')`
  display: inline-block;
  font-weight: 700;
  margin-right: ${(p) => p.theme.spacing(1) / 2}px;
  margin-left: ${(p) => p.theme.spacing(1) / 2}px;
`;

const Popover = styled('div')`
  position: fixed;
  bottom: 24px;
`;

const Annotation: React.FC<
  {
    size: number;
    hasSales: boolean;
  } & IAnnotationProps
> = ({ hasSales, size, selected, onSelect }) => {
  return (
    <div>
      <Circle
        style={{ width: size, height: size }}
        selected={selected}
        hasSales={hasSales}
        onClick={() => {
          return onSelect(!selected);
        }}
      />
    </div>
  );
};

export type HeatmapAnnotationData = {
  link: ProductLinkPosition;

  product: {
    id: string;
    name: string;
    url: string;
  };
  // Maybe keep using ICounterWithTrend
  data: Pick<
    AnalyticsResponseRowWithComparison['data'],
    | 'p'
    | 's'
    | 'v'
    | 'c'
    | 'epc_net'
    | 'gmv_sum_net'
    | 'commission_sum_net'
    | 'commission_count_net'
  >;
};

const findProduct = (
  link: ProductLinkPosition,
  productMap: ProductMap,
  productsByUrl: {
    [url: string]: INarrowProductWithCountsAndTrendsAndOccurrencesAndSales;
  }
) => {
  if (link.productId) {
    return productMap[link.productId];
  }

  // When we match URLs to what's on the page, try
  // several different variants to handle weird encoding

  const url = withoutTrailingSlashAtAnyPosition(link.href);

  // Ignore the incorrectly encoded postSlug parameter
  // in both URLS

  if (productsByUrl[url]) {
    return productsByUrl[url];
  }

  const cleanUrl = toCleanHref(url, {
    trimTrailingSlash: true,
    trimDanglingQuestionMark: false
  });

  if (productsByUrl[cleanUrl]) {
    return productsByUrl[cleanUrl];
  }

  // This is special handling for Insider, who added
  // this additional parameter to their links which we
  // did not get a chance to properly URL encode
  if (cleanUrl.includes('postSlug')) {
    const pSlugVal = new URL(cleanUrl).searchParams.get('postSlug');
    if (!pSlugVal) {
      return null;
    }

    const withDecodedPostSlug = cleanUrl.replace(
      encodeURIComponent(pSlugVal),
      pSlugVal
    );

    // Here we use "startsWith" because sometimes they have
    // another ?tag=bisafetynet2-20 at the end when
    // the page is loaded
    const matchingValue = Object.values(productsByUrl).find((u) =>
      u.url.startsWith(withDecodedPostSlug)
    );

    return matchingValue;
  }

  const decoded = withoutTrailingSlashAtAnyPosition(
    decodeURIComponent(cleanUrl)
  );

  return productsByUrl[decoded];
};

export type HeatmapDeviceOptions = 'desktop' | 'mobile' | 'all';

export const useHeatmapAnalyticsData = (
  spaceId: string,
  pageUrl: string,
  range: ISOTimeRange,
  device: HeatmapDeviceOptions = 'all'
) => {
  const q = useMemo<AnalyticsQuery>(
    () => ({
      range,
      filters: compact([
        { field: 'page_url', condition: 'in', values: [pageUrl] },
        device === 'desktop' && {
          field: 'device',
          condition: 'in',
          values: ['desktop']
        },
        device === 'mobile' && {
          field: 'device',
          condition: 'in',
          values: ['mobile', 'tablet']
        }
      ]),
      groupBy: ['link_id', 'link_occ'],
      select: [
        'p',
        's',
        'v',
        'c',
        'epc_net',
        'gmv_sum_net',
        'commission_sum_net',
        'commission_count_net'
      ]
    }),
    [pageUrl, range, device]
  );
  return usePromise(() => {
    return queryAnalyticsV2(spaceId, q).then(async (res) => {
      const linkIds = compact(res.rows.map((x) => x.group.link_id || null));
      const links = await getProductsByIdPg(spaceId, linkIds);
      return {
        rows: res.rows,
        links
      };
    });
  }, [spaceId, q]);
};

export const prepareHeatmapData = (
  positions: ProductLinkPosition[],
  links: IPostgresProduct[],
  rows: AnalyticsResponseRowWithComparison[]
) => {
  const linksByUrl = keyBy(links, (p) =>
    withoutTrailingSlashAtAnyPosition(p.url)
  );
  const dataByLinkOccKey = keyBy(rows, (r) =>
    [r.group.link_id, r.group.link_occ].join('-')
  );

  return compact(
    positions.map<HeatmapAnnotationData | null>((pos) => {
      const product = linksByUrl[withoutTrailingSlashAtAnyPosition(pos.href)]; // certainly some URL normalization to do here
      const occKey = [
        product?.id,
        padStart(pos.occurrence.toString(), 4, '0')
      ].join('-');
      const data = dataByLinkOccKey[occKey];

      if (!product || !data) {
        console.log('early return');
        return null;
      }
      return {
        link: pos,
        product: { id: product.id, name: product.name, url: product.url },
        data: data.data
      };
    })
  );
};

export const prepareHeatmapDataOld = (
  links: ProductLinkPosition[],
  productMap: ProductMap
): HeatmapAnnotationData[] => {
  const productsByUrl = keyBy(Object.values(productMap), (p) =>
    withoutTrailingSlashAtAnyPosition(p.url)
  );

  return compact(
    links.map<HeatmapAnnotationData | null>((link) => {
      const product = findProduct(link, productMap, productsByUrl);
      const counter = product?.byOccurrence[link.occurrence];
      if (!product || !counter) {
        return null;
      }
      return {
        link,
        product: { id: product.id, name: product.name, url: product.url },
        data: {
          p: {
            curr: counter.counts.pageViews.count,
            prev: counter.counts.pageViews.lastCount
          },
          s: {
            curr: counter.counts.served.count,
            prev: counter.counts.served.lastCount
          },
          v: {
            curr: counter.counts.viewed.count,
            prev: counter.counts.viewed.lastCount
          },
          c: {
            curr: counter.counts.clicked.count,
            prev: counter.counts.clicked.lastCount
          },
          epc_net: {
            curr: getEpc(
              counter.counts.clicked.count,
              counter.sales.curr.earnings.total
            ),
            prev: getEpc(
              counter.counts.clicked.lastCount,
              counter.sales.prev.earnings.total
            )
          },
          gmv_sum_net: {
            curr: counter.sales.curr.earnings.saleValue.total,
            prev: counter.sales.prev.earnings.saleValue.total
          },
          commission_sum_net: {
            curr: counter.sales.curr.earnings.total,
            prev: counter.sales.prev.earnings.total
          },
          commission_count_net: {
            curr: counter.sales.curr.earnings.salesCount,
            prev: counter.sales.prev.earnings.salesCount
          }
        }
      };
    })
  );
};

const findMax = (
  ds: HeatmapAnnotationData[],
  field: keyof HeatmapAnnotationData['data']
) => max(ds.map((d) => d.data[field]?.curr || 0)) || 0;

const ProductName = styled('div')`
  display: flex;
  align-items: center;
  width: 200px;
`;

const ProductCard = ({
  d,
  dimension
}: {
  d: HeatmapAnnotationData;
  dimension: IHeatmapAnnotationDimension;
}) => {
  console.log('PROD CARD', d);
  const currency = useSpaceCurrency();
  const hasAnySales = (d.data.commission_count_net?.curr || 0) > 0;
  return (
    <Card
      className={css((t) => ({
        width: '950px',
        padding: `${t.spacing(1)}px ${t.spacing(5)}px`,
        boxShadow:
          '0px 1px 5px 5px rgba(0,0,0,0.09), 2px 5px 9px 2px rgba(0,0,0,0.04)',
        borderRadius: '80px',
        backgroundColor: '#000',
        border: '2px solid white',
        color: '#fff',
        opacity: 0.93
      }))}
    >
      <Container>
        <Tooltip
          placement="top"
          title={`Open link to "${d.product.name}" in a new window`}
        >
          <ProductName>
            {truncate(d.product.name, { length: 25 })}{' '}
            <a href={d.product.url} target="_blank" rel="noopener noreferrer">
              &nbsp;
              <IconOpenNewWindow
                style={{ position: 'relative', top: '1px' }}
                size={18}
              />
            </a>
          </ProductName>
        </Tooltip>
        <HighlightedMetric
          selectedDimension={dimension === 'c'}
          hasSales={hasAnySales}
        >
          <BoldNumber>{d.data.c?.curr || 0}</BoldNumber>
          Clicks
        </HighlightedMetric>
        {hasAnySales && (
          <HighlightedMetric
            selectedDimension={dimension === 'commission_sum_net'}
            hasSales={hasAnySales}
          >
            <BoldNumber>
              <Currency
                cents={d.data.commission_sum_net?.curr || 0}
                currency={currency}
              />
            </BoldNumber>{' '}
            from{' '}
            {pluralize('sale', d.data.commission_count_net?.curr || 0, true)}
          </HighlightedMetric>
        )}
        {hasAnySales && (
          <HighlightedMetric
            selectedDimension={dimension === 'epc_net'}
            hasSales={true}
          >
            <BoldNumber>
              <Currency cents={d.data.epc_net?.curr || 0} currency={currency} />
            </BoldNumber>
            EPC
          </HighlightedMetric>
        )}
        {hasAnySales && (
          <HighlightedMetric
            selectedDimension={dimension === 'gmv_sum_net'}
            hasSales={true}
          >
            <BoldNumber>
              <Currency
                cents={d.data.gmv_sum_net?.curr || 0}
                currency={currency}
              />
            </BoldNumber>
            Sales volume
          </HighlightedMetric>
        )}
        <div>
          <Ctr
            rate={getClickRatio({
              viewed: d.data.v?.curr || 0,
              clicked: d.data.c?.curr || 0
            })}
          />{' '}
          <BoldNumber>CTR</BoldNumber>
        </div>
        <div>
          <BoldNumber>
            <Number
              n={getViewRatio({
                served: d.data.s?.curr || 0,
                viewed: d.data.v?.curr || 0
              })}
              format="percent"
            />
          </BoldNumber>
          Seen
        </div>
      </Container>
    </Card>
  );
};

export const Heatmap: React.FC<{
  space: ISpace;
  annotationData: HeatmapAnnotationData[];
  screenshot: Doc<IPageScreenshot>;
  device: HeatmapDeviceOptions;
  setDevice: (device: HeatmapDeviceOptions) => void;
  dimension: IHeatmapAnnotationDimension;
  setDimension: (dimension: IHeatmapAnnotationDimension) => void;
  scale: number;
  image: {
    alt: string;
    data: IImageInfo[];
    dimensions: {
      width: number;
      height: number;
    };
  };
  minNextRevisionDate: Timestamp;
}> = ({
  space,
  scale,
  annotationData,
  image,
  device,
  screenshot,
  dimension,
  setDimension,
  setDevice,
  minNextRevisionDate
}) => {
  const [selectedI, setSelectedI] = useState<number | null>(null);

  const [canRegenerateScreenshot] = useHasCurrentUserRequiredScopes([
    'pages.edit'
  ]);
  const refreshScreenshotDialog = useDialogState();

  // This actually does NOT work yet, as the productMap we pass in only
  // contains products of this one page.kk
  const maxCount = useMemo(() => {
    return findMax(annotationData, dimension);
  }, [annotationData, dimension]);

  const sizeScale = scaleLinear()
    .domain([0, Math.max(10, maxCount)])
    .range([5, 180]);

  const annotations: IAnnotation[] = annotationData.map((d) => {
    return {
      ...getCenter(d.link.position),
      component: (props) => {
        const scale = (() => {
          const metric = d.data[dimension]?.curr || 0;
          return sizeScale(metric);
        })();

        return (
          <Annotation
            {...props}
            hasSales={!!d.data.commission_count_net?.curr}
            size={scale}
          />
        );
      }
    };
  });

  const d = selectedI !== null ? annotationData[selectedI] : null;

  return (
    <>
      <ImageWithAnnotations
        images={image.data}
        alt={image.alt}
        dimensions={image.dimensions}
        scale={scale}
        annotations={annotations}
        selectedI={selectedI}
        setSelectedI={setSelectedI}
        controlsOffset={DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT_LARGE}
        additionalControls={
          <>
            <Tooltip title="Size by clicks" placement="right">
              <IconButton
                style={{
                  color: dimension === 'c' ? 'black' : undefined
                }}
                onClick={() => {
                  setDimension('c');
                }}
              >
                <MousePointer size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Size by earnings" placement="right">
              <IconButton
                style={{
                  color:
                    dimension === 'commission_sum_net' ? 'black' : undefined
                }}
                onClick={() => {
                  setDimension('commission_sum_net');
                }}
              >
                <DollarSign size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Size by EPC" placement="right">
              <IconButton
                style={{
                  color: dimension === 'epc_net' ? 'black' : undefined
                }}
                onClick={() => {
                  setDimension('epc_net');
                }}
              >
                <Divide size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Size by sales volume" placement="right">
              <IconButton
                style={{
                  color: dimension === 'gmv_sum_net' ? 'black' : undefined,

                  marginBottom: '24px'
                }}
                onClick={() => {
                  setDimension('gmv_sum_net');
                }}
              >
                <ShoppingCart size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title="All devices combined" placement="right">
              <IconButton
                style={{ color: device === 'all' ? 'black' : undefined }}
                onClick={() => {
                  setDevice('all');
                }}
              >
                <AllDevices size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Desktop only" placement="right">
              <IconButton
                style={{ color: device === 'desktop' ? 'black' : undefined }}
                onClick={() => {
                  setDevice('desktop');
                }}
              >
                <Monitor size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mobile only" placement="right">
              <IconButton
                style={{
                  color: device === 'mobile' ? 'black' : undefined,

                  marginBottom: '24px'
                }}
                onClick={() => {
                  setDevice('mobile');
                }}
              >
                <Smartphone size={18} />
              </IconButton>
            </Tooltip>
            {canRegenerateScreenshot && (
              <>
                <RevisionCreateIconButton
                  space={space}
                  pageUrl={screenshot.data.url}
                  size={18}
                  tooltipTitle="Create manual revision & heatmap..."
                  tooltipPlacement="right"
                  minDate={minNextRevisionDate}
                />
                <Tooltip title="Delete & replace heatmap..." placement="right">
                  <IconButton
                    style={{ opacity: 0.4 }}
                    onClick={() => {
                      refreshScreenshotDialog.openDialog();
                    }}
                  >
                    <Trash2 size={18} />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <RegenerateScreenshotDialog
              open={refreshScreenshotDialog.dialogOpen}
              onClose={() => refreshScreenshotDialog.closeDialog()}
              screenshot={screenshot}
            />
          </>
        }
      />
      {d && (
        <Popover>
          <ProductCard d={d} dimension={dimension} />
        </Popover>
      )}
    </>
  );
};
