import { ButtonBase } from '@material-ui/core';
import { groupBy, keyBy, mapValues } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { FileText } from 'react-feather';
import {
  Bar,
  ComposedChart,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { LegendLabel } from '../../../../../../components/Charts/Util';
import {
  formatCurrency,
  formatNumber
} from '../../../../../../components/Number';
import {
  EMPTY_DAILY_COUNTER,
  IDailyCounter,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import {
  IPageMetadata,
  IPageRevision
} from '../../../../../../domainTypes/page';
import {
  EMPTY_EARNING,
  IEarning
} from '../../../../../../domainTypes/performance';
import { styled } from '../../../../../../emotion';
import {
  formatTimeKey,
  getCpm,
  getTimeKeyRange
} from '../../../../../../services/analytics';
import {
  timeframeToMomentRange,
  toMoment
} from '../../../../../../services/time';
import { useTheme } from '../../../../../../themes';
import { EarningsByAdvertiser } from '../../service';
import { getAdvertiserColor } from '../../../../../../components/AdvertiserWithColor';
import { COLORS } from '../../../../../../domainTypes/colors';
import { ChartTooltip } from './Tooltip';
import { FreeFormTooltip } from '../../../../../../components/Charts/CustomTooltip';

export const REVISIONS_COLOR = COLORS.red.red5;
export const SERIES_COLOR = 'black';
export const COMPARE_SERIES_COLOR = '#999';

type Props = {
  traffic: IDailyCounter[];
  earnings: EarningsByAdvertiser;
  metadata: IPageMetadata | null;
  timeframe: Timeframe;
  currency: CurrencyCode;
  mode: [LeftMode, RightMode];
  onModeChange: (nextMode: [LeftMode, RightMode]) => void;
  height: string | number;

  compareLeft?: boolean;
};

export type ChartData = {
  timeKey: string;
  clicks: {
    prev: number;
    curr: number;
  };
  pageviews: {
    prev: number;
    curr: number;
  };
  ctr: {
    prev: number;
    curr: number;
  };
  earnings: Record<
    string,
    {
      partnerKey: string;
      advertiserName: string;
      earning: IEarning;
    }
  >;
  revisions: IPageRevision[]; // needs to be something else as we also want the version number
};

const toChartData = (
  traffic: IDailyCounter[],
  earningsByAdvertiser: EarningsByAdvertiser,
  metadata: IPageMetadata | null,
  timeframe: Timeframe,
  currency: CurrencyCode
): ChartData[] => {
  const trafficByTk = keyBy(traffic, (t) => t.timeKey);
  const revisionsByTk = groupBy(
    (metadata?.revisions || []).filter((r) => !r.ignore),
    (r) => toMoment(r.lastModifiedAt).tz(timeframe.tz).format(TIMEKEY_FORMAT)
  );

  const moments = timeframeToMomentRange(timeframe);
  const tkRange = getTimeKeyRange(moments.start, moments.end);
  const comparator = tkRange.length;

  return tkRange.map<ChartData>((tk) => {
    const currTraffic = trafficByTk[tk] || EMPTY_DAILY_COUNTER(tk);
    const revisions = revisionsByTk[tk] || [];
    const compTk = moment
      .tz(tk, TIMEKEY_FORMAT, timeframe.tz)
      .subtract(comparator, 'd')
      .format(TIMEKEY_FORMAT);

    const prevTraffic = trafficByTk[compTk] || EMPTY_DAILY_COUNTER(compTk);

    const earnings: ChartData['earnings'] = mapValues(
      earningsByAdvertiser,
      (data) => {
        return {
          partnerKey: data.partnerKey,
          advertiserName: data.advertiserName,
          earning:
            data.earnings.find((e) => e.timeKey === tk) ??
            EMPTY_EARNING(currency)
        };
      }
    );

    return {
      timeKey: tk,
      clicks: {
        prev: prevTraffic.clicked,
        curr: currTraffic.clicked
      },
      pageviews: {
        prev: prevTraffic.pageViews,
        curr: currTraffic.pageViews
      },
      ctr: {
        prev: getCpm(prevTraffic),
        curr: getCpm(currTraffic)
      },
      earnings,
      revisions
    };
  });
};

export type LeftMode = 'clicks' | 'pageviews' | 'ctr';
export type RightMode = 'earnings';

const CustomLegendContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f2f2f2;
  padding: 0px ${(p) => p.theme.spacing(1)}px;
  padding-top: ${(p) => p.theme.spacing(2)}px;
  margin-top: ${(p) => p.theme.spacing(2)}px;
`;

const CustomLegendSectionL = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: ${(p) => p.theme.spacing(2)}px;
`;

const CustomLegendSectionR = styled(CustomLegendSectionL)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Square = styled<'div', { color: string; active: boolean }>('div')`
  width: 12px;
  height: 12px;
  border: 3px solid ${(p) => p.color};
  background: ${(p) => (p.active ? p.color : 'none')};
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const Circle = styled<typeof Square, { color: string; active: boolean }>(
  Square
)`
  border-radius: 50%;
`;

const LegendElement = ({
  color,
  active,
  onClick,
  label,
  shape = 'circle'
}: {
  shape?: 'square' | 'circle';
  color: string;
  active: boolean;
  onClick?: () => void;
  label: string;
}) => {
  return (
    <ButtonBase onClick={onClick} disabled={!onClick}>
      {shape === 'circle' && <Circle color={color} active={active} />}
      {shape === 'square' && <Square color={color} active={active} />}
      <LegendLabel>{label}</LegendLabel>
    </ButtonBase>
  );
};

const CustomLegend = ({
  value,
  onChange
}: {
  value: [LeftMode, RightMode];
  onChange: (nextValue: [LeftMode, RightMode]) => void;
}) => {
  const [left, right] = value;
  return (
    <CustomLegendContainer>
      <CustomLegendSectionL>
        <LegendElement
          color={SERIES_COLOR}
          active={left === 'clicks'}
          onClick={() => onChange(['clicks', right])}
          label="Clicks"
        />
        <LegendElement
          color={SERIES_COLOR}
          active={left === 'pageviews'}
          onClick={() => onChange(['pageviews', right])}
          label="Pageviews"
        />
        <LegendElement
          color={SERIES_COLOR}
          active={left === 'ctr'}
          onClick={() => onChange(['ctr', right])}
          label="Page CTR"
        />
      </CustomLegendSectionL>
      <CustomLegendSectionR>
        <LegendElement
          color={REVISIONS_COLOR}
          active={true}
          label="Revisions"
        />
        <LegendElement
          shape="square"
          color={SERIES_COLOR}
          active={right === 'earnings'}
          onClick={() => onChange([left, 'earnings'])}
          label="Earnings"
        />
      </CustomLegendSectionR>
    </CustomLegendContainer>
  );
};

export const formatters = {
  clicks: (t: number) =>
    formatNumber({
      n: t,
      format: 'decimal',
      digits: 0
    }),
  pageviews: (t: number) =>
    formatNumber({
      n: t,
      format: 'decimal',
      digits: 0
    }),
  ctr: (t: number) => `${(Math.round(t * 10 * 100) / 10).toString()}%`,
  earnings: formatCurrency
};

export const earningsColor = (key: string) => {
  const [advertiserName, partnerKey] = key.split('---');
  return getAdvertiserColor(advertiserName, partnerKey);
};

export const Chart = ({
  traffic,
  earnings,
  metadata,
  timeframe,
  currency,
  mode,
  onModeChange,
  height,
  compareLeft
}: Props) => {
  const [leftMode, rightMode] = mode;
  const data = useMemo(
    () => toChartData(traffic, earnings, metadata, timeframe, currency),
    [traffic, earnings, metadata, timeframe, currency]
  );

  const startsInCurrentYear = useMemo(
    () =>
      timeframe.start &&
      moment(timeframe.start).format('YYYY') === moment().format('YYYY'),
    [timeframe.start]
  );

  const dataByTimeKey = keyBy(data, (d) => d.timeKey);

  const theme = useTheme();
  const tickStyle = {
    fill: '#9b9b9b',
    fontSize: theme.custom.fontSize.m
  };

  const keys = Object.keys(earnings);

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer
        width="99%"
        height={height}
        aspect={height === 'auto' ? 2.2 : undefined}
      >
        <ComposedChart
          data={data}
          layout="horizontal"
          maxBarSize={12}
          barGap={0}
        >
          <XAxis
            dataKey="timeKey"
            tickFormatter={(tk) =>
              formatTimeKey(
                tk || '',
                startsInCurrentYear ? 'MMM DD' : "MMM DD, 'YY"
              )
            }
            tick={{
              ...tickStyle,
              transform: 'translate(0, 8)'
            }}
            xAxisId="time"
            textAnchor="end"
            tickSize={0}
            minTickGap={60}
            stroke={'none'}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(-8, 0)`
            }}
            hide={leftMode !== 'clicks'}
            yAxisId="clicks"
            stroke="none"
            orientation="left"
            domain={[
              (min) => Math.max(Math.floor(min / 10) * 10 - 30, 0),
              (max) => Math.max(Math.floor(max / 10) * 10 + 10, 0)
            ]}
            tickFormatter={formatters.clicks}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(-8, 0)`
            }}
            hide={leftMode !== 'pageviews'}
            yAxisId="pageviews"
            stroke="none"
            orientation="left"
            domain={[
              (min) => Math.max(Math.floor(min / 10) * 10 - 100, 0),
              (max) => Math.max(Math.floor(max / 10) * 10 + 10, 0)
            ]}
            tickFormatter={formatters.pageviews}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(-8, 0)`
            }}
            hide={leftMode !== 'ctr'}
            yAxisId="ctr"
            stroke="none"
            orientation="left"
            tickFormatter={formatters.ctr}
          />
          <YAxis
            tick={{
              ...tickStyle,
              transform: `translate(8, 0)`
            }}
            domain={[
              (min) => (min < 0 ? Math.floor(min / 10) * 10 : min),
              (max) => {
                const m = max > 0 ? Math.ceil(max / 10) * 10 : max;
                return m + m / 2;
              }
            ]}
            allowDecimals={false}
            yAxisId="earnings"
            stroke="none"
            tickSize={0}
            orientation={'right'}
            tickFormatter={(v) => formatters.earnings(v, currency)}
          />
          {keys.map((k) => (
            <Bar
              yAxisId="earnings"
              xAxisId="time"
              stackId="1"
              dataKey={(e) => {
                return e.earnings[k]?.earning.total || 0;
              }}
              fill={earningsColor(k)}
              isAnimationActive={false}
            />
          ))}
          {leftMode === 'pageviews' && compareLeft && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="pageviews.prev"
              yAxisId="pageviews"
              xAxisId="time"
              stroke={COMPARE_SERIES_COLOR}
              strokeWidth={1.4}
              strokeDasharray="4, 2"
              dot={false}
            />
          )}
          {leftMode === 'pageviews' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="pageviews.curr"
              yAxisId="pageviews"
              xAxisId="time"
              stroke={SERIES_COLOR}
              strokeWidth={1.6}
              dot={false}
            />
          )}
          {leftMode === 'clicks' && compareLeft && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="clicks.prev"
              yAxisId="clicks"
              xAxisId="time"
              stroke={COMPARE_SERIES_COLOR}
              strokeWidth={1.4}
              strokeDasharray="4, 2"
              dot={false}
            />
          )}
          {leftMode === 'clicks' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="clicks.curr"
              yAxisId="clicks"
              xAxisId="time"
              stroke={SERIES_COLOR}
              strokeWidth={1.6}
              dot={false}
            />
          )}
          {leftMode === 'ctr' && compareLeft && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="ctr.prev"
              yAxisId="ctr"
              xAxisId="time"
              stroke={COMPARE_SERIES_COLOR}
              strokeWidth={1.4}
              strokeDasharray="4, 2"
              dot={false}
            />
          )}
          {leftMode === 'ctr' && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="ctr.curr"
              yAxisId="ctr"
              xAxisId="time"
              stroke={SERIES_COLOR}
              strokeWidth={1.6}
              dot={false}
            />
          )}
          <ReferenceLine
            y={0}
            xAxisId="time"
            yAxisId={rightMode}
            stroke={tickStyle.fill}
          />
          {data.map(
            (d) =>
              !!d.revisions.length && (
                <ReferenceDot
                  key={d.timeKey}
                  fill={REVISIONS_COLOR}
                  stroke="#FFF"
                  strokeWidth={2}
                  r={6}
                  yAxisId={leftMode}
                  xAxisId="time"
                  isFront={true}
                  y={d[leftMode].curr}
                  x={d.timeKey}
                  label={(p) => {
                    return (
                      <FileText
                        size={20}
                        strokeWidth={1.5}
                        x={p.viewBox.x + 2}
                        y={p.viewBox.y - 24}
                        fill="#FFF"
                      />
                    );
                  }}
                />
              )
          )}
          <Tooltip
            cursor={false}
            content={
              <FreeFormTooltip>
                {({ x }) => {
                  if (!x) {
                    return null;
                  }
                  const timeKey = `${x}`;
                  const d = dataByTimeKey[timeKey];
                  if (!d) {
                    return null;
                  }
                  return (
                    <ChartTooltip
                      trafficMetric={leftMode}
                      data={d}
                      timeframe={timeframe}
                      metadata={metadata}
                      startsInCurrentYear={Boolean(startsInCurrentYear)}
                    />
                  );
                }}
              </FreeFormTooltip>
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegend value={mode} onChange={onModeChange} />
    </div>
  );
};
