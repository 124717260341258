import { Card } from '@material-ui/core';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import {
  PlatformSelector,
  usePlatformFilter
} from '../../../../components/analytics_v2/PlatformSelector';
import {
  ColumnDefinitions,
  TableMetadata,
  useTable
} from '../../../../components/analytics_v2/Table';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { Centered } from '../../../../layout/Centered';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { useRoutes } from '../../../../routes';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { ProductLinkCellWithPartnerLazy } from '../../../Links/pages/Overview/components/ProductLinkCell';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { newSideNavProps } from '../../services/detailsSideNav';
import {
  TimeframePickerWithRevisions,
  useTimeframeWithRevisions
} from '../../../../components/analytics_v2/Timeframe/revisions';
import { usePage } from '../../../../services/page';
import { useHasComparison } from '../../../../hooks/timeframe';

const customColumns = ['link'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [
  ...customColumns,
  'count_uniq_link_occ',
  's',
  'v',
  'view_ratio',
  'c',
  'ctr',
  'epc_net',
  'quantity_net',
  'commission_sum_net',
  'gmv_sum_net',
  'avg_rate_net'
];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'count_uniq_link_occ',
  'view_ratio',
  'c',
  'ctr',
  'epc_net',
  'commission_sum_net',
  'gmv_sum_net',
  'avg_rate_net'
];

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  link: {
    column: {
      key: 'link',
      head: () => 'Link URL',
      headInfo: () =>
        `Combined metrics for all instances of this product link on the page. The number (like 3x) indicates how many times this link appears in the content.`,
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
        return (
          <ProductLinkCellWithPartnerLazy
            spaceId={o.spaceId}
            productId={p.group.link_id}
          />
        );
      },
      align: 'left',
      sortable: false,
      width: 400,
      flexGrow: 4
    },
    sorter: {
      key: 'link',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.link_id,
        dir: 'asc'
      }
    }
  }
};

const useFilters = (url: string) => {
  const platformFilter = usePlatformFilter();
  return useMemo<AnalyticsFilter[]>(
    () =>
      compact([
        {
          condition: 'not in',
          field: 'link_id',
          values: ['']
        },
        {
          condition: 'in',
          field: 'page_url',
          values: [url]
        },
        platformFilter
      ]),
    [platformFilter, url]
  );
};

const useLinksCount = (url: string, range: ISOTimeRange) => {
  const { space } = useCurrentUser();
  const filters = useFilters(url);
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: ['count_uniq_link_id'],
      filters
    }),
    [filters, range]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (resp) => resp.rows[0]?.data.count_uniq_link_id?.curr || 0
  );
};

const useLinksMetrics = (
  url: string,
  range: ISOTimeRange,
  compare: AnalyticsQuery['compare'],
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const filters = useFilters(url);
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      compare,
      select: metrics,
      paginate,
      orderBy: [orderBy],
      groupBy: ['link_id'],
      filters
    }),
    [compare, filters, metrics, orderBy, paginate, range]
  );

  return useAnalyticsQueryV2(space.id, query);
};

const useLinksExportQuery = (
  url: string,
  range: ISOTimeRange,
  compare: AnalyticsQuery['compare'],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
): AnalyticsQuery => {
  const filters = useFilters(url);
  return useMemo(
    () => ({
      range,
      compare,
      select: metrics,
      orderBy: [orderBy],
      groupBy: ['link_id', 'link_url'],
      filters
    }),
    [compare, filters, metrics, orderBy, range]
  );
};

const PAGE_SIZE = 20;
const headProps = { sticky: true, offset: DEFAULT_OFFSET };
const rowToKey = (d: AnalyticsResponseRowWithComparison) => d.group.link_id;

export const PageContentDetailsLinksBody: React.FC<{ url: string }> = ({
  url
}) => {
  const { ROUTES } = useRoutes();
  const sideNav = newSideNavProps(ROUTES, url);

  const urlFilter = useMemo<AnalyticsFilter[]>(
    () => [{ field: 'page_url', condition: 'in', values: [url] }],
    [url]
  );
  const showComparison = useHasComparison();

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    metrics,
    orderBy
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison
  });

  const { space } = useCurrentUser();
  const [pageMetadata] = usePage(space.id, url);
  const {
    ranges: { range, compare },
    pickerProps
  } = useTimeframeWithRevisions(
    pageMetadata ? pageMetadata.data.revisions : []
  );

  const exportQuery = useLinksExportQuery(
    url,
    range,
    compare,
    metrics,
    orderBy
  );

  const [data, loading, error] = useLinksMetrics(
    url,
    range,
    compare,
    metrics,
    pagination,
    orderBy
  );
  const [count = 1] = useLinksCount(url, range);

  useErrorLogger(error);

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
          <PageToolbarSection flex={1}>
            <PlatformSelector filters={urlFilter} />
            <ColumnSelector {...columnSelectorProps} />
            <CustomPagination
              {...paginationSelectorProps}
              count={Math.ceil(count / PAGE_SIZE)}
              siblingCount={0}
            />
            <ExportQueryButton
              title="Export links to CSV"
              reportType="links"
              query={exportQuery}
            />
          </PageToolbarSection>
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframePickerWithRevisions {...pickerProps} />
        </PageToolbarSection>
      </PageToolbar>
      {loading || !data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead
          headProps={headProps}
          rows={data.rows}
          rowToKey={rowToKey}
          rowToHref={(d) => ROUTES.links.overview_v2.url({}, d.group.link_id)}
        />
      )}
    </PageBody>
  );
};
