import { groupBy, map, mapValues, sortBy, sumBy } from 'lodash';
import { AnalyticsResponseRowWithComparison } from '../../../../../../domainTypes/analytics_v2';
import { EarningMetric, TrafficMetric } from '../../service';

export interface ChartData {
  timestamp: number;
  traffic: Record<string, number>;
  earnings: number;
}

type MetricSelector = (r: AnalyticsResponseRowWithComparison) => number;

const selectChannelId = (r: AnalyticsResponseRowWithComparison) =>
  r.group.channel_id;

const selectInterval = (r: AnalyticsResponseRowWithComparison) =>
  new Date(r.group.interval).getTime();

const aggregateInterval = (
  intervalTimestamp: string,
  rows: AnalyticsResponseRowWithComparison[],
  selectTrafficMetric: MetricSelector,
  selectEarningsMetric: MetricSelector
): ChartData => {
  const timestamp = parseInt(intervalTimestamp, 10);
  const rowsByChannel = groupBy(rows, selectChannelId);
  const traffic = mapValues(rowsByChannel, (originRows) =>
    sumBy(originRows, selectTrafficMetric)
  );
  const earnings = sumBy(rows, selectEarningsMetric);
  return {
    timestamp,
    traffic,
    earnings
  };
};

export const formatChartData = (
  rows: AnalyticsResponseRowWithComparison[],
  trafficMetric: TrafficMetric,
  earningsMetric: EarningMetric
) => {
  const rowsByInterval = groupBy(rows, selectInterval);
  const selectTrafficMetric = (r: AnalyticsResponseRowWithComparison) =>
    r.data[trafficMetric]?.curr ?? 0;
  const selectEarningsMetric = (r: AnalyticsResponseRowWithComparison) =>
    r.data[earningsMetric]?.curr ?? 0;

  const data = map(rowsByInterval, (rowsInInterval, intervalTimestamp) =>
    aggregateInterval(
      intervalTimestamp,
      rowsInInterval,
      selectTrafficMetric,
      selectEarningsMetric
    )
  );

  return sortBy(data, ['timestamp']);
};
