import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { Centered } from '../../../layout/Centered';
import { styled } from '../../../emotion';
import { COLORS } from '../../../domainTypes/colors';
import { Edit } from 'react-feather';
import { SupportButton } from '../../../components/SupportButton';

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const RevisionsEmptyState = () => {
  return (
    <Card
      style={{
        maxWidth: 600,
        padding: '36px',
        margin: '0 auto'
      }}
    >
      <Centered height={500}>
        <div>
          <IconWrapper
            style={{
              color: COLORS.blue.blue6,
              backgroundColor: COLORS.blue.blue2
            }}
          >
            <Edit size={42} />
          </IconWrapper>
          <Typography variant="h6" paragraph style={{ fontWeight: 700 }}>
            No revisions available yet for this page
          </Typography>
          <Typography variant="body1" paragraph>
            We don't have revisions for this particular page just yet.
            <br />
            <br />
            This can happen because:
          </Typography>
          <Typography variant="body1" component="ul" paragraph>
            <li>This page hasn't been processed yet in the queue.</li>
            <li>
              No modification data was available through your schema or sitemap.
            </li>
            <li>
              This page is not within your 2,000 most recently modified pages.
            </li>
            <li>
              This page has never sent us analytics data, or has only sent us
              analytics data starting today.
            </li>
          </Typography>
          <Typography variant="body1" paragraph>
            If this seems incorrect, you can always reach out to us.
          </Typography>
          <br />
          <div style={{ textAlign: 'center', margin: '0 auto' }}>
            <SupportButton
              label="Contact support"
              subject="No revisions available"
            />
          </div>
        </div>
      </Centered>
    </Card>
  );
};
