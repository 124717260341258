/* eslint-disable */

import { useCallback } from 'react';
import { Timeframe } from '../../domainTypes/analytics';
import { useCurrentUser } from '../currentUser';
import { LoadingValue } from '../db';
import {
  useDenormalizedDeviceClickCountsForPageInTimeframe,
  useDenormalizedDeviceClickCountsForProductsInTimeframe,
  useDenormalizedDeviceClickCountsInTimeframe,
  usePartnerFilter
} from './denormalization';

const EMPTY_ARR = () => [];

export const useDeviceClickCountsForProductInTimeframeFs = (
  productId: string,
  timeframe: Timeframe
) => {
  const { space } = useCurrentUser();
  const filter = useCallback(() => [productId], [productId]);
  return useDenormalizedDeviceClickCountsForProductsInTimeframe(
    space.id,
    filter,
    timeframe
  );
};

export const useDeviceClickCountForPartnerInTimeframe = (
  partnerKey: string,
  timeframe: Timeframe
) => {
  const { space } = useCurrentUser();
  const [filterProductIds, loadingPs, errorPs] = usePartnerFilter(
    space,
    partnerKey
  );
  const [
    result,
    loading,
    error
  ] = useDenormalizedDeviceClickCountsForProductsInTimeframe(
    space.id,
    filterProductIds || EMPTY_ARR,
    timeframe
  );

  return [result, loading || loadingPs, error || errorPs] as LoadingValue<
    typeof result
  >;
};

export const useDeviceClickCountsInTimeframe = (timeframe: Timeframe) => {
  const { space } = useCurrentUser();
  return useDenormalizedDeviceClickCountsInTimeframe(space.id, timeframe);
};
