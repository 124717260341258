import React, { useMemo } from 'react';
import { InlineLoader } from '../../../../components/Loader';
import { formatCurrency } from '../../../../components/Number';
import { RAlign } from '../../../../components/Typography';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { EMPTY_EARNING, IEarning } from '../../../../domainTypes/performance';
import { styled } from '../../../../emotion';
import { Props } from './props';

const ColoredDiff = styled<'div', { n: number }>('div')`
  color: ${(p) => {
    if (p.n === 0) {
      return p.theme.custom.colors.pending.main;
    }
    if (p.n > 0) {
      return p.theme.custom.colors.success.main;
    }
    return p.theme.custom.colors.error.main;
  }};
`;

const EarningsSummaryHeaderContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${(p) => p.theme.spacing(1)}px;
`;

const EarningsSummaryHeaderTotal = styled('div')`
  margin-top: ${(p) => p.theme.spacing(1)}px;
  font-size: ${(p) => p.theme.custom.fontSize.xl}px;
`;

const EarningsSummaryHeaderDiff = styled(ColoredDiff)`
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const EarningsSummaryHeader = ({
  prev,
  curr,
  loading
}: {
  prev: IEarning | null;
  curr: IEarning;
  loading: boolean;
}) => {
  return (
    <EarningsSummaryHeaderContainer>
      <EarningsSummaryHeaderTotal>
        {loading ? <InlineLoader /> : formatCurrency(curr.total, curr.currency)}
      </EarningsSummaryHeaderTotal>
      {prev && (
        <EarningsSummaryHeaderDiff n={curr.total - prev.total}>
          {!loading &&
            formatCurrency(curr.total - prev.total, curr.currency, true)}
        </EarningsSummaryHeaderDiff>
      )}
    </EarningsSummaryHeaderContainer>
  );
};

const EarningsSummaryRowValue = styled(RAlign)`
  font-weight: 600;
`;

const EarningsSummaryRow = ({
  label,
  value,
  loading
}: {
  label: string;
  value: IEarning;
  loading: boolean;
}) => {
  return (
    <>
      <div>{label}</div>
      <EarningsSummaryRowValue>
        {loading ? (
          <InlineLoader />
        ) : (
          formatCurrency(value.total, value.currency)
        )}
      </EarningsSummaryRowValue>
    </>
  );
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  color: #9b9b9b;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

export const EarningsSummary = ({
  sales,
  currency
}: {
  sales: Props['sales'];
  currency: CurrencyCode;
}) => {
  const emptyPlaceholder = useMemo(() => EMPTY_EARNING(currency), [currency]);
  const rows: React.ComponentProps<typeof EarningsSummaryRow>[] = [
    {
      label: 'Yesterday',
      value: sales.yesterday.d || emptyPlaceholder,
      loading: sales.yesterday.loading
    },
    {
      label: 'Month to date',
      value: sales.monthToDate.d || emptyPlaceholder,
      loading: sales.monthToDate.loading
    },
    {
      label: 'Last month',
      value: sales.lastMonth.d || emptyPlaceholder,
      loading: sales.lastMonth.loading
    }
  ];

  return (
    <>
      <EarningsSummaryHeader
        prev={sales.tfToCompare.d || null}
        curr={sales.tf.d || emptyPlaceholder}
        loading={sales.tf.loading}
      />
      <Grid>
        {rows.map((r) => (
          <EarningsSummaryRow key={r.label} {...r} />
        ))}
      </Grid>
    </>
  );
};
