import { ICounterWithTrend, ICountWithTrend, IShortCounter } from './analytics';
import { CurrencyCode } from './currency';
import { Dimensions } from './puppeteer';
import { Timestamp, UnixMilliseconds } from './time';
import { ProductLinkPosition } from './tracking';

export interface IPageSummary {
  spaceId: string;
  url: string;
  pageViews: {
    counts: number[];
    rank: number;
  };
  served: {
    counts: number[];
    rank: number;
  };
  viewed: {
    counts: number[];
    rank: number;
  };
  clicked: {
    counts: number[];
    rank: number;
  };
  ctr: {
    rank: number;
  };
  cpm: {
    rank: number;
  };
  sales: {
    values: number[];
    currency: CurrencyCode;
    rank: number;
  };
  lastUpdateTimekey: string;
  // we need a TZ to update sales properly!
}

export const addCountsToSummary = (
  summary: IPageSummary,
  counts: IShortCounter
) => {
  summary.pageViews.counts.push(counts.p);
  summary.served.counts.push(counts.s);
  summary.viewed.counts.push(counts.v);
  summary.clicked.counts.push(counts.c);
};

type Html = string;

export interface IPageRevisionNote {
  createdAt: Timestamp;
  createdBy: string;
  editedAt: Timestamp;
  editedBy: string;
  html: Html;
}

export interface IPageRevision {
  // might actually good to store an id only and create an own notes collection,
  // so that this whole object can be retrieved in a lightweight way, as notes
  // are only relevant for very specific pages
  // notes: IPageRevisionNote[];
  lastModifiedAt: Timestamp;
  ignore?: boolean;
  note?: string;

  createdAt: Timestamp | null; // mind that this was introduced only later, hence it can be null
  createdBy: string | null; // mind that this was introduced only later, hence it can be null
  provider: 'app' | 'user';
}

export interface IPageLabel {
  createdAt: Timestamp;
  createdBy: string;
  labelId: string; // referencing a label definition, that contains color, name and all other kinds of things
}

export const PARTIAL_SLUG_LENGTH = 6;
export interface IPageMetadata {
  url: string;
  spaceId: string;
  createdAt: Timestamp;
  labels: IPageLabel[];
  revisions: IPageRevision[]; // should we consider doing a subcollection here?
  sitemapError?: 'NOT_FOUND' | 'NO_LAST_MODIFIED' | null;
  partialSlug?: string; // technically always defined - it's undefined because we will never let a consumer define this. It's automatically populated by a db hook
}

export interface IPage {
  href: string;
  domain: string;
}

export interface IPageWithCountsAndTrends extends IPage {
  counts: ICounterWithTrend;
}

export interface IPageWithCountsAndTrendsAndSales
  extends IPageWithCountsAndTrends {
  sales: ICountWithTrend;
  salesCount: ICountWithTrend;
  orderCount: ICountWithTrend;
  saleValue: ICountWithTrend;
  tagIds: string[];
}

export type PageScreenshotStatus =
  | 'PENDING'
  | 'PENDING_LOADED'
  | 'PENDING_LINKS_FOUND'
  | 'DONE'
  | 'ERROR'
  | 'NO_SCRIPT'
  | 'TIMEOUT';

export const isScreenshotPending = (s: IPageScreenshot) =>
  s.status === 'PENDING' ||
  s.status === 'PENDING_LOADED' ||
  s.status === 'PENDING_LINKS_FOUND';

export interface IPageScreenshotDetails {
  links: ProductLinkPosition[]; // will be stored with compression
  storagePaths: string[];
  dimensions: Dimensions;
}

export interface IPageScreenshot {
  spaceId: string;
  url: string;
  createdBy: string;
  createdAt: Timestamp;
  finishedAt: Timestamp | null;
  pageModifiedAt: Timestamp | null;
  status: PageScreenshotStatus;
  hasError: boolean;
  byDevice: {
    desktop: IPageScreenshotDetails;
    mobile?: IPageScreenshotDetails;
  };
  v: 2;
}

// the actual values are prefixed with letters, so that
// we can use them with RDB's orderBy
export enum QueueStatus {
  QUEUED = 'aQueued',
  RUNNING = 'bRunning',
  DONE = 'cDone',
  ERROR = 'dError',
  ABORTED = 'eAborted',
  NO_SCRIPT = 'fNoScript',
  TIMEOUT = 'gTimeout'
}

export const isErroredQueueStatus = (status: QueueStatus) =>
  status === QueueStatus.ERROR ||
  status === QueueStatus.NO_SCRIPT ||
  status === QueueStatus.TIMEOUT;

export const isQueueItemDone = (d: IPageScreenshotQueueItem) =>
  d.status === QueueStatus.DONE || isErroredQueueStatus(d.status);

export const isQueueItemActive = (d: IPageScreenshotQueueItem) =>
  d.status === QueueStatus.QUEUED || d.status === QueueStatus.RUNNING;

export interface IPageScreenshotQueueItem {
  spaceId: string;
  url: string;
  lastKnownModificationDate: number | null;
  lastRun: number | null;
  sortKey: string; // a combination of status + createdAt -> we want to make queries to find the first queued item
  status: QueueStatus;
  running: boolean; // needed to sort by it
  errored: boolean; // needed to sort by it
  queuedAt: number;
  runningSince: number | null;
}

// hold queue items per space and per page in RDB
// add a listener to the status field of each individual page
// whenever a status changes to done/error, check if there are other queued pages
// take the first, create an IPageScreenshot document
// the onCreate listener for IPageScreenshot picks it up, screenshots the document
// When done, adds status to the RDB. If there are more items in the queue, we continue
//
// Whenever the denormalization runs, look at all pages which triggered events.
// Check out the queue and their sitemap.
// If we got pages that are not known to the queue, immediately add an entry
// (this triggers the process of taking screenshots)
// Use this opportunity to compare all page info we have with the sitemap.
// If there are new modifications, also add them to the queue (where adding to the queue means:
// change the status to a state, which triggers the creation of a new screenshot)

export type CreateRevisionManuallyArgs = {
  spaceId: string;
  pageUrl: string;
  immediatelyCollectScreenshot: boolean;
  modificationDate: UnixMilliseconds;
};
