import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { HelpIcon } from '../../../components/HelpIcon';
import { Loader } from '../../../components/Loader';
import { Currency, Number } from '../../../components/Number';
import { CurrencyCode } from '../../../domainTypes/currency';
import { SALE_UI_CONFIG } from '../../../domainTypes/performance';
import { styled } from '../../../emotion';
import { useDialogState } from '../../../hooks/useDialogState';
import { ARTICLES } from '../../../services/beacon';

const ProgressOuter = styled('div')`
  display: flex;
  height: ${(p) => p.theme.spacing(1.5)}px;
  width: 100%;
  border-radius: ${(p) => p.theme.shape.borderRadius / 2}px;
  overflow: hidden;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  background-color: ${(p) => p.theme.palette.grey[200]};
`;

const ProgressInner = styled('div')`
  height: 100%;
  color: ${(p) => p.theme.palette.common.white};
  background-color: ${(p) => p.theme.palette.grey[900]};
  transition: width 0.3s ease-in-out;
`;

const ProgressSection = ({
  title,
  percent,
  earnings,
  currency
}: {
  title: string;
  percent: number;
  earnings?: number;
  currency?: CurrencyCode;
}) => {
  const hasRevenueFigure = earnings && currency;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '6px'
        }}
      >
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <Typography variant="body2" gutterBottom component="div">
            {title}
          </Typography>
          {hasRevenueFigure && (
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              <Number n={percent} format="percent" digits={1} />
            </Typography>
          )}
        </div>
        {earnings && currency ? (
          <Typography variant="body2" component="div">
            <Currency cents={earnings} currency={currency} />
          </Typography>
        ) : (
          <Typography variant="body2" component="div">
            <Number n={percent} format="percent" digits={2} />
          </Typography>
        )}
      </div>
      <ProgressOuter>
        <ProgressInner
          style={{
            width: `${percent * 100}%`
          }}
        ></ProgressInner>
      </ProgressOuter>
    </div>
  );
};

type IVariant = 'progress' | 'summary';

const EmptySalesDialogContent = () => (
  <DialogContent>
    <Typography paragraph style={{ marginBottom: '24px' }}>
      There are a few reasons why you might not be able to see sales during this
      time period. If you think it's a mistake, you can always contact us using
      the little bubble in the bottom right corner of the screen.
    </Typography>
    <ol>
      <li>
        <b>Sales were made with a network that doesn't support Smart Labels </b>
        (such as Klook or Voyagin).
        <br />
        <br />
      </li>
      <li>
        <b>Sales were made with Amazon</b>, for which we're still building
        page-level support! Be sure to follow the tutorial on{' '}
        <a
          href="https://affilimate.io/blog/how-to-track-affiliate-sales-on-amazon?utm_source=app"
          style={{ textDecoration: 'underline' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          adding tracking IDs to Amazon links
        </a>{' '}
        to be prepared for that feature.
        <br />
        <br />
      </li>
      <li>
        <b>Sales were made with a network we haven't integrated yet</b> (
        <a
          href="https://help.affilimate.io/articles/reporting-integrations/?utm_source=app"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          See our list of integrations
        </a>
        ).
        <br />
        <br />
      </li>
      <li>
        <b>
          Smart Labels aren't turned on in your Performance integration settings
        </b>
        . (
        <a
          href="https://help.affilimate.io/articles/smart-labels/##how-to-enable-smart-labels-for-cloaked-links?utm_source=app"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          Learn how to do that!
        </a>
        )<br />
        <br />
      </li>
      <li>
        <b>You're using Thirsty Affiliates or Pretty Links</b>, and haven't set
        up parameter pass-through (
        <a
          href="https://help.affilimate.io/articles/smart-labels/##how-to-enable-smart-labels-for-cloaked-links?utm_source=app"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          Learn how to do that!
        </a>
        )<br />
        <br />
      </li>
      <li>
        <b>Links on this page use a URL shortener</b> that doesn't support
        parameter pass-through , like <u>fave.co</u> or <u>shrsl.com</u>. You
        can fix this by gradually creating new "long links" for Skimlinks and
        Shareasale, respectively.
        <br />
        <br />
      </li>
      <li>
        <b>You didn't make any sales :(</b> Sad, but always a possibility.
      </li>
    </ol>
  </DialogContent>
);

const Overlay = styled('div')((p) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}));

export type TotalsV2 = {
  total: number;
  final: number;
  pending: number;
  refunded: number;

  count_gross: number;
  count_net: number;
  gmv_sum_net: number;
};

export const TotalsCardV2 = ({
  totals: totalsOrig,
  loading,
  variant = 'progress',
  currency
}: {
  totals: void | TotalsV2;
  loading?: boolean;
  variant?: IVariant;
  currency: CurrencyCode;
}) => {
  // Currency for the empty earning doesn't matter - data here is just a placeholder
  const totals = useMemo<TotalsV2>(
    () =>
      totalsOrig || {
        total: 0,
        final: 0,
        pending: 0,
        refunded: 0,
        count_gross: 0,
        count_net: 0,
        gmv_sum_net: 0
      },
    [totalsOrig]
  );
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { total, final, pending, refunded, count_gross, count_net } = totals;
  const baseValue = Math.max(final + pending + Math.abs(refunded));
  const hasNoSales = !count_gross;
  const wrapperStyles = hasNoSales
    ? {
        filter: 'blur(8px)',
        zIndex: -1,
        opacity: 0.3
      }
    : {};

  const lostCommissionPercent = 1 - count_net / count_gross;

  return (
    <>
      <div style={{ width: '100%', position: 'relative' }}>
        {loading && !totalsOrig && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        {hasNoSales && !loading && (
          <Overlay>
            <Typography
              component="p"
              style={{ textAlign: 'center', marginBottom: '36px' }}
            >
              You don't have any earnings tracked during this time frame.
              <br />
              <br />
              <Button
                style={{ position: 'relative', zIndex: 2 }}
                onClick={openDialog}
                variant="outlined"
              >
                Learn more
              </Button>
            </Typography>
          </Overlay>
        )}
        <div style={wrapperStyles}>
          <Typography
            variant="h4"
            component="p"
            gutterBottom
            style={{ textAlign: 'center' }}
          >
            <Currency cents={total} currency={currency} />
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="textSecondary"
            style={{ textAlign: 'center', marginBottom: '38px' }}
          >
            <strong>
              <Currency cents={totals.gmv_sum_net} currency={currency} />
            </strong>{' '}
            sales volume
          </Typography>
          <ProgressSection
            title={SALE_UI_CONFIG.Final.label}
            percent={final / baseValue}
            earnings={final}
            currency={currency}
          />
          <ProgressSection
            title={SALE_UI_CONFIG.Pending.label}
            percent={pending / baseValue}
            earnings={pending}
            currency={currency}
          />
          <ProgressSection
            title={SALE_UI_CONFIG.Refunded.label}
            percent={refunded / baseValue}
            earnings={-refunded}
            currency={currency}
          />
          <ProgressSection
            title={SALE_UI_CONFIG.Lost.label}
            percent={lostCommissionPercent}
          />
          <br />
          <HelpIcon
            type="sidebar"
            articleId={ARTICLES.transactions.statuses}
            color="gray"
          >
            How transaction statuses work
          </HelpIcon>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Why are there no commissions?</DialogTitle>
        <EmptySalesDialogContent />
        <DialogActions>
          <Button variant="contained" onClick={closeDialog}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
