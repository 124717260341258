import React, { useMemo } from 'react';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useTimeframe } from '../../../../../components/analytics_v2/Timeframe';
import {
  formatMetric,
  metricTitle
} from '../../../../../services/analyticsV2/metrics';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar
} from '../../../../../components/Charts/ChartCard';
import { useTypedStringQueryParam } from '../../../../../routes';
import { usePromise } from '../../../../../hooks/usePromise';
import { ILegendItem, Legend } from '../../../../../components/Charts/Util';
import {
  IntervalSelector,
  useInterval
} from '../../../../../components/analytics_v2/IntervalSelector';
import { Slot } from '../service/slot';
import { useFilterClauses } from '../../../../../components/analytics_v2/Filters/hooks';
import { Centered } from '../../../../../layout/Centered';
import { Loader } from '../../../../../components/Loader';
import Typography from '@material-ui/core/Typography';
import { TimeseriesChart } from '../../../../../components/analytics_v2/Chart/TimeseriesChart';
import { lineSeries } from '../../../../../components/analytics_v2/Chart/lineSeries';
import { tooltip } from '../../../../../components/analytics_v2/Chart/tooltip';
import { xAxis } from '../../../../../components/analytics_v2/Chart/xAxis';
import { yAxis } from '../../../../../components/analytics_v2/Chart/yAxis';
import { timeseries } from '../../../../../components/analytics_v2/Chart/service/query';
import { readDataKey } from '../../../../../components/analytics_v2/Chart/service/keys';
import { CustomDimensionOptionLabel } from '../../../../../components/CustomDimensionOptionLabel';

type ChartMetric = typeof chartMetrics[number];

const chartMetrics = [
  'c',
  'commission_sum_net',
  'epc_net',
  'gmv_sum_net'
] as const;

const TimeseriesCard: React.FC = ({ children }) => {
  const [metric, setMetric] = useChartMetric();
  const items = useMemo<ILegendItem[]>(
    () =>
      chartMetrics.map((m) => ({
        color: '#444',
        shape: 'circle',
        active: metric === m,
        label: metricTitle(m),
        onClick: () => setMetric(m)
      })),
    [metric, setMetric]
  );

  return (
    <ChartCard
      noMaximize
      heading={metricTitle(metric)}
      topRight={
        <div style={{ marginRight: '36px' }}>
          <IntervalSelector />
        </div>
      }
    >
      {children}
      <ChartCardFooter>
        <ChartCardFooterBar>
          <Legend items={items} />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};

const useChartMetric = () =>
  useTypedStringQueryParam<ChartMetric>('metric', 'c');

interface CustomDimensionTimeseriesProps {
  slot: Slot;
}

const useCustomDimensionTimeseries = (slot: Slot) => {
  const [metric] = useChartMetric();
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const interval = useInterval();
  const filters = useFilterClauses();
  return usePromise(
    () => timeseries(space.id, range, interval, [metric], slot, filters),
    [space.id, range, interval, filters, metric, slot]
  );
};

const HEIGHT = 300;

export const CustomDimensionTimeseries: React.FC<CustomDimensionTimeseriesProps> = ({
  slot
}) => {
  const [timeseries, loading] = useCustomDimensionTimeseries(slot);
  const [metric] = useChartMetric();
  const metrics = useMemo(() => [metric], [metric]);

  if (loading || !timeseries) {
    return (
      <TimeseriesCard>
        <Centered height={HEIGHT}>
          <Loader size={32} />
        </Centered>
      </TimeseriesCard>
    );
  }
  if (timeseries.length === 0) {
    return (
      <TimeseriesCard>
        <Centered height={HEIGHT}>
          <Typography variant="h6">No data</Typography>
        </Centered>
      </TimeseriesCard>
    );
  }

  return (
    <TimeseriesCard>
      <TimeseriesChart
        data={timeseries}
        field={slot}
        metrics={metrics}
        ResponsiveContainerProps={{ height: HEIGHT }}
      >
        {({ fieldValues, context }) => {
          return [
            xAxis(context),
            yAxis(metric, 'right', context),
            tooltip(context, 'day', {
              formatter: (value, name, { dataKey }) => {
                const [metric] = readDataKey(dataKey);
                return [
                  formatMetric(value as number, metric, context.currency),
                  <CustomDimensionOptionLabel slot={slot} value={name} />
                ];
              }
            }),
            lineSeries(fieldValues, metric)
          ];
        }}
      </TimeseriesChart>
    </TimeseriesCard>
  );
};
