import { InputBase, MenuItem, Select } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  AnalyticsInterval,
  AnalyticsIntervalUnit
} from '../../../domainTypes/analytics_v2';
import { useQueryParam } from '../../../routes';
import { useCurrentUser } from '../../../services/currentUser';

const OPTIONS = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'quarter', label: 'Quarter' },
  { value: 'year', label: 'Year' }
];

const useIntervalSelectorState = () => {
  return useQueryParam<AnalyticsIntervalUnit>(
    'interval',
    // NOTE: Add some validation here. Time for `invariant` library? ;)
    (unit) => (unit as AnalyticsIntervalUnit) || OPTIONS[0].value,
    (unit) => unit
  );
};

export const IntervalSelector = () => {
  const [interval, setInterval] = useIntervalSelectorState();
  return (
    <Select
      value={interval}
      onChange={(ev) => {
        const unit = ev.target.value as AnalyticsIntervalUnit;
        return setInterval(unit);
      }}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {OPTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}&nbsp;&nbsp;
        </MenuItem>
      ))}
    </Select>
  );
};

export const useInterval = (): AnalyticsInterval => {
  const { tz } = useCurrentUser();
  const [interval] = useIntervalSelectorState();
  return useMemo(
    () => ({
      tz: tz,
      value: 1,
      unit: interval
    }),
    [interval, tz]
  );
};
