import { InputBase, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { AnalyticsIntervalUnit } from '../../../domainTypes/analytics_v2';

const OPTIONS = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'quarter', label: 'Quarter' },
  { value: 'year', label: 'Year' }
];

export const IntervalSelector = ({
  value,
  onChange
}: {
  value: AnalyticsIntervalUnit;
  onChange: (value: AnalyticsIntervalUnit) => void;
}) => {
  return (
    <Select
      value={value}
      onChange={(ev) => {
        const gm = ev.target.value as AnalyticsIntervalUnit;
        return onChange(gm);
      }}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {OPTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
