import { useState } from 'react';

class MemoryStore implements Storage {
  store: { [key: string]: string } = {};

  [key: string]: any;
  length: number = 0;

  clear(): void {
    throw new Error('Method not implemented.');
  }
  getItem(key: string): string | null {
    return this.store[key] || null;
  }
  key(index: number): string | null {
    throw new Error('Method not implemented.');
  }
  removeItem(key: string): void {
    delete this.store[key];
  }
  setItem(key: string, value: string): void {
    this.store[key] = value;
  }
}

class JsonStorage {
  storage: Storage;
  prefix: string;

  constructor(storage: Storage, prefix = '') {
    this.storage = storage;
    this.prefix = prefix;
  }

  getItem<T>(key: string): T | null {
    const item = this.storage.getItem(this.prefix + key);
    return item ? (JSON.parse(item) as T) : null;
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: any): void {
    this.storage.setItem(this.prefix + key, JSON.stringify(value, null, 0));
  }
}

const ls =
  'localStorage' in window && window.localStorage
    ? window.localStorage
    : new MemoryStore();

const PREFIX = 'am_app-';
export const localJsonStorage = new JsonStorage(ls, PREFIX);
export const useLocalJsonStorage = <T>(
  key: string,
  defaultValue: T
): [T, (nextValue: T) => void] => {
  const [v, setV] = useState(() => {
    const value = localJsonStorage.getItem<T>(key);
    return value === null ? defaultValue : value;
  });
  const setValue = (nextValue: T) => {
    localJsonStorage.setItem(key, nextValue);
    setV(nextValue);
  };

  return [v, setValue];
};

export const useVersionedLocalJsonStorageWithDefaults = <
  T extends { v: string | number }
>(
  key: string,
  defaultValue: () => T
): [T, (nextValue: Partial<T>) => void] => {
  const defaults = defaultValue();
  const [config, setConfig] = useLocalJsonStorage<T>(key, defaults);

  const mergedConfig =
    config.v === defaults.v ? { ...defaults, ...config } : defaults;
  const updateConfig = (nextConfig: Partial<T>) =>
    setConfig({ ...mergedConfig, ...nextConfig });

  return [mergedConfig, updateConfig] as [
    typeof mergedConfig,
    typeof updateConfig
  ];
};
