import { compact } from 'lodash';
import React, { useMemo } from 'react';
import {
  Data,
  EarningsBarChart,
  limitAndSort
} from '../../../../components/Charts/EarningsChart';
import { ITimeBasedCounter } from '../../../../components/Charts/TrafficBiaxialChart';
import { Timeframe } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import { IDailyEarning } from '../../../../domainTypes/performance';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { CHART_HEIGHT } from './constants';
import { styled } from '../../../../emotion';

type Props = {
  sales: { [partnerKey: string]: IDailyEarning[] };
  traffic?: ITimeBasedCounter[];
  currency: CurrencyCode;
  timeframe: Timeframe;
};

const Wrapper = styled('div')`
  .recharts-tooltip-wrapper {
    z-index: 2;
  }
`;

export const EarningsChart = ({
  sales,
  traffic,
  currency,
  timeframe
}: Props) => {
  const data: Data[] = useMemo(() => {
    if (!sales) {
      return EMPTY_ARR;
    }
    return limitAndSort(
      compact(
        Object.entries(sales).map(([pK, earnings]) => {
          const partner = getKnownPartnerForKey(pK);
          if (!partner) {
            return null;
          }

          return {
            container: {
              key: partner.key,
              color: partner.color,
              label: partner.name
            },
            earnings
          };
        })
      ),
      15
    );
  }, [sales]);

  return (
    <Wrapper>
      <EarningsBarChart
        data={data}
        clickData={traffic}
        height={CHART_HEIGHT}
        currency={currency}
        size="small"
        timeframe={timeframe}
      />
    </Wrapper>
  );
};
