import { css, styled } from '../../../../emotion';
import { Paper } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FlexContainer } from '../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useCurrentUser } from '../../../../services/currentUser';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { ALL_COLUMNS, ALL_SORTERS, TableMetadata } from '../index';
import {
  AnalyticsColumnTransformer,
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { compact, isNil, max } from 'lodash';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { toPercent } from '../../../Number';
import { Centered } from '../../../../layout/Centered';
import { Loader } from '../../../Loader';
import { RowsRenderer } from '../../../GroupableList';
import { IColumn } from '../../../Table/Column';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';

export const TopPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)}px 0;
`;

interface TopHeaderProps {
  title: string;
  description?: string;
}

export const TopHeader: React.FC<TopHeaderProps> = ({
  title,
  description,
  children
}) => {
  return (
    <FlexContainer
      justifyContent="space-between"
      className={css((t) => ({
        height: 60,
        padding: `${t.spacing(2)}px`
      }))}
    >
      <div>
        <Typography
          variant="body1"
          gutterBottom
          className={css(() => ({
            paddingLeft: 6
          }))}
        >
          <strong>{title}</strong>
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={css(() => ({
            paddingLeft: 6
          }))}
        >
          {description}
        </Typography>
      </div>
      {children}
    </FlexContainer>
  );
};

const TopFooterWrapper = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
  color: ${(p) => p.theme.palette.text.secondary};
  text-align: center;
`;

export const TopFooter = ({ route }: { route: string }) => {
  return (
    <TopFooterWrapper>
      <Link to={route}>
        View all{' '}
        <ArrowRight size={14} style={{ position: 'relative', top: '2px' }} />
      </Link>
    </TopFooterWrapper>
  );
};

const RelativeContainerFullWidth = styled('div')(() => ({
  width: '100%',
  position: 'relative'
}));

const BackgroundColorContainer = styled('div')<{
  color: string;
  percentage: number;
}>((p) => ({
  backgroundColor: p.color,
  width: `${p.percentage * 100}%`,
  position: 'absolute',
  padding: `${p.theme.spacing(1)}px ${p.theme.spacing(1)}px`,
  borderRadius: p.theme.custom.border.radius()
}));

const ContentContainer = styled('div')((p) => ({
  position: 'relative',
  padding: `${p.theme.spacing(1)}px ${p.theme.spacing(1)}px`
}));

const CellContainer: React.FC<{
  color?: string;
  percentage?: number;
}> = ({ color, percentage, children }) => {
  return (
    <RelativeContainerFullWidth>
      {color && (
        <BackgroundColorContainer color={color} percentage={percentage || 0}>
          &nbsp;
        </BackgroundColorContainer>
      )}
      <ContentContainer>{children}</ContentContainer>
    </RelativeContainerFullWidth>
  );
};

export const createNameColumn = (
  cell: Column['cell'],
  head: string,
  color: string,
  metric: Metric
): Column => ({
  key: 'name',
  head: () => head,
  cell: (d, o) => {
    const percent = toPercent(d.data[metric]?.curr || 0, o.maxVal);
    return (
      <CellContainer color={color} percentage={percent || 0}>
        {cell(d, o)}
      </CellContainer>
    );
  },
  align: 'left',
  sortable: false,
  width: 200,
  flexGrow: 4
});

type Column = IColumn<
  AnalyticsResponseRowWithComparison,
  Metric | 'name',
  TableMetadata & { maxVal: number }
>;

const simpleColumn = (col: Column): Column => {
  return {
    ...col,
    sortable: false,
    headInfo: undefined
  };
};

interface TopTableProps<T extends Metric> {
  groupField: AnalyticsField;
  metrics: readonly T[];
  metric: T;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  compareRange?: ISOTimeRange;
  columnTransformers?: AnalyticsColumnTransformer[];
  nameColumn: Column;
}

export function TopTable<T extends Metric>({
  groupField,
  filters,
  columnTransformers = EMPTY_ARR,
  nameColumn,
  metrics,
  metric,
  range,
  compareRange
}: TopTableProps<T>) {
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();
  const spaceId = space.id;

  const topTableLimit = spaceId === 'ddM7Qq1gR' ? 25 : 7;
  const sorter = useMemo(() => ALL_SORTERS[metric], [metric]);
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      compare: compareRange && { range: compareRange },
      select: [...metrics],
      paginate: { page: 1, limit: topTableLimit },
      orderBy: [{ field: metric, direction: 'DESC' }],
      groupBy: [groupField],
      columnTransformers,
      filters: compact([
        ...filters,
        // TODO: maybe additional filter?
        //
        // Device is an enum - there are no empties here, so we can ignore this
        // THis will also be required for others fields.
        // Arguably the backend could also be smart about it
        // TODO: 'includeUnknown' prop?
        groupField !== 'device' && {
          field: groupField,
          condition: 'not in',
          values: ['']
        }
      ])
    }),
    [
      range,
      compareRange,
      metrics,
      metric,
      groupField,
      columnTransformers,
      filters,
      topTableLimit
    ]
  );

  const [data, loading] = useAnalyticsQueryV2(spaceId, query);

  const otherProps = useMemo(
    () => ({
      showComparison: !isNil(compareRange),
      currency,
      spaceId,
      maxVal:
        (data && max(data.rows.map((r) => r.data[metric]?.curr || 0))) || 0
    }),
    [compareRange, currency, data, metric, spaceId]
  );

  const columns = useMemo(() => {
    const metricCols = metrics.map((m) => simpleColumn(ALL_COLUMNS[m]));
    return [nameColumn, ...metricCols];
  }, [metrics, nameColumn]);

  if (!data && loading) {
    return (
      <Centered height={250}>
        <Loader size={24} />
      </Centered>
    );
  }
  if (!data) {
    // should never happen
    return null;
  }

  if (data.rows.length === 0) {
    return (
      <Centered height={250}>
        <Typography variant="body1" color="textSecondary">
          No data found
        </Typography>
      </Centered>
    );
  }

  return (
    <RowsRenderer
      columns={columns}
      sorter={sorter}
      rows={data.rows}
      otherProps={otherProps}
      renderHead={true}
      rowToKey={(d) => d.group[groupField]}
      variant="plain"
    />
  );
}
