import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { UNKNOWN } from '../../../../components/GroupableList';
import { getTrend } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { IEarning } from '../../../../domainTypes/performance';
import { useRoutes } from '../../../../routes';
import { getPathname } from '../../../../services/url';
import { MAX_CONTENT_ROWS } from './constants';
import { EarningsTable, EarningsTableRow } from './EarningsTable';

type ContentTrendsEarningsProps = {
  ds: {
    [pageUrl: string]: {
      prev: IEarning | null;
      curr: IEarning;
    };
  };
  currency: CurrencyCode;
};

export const ContentTrendsByEarnings = ({
  ds,
  currency
}: ContentTrendsEarningsProps) => {
  const { ROUTES } = useRoutes();
  const topRows: EarningsTableRow[] = useMemo(() => {
    return sortBy(Object.entries(ds), ([_, e]) => -e.curr.total)
      .filter(([pageUrl]) => {
        return pageUrl.toUpperCase() !== UNKNOWN.toUpperCase();
      })
      .slice(0, MAX_CONTENT_ROWS)
      .map<EarningsTableRow>(([pageUrl, e]) => {
        return {
          key: pageUrl,
          title: getPathname(pageUrl),
          linkTo: ROUTES.content.details.trends.url(pageUrl),
          earnings: {
            count: e.curr.total,
            lastCount: e.prev?.total || 0,
            trend: getTrend(e.prev?.total || 0, e.curr.total)
          }
        };
      });
  }, [ds, ROUTES]);

  return <EarningsTable rows={topRows} currency={currency} showComparison />;
};
