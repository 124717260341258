import { useMemo } from 'react';
import { AnalyticsFilter } from '../../../domainTypes/analytics_v2';
import {
  FilterDefinition,
  FiltersDefinition,
  printFilters,
  readFilters
} from '../../../domainTypes/filters';
import { useQueryParam } from '../../../routes';
import { useDomains } from '../../../services/domains';
import { groupFilterClause } from '../../../services/referrers-query';

export const useFiltersParam = () => {
  return useQueryParam<FiltersDefinition>(
    'filter',
    (param: string | null | undefined) => (param ? readFilters(param) : []),
    printFilters
  );
};

interface FilterContext {
  domains: string[];
}

const useFilterContext = () => {
  const domains = useDomains();
  return useMemo<FilterContext>(
    () => ({
      domains
    }),
    [domains]
  );
};

const toFilterClauses = (
  definition: FilterDefinition,
  context: FilterContext
): AnalyticsFilter[] => {
  switch (definition.k) {
    case 'campaign_status':
      // It is not a real filter!
      return [];
    case 'campaign_advertiser':
      // It is not a real filter!
      return [];
    case 'campaign_manager':
      // It is not a real filter!
      return [];
    case 'integration_id':
      return [
        {
          field: 'integration_id',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'advertiser':
      return [
        {
          field: 'advertiser_name',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'channel':
      return [
        {
          field: 'channel_id',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'click_data_02':
      return [
        { field: 'click_data_02', condition: 'in', values: definition.v }
      ];
    case 'click_data_03':
      return [
        { field: 'click_data_03', condition: 'in', values: definition.v }
      ];
    case 'click_data_04':
      return [
        { field: 'click_data_04', condition: 'in', values: definition.v }
      ];
    case 'click_data_05':
      return [
        { field: 'click_data_05', condition: 'in', values: definition.v }
      ];
    case 'click_data_06':
      return [
        { field: 'click_data_06', condition: 'in', values: definition.v }
      ];
    case 'click_data_07':
      return [
        { field: 'click_data_07', condition: 'in', values: definition.v }
      ];
    case 'click_data_08':
      return [
        { field: 'click_data_08', condition: 'in', values: definition.v }
      ];
    case 'click_data_09':
      return [
        { field: 'click_data_09', condition: 'in', values: definition.v }
      ];
    case 'click_data_10':
      return [
        { field: 'click_data_10', condition: 'in', values: definition.v }
      ];
    case 'click_data_01':
      return [
        {
          field: 'click_data_01',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'country':
      return [
        {
          field: 'country',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'device':
      return [
        {
          field: 'device',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'utm_medium':
      return [
        {
          field: 'utm_medium',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'utm_source':
      return [
        {
          field: 'utm_source',
          condition: 'in',
          values: definition.v
        }
      ];

    case 'utm_term':
      return [
        {
          field: 'utm_term',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'utm_content':
      return [
        {
          field: 'utm_content',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'utm_campaign':
      return [
        {
          field: 'utm_campaign',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'referrer': {
      if (definition.v.mode === 'group') {
        if (definition.v.v === null) return [];
        return groupFilterClause(definition.v.v, context.domains);
      } else {
        return [
          {
            field: 'referrer_entry_origin',
            condition: 'in',
            values: definition.v.v
          }
        ];
      }
    }
    case 'tag':
      return [
        {
          field: 'tags',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'site':
      return [
        {
          field: 'page_url_origin',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'platform':
      return [
        {
          field: 'pk',
          values: definition.v,
          condition: 'in'
        }
      ];
    case 'payout_id':
      return [
        {
          field: 'payout_id',
          condition: 'in',
          values: definition.v
        }
      ];
    case 'payout_status':
      return [
        {
          field: 'payout_status',
          values: definition.v,
          condition: 'in'
        }
      ];
    case 'transaction_status':
      return [
        {
          field: 'sale_status',
          values: definition.v,
          condition: 'in'
        }
      ];
    case 'transaction_type':
      return [
        {
          field: 'sale_type',
          values: definition.v,
          condition: 'in'
        }
      ];
  }
};
export const useFilters = () => {
  const [filters] = useFiltersParam();
  const context = useFilterContext();
  return useMemo(
    () =>
      filters.flatMap((filter) => {
        const clauses = toFilterClauses(filter, context);
        return clauses.map((clause) => ({ clause, dimension: filter.k }));
      }),
    [context, filters]
  );
};

export const useFilterClauses = (): AnalyticsFilter[] => {
  const [filters] = useFiltersParam();
  const context = useFilterContext();
  return useMemo(
    () => filters.flatMap((filter) => toFilterClauses(filter, context)),
    [context, filters]
  );
};
