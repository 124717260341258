import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { last } from 'lodash';
import React from 'react';
import { Check as IconCheck } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { PageBody, SideNavHowTo } from '../../../../layout/PageBody';
import { PageToolbar, PageToolbarTitle } from '../../../../layout/PageToolbar';
import { useRoutes } from '../../../../routes';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePage } from '../../../../services/page';
import { getPathname } from '../../../../services/url';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { RevisionsEmptyState } from '../../components/EmptyStateRevision';
import { RevisionCreateButton } from '../../components/RevisionCreateDialog';
import { newSideNavProps, sideNavProps } from '../../services/detailsSideNav';
import { RevisionReadyWrapper, Revisions } from './components/RevisionsTable';

const HowTo: SideNavHowTo = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>What are revisions?</DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="p" paragraph>
          <strong>
            Revisions are a lightweight way to run experiments on your blog or
            website.
          </strong>
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Every time you edit a post or page, we'll capture a new screenshot and
          add a new entry to this page.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          We do that because{' '}
          <strong>it's important to look at your data in-context</strong> of how
          your post or page looked at the time the user clicked on your
          affiliate link.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          When a revision has received enough visitors (at least 2,500 over 14
          days or more), you'll see a blue checkmark appear next to it that
          looks like this:
        </Typography>
        <Typography variant="body1" component="span">
          <strong>
            This icon means your revision has enough data to review:
          </strong>
        </Typography>{' '}
        <RevisionReadyWrapper>
          <IconCheck size={14} />
        </RevisionReadyWrapper>
        <br />
        <br />
        <Typography variant="body1" component="p" paragraph>
          If your site doesn't get much traffic yet, you can still use your
          data. Just be aware that the less data you have, the more fluctuation
          you'll see. A little patience will yield more reliable results{' '}
          <span role="img" aria-label="winking">
            😉
          </span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const PageContentDetailsRevisions = ({ url }: { url: string }) => {
  const { ROUTES } = useRoutes();
  const [isExperiment] = useExperimentalContext();
  const sideNav = isExperiment
    ? sideNavProps(ROUTES, url, HowTo)
    : newSideNavProps(ROUTES, url, HowTo);
  const { space } = useCurrentUser();
  const [metadata, loading, error] = usePage(space.id, url);
  const [canView] = useHasCurrentUserRequiredScopes(['revisions.view']);
  const [canEdit] = useHasCurrentUserRequiredScopes(['pages.edit']);
  useErrorLogger(error);
  useTrackMixpanelView('view_revisions', { url });

  const latestRevisionDate = (
    last(metadata ? metadata.data.revisions : []) || null
  )?.lastModifiedAt;

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      <PageToolbar>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
        </PageToolbarTitle>
        {canEdit && latestRevisionDate && (
          <RevisionCreateButton
            space={space}
            pageUrl={url}
            minDate={latestRevisionDate}
          />
        )}
      </PageToolbar>
      {canView ? (
        <>
          {!loading && !metadata && <RevisionsEmptyState />}
          {loading && <Loader height={500} />}
          {metadata && <Revisions url={url} metadata={metadata} />}
        </>
      ) : (
        <NoPermissions />
      )}
    </PageBody>
  );
};
