import React, { useEffect, useRef, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { styled } from '../../../../../emotion';
import { throttle } from 'lodash';
import { InputAdornment, TextField } from '@material-ui/core';
import { useRoutes } from '../../../../../routes';

type SearchItem = {
  slug: string;
  name: string;
  preview: string;
};

const SearchResultName = styled('span')`
  font-size: 16px;
  display: block;
  font-weight: bold;
`;

const SearchResultDescription = styled('span')`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 245px;
  font-size: 12px;
  color: gray;
`;

const SearchResultItem = styled(Link)``;

export const KnowledgeBaseSearch = () => {
  const { ROUTES } = useRoutes();
  const [loading, setLoading] = useState(false);
  const [value] = useState<SearchItem | null | string>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<SearchItem[]>([]);

  const throttled = useRef(
    throttle((newValue: any) => {
      if (newValue === '' || newValue.length < 3) {
        console.log('set options to empty');
        setOptions([]);
        return;
      }
      setLoading(true);
      const urlToFetch = new URL(
        `https://us-central1-affilimate-dev.cloudfunctions.net/helpCenter-search`
      );
      urlToFetch.searchParams.set('query', newValue);
      urlToFetch.searchParams.set('page', '1');
      fetch(urlToFetch.href)
        .then((r) => r.json())
        .then((r: any) => {
          console.log('results', r);
          const opts = r.results.items;
          setOptions(opts);
          setLoading(false);
        });
    }, 1000)
  );

  useEffect(() => throttled.current(inputValue), [inputValue]);

  return (
    <Autocomplete
      id="help-center-search"
      options={options}
      freeSolo
      autoComplete
      filterOptions={(x) => x}
      value={value}
      noOptionsText="No results found for this search"
      loading={loading}
      fullWidth
      open={options.length > 0}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search articles"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size={16} />
              </InputAdornment>
            )
          }}
          fullWidth
          style={{
            minWidth: '300px',
            background: 'white',
            color: 'black',
            borderRadius: '8px'
          }}
        />
      )}
      renderOption={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return (
          <SearchResultItem to={ROUTES.docs.knowledgeBase.url(option.slug)}>
            <SearchResultName>{option.name}</SearchResultName>
            <SearchResultDescription>{option.preview}</SearchResultDescription>
          </SearchResultItem>
        );
      }}
    />
  );
};
