import { compact, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { AdvertiserWithColor } from '../../../../../../components/AdvertiserWithColor';
import {
  Data,
  limitAndSort,
  transformAnalyticsResponseToEarningsChartData
} from '../../../../../../components/Charts/EarningsChartV2';
import { ITimeBasedCounter } from '../../../../../../components/Charts/TrafficBiaxialChart';
import { toPercent } from '../../../../../../components/Number';
import { getTrend } from '../../../../../../domainTypes/analytics';
import {
  AnalyticsField,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../../../../domainTypes/emptyConstants';
import { HOURKEY_FORMAT } from '../../../../../../domainTypes/monitoring';
import { useRoutes } from '../../../../../../routes';
import { AnalyticsGroupContainer } from '../../../../../../services/analyticsV2/groups';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import {
  LoadingObject,
  LoadingValue,
  useAsLoadingObject,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../../../services/db';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../../../services/partner';
import {
  guessCurrentTimezone,
  intervalToTk,
  ISOTimeRange,
  momentRangeToIsoRange
} from '../../../../../../services/time';
import { getPathname } from '../../../../../../services/url';
import { ClicksTableRow } from '../../../../components/DomainSummary/ClicksTable';
import { MAX_CONTENT_ROWS } from '../../../../components/DomainSummary/constants';
import { EarningsTableRow } from '../../../../components/DomainSummary/EarningsTable';
import { EarningsSummaryData } from '../EarningsSummary';

const _useAverageCommissionGroupedBy = (
  spaceId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  grouper: AnalyticsField
) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      select: ['commission_sum_net', 'commission_count_net'],
      groupBy: [grouper]
    };
  }, [range, compareRange, grouper]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, { logLabel: `avg comm by ${grouper}` }),
    (res) => {
      return Object.fromEntries(
        res.rows.map((r) => {
          const grouperValue = r.group[grouper] || '';
          const { commission_sum_net, commission_count_net } = r.data;

          return [
            grouperValue,
            {
              curr:
                (commission_sum_net?.curr || 0) /
                  (commission_count_net?.curr || 0) || 0,
              prev:
                isUndefined(commission_sum_net?.prev) ||
                isUndefined(commission_count_net?.prev)
                  ? undefined
                  : (commission_sum_net?.prev || 0) /
                      (commission_count_net?.prev || 0) || 0
            }
          ];
        })
      );
    }
  );
};

export const useAverageCommissionForDomain = (
  spaceId: string,
  domain: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
) => {
  return useMappedLoadingValue(
    _useAverageCommissionGroupedBy(
      spaceId,
      range,
      compareRange,
      'page_url_origin'
    ),
    (x) => x[domain] || { curr: 0, prev: 0 }
  );
};

const _useOrderRateGroupedBy = (
  spaceId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  grouper: AnalyticsField
) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      select: ['order_count_net', 'c'],
      groupBy: [grouper]
    };
  }, [range, compareRange, grouper]);
  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, { logLabel: `order rate by ${grouper}` }),
    (res) => {
      return Object.fromEntries(
        res.rows.map((r) => {
          const grouperValue = r.group[grouper] || '';
          const { order_count_net, c } = r.data;

          return [
            grouperValue,
            {
              curr: toPercent(order_count_net?.curr, c?.curr),
              prev:
                isUndefined(order_count_net?.prev) || isUndefined(c?.prev)
                  ? undefined
                  : toPercent(order_count_net?.prev, c?.prev)
            }
          ];
        })
      );
    }
  );
};

export const useOrderRateForDomain = (
  spaceId: string,
  domain: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
) => {
  return useMappedLoadingValue(
    _useOrderRateGroupedBy(spaceId, range, compareRange, 'page_url_origin'),
    (x) => x[domain] || { curr: 0, prev: 0 }
  );
};

const _useSummaryEarningsDataGroupedBy = (
  spaceId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  tz: string,
  grouper: AnalyticsField
): {
  tf: LoadingObject<AnalyticsResponse>;
  yesterday: LoadingObject<AnalyticsResponse>;
  monthToDate: LoadingObject<AnalyticsResponse>;
  lastMonth: LoadingObject<AnalyticsResponse>;
  yearToDate: LoadingObject<AnalyticsResponse>;
} => {
  const args = useMemo<{
    tf: AnalyticsQuery;
    yesterday: AnalyticsQuery;
    monthToDate: AnalyticsQuery;
    lastMonth: AnalyticsQuery;
    yearToDate: AnalyticsQuery;
  }>(() => {
    const select: AnalyticsQuery['select'] = ['commission_sum_net'];
    const groupBy: AnalyticsQuery['groupBy'] = [grouper];
    const n = moment.tz(tz).startOf('h');
    return {
      tf: {
        range,
        compare: compareRange
          ? {
              range: compareRange
            }
          : undefined,
        groupBy,
        select
      },
      yesterday: {
        range: {
          start: n.clone().startOf('d').subtract(1, 'd').toISOString(),
          end: n.clone().startOf('d').toISOString()
        },
        groupBy,
        select
      },
      monthToDate: {
        range: {
          start: n.clone().startOf('month').toISOString(),
          end: n.toISOString()
        },
        groupBy,
        select
      },
      lastMonth: {
        range: {
          start: n.clone().startOf('month').subtract(1, 'month').toISOString(),
          end: n.clone().startOf('month').toISOString()
        },
        groupBy,
        select
      },
      yearToDate: {
        range: {
          start: n.clone().startOf('year').toISOString(),
          end: n.toISOString()
        },
        groupBy,
        select
      }
    };
  }, [range, compareRange, tz, grouper]);
  return {
    tf: useAsLoadingObject(
      useAnalyticsQueryV2(spaceId, args.tf, { logLabel: 'tf' }),
      { reportLoadingOnlyOnInitialLoad: true }
    ),
    yesterday: useAsLoadingObject(
      useAnalyticsQueryV2(spaceId, args.yesterday, { logLabel: 'yesterday' }),
      { reportLoadingOnlyOnInitialLoad: true }
    ),
    monthToDate: useAsLoadingObject(
      useAnalyticsQueryV2(spaceId, args.monthToDate, {
        logLabel: 'month to date'
      }),
      { reportLoadingOnlyOnInitialLoad: true }
    ),
    lastMonth: useAsLoadingObject(
      useAnalyticsQueryV2(spaceId, args.lastMonth, { logLabel: 'last month' }),
      { reportLoadingOnlyOnInitialLoad: true }
    ),
    yearToDate: useAsLoadingObject(
      useAnalyticsQueryV2(spaceId, args.yearToDate, {
        logLabel: 'year to date'
      }),
      { reportLoadingOnlyOnInitialLoad: true }
    )
  };
};

// This hook returns data for one grouper, but under the hood
// it just uses a query to get all of them grouped. For Clickhouse
// it's a very similar amount of work, but with with this we save
// making too many CF requests, which introduce more latency.
const useSummaryEarningsDataGrouper = (
  spaceId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  tz: string,
  grouper: AnalyticsField,
  grouperValue: string
): EarningsSummaryData => {
  const data = _useSummaryEarningsDataGroupedBy(
    spaceId,
    range,
    compareRange,
    tz,
    grouper
  );
  return useMemo(() => {
    const getDataForDomain = (x: void | AnalyticsResponse) => {
      return (
        (x &&
          x.rows.find((r) => r.group[grouper] === grouperValue)?.data
            .commission_sum_net) ||
        null
      );
    };
    const { tf, yesterday, monthToDate, lastMonth, yearToDate } = data;
    return {
      tf: {
        curr: getDataForDomain(tf.d)?.curr || 0,
        prev: getDataForDomain(tf.d)?.prev || null,
        loading: tf.loading
      },
      yesterday: {
        curr: getDataForDomain(yesterday.d)?.curr || 0,
        loading: yesterday.loading
      },
      monthToDate: {
        curr: getDataForDomain(monthToDate.d)?.curr || 0,
        loading: monthToDate.loading
      },
      lastMonth: {
        curr: getDataForDomain(lastMonth.d)?.curr || 0,
        loading: lastMonth.loading
      },
      yearToDate: {
        curr: getDataForDomain(yearToDate.d)?.curr || 0,
        loading: yearToDate.loading
      }
    };
  }, [data, grouper, grouperValue]);
};

export const useSummaryEarningsDataForDomain = (
  spaceId: string,
  domain: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  tz: string
): EarningsSummaryData => {
  return useSummaryEarningsDataGrouper(
    spaceId,
    range,
    compareRange,
    tz,
    'page_url_origin',
    domain
  );
};

export const useSummaryEarningsDataTotal = (
  spaceId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  tz: string
) => {
  const args = useMemo<{
    tf: AnalyticsQuery;
    yesterday: AnalyticsQuery;
    monthToDate: AnalyticsQuery;
    lastMonth: AnalyticsQuery;
  }>(() => {
    const select: AnalyticsQuery['select'] = ['commission_sum_net'];
    const n = moment.tz(tz).startOf('h');
    return {
      tf: {
        range,
        compare: compareRange
          ? {
              range: compareRange
            }
          : undefined,
        select
      },
      yesterday: {
        range: {
          start: n.clone().startOf('d').subtract(1, 'd').toISOString(),
          end: n.clone().startOf('d').toISOString()
        },
        select
      },
      monthToDate: {
        range: {
          start: n.clone().startOf('month').toISOString(),
          end: n.toISOString()
        },
        select
      },
      lastMonth: {
        range: {
          start: n.clone().startOf('month').subtract(1, 'month').toISOString(),
          end: n.clone().startOf('month').toISOString()
        },
        select
      }
    };
  }, [range, compareRange, tz]);
  const tf = useAsLoadingObject(
    useAnalyticsQueryV2(spaceId, args.tf, { logLabel: 'tf' })
  );
  const yesterday = useAsLoadingObject(
    useAnalyticsQueryV2(spaceId, args.yesterday, { logLabel: 'yesterday' })
  );
  const monthToDate = useAsLoadingObject(
    useAnalyticsQueryV2(spaceId, args.monthToDate, {
      logLabel: 'month to date'
    })
  );
  const lastMonth = useAsLoadingObject(
    useAnalyticsQueryV2(spaceId, args.lastMonth, { logLabel: 'last month' })
  );

  const summaryData = useMemo<EarningsSummaryData>(() => {
    const getFirstCommission = (x: void | AnalyticsResponse) =>
      (x && x.rows[0]?.data.commission_sum_net) || null;
    return {
      tf: {
        curr: getFirstCommission(tf.d)?.curr || 0,
        prev: getFirstCommission(tf.d)?.prev || null,
        loading: tf.loading
      },
      yesterday: {
        curr: getFirstCommission(yesterday.d)?.curr || 0,
        loading: yesterday.loading
      },
      monthToDate: {
        curr: getFirstCommission(monthToDate.d)?.curr || 0,
        loading: monthToDate.loading
      },
      lastMonth: {
        curr: getFirstCommission(lastMonth.d)?.curr || 0,
        loading: lastMonth.loading
      }
    };
  }, [tf, yesterday, monthToDate, lastMonth]);

  return summaryData;
};

export const useContentTrendsByEarningsForDomain = (
  spaceId: string,
  domain: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
): LoadingValue<EarningsTableRow[]> => {
  const { ROUTES } = useRoutes();
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['commission_sum_net'],
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      groupBy: ['page_url'],
      filters: [
        { field: 'page_url_origin', condition: 'in', values: [domain] },
        { field: 'page_url', condition: 'not in', values: [''] }
      ],
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
      paginate: { page: 1, limit: MAX_CONTENT_ROWS }
    };
  }, [range, compareRange, domain]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, {
      logLabel: `top content by earnings (${domain})`
    }),
    (res) => {
      return compact(
        res.rows.map((r) => {
          const pageUrl = r.group['page_url'];
          if (!pageUrl) {
            return null;
          }
          const d = r.data['commission_sum_net'];
          const curr = d?.curr || 0;
          const prev = d?.prev || 0;
          return {
            key: pageUrl,
            title: getPathname(pageUrl),
            linkTo: ROUTES.content.details.trends.url(pageUrl),
            earnings: {
              count: curr,
              lastCount: prev,
              trend: getTrend(prev, curr)
            }
          };
        })
      );
    }
  );
};

export const useContentTrendsByClicksForDomain = (
  spaceId: string,
  domain: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
): LoadingValue<ClicksTableRow[]> => {
  const { ROUTES } = useRoutes();
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['c'],
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      groupBy: ['page_url'],
      filters: [
        { field: 'page_url_origin', condition: 'in', values: [domain] },
        { field: 'page_url', condition: 'not in', values: [''] }
      ],
      orderBy: [{ field: 'c', direction: 'DESC' }],
      paginate: { page: 1, limit: MAX_CONTENT_ROWS }
    };
  }, [range, compareRange, domain]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, {
      logLabel: `top content by clicks (${domain})`
    }),
    (res) => {
      return compact(
        res.rows.map((r) => {
          const pageUrl = r.group['page_url'];
          if (!pageUrl) {
            return null;
          }
          const d = r.data['c'];
          const curr = d?.curr || 0;
          const prev = d?.prev || 0;
          return {
            key: pageUrl,
            title: getPathname(pageUrl),
            linkTo: ROUTES.content.details.trends.url(pageUrl),
            clicks: {
              count: curr,
              lastCount: prev,
              trend: getTrend(prev, curr)
            }
          };
        })
      );
    }
  );
};

export const useAdvertiserEarningsDataTotal = (
  spaceId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
): LoadingValue<EarningsTableRow[]> => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['commission_sum_net'],
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      groupBy: ['advertiser_name', 'advertiser_id', 'pk'], // i don't remmeber anymore why we look at both name and id - the previous version of the code did it this way, it's mimicked here
      filters: [
        { field: 'advertiser_name', condition: 'not in', values: [''] },
        { field: 'advertiser_id', condition: 'not in', values: [''] },
        { field: 'pk', condition: 'not in', values: [''] }
      ],
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
      paginate: { page: 1, limit: MAX_CONTENT_ROWS }
    };
  }, [range, compareRange]);
  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, {
      logLabel: `top advertisers by earnings`
    }),
    (res) => {
      return compact(
        res.rows.map((r) => {
          const advName = r.group['advertiser_name'];
          const advId = r.group['advertiser_id'];
          const pk = r.group['pk'];
          if (!advName || !advId || !pk) {
            return null;
          }
          const key = `${advName}---${advId}---${pk}`;
          const d = r.data['commission_sum_net'];
          const curr = d?.curr || 0;
          const prev = d?.prev || 0;

          return {
            key,
            title: (
              <AdvertiserWithColor advertiserName={advName} partnerKey={pk} />
            ),
            earnings: {
              count: curr,
              lastCount: prev,
              trend: getTrend(prev, curr)
            }
          };
        })
      );
    }
  );
};

export const useRealtimeReportingMiniChartData = (
  spaceId: string
): LoadingValue<ITimeBasedCounter[]> => {
  const q = useMemo<AnalyticsQuery>(() => {
    const n = moment();
    const range = momentRangeToIsoRange({
      start: n.clone().startOf('h').subtract(48, 'h'),
      end: n.clone().startOf('h')
    });
    return {
      select: ['p', 'c'],
      interval: { unit: 'hour', value: 1, tz: 'UTC' }, // TZ doesn't matter, as we transform them ourselves in the next step
      orderBy: [{ field: 'interval', direction: 'ASC' }],
      range
    };
  }, []);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, { logLabel: 'rt mini chart' }),
    (res) => {
      return res.rows.map((r) => {
        const { interval } = r.group;
        const hourKey = moment
          .utc(interval, 'YYYY-MM-DD HH:mm')
          .tz(guessCurrentTimezone())
          .format(HOURKEY_FORMAT);
        const { p, c } = r.data;
        return {
          ts: hourKey,
          pageViews: p?.curr || 0,
          served: 0, // not in use for this chart
          viewed: 0, // not in use for this chart
          clicked: c?.curr || 0
        };
      });
    }
  );
};

export const useEarningsBarChartData = (
  spaceId: string,
  range: ISOTimeRange,
  tz: string,
  filters: AnalyticsQuery['filters'] = EMPTY_ARR
): LoadingValue<{
  sales: Data[];
  clicks: {
    tk: string;
    clicks: number;
  }[];
}> => {
  const { salesQ, clickQ } = useMemo<{
    salesQ: AnalyticsQuery;
    clickQ: AnalyticsQuery;
  }>(() => {
    return {
      salesQ: {
        range,
        select: ['commission_sum_net'],
        interval: {
          unit: 'day',
          value: 1,
          tz
        },
        filters,
        groupBy: ['pk']
      },
      clickQ: {
        range,
        select: ['c'],
        interval: {
          unit: 'day',
          value: 1,
          tz
        },
        filters
      }
    };
  }, [range, tz, filters]);

  return useMappedLoadingValue(
    useCombineLoadingValues(
      useAnalyticsQueryV2(spaceId, salesQ),
      useAnalyticsQueryV2(spaceId, clickQ)
    ),
    ([sales, clicks]) => {
      const salesData = transformAnalyticsResponseToEarningsChartData(
        sales,
        (r) => r.group['pk'],
        (pk) => {
          const partner =
            getKnownPartnerForKey(pk) || constructPartnerForKey(pk);
          const container: AnalyticsGroupContainer = {
            key: partner.key,
            color: partner.color,
            label: partner.name
          };
          return container;
        },
        tz,
        'commission_sum_net',
        15
      );
      const clickData = clicks.rows.map((r) => ({
        tk: intervalToTk(r.group['interval'], tz),
        clicks: r.data['c']?.curr || 0
      }));
      return {
        sales: limitAndSort(salesData, 'commission_sum_net', 15),
        clicks: clickData
      };
    }
  );
};

// This retrieves data grouped by channel_id - this is on purpose,
// as many of these are called in parallel and can thus be served
// by one database request
export const useEarningsBarChartDataForChannel = (
  spaceId: string,
  range: ISOTimeRange,
  tz: string,
  channelId: string
): LoadingValue<{
  sales: Data[];
  clicks: {
    tk: string;
    clicks: number;
  }[];
}> => {
  const { salesQ, clickQ } = useMemo<{
    salesQ: AnalyticsQuery;
    clickQ: AnalyticsQuery;
  }>(() => {
    return {
      salesQ: {
        range,
        select: ['commission_sum_net'],
        interval: {
          unit: 'day',
          value: 1,
          tz
        },
        groupBy: ['channel_id', 'pk']
      },
      clickQ: {
        range,
        select: ['c'],
        interval: {
          unit: 'day',
          value: 1,
          tz
        },
        groupBy: ['channel_id']
      }
    };
  }, [range, tz]);

  const mapFn = useCallback(
    ([sales, clicks]: [AnalyticsResponse, AnalyticsResponse]) => {
      const filterRowsByChannelId = (
        rows: AnalyticsResponseRowWithComparison[]
      ) => rows.filter((r) => r.group['channel_id'] === channelId);
      const salesData = transformAnalyticsResponseToEarningsChartData(
        sales,
        (r) => r.group['pk'],
        (pk) => {
          const partner =
            getKnownPartnerForKey(pk) || constructPartnerForKey(pk);
          const container: AnalyticsGroupContainer = {
            key: partner.key,
            color: partner.color,
            label: partner.name
          };
          return container;
        },
        tz,
        'commission_sum_net',
        15,
        filterRowsByChannelId
      );
      const clickData = filterRowsByChannelId(clicks.rows).map((r) => ({
        tk: intervalToTk(r.group['interval'], tz),
        clicks: r.data['c']?.curr || 0
      }));
      return {
        sales: limitAndSort(salesData, 'commission_sum_net', 15),
        clicks: clickData
      };
    },

    [channelId, tz]
  );

  return useMappedLoadingValue(
    useCombineLoadingValues(
      useAnalyticsQueryV2(spaceId, salesQ),
      useAnalyticsQueryV2(spaceId, clickQ)
    ),
    mapFn,
    true
  );
};

export const useSummaryEarningsDataForChannel = (
  spaceId: string,
  channelId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null,
  tz: string
): EarningsSummaryData => {
  return useSummaryEarningsDataGrouper(
    spaceId,
    range,
    compareRange,
    tz,
    'channel_id',
    channelId
  );
};

export const useAverageCommissionForChannel = (
  spaceId: string,
  channelId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
) => {
  return useMappedLoadingValue(
    _useAverageCommissionGroupedBy(spaceId, range, compareRange, 'channel_id'),
    (x) => x[channelId] || { curr: 0, prev: 0 }
  );
};

export const useOrderRateForChannel = (
  spaceId: string,
  channelId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
) => {
  return useMappedLoadingValue(
    _useOrderRateGroupedBy(spaceId, range, compareRange, 'channel_id'),
    (x) => x[channelId] || { curr: 0, prev: 0 }
  );
};

// This retrieves data grouped by channel_id - this is on purpose,
// as many of these are called in parallel and can thus be served
// by one database request
export const useEarningsBySubIdForChannel = (
  spaceId: string,
  channelId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
) => {
  // const { ROUTES } = useRoutes();
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['commission_sum_net'],
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      groupBy: ['channel_id', 'tracking_label'],
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
      limitBy: {
        fields: ['channel_id'],
        limit: MAX_CONTENT_ROWS
      }
    };
  }, [range, compareRange]);

  const mapFn = useCallback(
    (res: AnalyticsResponse) => {
      return compact(
        res.rows.map((r) => {
          if (r.group['channel_id'] !== channelId) {
            return null;
          }
          const subId = r.group['tracking_label'];
          if (!subId) {
            return null;
          }
          const d = r.data['commission_sum_net'];
          const curr = d?.curr || 0;
          const prev = d?.prev || 0;
          return {
            key: subId,
            title: subId,
            linkTo: undefined, // TODO
            earnings: {
              count: curr,
              lastCount: prev,
              trend: getTrend(prev, curr)
            }
          };
        })
      );
    },
    [channelId]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, {
      logLabel: `top subIds by earnings (${channelId})`
    }),
    mapFn,
    true
  );
};

// This retrieves data grouped by channel_id - this is on purpose,
// as many of these are called in parallel and can thus be served
// by one database request
export const useEarningsByCampaignIdForChannel = (
  spaceId: string,
  channelId: string,
  range: ISOTimeRange,
  compareRange: ISOTimeRange | null
) => {
  // const { ROUTES } = useRoutes();
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['commission_sum_net'],
      range,
      compare: compareRange ? { range: compareRange } : undefined,
      groupBy: ['channel_id', 'utm_campaign'],
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
      limitBy: {
        fields: ['channel_id'],
        limit: MAX_CONTENT_ROWS
      }
    };
  }, [range, compareRange]);

  const mapFn = useCallback(
    (res: AnalyticsResponse) => {
      return compact(
        res.rows.map((r) => {
          if (r.group['channel_id'] !== channelId) {
            return null;
          }
          const campaign = r.group['utm_campaign'];
          if (!campaign) {
            return null;
          }
          const d = r.data['commission_sum_net'];
          const curr = d?.curr || 0;
          const prev = d?.prev || 0;
          return {
            key: campaign,
            title: campaign,
            linkTo: undefined, // TODO
            earnings: {
              count: curr,
              lastCount: prev,
              trend: getTrend(prev, curr)
            }
          };
        })
      );
    },
    [channelId]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q, {
      logLabel: `top subIds by earnings (${channelId})`
    }),
    mapFn,
    true
  );
};
