import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { AddAdditionalDomainModal } from '../../../../components/AddDomainModal';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../components/AdditionalActionsMenu';
import { AlertBox } from '../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { Loader } from '../../../../components/Loader';
import { PulsingCircle } from '../../../../components/PulsingCircle';
import { SiteSettings } from '../../../../components/SiteSettings';
import { StartHere } from '../../../../components/StartHere';
import { Heading } from '../../../../components/Typography';
import { EMPTY_ARR, EMPTY_OBJ } from '../../../../domainTypes/emptyConstants';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useRoutes } from '../../../../routes';
import { useAdminOrImpersonatorClaim } from '../../../../services/auth';
import { ARTICLES } from '../../../../services/beacon';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { setDomainActive } from '../../../../services/space';
import { withoutProtocol } from '../../../../services/url';
import { CHART_HEIGHT } from './constants';
import { ContentTrendsByClicks } from './ContentTrendsByClicks';
import { ContentTrendsByEarnings } from './ContentTrendsByEarnings';
import { AverageCommissionChip, SaleRateChip } from './DomainStats';
import { EarningsChart } from './EarningsChart';
import { EarningsSummary } from './EarningsSummary';
import { Props } from './props';
import { RealtimeReporting } from './RealtimeReporting';
import { TopAdvertisers } from './TopAdvertisers';
import { useFeatureEnabled } from '../../../../services/features';
import { OverflowingCard } from '../OverflowingCard';
import {
  SummaryGrid,
  SummaryGridItem,
  SummaryHeader,
  SummaryHeaderLeft
} from '../SummaryGrid';
import { createSiteFilterDefinition } from '../../../../components/analytics_v2/Filters/filters';

export const DomainSummary = ({
  spaceId,
  domain,
  sales,
  trafficTimeseries,
  trafficSum,
  timeseriesForNetworks,
  contentTrends,
  timeframe,
  currency
}: Props) => {
  const { ROUTES } = useRoutes();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const [canEditDomains] = useHasCurrentUserRequiredScopes(['domains.edit']);

  const options = useMemo(() => {
    const os: AdditionActionsMenuOption[] = [
      {
        key: 'settings',
        label: 'Site Settings',
        dialogMaxWidth: 'sm',
        dialog: ({ onClose }) => {
          return (
            <>
              <DialogTitle>Site settings</DialogTitle>
              <DialogContent>
                <SiteSettings domain={domain.url} />
              </DialogContent>
            </>
          );
        }
      },
      {
        key: 'deactive',
        label: 'Deactivate',
        dialogMaxWidth: 'sm',
        dialog: ({ onClose }) => {
          return (
            <>
              <DialogTitle>
                Deactivate {withoutProtocol(domain.url)}?
              </DialogTitle>
              <DialogContent>
                <AlertBox variant="pending">
                  <Typography variant="body1" component="p">
                    Deactivating a website will hide this website from your
                    dashboard, but <strong>does not</strong> stop it from
                    sending data to Affilimate.
                  </Typography>
                </AlertBox>
                <br />
                <Typography variant="body1" component="p" paragraph>
                  Please remove the Affilimate tracking snippet from this
                  website before pressing Deactivate. Once you've both removed
                  the snippet and deactivated, this site will no longer be
                  tracked in your dashboard or send data to Affilimate.
                </Typography>
                <Typography variant="body1" component="p" paragraph>
                  Don't remember how you installed the snippet?{' '}
                  <Link
                    to={ROUTES.docs.knowledgeBase.url(ARTICLES.setup.install)}
                    style={{ borderBottom: '1px solid' }}
                  >
                    Review original install guide
                  </Link>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ButtonWithPromise
                  variant="contained"
                  color="secondary"
                  onClick={() => setDomainActive(spaceId, domain.url, false)}
                  pending="Deactivating..."
                >
                  Deactivate site, I've removed the snippet
                </ButtonWithPromise>
              </DialogActions>
            </>
          );
        }
      }
    ];
    return os;
  }, [spaceId, domain.url, ROUTES.docs.knowledgeBase]);
  const domainWithoutProtocol = withoutProtocol(domain.url);

  const transactionsPage = ROUTES.performanceNew.transactions.url({
    filters: [createSiteFilterDefinition([domainWithoutProtocol])]
  });

  return (
    <OverflowingCard>
      <SummaryHeader>
        <SummaryHeaderLeft>
          <Heading>
            <Link to={transactionsPage}>{domainWithoutProtocol}</Link>
          </Heading>
          {!domain.verified && (
            <StartHere>
              <Button
                onClick={openDialog}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PulsingCircle color="green" /> Waiting for data
              </Button>
            </StartHere>
          )}
          {domain.verified && (
            <>
              <SaleRateChip
                d={
                  sales.tf.d && trafficSum.d
                    ? {
                        prev: sales.tfToCompare.d || null,
                        curr: sales.tf.d,
                        traffic: trafficSum.d
                      }
                    : undefined
                }
                loading={
                  sales.tf.loading ||
                  sales.tfToCompare.loading ||
                  trafficSum.loading
                }
              />
              <AverageCommissionChip
                loading={sales.tf.loading || sales.tfToCompare.loading}
                d={
                  sales.tf.d
                    ? {
                        prev: sales.tfToCompare.d || null,
                        curr: sales.tf.d
                      }
                    : undefined
                }
                currency={currency}
              />
            </>
          )}
        </SummaryHeaderLeft>
        {canEditDomains && (
          <AdditionalActionsMenu variant="cog" options={options} />
        )}
      </SummaryHeader>

      <SummaryGrid>
        <Link to={transactionsPage}>
          <SummaryGridItem
            heading="Earnings summary"
            style={{
              height: '100%'
            }}
          >
            <EarningsSummary sales={sales} currency={currency} />
          </SummaryGridItem>
        </Link>
        <Link to={transactionsPage}>
          <SummaryGridItem heading="Daily earnings and clicks">
            {timeseriesForNetworks.loading ? (
              <Loader height={CHART_HEIGHT} />
            ) : (
              <EarningsChart
                sales={timeseriesForNetworks.d || EMPTY_OBJ}
                currency={currency}
                timeframe={timeframe}
                traffic={trafficTimeseries.d || EMPTY_ARR}
              />
            )}
          </SummaryGridItem>
        </Link>
        <SummaryGridItem
          heading={
            <Link
              to={ROUTES.content.overview.url({ site: domainWithoutProtocol })}
            >
              Content by earnings
            </Link>
          }
        >
          {contentTrends.byEarnings.loading ? (
            <Loader height={CHART_HEIGHT} />
          ) : (
            <ContentTrendsByEarnings
              ds={contentTrends.byEarnings.d || EMPTY_OBJ}
              currency={currency}
            />
          )}
        </SummaryGridItem>
        <SummaryGridItem heading="Content by clicks">
          {contentTrends.byClicks.loading ? (
            <Loader height={CHART_HEIGHT} />
          ) : (
            <ContentTrendsByClicks ds={contentTrends.byClicks.d || EMPTY_ARR} />
          )}
        </SummaryGridItem>
      </SummaryGrid>
      <AddAdditionalDomainModal
        open={dialogOpen}
        onClose={closeDialog}
        domain={domain.url}
        onVerifyDomain={() => Promise.resolve()}
        onScan={() => Promise.resolve()}
      />
    </OverflowingCard>
  );
};

export const TotalSummary = ({
  spaceId,
  sales,
  timeseriesForNetworks,
  timeframe,
  trafficTimeseries,
  currency
}: Omit<Props, 'domain' | 'contentTrends'>) => {
  const { ROUTES } = useRoutes();
  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();
  const transactionsPage = ROUTES.performanceNew.transactions.url();
  const advertisersPage = ROUTES.performanceNew.advertisers.overview.url();

  // To start, we will only surface realtime to publishers
  // with access to traffic sources. This will
  // probably get removed soon.
  const hasTrafficSourcesEnabled = useFeatureEnabled('REFERRER_REPORTS_V1');

  return (
    <OverflowingCard highlight elevation={1}>
      <SummaryGrid>
        <Link to={transactionsPage}>
          <SummaryGridItem
            heading="Portfolio summary"
            info="All revenue from all traffic sources, including commissions without site attribution. Typically higher than site totals for this reason."
            style={{
              height: '100%'
            }}
          >
            <EarningsSummary sales={sales} currency={currency} />
          </SummaryGridItem>
        </Link>
        <Link to={transactionsPage}>
          <SummaryGridItem heading="Combined earnings and clicks">
            {timeseriesForNetworks.loading ? (
              <Loader height={CHART_HEIGHT} />
            ) : (
              <EarningsChart
                sales={timeseriesForNetworks.d || EMPTY_OBJ}
                currency={currency}
                timeframe={timeframe}
                traffic={trafficTimeseries.d || EMPTY_ARR}
              />
            )}
          </SummaryGridItem>
        </Link>

        <Link to={advertisersPage}>
          <SummaryGridItem heading="Advertisers by earnings">
            <TopAdvertisers
              spaceId={spaceId}
              currency={currency}
              timeframe={timeframe}
            />
          </SummaryGridItem>
        </Link>
        {isAdminOrImpersonator || hasTrafficSourcesEnabled ? (
          <Link to={ROUTES.dashboard.realtime.url()}>
            <SummaryGridItem
              heading={
                <>
                  Realtime{' '}
                  <ArrowRight
                    size={14}
                    style={{ position: 'relative', top: '2px' }}
                  />
                </>
              }
              subheading="Last 48h&nbsp;&nbsp;&nbsp;"
            >
              <RealtimeReporting spaceId={spaceId} />
            </SummaryGridItem>
          </Link>
        ) : (
          <SummaryGridItem
            heading="Monitoring"
            info="Clicks and pageviews within affiliate content. Updated hourly, displayed in your own browser timezone rather than account timezone."
            subheading="Last 48h&nbsp;&nbsp;&nbsp;"
          >
            <RealtimeReporting spaceId={spaceId} />
          </SummaryGridItem>
        )}
      </SummaryGrid>
    </OverflowingCard>
  );
};
