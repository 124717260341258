import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@material-ui/core';
import { groupBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { ExternalLink, PlusCircle, X } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import { Dropzone } from '../../../../components/Dropzone';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { ErrorSupportForm } from '../../../../components/SupportForm/Error';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { IPartner } from '../../../../domainTypes/partners';
import {
  SECRET_CATEGORY,
  HANDLERS_WITH_PAYOUTS
} from '../../../../domainTypes/reporting';
import { ISpace } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { useLoadingValue } from '../../../../hooks/useLoadingValue';
import { Centered } from '../../../../layout/Centered';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { unzipFiles } from '../../../../services/file';
import {
  setupSalesApiFetchScheduleForHandler,
  useSalesApiFetchScheduleForHandlerWithoutDefault
} from '../../../../services/schedules/salesApiFetch';
import { emptySecret, useSecrets } from '../../../../services/secret';
import { MomentRange } from '../../../../services/time';
import {
  FileReportParseResult,
  IApiReportHandler,
  IReportHandlerBase,
  ReportHandler
} from '../../services/handlers/types';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { Beacon, DOCS_LIST } from '../../../../services/beacon';
import { parseFile } from '../../services/report';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { updateOrCreateSchedule } from '../../../../services/schedules/helper';
import { useRoutes } from '../../../../routes';
import { useAutoLabelConfig } from '../../../../services/autoLabelConfig';
import { PARTNERS_WITH_TRACKING } from '../../../../domainTypes/tracking';
import { CF } from '../../../../versions';
import { ScheduleFormSimple } from '../../../../components/Schedule/SimpleForm';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { Link } from 'react-router-dom';
import { ApiSettings } from '../ApiSettings';
import { FileImportResults } from '../FileImportResults';
import { StyledContent } from './commons';
import { changeAutoLabelConfig } from '../../pages/Integrations/HandlerList';
import { SmartLabelSwitch } from '../SmartLabelSwitch';

const HEIGHT = 600;
const LOADER_HEIGHT = 300;

type ImporterProps = {
  setLoading: (loading: boolean) => void;
  setError: (err: any) => void;
};

const FileImporter = ({
  setResults,
  setLoading,
  setError,
  space
}: ImporterProps & {
  space: ISpace;
  setResults: (next: FileReportParseResult[] | null) => void;
}) => {
  const onDrop = (files: File[] | null) => {
    if (!files) {
      return;
    }

    setLoading(true);

    unzipFiles(files).then((fs) => {
      console.log(fs);
      return Promise.all(fs.map((file) => parseFile(space, file))).then(
        (results) => {
          console.log(results);
          return setResults(results);
        },
        setError
      );
    });
  };

  return (
    <Dropzone
      onDrop={onDrop}
      note="You'll need to re-upload these types of reports on a regular basis (such as weekly or monthly)"
    />
  );
};

const RunApiImport = ({
  spaceId,
  integrationId,
  handler,
  onDone
}: {
  spaceId: string;
  integrationId: string;
  handler: IApiReportHandler;
  onDone: () => void;
}) => {
  const [schedule] = useSalesApiFetchScheduleForHandlerWithoutDefault(
    spaceId,
    handler.configName
  );

  const options = useMemo(() => {
    return [
      {
        label: 'Last 12 months',
        range: {
          start: moment().subtract(12, 'months').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'Last 24 months',
        range: {
          start: moment().subtract(12, 'months').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2022 – Today',
        range: {
          start: moment('2022-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2021 – Today',
        range: {
          start: moment('2021-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2020 – Today',
        range: {
          start: moment('2020-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2019 – Today',
        range: {
          start: moment('2019-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      }
    ];
  }, []);

  const [importRange, setImportRange] = useState<{
    label: string;
    range: MomentRange;
  }>(options[0]);

  const scheduleImports = async () => {
    try {
      const promises = [
        callFirebaseFunction(CF.reporting.scheduleReportingJobs, {
          spaceId,
          instanceId: integrationId,
          handler: handler.configName,
          type: 'reporting',
          range: {
            start: importRange.range.start.format('YYYY-MM-DD'),
            end: importRange.range.end.format('YYYY-MM-DD')
          }
        })
      ];

      if (HANDLERS_WITH_PAYOUTS.includes(handler.configName)) {
        promises.push(
          callFirebaseFunction(CF.reporting.scheduleReportingJobs, {
            spaceId,
            instanceId: integrationId,
            handler: handler.configName,
            type: 'payout',
            range: {
              start: importRange.range.start.format('YYYY-MM-DD'),
              end: importRange.range.end.format('YYYY-MM-DD')
            }
          })
        );
      }

      await Promise.all(promises);
      onDone();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <AlertBox variant="primary" style={{ marginBottom: '18px' }}>
        Successful connection! Now add your import settings.
      </AlertBox>
      <Typography variant="body2" component="p" style={{ marginBottom: '4px' }}>
        <strong>When should we import your transactions?</strong>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        We recommend sometime after midnight GMT for most platforms.
      </Typography>
      {schedule ? (
        <ScheduleFormSimple
          label="Import transactions from this platform"
          doc={schedule}
          onChange={(s) => updateOrCreateSchedule(s.id, s.data)}
        />
      ) : (
        <Loader size={12} />
      )}
      <br />
      <br />
      <Typography variant="body2" component="p" style={{ marginBottom: '4px' }}>
        <strong>How much data should we start backfilling?</strong>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        Depending on API limits, this process can take hours or days.
      </Typography>
      <Select
        variant="outlined"
        value={options.indexOf(importRange)}
        onChange={(ev) => setImportRange(options[Number(ev.target.value)])}
        fullWidth
      >
        {options.map((o, i) => {
          return (
            <MenuItem value={i} key={o.label}>
              {o.label}
            </MenuItem>
          );
        })}
      </Select>
      <br />
      <br />
      <ButtonWithPromise
        size="large"
        variant="contained"
        color="primary"
        fullWidth
        onClick={scheduleImports}
        pending="Scheduling imports..."
      >
        Start importing
      </ButtonWithPromise>
    </div>
  );
};

const ConnectionContainer = styled(Card)`
  padding: ${(p) => p.theme.spacing(2)}px;
  border: 1px solid ${(p) => p.theme.palette.grey.A200};
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const NewSecretHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type NewSecretMap = {
  [K in IPartner['key']]: boolean;
};

const ApiImporter = ({
  handler,
  status,
  setStatus
}: {
  handler: IApiReportHandler;
  status: 'setupComplete' | 'importsScheduled' | 'error' | null;
  setStatus: React.Dispatch<
    React.SetStateAction<'setupComplete' | 'importsScheduled' | 'error' | null>
  >;
}) => {
  const { ROUTES } = useRoutes();
  const [currentIntegrationId, setCurrentIntegrationId] = useState('');
  const [error, setError] = useState<any | null>(null);
  const [newSecrets, setNewSecret] = useState<NewSecretMap>({});
  const partner = getKnownPartnerForKey(handler.partnerKey);
  const [canAddIntegrations] = useHasCurrentUserRequiredScopes([
    'integrations.create'
  ]);
  const { space, id: userId, tz } = useCurrentUser();
  const spaceId = space.id;
  const [schedule] = useSalesApiFetchScheduleForHandlerWithoutDefault(
    spaceId,
    handler.configName
  );
  const [allSecrets, loadingSecrets, errorSecrets] = useSecrets(
    spaceId,
    SECRET_CATEGORY
  );

  const secretsByName = useMemo(
    () => groupBy(allSecrets || [], (s) => s.data.name),
    [allSecrets]
  );

  const additionalSecret = emptySecret(
    space.id,
    userId,
    SECRET_CATEGORY,
    handler.configName,
    handler.configFields
  );

  const secrets = secretsByName[handler.configName] || [additionalSecret];

  if (loadingSecrets) {
    return <Loader height={100} />;
  }

  if (errorSecrets) {
    return (
      <Centered>Uh oh, an error occurred fetching your API keys.</Centered>
    );
  }

  if (status === 'importsScheduled') {
    return (
      <div>
        <AlertBox variant="primary" style={{ marginBottom: '24px' }}>
          <strong>Your imports have been scheduled!</strong>
          <br />
          <br />
          We'll start importing transactions from this API connection right
          away. This process can take several hours or days depending on
          transaction volume and API limits.
        </AlertBox>
        <Typography
          color="textSecondary"
          variant="body2"
          component="p"
          paragraph
          style={{ marginBottom: '24px' }}
        >
          Now you can connect a different API, add another connection to the
          same platform, or view import progress.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setStatus(null);
          }}
        >
          Add another API connection
        </Button>
        &nbsp;&nbsp;
        <Link to={ROUTES.performanceNew.activity.url()} style={{ border: 0 }}>
          <Button color="primary" variant="outlined">
            See import progress
          </Button>
        </Link>
      </div>
    );
  }

  if (status === 'setupComplete' && currentIntegrationId !== '') {
    return (
      <RunApiImport
        spaceId={spaceId}
        integrationId={currentIntegrationId}
        handler={handler}
        onDone={() => {
          setStatus('importsScheduled');
        }}
      />
    );
  }

  return (
    <div>
      {error && (
        <AlertBox variant="error">
          Test failed. Are your credentials correct?
        </AlertBox>
      )}
      {partner && canAddIntegrations && newSecrets[partner.key] && (
        <ConnectionContainer>
          <NewSecretHeader>
            <Typography variant="body2" component="p">
              Add another connection to {partner.name}
            </Typography>
            <IconButton
              onClick={() => {
                setNewSecret({ ...newSecrets, [partner.key]: false });
              }}
            >
              <X size={22} />
            </IconButton>
          </NewSecretHeader>
          <ApiSettings
            handler={handler}
            secret={additionalSecret}
            onSuccess={() => {
              setStatus('setupComplete');
              setCurrentIntegrationId(additionalSecret.data.instanceId);
            }}
            onError={(err) => setError(err || true)}
          />
        </ConnectionContainer>
      )}
      {partner &&
        secretsByName[handler.configName] &&
        canAddIntegrations &&
        !newSecrets[partner.key] && (
          <ConnectionContainer>
            <Button
              color="primary"
              onClick={() => {
                setNewSecret({ ...newSecrets, [partner.key]: true });
              }}
            >
              <PlusCircle size={18} style={{ marginRight: '6px' }} /> Add
              another connection to {partner.name}
            </Button>
          </ConnectionContainer>
        )}
      {secrets
        .filter((s) => s.data.status !== 'archived')
        .map((secret) => (
          <ConnectionContainer key={secret.id}>
            <strong>Connection details</strong>
            <ApiSettings
              handler={handler}
              secret={secret}
              onSuccess={() => {
                setStatus('setupComplete');
                setCurrentIntegrationId(secret.data.instanceId);
                if (!schedule) {
                  setupSalesApiFetchScheduleForHandler(
                    space.id,
                    handler.configName,
                    userId,
                    tz
                  );
                }
              }}
              onError={(err) => setError(err || true)}
            />
          </ConnectionContainer>
        ))}
    </div>
  );
};

const Instructions = ({ handler }: { handler: ReportHandler | null }) => {
  if (!handler) {
    return null;
  }

  const partner = getKnownPartnerForKey(handler.partnerKey);

  if (!partner) {
    return null; // Should never happen if we write instructions for every new partner
  }

  const onClick = () => {
    const docConfig = DOCS_LIST.find(
      (d) => d.partnerKey === handler.partnerKey
    );
    if (!docConfig) {
      return;
    }

    Beacon('article', docConfig.docId);
  };

  return (
    <div>
      <Typography
        variant="body1"
        component="p"
        paragraph
        style={{ padding: '20px 0 0' }}
      >
        <strong>Connect {partner.name}</strong>
        &nbsp; &nbsp;
        <HelpIcon onClick={onClick} color="blue">
          Show instructions
        </HelpIcon>
      </Typography>
    </div>
  );
};

export const FileWorkflow = ({
  handler,
  onClose,
  currency,
  space,
  setDialogStyle
}: {
  handler: ReportHandler;
  onClose: () => void;
  currency: CurrencyCode;
  space: ISpace;
  setDialogStyle: (nextStyle: () => 'md' | 'lg') => void;
}) => {
  const {
    value: results,
    loading,
    error,
    setValue: setResults,
    setLoading,
    setError
  } = useLoadingValue<FileReportParseResult[] | null>(null, false);
  const [canImportFiles] = useHasCurrentUserRequiredScopes([
    'integrations.upload_report'
  ]);

  useErrorLogger(error);

  if (loading) {
    return <Loader height={LOADER_HEIGHT} />;
  }

  if (results) {
    return (
      <FileImportResults
        results={results}
        onDone={onClose}
        onCancel={onClose}
        onError={setError}
        currency={currency}
        height={HEIGHT}
      />
    );
  }

  if (error) {
    return (
      <DialogContent>
        <ErrorSupportForm onDone={onClose} />
      </DialogContent>
    );
  }

  return (
    <>
      <StyledContent>
        <Instructions handler={handler} />
        <SmartLabelsSection handler={handler} spaceId={space.id} />
        {canImportFiles ? (
          <FileImporter
            setResults={(rs) => {
              if (rs) {
                setDialogStyle(() => 'lg');
              }
              setResults(rs);
            }}
            setLoading={setLoading}
            setError={setError}
            space={space}
          />
        ) : (
          <Typography variant="body2" component="p" color="textSecondary">
            You do not have permission to import new reports.
          </Typography>
        )}
      </StyledContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};

const SmartLabelsSection = ({
  spaceId,
  handler
}: {
  spaceId: string;
  handler: IReportHandlerBase;
}) => {
  const [autoLabelConfig] = useAutoLabelConfig(spaceId);
  const partner = getKnownPartnerForKey(handler.partnerKey);
  const [canToggleSmartLabels] = useHasCurrentUserRequiredScopes([
    'integrations.toggle_smart_labels'
  ]);

  const smartLabels = useMemo(() => {
    if (!autoLabelConfig) {
      return false;
    }
    return autoLabelConfig.data.partners.indexOf(handler.partnerKey) !== -1;
  }, [autoLabelConfig, handler.partnerKey]);

  // Do not auto-suggest smart labels for partners that don't support it
  if (handler.partnerKey === 'amazon') {
    return null;
  }

  const onChangeSmartLabels = (enabled: boolean) => {
    if (!autoLabelConfig) {
      return;
    }

    return changeAutoLabelConfig(autoLabelConfig, handler.partnerKey, enabled);
  };

  if (!PARTNERS_WITH_TRACKING[handler.partnerKey]) {
    return null;
  }

  return (
    <ConnectionContainer>
      <Typography variant="body2" component="p" style={{ marginBottom: '4px' }}>
        <strong>
          Auto-attribute commissions from future clicks (Recommended)
        </strong>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        Smart Labels attribute revenue to your content and links by overriding a
        link's SubIDs. &nbsp;
        <Tooltip
          title="See which settings to update for popular affiliate link cloakers, such as Thirsty Affiliates and Pretty Links."
          placement="top"
        >
          <a
            href="https://affilimate.com/docs/enable-revenue-attribution/"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'inherit', borderBottom: '1px solid' }}
          >
            Using a link cloaker/shortener? <ExternalLink size={12} />
          </a>
        </Tooltip>
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          left: '-9px'
        }}
      >
        <SmartLabelSwitch
          value={smartLabels}
          partnerKey={handler.partnerKey}
          disabled={!canToggleSmartLabels}
          onChange={onChangeSmartLabels}
        />
        <p>Enable Smart Labels {partner && `for ${partner.name}`}</p>
      </div>
    </ConnectionContainer>
  );
};

export const ApiWorkflow = ({
  handler,
  onClose
}: {
  handler: IApiReportHandler;
  onClose: () => void;
}) => {
  const { space } = useCurrentUser();
  const [status, setStatus] = useState<
    'setupComplete' | 'importsScheduled' | 'error' | null
  >(null);

  return (
    <>
      <StyledContent>
        <PendingIntegrationNotice handler={handler} />
        <Instructions handler={handler} />
        {['error', null].indexOf(status) !== -1 && (
          <SmartLabelsSection handler={handler} spaceId={space.id} />
        )}
        <ApiImporter handler={handler} status={status} setStatus={setStatus} />
      </StyledContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};

const PendingIntegrationNotice = ({
  handler
}: {
  handler: IApiReportHandler;
}) => {
  const handlerName = handler.partnerKey;
  const pendingHandlers = ['optimise', 'howl'];
  if (pendingHandlers.indexOf(handlerName) === -1) {
    return null;
  }
  return (
    <AlertBox variant="pending" style={{ marginBottom: '12px' }}>
      <strong>
        This integration is in development and will NOT import your transactions
        after initial setup.
      </strong>{' '}
      Add it now, and your reports will be pulled automatically once it's
      available.
    </AlertBox>
  );
};
