import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { IPageWithCountsAndTrends } from '../../../../domainTypes/page';
import { useRoutes } from '../../../../routes';
import { getPathname } from '../../../../services/url';
import { ClicksTable, ClicksTableRow } from './ClicksTable';
import { MAX_CONTENT_ROWS } from './constants';

export const ContentTrendsByClicks = ({
  ds
}: {
  ds: IPageWithCountsAndTrends[];
}) => {
  const { ROUTES } = useRoutes();
  const rows = useMemo<ClicksTableRow[]>(() => {
    const top = orderBy(ds, (d) => d.counts.clicked.count, 'desc').slice(
      0,
      MAX_CONTENT_ROWS
    );
    return top.map((d) => ({
      key: d.href,
      title: getPathname(d.href),
      linkTo: ROUTES.content.details.trends.url(d.href),
      clicks: d.counts.clicked
    }));
  }, [ds, ROUTES]);
  return <ClicksTable rows={rows} />;
};
