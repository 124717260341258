import { Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Ctr } from '../../../../components/Ctr';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  SortValue
} from '../../../../components/GroupableList';
import { LinkExternalWithEllipsis } from '../../../../components/LinkExternal';
import { Loader } from '../../../../components/Loader';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { BaseCountCell, CountCell } from '../../../../components/Table';
import { IColumn } from '../../../../components/Table/Column';
import { Dash } from '../../../../components/Table/CountCell';
import {
  toAbsoluteGrowth,
  toMultiSort,
  toSingleSort,
  ValueGetter
} from '../../../../components/Table/ValueGetter';
import { getTrend, mapCounter, Mode } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { getSortValueForProductDataStatus } from '../../../../domainTypes/linkCheckV2';
import {
  INarrowProductWithCountsAndTrendsAndOccurrencesAndSales,
  IProductLinkCheckIssue,
  ProductIssueType
} from '../../../../domainTypes/product';
import { css, styled } from '../../../../emotion';
import { SortDirection } from '../../../../hooks/useSort';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../layout/PageToolbar';
import { useRoutes } from '../../../../routes';
import {
  getClickRatio,
  getCommissionRate,
  getCpm,
  getEpc,
  getViewRatio
} from '../../../../services/analytics';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../services/partner';
import { renderLinkName } from '../../../../services/products';
import * as tracking from '../../../../tracking';
import { ProductIssue } from '../../../Links/pages/Overview/components/ProductIssue';

export type ColumnName =
  | 'name'
  | 'pageViews'
  | 'served'
  | 'viewed'
  | 'clicked'
  | 'viewRatio'
  | 'clicked'
  | 'clickRatio'
  | 'epc'
  | 'itemsSold'
  | 'earnings'
  | 'gmv'
  | 'rate'
  | 'issues';

type Data = INarrowProductWithCountsAndTrendsAndOccurrencesAndSales;

type OtherProps = {
  compare: boolean;
  mode: Mode;
};

type Column = IColumn<Data, ColumnName, OtherProps>;

const isusesValueGetter = (p: Data): SortValue => {
  const linkCheckIssue = p.issues.find(
    (i) => i.type === ProductIssueType.LINK_CHECK && !i.resolved
  ) as IProductLinkCheckIssue | undefined;
  return linkCheckIssue
    ? getSortValueForProductDataStatus(linkCheckIssue.status)
    : -1;
};

const VALUE_GETTERS: {
  [K in ColumnName]: ValueGetter<Data>;
} = {
  name: {
    current: (p) => p.name,
    prev: (p) => p.name,
    absoluteGrowth: (p) => p.name,
    relativeGrowth: (p) => p.name
  },
  gmv: {
    current: (p) => p.sales.curr.earnings.saleValue.total,
    prev: (p) => p.sales.prev.earnings.saleValue.total,
    absoluteGrowth: (p) =>
      p.sales.curr.earnings.saleValue.total -
      p.sales.prev.earnings.saleValue.total,
    relativeGrowth: (p) =>
      p.sales.curr.earnings.saleValue.total /
      p.sales.prev.earnings.saleValue.total
  },
  rate: {
    current: (p) =>
      getCommissionRate(
        p.sales.curr.earnings.total,
        p.sales.curr.earnings.saleValue.total
      ),
    prev: (p) =>
      getCommissionRate(
        p.sales.prev.earnings.total,
        p.sales.prev.earnings.saleValue.total
      ),
    absoluteGrowth: (p) =>
      getCommissionRate(
        p.sales.curr.earnings.total,
        p.sales.curr.earnings.saleValue.total
      ) -
      getCommissionRate(
        p.sales.prev.earnings.total,
        p.sales.prev.earnings.saleValue.total
      ),
    relativeGrowth: (p) =>
      getCommissionRate(
        p.sales.curr.earnings.total,
        p.sales.curr.earnings.saleValue.total
      ) /
      getCommissionRate(
        p.sales.prev.earnings.total,
        p.sales.prev.earnings.saleValue.total
      )
  },
  pageViews: {
    current: (p) => p.counts.pageViews.count,
    prev: (p) => p.counts.pageViews.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.pageViews),
    relativeGrowth: (p) => p.counts.pageViews.trend
  },
  served: {
    current: (p) => p.counts.served.count,
    prev: (p) => p.counts.served.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.served),
    relativeGrowth: (p) => p.counts.served.trend
  },
  viewed: {
    current: (p) => p.counts.viewed.count,
    prev: (p) => p.counts.viewed.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.viewed),
    relativeGrowth: (p) => p.counts.viewed.trend
  },
  clicked: {
    current: (p) => p.counts.clicked.count,
    prev: (p) => p.counts.clicked.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.clicked),
    relativeGrowth: (p) => p.counts.clicked.trend
  },
  viewRatio: {
    current: (p) => getViewRatio(mapCounter(p.counts, (v) => v.count)),
    prev: (p) => getViewRatio(mapCounter(p.counts, (v) => v.lastCount)),

    absoluteGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.counts, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.counts, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.counts, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.counts, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  clickRatio: {
    current: (p) => getClickRatio(mapCounter(p.counts, (v) => v.count)),
    prev: (p) => getClickRatio(mapCounter(p.counts, (v) => v.lastCount)),
    absoluteGrowth: (p) => {
      const current = getCpm(mapCounter(p.counts, (v) => v.count));
      const prev = getCpm(mapCounter(p.counts, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getCpm(mapCounter(p.counts, (v) => v.count));
      const prev = getCpm(mapCounter(p.counts, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  issues: {
    current: isusesValueGetter,
    prev: isusesValueGetter,
    absoluteGrowth: isusesValueGetter,
    relativeGrowth: isusesValueGetter
  },
  epc: {
    current: (p) => getEpc(p.counts.clicked.count, p.sales.curr.earnings.total),
    prev: (p) =>
      getEpc(p.counts.clicked.lastCount, p.sales.prev.earnings.total),
    absoluteGrowth: (p) =>
      getEpc(p.counts.clicked.count, p.sales.curr.earnings.total) -
      getEpc(p.counts.clicked.lastCount, p.sales.prev.earnings.total),

    relativeGrowth: (p) =>
      getTrend(
        getEpc(p.counts.clicked.lastCount, p.sales.prev.earnings.total),
        getEpc(p.counts.clicked.count, p.sales.curr.earnings.total)
      )
  },
  itemsSold: {
    current: (p) => p.sales.curr.earnings.totalCount,
    prev: (p) => p.sales.prev.earnings.totalCount,
    absoluteGrowth: (p) =>
      p.sales.curr.earnings.totalCount - p.sales.prev.earnings.totalCount,
    relativeGrowth: (p) =>
      getTrend(
        p.sales.prev.earnings.totalCount,
        p.sales.curr.earnings.totalCount
      )
  },
  earnings: {
    current: (p) => p.sales.curr.earnings.total,
    prev: (p) => p.sales.prev.earnings.total,
    absoluteGrowth: (p) =>
      p.sales.curr.earnings.total - p.sales.prev.earnings.total,
    relativeGrowth: (p) =>
      getTrend(p.sales.prev.earnings.total, p.sales.curr.earnings.total)
  }
};

const createSorters = (mode: Mode): ItemSorters<Data> => ({
  name: {
    key: 'name',
    items: { sort: toSingleSort(VALUE_GETTERS['name'], mode), dir: 'asc' }
  },
  pageViews: {
    key: 'pageViews',
    items: { sort: toSingleSort(VALUE_GETTERS['pageViews'], mode), dir: 'desc' }
  },
  served: {
    key: 'served',
    items: { sort: toSingleSort(VALUE_GETTERS['served'], mode), dir: 'desc' }
  },
  viewed: {
    key: 'viewed',
    items: { sort: toSingleSort(VALUE_GETTERS['viewed'], mode), dir: 'desc' }
  },
  viewRatio: {
    key: 'viewRatio',
    items: { sort: toSingleSort(VALUE_GETTERS['viewRatio'], mode), dir: 'desc' }
  },
  clicked: {
    key: 'clicked',
    items: { sort: toSingleSort(VALUE_GETTERS['clicked'], mode), dir: 'desc' }
  },
  clickRatio: {
    key: 'clickRatio',
    items: {
      sort: toSingleSort(VALUE_GETTERS['clickRatio'], mode),
      dir: 'desc'
    }
  },
  epc: {
    key: 'epc',
    items: { sort: toMultiSort(VALUE_GETTERS['epc'], mode), dir: 'desc' }
  },
  itemsSold: {
    key: 'itemsSold',
    items: { sort: toMultiSort(VALUE_GETTERS['itemsSold'], mode), dir: 'desc' }
  },
  earnings: {
    key: 'earnings',
    items: { sort: toMultiSort(VALUE_GETTERS['earnings'], mode), dir: 'desc' }
  },
  gmv: {
    key: 'gmv',
    items: { sort: toMultiSort(VALUE_GETTERS['gmv'], mode), dir: 'desc' }
  },
  rate: {
    key: 'rate',
    items: { sort: toMultiSort(VALUE_GETTERS['rate'], mode), dir: 'desc' }
  },
  issues: {
    key: 'issues',
    items: { sort: toSingleSort(VALUE_GETTERS['issues'], mode), dir: 'desc' }
  }
});
export const SORTERS: {
  [K in Mode]: ItemSorters<Data>;
} = {
  'absolute-numbers': createSorters('absolute-numbers'),
  'absolute-growth': createSorters('absolute-growth'),
  'relative-growth': createSorters('relative-growth')
};

const ProductNameWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(2)}px;
`;

const ProductNameContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing()}px;
`;

const ProductLink = styled(LinkExternalWithEllipsis)`
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.72);
`;

const ProductName = ({
  d
}: {
  d: INarrowProductWithCountsAndTrendsAndOccurrencesAndSales;
}) => {
  const { ROUTES } = useRoutes();
  const partner =
    getKnownPartnerForKey(d.partner_key) ||
    constructPartnerForKey(d.partner_key);

  const linkName = renderLinkName({
    name: d.name,
    url: d.url,
    partnerKey: d.partner_key
  });

  const tooltipName =
    linkName !== d.url ? (
      <div>
        <strong>Name or deeplink</strong> — {linkName}
        <br />
        <br />
        <strong>URL</strong> — {d.url}
      </div>
    ) : (
      d.url
    );

  return (
    <>
      <Tooltip title={tooltipName} placement="top">
        <ProductNameWrapper>
          <PartnerLogo partner={partner} />
          <div style={{ width: '100%' }}>
            <Link
              to={ROUTES.links.details.overview.url(d.id)}
              onClick={(ev) => {
                ev.stopPropagation();
                tracking.sendEvent({
                  category: tracking.toAppCategory(),
                  action: 'Click',
                  label: 'Product table link'
                });
              }}
            >
              <ProductNameContainer>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {linkName}
                </Typography>
                <Typography
                  component="span"
                  className={css((t) => ({
                    fontSize: t.custom.fontSize.s,
                    color: t.palette.text.secondary
                  }))}
                >
                  {Object.keys(d.byOccurrence).length}x
                </Typography>
              </ProductNameContainer>
            </Link>
            <ProductLink href={d.url} />
          </div>
        </ProductNameWrapper>
      </Tooltip>
    </>
  );
};

export const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Link URL',
    headInfo: () =>
      `Combined metrics for all instances of this product link on the page. The number (like 3x) indicates how many times this link appears in the content.`,
    cell: (d) => <ProductName d={d} />,
    align: 'left',
    width: 300,
    flexGrow: 3,
    sortable: true
  },
  {
    key: 'served',
    head: () => 'Served',
    headInfo: () =>
      'How many times this affiliate link was served on this page to one of your visitors',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={d.counts.served.lastCount}
          after={d.counts.served.count}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'viewed',
    head: () => 'Viewed',
    headInfo: () =>
      'How many times this affiliate link seen as a result of scrolling down on this page',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={d.counts.viewed.lastCount}
          after={d.counts.viewed.count}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'viewRatio',
    head: () => 'Visibility',
    headInfo: () =>
      'How often visitors scroll far enough on the page to see this link',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={getViewRatio(mapCounter(d.counts, (v) => v.lastCount))}
          after={getViewRatio(mapCounter(d.counts, (v) => v.count))}
          compare={o.compare}
          format="percent"
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'clicked',
    head: () => 'Clicks',
    headInfo: () => 'How many times this link was clicked',
    cell: (d, o) => {
      return (
        <CountCell
          variant={o.mode}
          before={d.counts.clicked.lastCount}
          after={d.counts.clicked.count}
          compare={o.compare}
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'clickRatio',
    head: () => 'Avg CTR',
    headInfo: () =>
      'How often visitors who see the affiliate link to this product actually click on the link',
    cell: (d, o) => {
      const before = getClickRatio(mapCounter(d.counts, (v) => v.lastCount));
      const after = getClickRatio(mapCounter(d.counts, (v) => v.count));
      if (o.mode === 'absolute-numbers') {
        return (
          <BaseCountCell
            before={before}
            after={after}
            compare={o.compare}
            format="percent"
            digits={1}
          >
            <Ctr rate={after} />
          </BaseCountCell>
        );
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          format="percent"
        />
      );
    },
    align: 'right',
    width: 80,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'epc',
    head: () => 'EPC', // needs tooltip
    headInfo: () => `
      Earnings made per click on this link.
    `,
    cell: (d, o) => {
      const before = getEpc(
        d.counts.clicked.lastCount,
        d.sales.prev.earnings.total
      );
      const after = getEpc(d.counts.clicked.count, d.sales.curr.earnings.total);

      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={d.sales.curr.earnings.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'itemsSold',
    head: () => 'Items', // needs tooltip
    headInfo: () => `
      Number of sales made from clicks that ocurred during this time period.
      Sales made during this time period from earlier clicks can be found on the Performance page.
    `,
    cell: (d, o) => {
      const before = d.sales.prev.earnings.totalCount;
      const after = d.sales.curr.earnings.totalCount;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'earnings',
    head: () => 'Earnings', // needs tooltip
    headInfo: () => `
      Sales made from clicks that ocurred during this time period.
      Sales made during this time period from earlier clicks can be found on the Performance page.
    `,
    cell: (d, o) => {
      const before = d.sales.prev.earnings.total;
      const after = d.sales.curr.earnings.total;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={d.sales.curr.earnings.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 90,
    flexGrow: 1
  },
  {
    key: 'gmv',
    head: () => 'Sales volume', // needs tooltip
    headInfo: () =>
      `Sales volume you've driven to the advertiser through this product link.`,
    cell: (d, o) => {
      const before = d.sales.prev.earnings.saleValue.total;
      const after = d.sales.curr.earnings.saleValue.total;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={d.sales.curr.earnings.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 90,
    flexGrow: 1
  },
  {
    key: 'rate',
    head: () => 'Rate', // needs tooltip
    headInfo: () => `Commission rate`,
    cell: (d) => {
      const before = getCommissionRate(
        d.sales.prev.earnings.total,
        d.sales.prev.earnings.saleValue.total
      );
      const after = getCommissionRate(
        d.sales.curr.earnings.total,
        d.sales.curr.earnings.saleValue.total
      );

      if (!before && !after) {
        return <Dash />;
      }
      return (
        <CountCell
          before={before}
          after={after}
          compare={false}
          format="percent"
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 60,
    flexGrow: 1
  },
  {
    key: 'issues',
    head: () => 'Issues',
    headInfo: () =>
      'Any known issues with this link, for example being broken or out of stock.',
    cell: (d) => {
      return <ProductIssue issues={d.issues} />;
    },
    align: 'center',
    width: 30,
    flexGrow: 1,
    sortable: true
  }
];

export const DEFAULT_COLUMNS: ColumnName[] = [
  'name',
  'viewRatio',
  'clicked',
  'clickRatio',
  'epc',
  'itemsSold',
  'earnings',
  'gmv',
  'rate'
];

const ROW_TO_KEY = (p: Data) => p.id;

export const Table = ({
  ds,
  loading,
  sorter,
  sortDirection,
  onSort,
  currency,
  columns: columnNames,
  compare,
  rowToHref,
  mode
}: {
  sorter: ItemSorter<Data>;
  sortDirection: SortDirection | undefined;
  onSort: (c: ColumnName, dir: SortDirection | undefined) => void;
  ds: void | Data[];
  loading: boolean;
  currency: CurrencyCode;
  columns: Set<string>;
  compare: boolean;
  rowToHref?: (d: Data) => string;
  mode: Mode;
}) => {
  const rows = useMemo(() => ds, [ds]);

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const otherProps = useMemo(
    () => ({
      compare,
      currency,
      mode
    }),
    [compare, currency, mode]
  );

  if (!rows || loading) {
    return (
      <Paper>
        <Loader height={800} />
      </Paper>
    );
  }

  return (
    <RowsRenderer
      variant="contained"
      columns={columns}
      rows={rows}
      rowToKey={ROW_TO_KEY}
      sorter={sorter}
      sortDirection={sortDirection}
      renderHead={true}
      headProps={{
        sticky: true,
        offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT + 8
      }}
      onHeadClick={(c, dir) => onSort(c.key, dir)}
      chunkSize={30}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.airy}
      rowToHref={rowToHref}
      otherProps={otherProps}
    />
  );
};
