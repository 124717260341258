import React, { useCallback, useMemo } from 'react';
import { Card } from '@material-ui/core';
import { LoadingValue } from '../../../../../../services/db';
import { Loader } from '../../../../../../components/Loader';
import { Centered } from '../../../../../../layout/Centered';
import { COLORS, isReferrerGroup } from '../services/groups';
import { ErrorMessage } from './ErrorMessage';
import {
  ChartCard,
  ChartCardFooter
} from '../../../../../../components/Charts/ChartCard';
import { ReferrersChartFooter } from './ReferrersChartFooter';
import { AnalyticsResponse } from '../../../../../../domainTypes/analytics_v2';
import { useReportMetric } from '../services/report-metric';
import { ReferrerGroup, groups } from '../../../../../../domainTypes/referrers';
import { TimeseriesChart } from '../../../../../../components/analytics_v2/Chart/TimeseriesChart';
import { xAxis } from '../../../../../../components/analytics_v2/Chart/xAxis';
import { yAxis } from '../../../../../../components/analytics_v2/Chart/yAxis';
import { tooltip } from '../../../../../../components/analytics_v2/Chart/tooltip';
import {
  timeRangeToGranularity,
  toReferrerGroupTitle
} from '../services/referrer-groups';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import { areaSeries } from '../../../../../../components/analytics_v2/Chart/areaSeries';
import { COLOR_UNKNOWN } from '../../../../../../services/color';

interface ChartProps {
  data: LoadingValue<AnalyticsResponse>;
}

// NOTE: This is a mutable copy of readonly groups array to satisfy typescript.
const GROUPS = [...groups];

export const GroupsTimeseriesChart: React.FC<ChartProps> = ({ data }) => {
  const { range } = useTimeframe();
  const { unit } = timeRangeToGranularity(range);
  const [metric, setMetric] = useReportMetric();
  const metrics = useMemo(() => [metric], [metric]);
  const groupColor = useCallback(
    (group: string) => (isReferrerGroup(group) ? COLORS[group] : COLOR_UNKNOWN),
    []
  );
  const groupName = useCallback(
    (group: string) => toReferrerGroupTitle(group as ReferrerGroup),
    []
  );
  const [timeseries, loading, error] = data;

  if (error) {
    return (
      <Card>
        <Centered height={350}>
          <ErrorMessage />
        </Centered>
      </Card>
    );
  }
  if (loading || !timeseries) {
    return (
      <Card>
        <Centered height={350}>
          <Loader size={32} />
        </Centered>
      </Card>
    );
  }

  return (
    <ChartCard noMaximize>
      <ChartCardFooter>
        <ReferrersChartFooter metric={metric} setMetric={setMetric} />
      </ChartCardFooter>
      <TimeseriesChart
        data={timeseries.rows}
        field="referrer_entry_origin"
        metrics={metrics}
        ResponsiveContainerProps={{ height: 300 }}
      >
        {({ context }) => [
          xAxis(context, unit, { textAnchor: 'middle' }),
          yAxis(metric, 'right', context),
          tooltip(context, unit, {
            cursor: true
          }),
          areaSeries(GROUPS, metric, {
            stroke: groupColor,
            fill: groupColor,
            name: groupName
          })
        ]}
      </TimeseriesChart>
    </ChartCard>
  );
};
