import { trim } from 'lodash';
import { useState } from 'react';
import shortid from 'shortid';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { IPageMetadata, IPageRevision } from '../../domainTypes/page';
import { CF, FS } from '../../versions';
import { store, updateInTransaction, useMappedLoadingValue } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';
import { now } from '../time';
import { removeTrailingSlash } from '../url';

const DELIMITER = '@|||@|||@';

export const pageCollection = () => store().collection(FS.pages);
export const getPagesQuery = (spaceId: string) =>
  pageCollection().where('spaceId', '==', spaceId);
export const getPageQuery = (spaceId: string, url: string) =>
  getPagesQuery(spaceId).where('url', '==', removeTrailingSlash(url));

export const toPageMetadataDoc = generateToDocFn<IPageMetadata>((d) => {
  d.revisions.forEach((r) => {
    r.provider = r.provider || 'app';
    r.createdAt = r.createdAt || null;
    r.createdBy = r.createdBy || 'AFFILIMATE';
  });
  return d;
});

const getCollectionListener = createCollectionListenerStore((key) => {
  const [spaceId, url] = key.split(DELIMITER);
  return new CollectionListener(getPageQuery(spaceId, url), toPageMetadataDoc);
});

const getPageCollectionListener = (spaceId: string, url: string) =>
  getCollectionListener([spaceId, url].join(DELIMITER));

// cache this - and if present in cache, pipe all other single accessors through it
export const getAllPageMetadataInSpace = (spaceId: string) =>
  getPagesQuery(spaceId)
    .get()
    .then((s) => s.docs.map(toPageMetadataDoc));

export const createNewPage = (
  spaceId: string,
  url: string
): Doc<IPageMetadata> => {
  return {
    id: shortid(),
    collection: FS.pages,
    data: {
      spaceId,
      url,
      createdAt: now(),
      revisions: [],
      labels: []
    }
  };
};

const createNewAndUpdateRevisions = async (spaceId: string, url: string) => {
  const doc = createNewPage(spaceId, url);
  await pageCollection().doc(doc.id).set(doc.data);
  await callFirebaseFunction(CF.pages.checkPageForModifications, {
    spaceId,
    url
  });
};

export const usePageOrCreate = (spaceId: string, url: string) => {
  const [setupInProgress, setSetupInProgress] = useState(false);
  const [value, loading, error] = usePage(spaceId, url);

  if (!loading && value === null && !error && !setupInProgress) {
    setSetupInProgress(true);
    try {
      createNewAndUpdateRevisions(spaceId, url);
    } catch (err) {
      console.log(err);
    }
  }

  return [value, loading, error, !value && setupInProgress] as [
    typeof value,
    typeof loading,
    typeof error,
    boolean
  ];
};

const getFirstPageDoc = (docs: Doc<IPageMetadata>[]) => {
  if (!docs.length) {
    return null;
  }
  const firstDoc = docs[0];
  if (docs.length > 1) {
    console.log(
      'Multiple page objects found for',
      firstDoc.data.spaceId,
      firstDoc.data.url
    );
  }
  return firstDoc;
};

export const usePage = (spaceId: string, url: string) => {
  return useMappedLoadingValue(
    useCollectionListener(getPageCollectionListener(spaceId, url)),
    getFirstPageDoc
  );
};

export const updatePageRevision = async (
  spaceId: string,
  url: string,
  revision: IPageRevision
) => {
  // We want to make this transactional - so we query first for the doc, to get its id
  // then perform the actual transaction. A bit weird.
  const doc = await getPageCollectionListener(spaceId, url)
    .get()
    .then(getFirstPageDoc);
  if (!doc) {
    console.log('Page not found', spaceId, url);
    return;
  }
  return updateInTransaction<IPageMetadata>(
    pageCollection().doc(doc.id),
    async (d) => {
      if (!d) {
        return null;
      }
      const lastModifiedAtMs = revision.lastModifiedAt.toMillis();
      const revisions = [...d.revisions];
      const i = revisions.findIndex(
        (r) => r.lastModifiedAt.toMillis() === lastModifiedAtMs
      );
      if (i === -1) {
        console.log('Revision not found');
        return null;
      }
      revisions[i] = revision;

      return { revisions };
    }
  );
};

export const generateSlug = (d: IPageMetadata) => {
  try {
    const u = new URL(d.url);
    const { pathname } = u;
    return trim(pathname, '/').replace(/[-_/]/g, '');
  } catch {
    return '';
  }
};
