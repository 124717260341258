import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';
import { styled } from '../../../../../emotion';
import { File, Star } from 'react-feather';
import { HelpScoutArticlePreview } from '../service';
import { useAdminOrImpersonatorClaim } from '../../../../../services/auth';
import { DraftStatus, UnpublishedStatus } from './ArticleStatus';
import { useRoutes } from '../../../../../routes';

const Articles = styled('ul')`
  font-size: 16px;
  line-height: 30px;
  list-style: none;
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  padding: 0;

  li {
    line-height: 22px;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 12px 1fr;
    grid-column-gap: 12px;

    & > svg {
      position: relative;
      top: 2px;
      margin-right: 12px;
      stroke: ${(p) => p.theme.palette.grey[500]};
    }
  }

  a {
    display: inline;
    color: ${(p) => p.theme.palette.primary.main};
    text-decoration: underline;
  }
`;

const FeaturedArticle = styled('span')`
  background-color: #fff1b8;
  height: 22px;
  border-radius: 8px;
  padding: 1px 8px 1px 4px;
  margin-left: 2px;
  font-size: 12px;
  font-weight: 700;
  color: ${(p) => p.theme.palette.warning.dark};
  display: inline-block;
  line-height: 14px;

  svg {
    position: relative;
    top: 3px;
    stroke: #faad14 !important;
  }
`;

export const ArticleList = ({
  articles,
  columns
}: {
  articles: Pick<
    HelpScoutArticlePreview,
    'id' | 'slug' | 'name' | 'keywords' | 'hasDraft' | 'status'
  >[];
  columns: number;
}) => {
  const { ROUTES } = useRoutes();
  const [showDraftStatus] = useAdminOrImpersonatorClaim();
  const sortedArticles = useMemo(() => {
    return sortBy(articles, (a) => a.name.toLowerCase());
  }, [articles]);
  return (
    <Articles
      style={{ gridTemplateColumns: columns === 2 ? '1fr 1fr' : '1fr' }}
    >
      {sortedArticles.map((a) => (
        <li key={a.id}>
          <File size={16} />{' '}
          <span>
            <Link to={ROUTES.docs.knowledgeBase.url(a.slug)}>{a.name}</Link>{' '}
            {(a.keywords || []).indexOf('featured') !== -1 && (
              <FeaturedArticle>
                <Star size={14} /> <span>Featured</span>
              </FeaturedArticle>
            )}
            {showDraftStatus && a.hasDraft && <DraftStatus />}
            {showDraftStatus && a.status === 'notpublished' && (
              <UnpublishedStatus />
            )}
          </span>
        </li>
      ))}
    </Articles>
  );
};
