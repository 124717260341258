import { capitalize } from 'lodash';
import { slotName, slotTitle } from '../../../domainTypes/customDimensions';
import {
  AdvertiserFilterDefinition,
  CampaignAdvertiserFilterDefinition,
  CampaignManagerFilterDefinition,
  CampaignStatusFilterDefinition,
  ChannelFilterDef,
  ClickDataFilterDefinition,
  ClickDataSlot,
  CountryFilterDef,
  DeviceFilterDef,
  FilterDefinition,
  IntegrationIdFilterDefinition,
  PayoutIdFilterDefinition,
  PayoutStatusFilterDefinition,
  PlatformFilterDef,
  ReferrerFilterDef,
  SiteFilterDef,
  TagFilterDef,
  TransactionStatusFilterDefinition,
  TransactionTypeFilterDefinition,
  UtmFilterDefinition
} from '../../../domainTypes/filters';
import {
  PayoutStatus,
  SaleType,
  TransactionStatus
} from '../../../domainTypes/performance';
import { ReferrerGroup } from '../../../domainTypes/referrers';
import { Device } from '../../../domainTypes/tracking';
import {
  dbColumn,
  UtmParameterType
} from '../../../features/Content/pages/Traffic/Utm/service/utm';
import { useCustomDimensions } from '../../../services/customDimensions';
import { CampaignStatus } from '../../../features/Campaigns/service';

export type Dimension = FilterDefinition['k'];

export const ALL_DIMENSIONS: Dimension[] = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_content',
  'referrer',
  'tag',
  'site',
  'platform',
  'device',
  'click_data_01'
];

export const initFilterValue = (dimension: Dimension): FilterDefinition => {
  switch (dimension) {
    case 'campaign_status':
      return { k: 'campaign_status', v: [] };
    case 'campaign_advertiser':
      return { k: 'campaign_advertiser', v: [] };
    case 'campaign_manager':
      return { k: 'campaign_manager', v: [] };
    case 'integration_id':
      return { k: 'integration_id', v: [] };
    case 'advertiser':
      return { k: 'advertiser', v: [] };
    case 'channel':
      return { k: 'channel', v: [] };
    case 'click_data_02':
      return { k: 'click_data_02', v: [] };
    case 'click_data_03':
      return { k: 'click_data_03', v: [] };
    case 'click_data_04':
      return { k: 'click_data_04', v: [] };
    case 'click_data_05':
      return { k: 'click_data_05', v: [] };
    case 'click_data_06':
      return { k: 'click_data_06', v: [] };
    case 'click_data_07':
      return { k: 'click_data_07', v: [] };
    case 'click_data_08':
      return { k: 'click_data_08', v: [] };
    case 'click_data_09':
      return { k: 'click_data_09', v: [] };
    case 'click_data_10':
      return { k: 'click_data_10', v: [] };
    case 'click_data_01':
      return { k: 'click_data_01', v: [] };
    case 'country':
      return { k: 'country', v: [] };
    case 'device':
      return { k: 'device', v: [] };
    case 'utm_medium':
      return { k: 'utm_medium', v: [] };
    case 'utm_source':
      return { k: 'utm_source', v: [] };
    case 'utm_term':
      return { k: 'utm_term', v: [] };
    case 'utm_content':
      return { k: 'utm_content', v: [] };
    case 'utm_campaign':
      return { k: 'utm_campaign', v: [] };
    case 'referrer':
      return { k: 'referrer', v: { mode: 'group', v: null } };
    case 'tag':
      return { k: 'tag', v: [] };
    case 'site':
      return { k: 'site', v: [] };
    case 'platform':
      return { k: 'platform', v: [] };
    case 'payout_id':
      return { k: 'payout_id', v: [] };
    case 'payout_status':
      return { k: 'payout_status', v: [] };
    case 'transaction_status':
      return { k: 'transaction_status', v: [] };
    case 'transaction_type':
      return { k: 'transaction_type', v: [] };
  }
};

export const validateFilterDefinition = (
  definition: FilterDefinition
): boolean => {
  switch (definition.k) {
    case 'campaign_status':
      return definition.v.length > 0;
    case 'campaign_advertiser':
      return definition.v.length > 0;
    case 'campaign_manager':
      return definition.v.length > 0;
    case 'integration_id':
      return definition.v.length > 0;
    case 'advertiser':
      return definition.v.length > 0;
    case 'channel':
      return definition.v.length > 0;
    case 'click_data_02':
      return definition.v.length > 0;
    case 'click_data_03':
      return definition.v.length > 0;
    case 'click_data_04':
      return definition.v.length > 0;
    case 'click_data_05':
      return definition.v.length > 0;
    case 'click_data_06':
      return definition.v.length > 0;
    case 'click_data_07':
      return definition.v.length > 0;
    case 'click_data_08':
      return definition.v.length > 0;
    case 'click_data_09':
      return definition.v.length > 0;
    case 'click_data_10':
      return definition.v.length > 0;
    case 'click_data_01':
      return definition.v.length > 0;
    case 'country':
      return definition.v.length > 0;
    case 'device':
      return definition.v.length > 0;
    case 'utm_medium':
      return definition.v.length > 0;
    case 'utm_source':
      return definition.v.length > 0;
    case 'utm_term':
      return definition.v.length > 0;
    case 'utm_content':
      return definition.v.length > 0;
    case 'utm_campaign':
      return definition.v.length > 0;
    case 'referrer':
      return definition.v.mode === 'domains'
        ? definition.v.v.length > 0
        : definition.v.v !== null;
    case 'tag':
      return definition.v.length > 0;
    case 'site':
      return definition.v.length > 0;
    case 'platform':
      return definition.v.length > 0;
    case 'payout_id':
      return definition.v.length > 0;
    case 'payout_status':
      return definition.v.length > 0;
    case 'transaction_status':
      return definition.v.length > 0;
    case 'transaction_type':
      return definition.v.length > 0;
  }
};

export const dimensionName = (dimension: Dimension): string => {
  switch (dimension) {
    case 'campaign_status':
      return 'status';
    case 'campaign_advertiser':
      return 'advertiser';
    case 'campaign_manager':
      return 'campaign manager';
    case 'integration_id':
      return 'integration_id';
    case 'advertiser':
      return 'advertiser';
    case 'channel':
      return 'channel';
    case 'click_data_01':
      return slotName('click_data_01');
    case 'click_data_02':
      return slotName('click_data_02');
    case 'click_data_03':
      return slotName('click_data_03');
    case 'click_data_04':
      return slotName('click_data_04');
    case 'click_data_05':
      return slotName('click_data_05');
    case 'click_data_06':
      return slotName('click_data_06');
    case 'click_data_07':
      return slotName('click_data_07');
    case 'click_data_08':
      return slotName('click_data_08');
    case 'click_data_09':
      return slotName('click_data_09');
    case 'click_data_10':
      return slotName('click_data_10');
    case 'country':
      return 'country';
    case 'device':
      return 'device';
    case 'utm_campaign':
      return 'UTM campaign';
    case 'utm_medium':
      return 'UTM medium';
    case 'utm_source':
      return 'UTM source';
    case 'utm_term':
      return 'UTM term';
    case 'utm_content':
      return 'UTM content';
    case 'referrer':
      return 'referrer';
    case 'tag':
      return 'tag';
    case 'site':
      return 'site';
    case 'platform':
      return 'platform';
    case 'payout_id':
      return 'payout id';
    case 'payout_status':
      return 'payout status';
    case 'transaction_status':
      return 'transaction status';
    case 'transaction_type':
      return 'transaction type';
  }
};

export const useDimensionNameFn = (): ((dimension: Dimension) => string) => {
  const [customDimensions = {}] = useCustomDimensions();
  return (dimension: Dimension): string => {
    switch (dimension) {
      case 'campaign_status':
        return 'status';
      case 'campaign_advertiser':
        return 'advertiser';
      case 'campaign_manager':
        return 'campaign manager';
      case 'integration_id':
        return 'integration';
      case 'advertiser':
        return 'advertiser';
      case 'channel':
        return 'site or channel';
      case 'click_data_01':
        return (
          customDimensions?.click_data_01?.name ?? slotName('click_data_01')
        );
      case 'click_data_02':
        return (
          customDimensions?.click_data_02?.name ?? slotName('click_data_02')
        );
      case 'click_data_03':
        return (
          customDimensions?.click_data_03?.name ?? slotName('click_data_03')
        );
      case 'click_data_04':
        return (
          customDimensions?.click_data_04?.name ?? slotName('click_data_04')
        );
      case 'click_data_05':
        return (
          customDimensions?.click_data_05?.name ?? slotName('click_data_05')
        );
      case 'click_data_06':
        return (
          customDimensions?.click_data_06?.name ?? slotName('click_data_06')
        );
      case 'click_data_07':
        return (
          customDimensions?.click_data_07?.name ?? slotName('click_data_07')
        );
      case 'click_data_08':
        return (
          customDimensions?.click_data_08?.name ?? slotName('click_data_08')
        );
      case 'click_data_09':
        return (
          customDimensions?.click_data_09?.name ?? slotName('click_data_09')
        );
      case 'click_data_10':
        return (
          customDimensions?.click_data_10?.name ?? slotName('click_data_10')
        );
      case 'country':
        return 'country';
      case 'device':
        return 'device';
      case 'utm_campaign':
        return 'UTM campaign';
      case 'utm_medium':
        return 'UTM medium';
      case 'utm_source':
        return 'UTM source';
      case 'utm_term':
        return 'UTM term';
      case 'utm_content':
        return 'UTM content';
      case 'referrer':
        return 'referrer';
      case 'tag':
        return 'tag';
      case 'site':
        return 'site';
      case 'platform':
        return 'platform';
      case 'payout_id':
        return 'payout id';
      case 'payout_status':
        return 'payout status';
      case 'transaction_status':
        return 'transaction status';
      case 'transaction_type':
        return 'transaction type';
    }
  };
};

// NOTE: We need this contrived getter so we can use it in filters tree matcher
export const useDimensionName = (dimension: Dimension): string => {
  const fn = useDimensionNameFn();
  return fn(dimension);
};

export const useDimensionTitle = (dimension: Dimension): string => {
  const [customDimensions = {}] = useCustomDimensions();
  switch (dimension) {
    case 'campaign_status':
      return 'Status';
    case 'campaign_advertiser':
      return 'Advertiser';
    case 'campaign_manager':
      return 'Campaign manager';
    case 'integration_id':
      return 'Integration ID';
    case 'advertiser':
      return 'Advertiser';
    case 'channel':
      return 'Site or Channel';
    case 'click_data_01':
      return capitalize(
        customDimensions?.click_data_01?.name ?? slotTitle('click_data_01')
      );
    case 'click_data_02':
      return capitalize(
        customDimensions?.click_data_02?.name ?? slotTitle('click_data_02')
      );
    case 'click_data_03':
      return capitalize(
        customDimensions?.click_data_03?.name ?? slotTitle('click_data_03')
      );
    case 'click_data_04':
      return capitalize(
        customDimensions?.click_data_04?.name ?? slotTitle('click_data_04')
      );
    case 'click_data_05':
      return capitalize(
        customDimensions?.click_data_05?.name ?? slotTitle('click_data_05')
      );
    case 'click_data_06':
      return capitalize(
        customDimensions?.click_data_06?.name ?? slotTitle('click_data_06')
      );
    case 'click_data_07':
      return capitalize(
        customDimensions?.click_data_07?.name ?? slotTitle('click_data_07')
      );
    case 'click_data_08':
      return capitalize(
        customDimensions?.click_data_08?.name ?? slotTitle('click_data_08')
      );
    case 'click_data_09':
      return capitalize(
        customDimensions?.click_data_09?.name ?? slotTitle('click_data_09')
      );
    case 'click_data_10':
      return capitalize(
        customDimensions?.click_data_10?.name ?? slotTitle('click_data_10')
      );
    case 'country':
      return 'Country';
    case 'device':
      return 'Device';
    case 'utm_campaign':
      return 'UTM campaign';
    case 'utm_medium':
      return 'UTM medium';
    case 'utm_source':
      return 'UTM source';
    case 'utm_term':
      return 'UTM term';
    case 'utm_content':
      return 'UTM content';
    case 'referrer':
      return 'Referrer';
    case 'tag':
      return 'Tag';
    case 'site':
      return 'Site';
    case 'platform':
      return 'Platform';
    case 'payout_id':
      return 'Payout ID';
    case 'payout_status':
      return 'Payout status';
    case 'transaction_status':
      return 'Transaction status';
    case 'transaction_type':
      return 'Transaction type';
  }
};

export const createUTMFilterDefinition = (
  paramType: UtmParameterType,
  values: Array<string>
): UtmFilterDefinition => ({
  k: dbColumn(paramType),
  v: values
});

export const createReferrerGroupFilterDefinition = (
  group: ReferrerGroup
): ReferrerFilterDef => ({
  k: 'referrer',
  v: {
    mode: 'group',
    v: group
  }
});

export const createReferrersFilterDefinition = (
  domains: string[]
): ReferrerFilterDef => ({
  k: 'referrer',
  v: {
    mode: 'domains',
    v: domains
  }
});

export const createTagsFilterDefinition = (tags: string[]): TagFilterDef => ({
  k: 'tag',
  v: tags
});

export const createSiteFilterDefinition = (sites: string[]): SiteFilterDef => ({
  k: 'site',
  v: sites
});

export const createDeviceFilterDefinition = (
  devices: string[]
): DeviceFilterDef => ({
  k: 'device',
  v: devices as Device[]
});

export const createPlatformFilterDefinition = (
  platforms: string[]
): PlatformFilterDef => ({
  k: 'platform',
  v: platforms
});

export const createIntegrationIdFilterDefinition = (
  integrationIds: string[]
): IntegrationIdFilterDefinition => ({
  k: 'integration_id',
  v: integrationIds
});

export const createAdvertiserFilterDefinition = (
  advertisers: string[]
): AdvertiserFilterDefinition => ({
  k: 'advertiser',
  v: advertisers
});

export const createCountryFilterDefinition = (
  countries: string[]
): CountryFilterDef => ({
  k: 'country',
  v: countries
});

export const createCustomDimensionFilterDefinition = (
  k: ClickDataSlot,
  values: string[]
): ClickDataFilterDefinition => ({
  k,
  v: values
});

export const createChannelFilterDefinition = (
  channels: string[]
): ChannelFilterDef => ({
  k: 'channel',
  v: channels
});

export const createPayoutIdFilterDefinition = (
  payoutIds: string[]
): PayoutIdFilterDefinition => ({
  k: 'payout_id',
  v: payoutIds
});

export const createPayoutStatusFilterDefinition = (
  statuses: PayoutStatus[]
): PayoutStatusFilterDefinition => ({
  k: 'payout_status',
  v: statuses
});

export const createTransactionStatusFilterDefinition = (
  statuses: TransactionStatus[]
): TransactionStatusFilterDefinition => ({
  k: 'transaction_status',
  v: statuses
});

export const createTransactionTypeFilterDefinition = (
  types: SaleType[]
): TransactionTypeFilterDefinition => ({
  k: 'transaction_type',
  v: types
});

export const createCampaignManagerFilterDefinition = (
  campaignManagers: string[]
): CampaignManagerFilterDefinition => ({
  k: 'campaign_manager',
  v: campaignManagers
});

export const createCampaignAdvertiserFilterDefinition = (
  advertisers: string[]
): CampaignAdvertiserFilterDefinition => ({
  k: 'campaign_advertiser',
  v: advertisers
});

export const createCampaignStatusFilterDefinition = (
  statuses: CampaignStatus[]
): CampaignStatusFilterDefinition => ({
  k: 'campaign_status',
  v: statuses
});
