import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { capitalize, truncate } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {
  ArrowDown,
  ArrowRightCircle,
  ArrowUp,
  ChevronDown,
  Grid
} from 'react-feather';
import { Link } from 'react-router-dom';
import { DeviceIconWithLabel } from '../../../../components/DeviceIcon';
import { InlineLoader } from '../../../../components/Loader';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { SingleSelector } from '../../../../components/SingleSelector';
import { UNKNOWN } from '../../../../domainTypes/analytics';
import {
  AnalyticsInterval,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { interpolatePath, PATHS } from '../../../../domainTypes/routes';
import { Device } from '../../../../domainTypes/tracking';
import { usePromise } from '../../../../hooks/usePromise';
import { useCurrentUser } from '../../../../services/currentUser';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { getProductByIdPg } from '../../../../services/products';
import { MOMENT_TO_INTERVAL_LABEL } from '../../../../services/time';
import { withoutProtocol } from '../../../../services/url';

const LazyProductNameWithLink = ({ productId }: { productId: string }) => {
  const {
    space: { id: spaceId }
  } = useCurrentUser();
  const [product, loading, error] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);
  console.log(product, loading, error);
  return (
    <>
      {product ? truncate(product.name, { length: 50 }) : productId}{' '}
      {loading && <InlineLoader color="inherit" />}
      {loading && ' '}
      <Link
        to={interpolatePath(PATHS.links.details.overview, {
          productId
        })}
      >
        <Tooltip title="Open analytics for this specific link" placement="top">
          <IconButton
            color="primary"
            style={{ position: 'relative', top: '-2px', padding: '4px 4px' }}
          >
            <ArrowRightCircle size={18} />
          </IconButton>
        </Tooltip>
      </Link>
    </>
  );
};

export type Sorter = {
  key: string;
  label: string;
  orderBy: AnalyticsOrderBy[];
  additionalFieldsToSelect?: AnalyticsQuery['select'];
};

export type Grouper = {
  key: string;
  label: string;
  toKey: (r: AnalyticsResponseRowWithComparison) => string;
  toLabel: (r: AnalyticsResponseRowWithComparison) => React.ReactNode;
  groupBy: AnalyticsQuery['groupBy'];
  interval: undefined | ((tz: string) => AnalyticsInterval);
  defaultSorter: Sorter;
};

export const SORTERS: { [key: string]: Sorter } = {
  // used to have clickDate, gone after Clickhouse transition and probably not required
  // also used to have date - but this one is also gone. It was slightly non-sensical ot have
  // it there as when you group by e.g. page, what does it mean to sort by date?
  // We also cannot do it anymore with clickhouse in the same vein - so out it is!
  amount: {
    key: 'amount',
    label: 'Earnings (DESC)',
    orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
    additionalFieldsToSelect: ['commission_sum_net']
  },
  amountAsc: {
    key: 'amountAsc',
    label: 'Earnings (ASC)',
    orderBy: [{ field: 'commission_sum_net', direction: 'ASC' }],
    additionalFieldsToSelect: ['commission_sum_net']
  },
  gmv: {
    key: 'gmv',
    label: 'Sales volume (DESC)',
    orderBy: [{ field: 'gmv_sum_net', direction: 'DESC' }],
    additionalFieldsToSelect: ['gmv_sum_net']
  },
  gmvAsc: {
    key: 'gmvAsc',
    label: 'Sales volume (ASC)',
    orderBy: [{ field: 'gmv_sum_net', direction: 'ASC' }],
    additionalFieldsToSelect: ['gmv_sum_net']
  },
  itemsSold: {
    key: 'itemsSold',
    label: 'Quantity (DESC)',
    orderBy: [{ field: 'quantity_net', direction: 'DESC' }],
    additionalFieldsToSelect: ['quantity_net']
  },
  itemsSoldAsc: {
    key: 'itemsSoldAsc',
    label: 'Quantity (ASC)',
    orderBy: [{ field: 'quantity_net', direction: 'ASC' }],
    additionalFieldsToSelect: ['quantity_net']
  },
  itemsRefunded: {
    key: 'itemsRefunded',
    label: 'Quantity refunded (DESC)',
    orderBy: [{ field: 'refunded_count', direction: 'DESC' }],
    additionalFieldsToSelect: ['refunded_count']
  },
  itemsRefundedAsc: {
    key: 'itemsRefundedAsc',
    label: 'Quanity refunded (ASC)',
    orderBy: [{ field: 'refunded_count', direction: 'ASC' }],
    additionalFieldsToSelect: ['refunded_count']
  },
  itemsCancelled: {
    key: 'itemsCancelled',
    label: 'Quantity canceled (DESC)',
    orderBy: [{ field: 'canceled_count', direction: 'DESC' }],
    additionalFieldsToSelect: ['canceled_count']
  },
  itemsCancelledAsc: {
    key: 'itemsCancelledAsc',
    label: 'Quantity canceled (ASC)',
    orderBy: [{ field: 'canceled_count', direction: 'ASC' }],
    additionalFieldsToSelect: ['canceled_count']
  }
};

export const GROUPERS: { [key: string]: Grouper } = {
  date: {
    key: 'date',
    label: 'Day',
    toKey: (r) => r.group['interval'] || '',
    toLabel: (r) =>
      r.group['interval']
        ? moment(r.group['interval'], 'YYYY-MM-DD HH:mm:ss').format(
            'MMMM Do (dddd)'
          )
        : '',
    groupBy: undefined,
    interval: (tz) => ({
      unit: 'day',
      value: 1,
      tz,
      fill: false
    }),
    defaultSorter: {
      key: 'date',
      label: 'Day',
      orderBy: [{ field: 'interval', direction: 'DESC' }]
    }
  },
  week: {
    key: 'week',
    label: 'Week',
    toKey: (r) => r.group['interval'] || '',
    toLabel: (r) => {
      const label = r.group['interval']
        ? moment(r.group['interval'], 'YYYY-MM-DD HH:mm:ss').format('MMMM Do')
        : '';
      return `Week of ${label}`;
    },
    groupBy: undefined,
    interval: (tz) => ({
      unit: 'week',
      value: 1,
      tz,
      fill: false
    }),
    defaultSorter: {
      key: 'week',
      label: 'Week',
      orderBy: [{ field: 'interval', direction: 'DESC' }]
    }
  },
  month: {
    key: 'month',
    label: 'Month',
    toKey: (r) => r.group['interval'] || '',
    toLabel: (r) =>
      r.group['interval']
        ? moment(r.group['interval'], 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY')
        : '',
    groupBy: undefined,
    interval: (tz) => ({
      unit: 'month',
      value: 1,
      tz,
      fill: false
    }),
    defaultSorter: {
      key: 'month',
      label: 'Month',
      orderBy: [{ field: 'interval', direction: 'DESC' }]
    }
  },
  quarter: {
    key: 'quarter',
    label: 'Quarter',
    toKey: (r) => r.group['interval'] || '',
    toLabel: (r) => {
      const interval = r.group['interval'] || '';
      if (!interval) {
        return '';
      }
      const m = moment(interval, 'YYYY-MM-DD HH:mm:ss');
      return `Q${m.format('Q')} ${m.format('YYYY')}`;
    },
    groupBy: undefined,
    interval: (tz) => ({
      unit: 'quarter',
      value: 1,
      tz,
      fill: false
    }),
    defaultSorter: {
      key: 'quarter',
      label: 'Quarter',
      orderBy: [{ field: 'interval', direction: 'DESC' }]
    }
  },
  year: {
    key: 'year',
    label: 'Year',
    toKey: (r) => r.group['interval'] || '',
    toLabel: (r) =>
      r.group['interval']
        ? MOMENT_TO_INTERVAL_LABEL.year.startsInCurrentYear(
            moment(r.group['interval'], 'YYYY-MM-DD HH:mm:ss')
          )
        : '',
    groupBy: undefined,
    interval: (tz) => ({
      unit: 'year',
      value: 1,
      tz,
      fill: false
    }),
    defaultSorter: {
      key: 'year',
      label: 'Year',
      orderBy: [{ field: 'interval', direction: 'DESC' }]
    }
  },
  partner: {
    key: 'partner',
    label: 'Platform',
    toKey: (r) => r.group['pk'] || '',
    toLabel: (r) => {
      const pk = r.group['pk'] || '';
      const partner = getKnownPartnerForKey(pk);
      return partner ? <PlatformWithColor partner={partner} /> : pk;
    },
    interval: undefined,
    groupBy: ['pk'],
    defaultSorter: {
      key: 'pk',
      label: 'Platform',
      orderBy: [{ field: 'pk', direction: 'ASC', emptyLast: true }]
    }
  },
  advertiser: {
    key: 'advertiser',
    label: 'Advertiser',
    toKey: (r) => r.group['advertiser_name'] || '',
    toLabel: (r) => {
      return r.group['advertiser_name'] || '';
    },
    interval: undefined,
    groupBy: ['advertiser_name'],
    defaultSorter: {
      key: 'advertiser_name',
      label: 'Advertiser',
      orderBy: [{ field: 'advertiser_name', direction: 'ASC', emptyLast: true }]
    }
  },
  status: {
    key: 'status',
    label: 'Transaction status',
    toKey: (r) => r.group['sale_status'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sale_status'] || UNKNOWN;
      return capitalize(l);
    },
    interval: undefined,
    groupBy: ['sale_status'],
    defaultSorter: {
      key: 'status',
      label: 'Transaction status',
      orderBy: [{ field: 'sale_status', direction: 'ASC', emptyLast: true }]
    }
  },
  origin: {
    key: 'origin',
    label: 'Site',
    toKey: (r) => r.group['page_url_origin'] || UNKNOWN,
    toLabel: (r) => {
      const url = r.group['page_url_origin'] || '';
      return url || UNKNOWN; // TODO offer link
    },
    interval: undefined,
    groupBy: ['page_url_origin'],
    defaultSorter: {
      key: 'origin',
      label: 'Site',
      orderBy: [{ field: 'page_url_origin', direction: 'ASC', emptyLast: true }]
    }
  },
  /*
  channel: {
    key: 'channel',
    label: 'Channel',
    toKey: (r) => r.group['channel_id'] || UNKNOWN,
    toLabel: (r) => {
      const url = r.group['channel_id'] || '';
      return url || UNKNOWN; // TODO offer link
    },
    interval: undefined,
    groupBy: ['channel_id'],
    defaultSorter: {
      key: 'channel_id',
      label: 'Channel',
      orderBy: [{ field: 'channel_id', direction: 'ASC', emptyLast: true }]
    }
  },
  */
  page: {
    key: 'page',
    label: 'Page URL',
    toKey: (r) => r.group['page_url'] || UNKNOWN,
    toLabel: (r) => {
      const url = r.group['page_url'] || '';
      return url ? withoutProtocol(url) : UNKNOWN; // TODO offer link
    },
    interval: undefined,
    groupBy: ['page_url'],
    defaultSorter: {
      key: 'page',
      label: 'Page URL',
      orderBy: [{ field: 'page_url', direction: 'ASC', emptyLast: true }]
    }
  },
  linkClicked: {
    key: 'linkClicked',
    label: 'Link clicked',
    toKey: (r) => r.group['link_id'] || '',
    toLabel: (r) => {
      const linkId = r.group['link_id'] || '';
      if (!linkId) {
        return UNKNOWN;
      }
      return <LazyProductNameWithLink productId={linkId} />;
    },
    interval: undefined,
    groupBy: ['link_id'],
    defaultSorter: {
      key: 'linkClicked',
      label: 'Link clicked',
      orderBy: [{ field: 'link_id', direction: 'ASC', emptyLast: true }]
    }
  },
  // TODO Product clicked
  productSold: {
    key: 'productSold',
    label: 'Product sold',
    toKey: (r) => r.group['partner_product_name'] || UNKNOWN,
    toLabel: (r) => {
      const p = r.group['partner_product_name'] || '';
      if (!p) {
        return UNKNOWN;
      }
      return <div title={p}>{truncate(p, { length: 80 })}</div>;
    },
    interval: undefined,
    groupBy: ['partner_product_name'],
    defaultSorter: {
      key: 'productSold',
      label: 'Product sold',
      orderBy: [
        { field: 'partner_product_name', direction: 'ASC', emptyLast: true }
      ]
    }
  },
  device: {
    key: 'device',
    label: 'Device',
    toKey: (r) => r.group['device'] || 'unknown',
    toLabel: (r) => {
      const device = r.group['device'] || 'unknown';
      return <DeviceIconWithLabel size={18} device={device as Device} />;
    },
    groupBy: ['device'],
    interval: undefined,
    defaultSorter: {
      key: 'device',
      label: 'Device',
      orderBy: [{ field: 'device', direction: 'ASC' }]
    }
  },
  subId: {
    key: 'subId',
    label: 'Clean SubID',
    toKey: (r) => r.group['tracking_label'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['tracking_label'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['tracking_label'],
    defaultSorter: {
      key: 'subId',
      label: 'Clean SubID',
      orderBy: [{ field: 'tracking_label', direction: 'ASC', emptyLast: true }]
    }
  },
  subId1: {
    key: 'subId1',
    label: 'SubID 1',
    toKey: (r) => r.group['sub_id_01'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_01'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_01'],
    defaultSorter: {
      key: 'subId1',
      label: 'Sub ID 1',
      orderBy: [{ field: 'sub_id_01', direction: 'ASC', emptyLast: true }]
    }
  },
  subId2: {
    key: 'subId2',
    label: 'SubID 2',
    toKey: (r) => r.group['sub_id_02'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_02'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_02'],
    defaultSorter: {
      key: 'subId2',
      label: 'Sub ID 2',
      orderBy: [{ field: 'sub_id_02', direction: 'ASC', emptyLast: true }]
    }
  },
  subId3: {
    key: 'subId3',
    label: 'SubID 3',
    toKey: (r) => r.group['sub_id_03'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_03'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_03'],
    defaultSorter: {
      key: 'subId3',
      label: 'Sub ID 3',
      orderBy: [{ field: 'sub_id_03', direction: 'ASC', emptyLast: true }]
    }
  },
  subId4: {
    key: 'subId4',
    label: 'SubID 4',
    toKey: (r) => r.group['sub_id_04'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_04'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_04'],
    defaultSorter: {
      key: 'subId4',
      label: 'Sub ID 4',
      orderBy: [{ field: 'sub_id_04', direction: 'ASC', emptyLast: true }]
    }
  },
  subId5: {
    key: 'subId5',
    label: 'SubID 5',
    toKey: (r) => r.group['sub_id_05'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_05'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_05'],
    defaultSorter: {
      key: 'subId5',
      label: 'Sub ID 5',
      orderBy: [{ field: 'sub_id_05', direction: 'ASC', emptyLast: true }]
    }
  },
  subId6: {
    key: 'subId6',
    label: 'SubID 6',
    toKey: (r) => r.group['sub_id_06'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_06'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_06'],
    defaultSorter: {
      key: 'subId6',
      label: 'Sub ID 6',
      orderBy: [{ field: 'sub_id_06', direction: 'ASC', emptyLast: true }]
    }
  },
  subId7: {
    key: 'subId7',
    label: 'SubID 7',
    toKey: (r) => r.group['sub_id_07'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_07'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_07'],
    defaultSorter: {
      key: 'subId7',
      label: 'Sub ID 7',
      orderBy: [{ field: 'sub_id_07', direction: 'ASC', emptyLast: true }]
    }
  },
  subId8: {
    key: 'subId8',
    label: 'SubID 8',
    toKey: (r) => r.group['sub_id_08'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['sub_id_08'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['sub_id_08'],
    defaultSorter: {
      key: 'subId8',
      label: 'Sub ID 8',
      orderBy: [{ field: 'sub_id_08', direction: 'ASC', emptyLast: true }]
    }
  },
  advertiserSubId1: {
    key: 'advertiserSubId1',
    label: 'Advertiser SubID 1',
    toKey: (r) => r.group['advertiser_sub_id_01'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['advertiser_sub_id_01'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['advertiser_sub_id_01'],
    defaultSorter: {
      key: 'advertiserSubId1',
      label: 'Advertiser Sub ID 1',
      orderBy: [
        { field: 'advertiser_sub_id_01', direction: 'ASC', emptyLast: true }
      ]
    }
  },
  advertiserSubId2: {
    key: 'advertiserSubId2',
    label: 'Advertiser SubID 2',
    toKey: (r) => r.group['advertiser_sub_id_02'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['advertiser_sub_id_02'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['advertiser_sub_id_02'],
    defaultSorter: {
      key: 'advertiserSubId2',
      label: 'Advertiser Sub ID 2',
      orderBy: [
        { field: 'advertiser_sub_id_02', direction: 'ASC', emptyLast: true }
      ]
    }
  },
  advertiserSubId3: {
    key: 'advertiserSubId3',
    label: 'Advertiser SubID 3',
    toKey: (r) => r.group['advertiser_sub_id_03'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['advertiser_sub_id_03'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['advertiser_sub_id_03'],
    defaultSorter: {
      key: 'advertiserSubId3',
      label: 'Advertiser Sub ID 3',
      orderBy: [
        { field: 'advertiser_sub_id_03', direction: 'ASC', emptyLast: true }
      ]
    }
  },
  advertiserSubId4: {
    key: 'advertiserSubId4',
    label: 'Advertiser SubID 4',
    toKey: (r) => r.group['advertiser_sub_id_04'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['advertiser_sub_id_04'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['advertiser_sub_id_04'],
    defaultSorter: {
      key: 'advertiserSubId4',
      label: 'Advertiser Sub ID 4',
      orderBy: [
        { field: 'advertiser_sub_id_04', direction: 'ASC', emptyLast: true }
      ]
    }
  },
  advertiserSubId5: {
    key: 'advertiserSubId5',
    label: 'Advertiser SubID 5',
    toKey: (r) => r.group['advertiser_sub_id_05'] || UNKNOWN,
    toLabel: (r) => {
      const l = r.group['advertiser_sub_id_05'] || 'None';
      return l;
    },
    interval: undefined,
    groupBy: ['advertiser_sub_id_05'],
    defaultSorter: {
      key: 'advertiserSubId5',
      label: 'Advertiser Sub ID 5',
      orderBy: [
        { field: 'advertiser_sub_id_05', direction: 'ASC', emptyLast: true }
      ]
    }
  }
};

export const SortSelectorV2 = ({
  value,
  onChange,
  defaultValue,
  sorters
}: {
  value: Sorter | null;
  onChange: (nextValue: Sorter | null) => void;
  defaultValue: Sorter;
  sorters: { [key: string]: Sorter };
}) => {
  const allSorters = Object.values(sorters);
  const ss =
    allSorters.indexOf(defaultValue) === -1
      ? [defaultValue, ...allSorters]
      : allSorters;
  const activeValue = value || defaultValue;
  return (
    <SingleSelector
      value={value ? value.key : null}
      onChange={(key) =>
        onChange(key === defaultValue.key ? null : sorters[key])
      }
      legend="Sort by..."
      options={ss.map((g) => ({
        value: g.key,
        label: g.label,
        searchValue: g.label
      }))}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        {value?.key.endsWith('Asc') ? (
          <ArrowUp size={18} />
        ) : (
          <ArrowDown size={18} />
        )}{' '}
        Sort <strong>{activeValue.label}</strong>
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};

export const GroupSelectorV2 = ({
  value,
  onChange
}: {
  value: Grouper | null;
  onChange: (nextValue: Grouper | null) => void;
}) => {
  return (
    <SingleSelector
      value={value ? value.key : null}
      onChange={(key) => onChange(GROUPERS[key])}
      legend="Group by..."
      options={Object.values(GROUPERS).map((g) => ({
        value: g.key,
        label: g.label,
        searchValue: g.label
      }))}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <Grid size={18} />{' '}
        {value ? (
          <>
            Group <strong>{value.label}</strong>
          </>
        ) : (
          'No group'
        )}
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};
