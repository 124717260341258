import React, { useMemo } from 'react';
import { TableMetadata } from '../../../../../components/analytics_v2/Table';
import {
  AnalyticsField,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { ProductLinkCellLazyMinimal } from '../../../../Links/pages/Overview/components/ProductLinkCell';
import {
  useRealtimeFilters,
  useRealtimeMetric,
  useSmallTableMetrics
} from '../service';
import { useRoutes } from '../../../../../routes';
import {
  comparePeriodRange,
  usePeriodRangeToTimeframe
} from './PeriodSelector';
import {
  createNameColumn,
  TopPaper,
  TopTable,
  TopHeader,
  TopFooter
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';

const GROUP_FIELD: AnalyticsField = 'link_id';

const cell = (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
  const linkId = p.group[GROUP_FIELD];
  return <ProductLinkCellLazyMinimal spaceId={o.spaceId} productId={linkId} />;
};

export const TopLinksTable = ({
  range,
  color
}: {
  range: ISOTimeRange;
  color: string;
}) => {
  const [metric] = useRealtimeMetric();
  const metrics = useSmallTableMetrics();
  const { filters, columnTransformers } = useRealtimeFilters();
  const { ROUTES } = useRoutes();
  const timeframe = usePeriodRangeToTimeframe();
  const linksColumn = useMemo(
    () => createNameColumn(cell, 'Link name or deeplink', color, metric),
    [color, metric]
  );
  return (
    <TopPaper>
      <TopHeader
        title="Top links and products"
        description="Engagement and performance by link, shown by deeplink destination when available."
      ></TopHeader>
      <TopTable
        filters={filters}
        columnTransformers={columnTransformers}
        metrics={metrics}
        range={range}
        compareRange={comparePeriodRange(range)}
        metric={metric}
        groupField={GROUP_FIELD}
        nameColumn={linksColumn}
      />
      <TopFooter route={ROUTES.links.overview_v2.url({ timeframe })} />
    </TopPaper>
  );
};
