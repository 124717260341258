import React, { useMemo } from 'react';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useFilters } from '../hooks';
import { DimensionMenu } from '../Menus/Dimension';
import { ISOTimeRange } from '../../../../domainTypes/analytics_v2';
import { FilterDrawerRenderContext } from './types';

interface FilterMenuBodyProps {
  initialDefinition: FilterDefinition;
  onSave: (value: FilterDefinition) => void;
  orderBy: Metric;
  range: ISOTimeRange;
  renderContext?: FilterDrawerRenderContext;
}

export const FilterMenuBody: React.FC<FilterMenuBodyProps> = ({
  initialDefinition,
  onSave,
  orderBy,
  range,
  renderContext
}) => {
  const filters = useFilters();
  const filterClauses = useMemo(
    () =>
      filters
        .filter((f) => f.dimension !== initialDefinition.k)
        .map((f) => f.clause),
    [filters, initialDefinition.k]
  );

  return (
    <DimensionMenu
      filters={filterClauses}
      orderBy={orderBy}
      range={range}
      definition={initialDefinition}
      onSave={onSave}
      renderContext={renderContext}
    />
  );
};
