import {
  ButtonBase,
  Card,
  CardContent,
  LinearProgress,
  Paper,
  Typography
} from '@material-ui/core';
import { keyBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AlertBox } from '../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { HelpIcon } from '../../../../components/HelpIcon';
import { IImageInfo } from '../../../../components/ImageWithAnnotations';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { ProgressStepper } from '../../../../components/ProgressStepper';
import { Timeframe } from '../../../../domainTypes/analytics';
import { Doc } from '../../../../domainTypes/document';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import {
  IPageRevision,
  IPageScreenshot,
  isScreenshotPending
} from '../../../../domainTypes/page';
import { ISpace } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import { ARTICLES, Beacon } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useLoadingValueTimer } from '../../../../services/db';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePage } from '../../../../services/page';
import { getLatestRevision } from '../../../../services/page/revision';
import {
  createScreenshotDoc,
  useScreenshotMetadata
} from '../../../../services/pageScreenshots';
import { useProductsWithCountsAndSalesOnPageWithLazyProductRetrieval } from '../../../../services/products';
import { useDownloadUrlsWithCustomMetadata } from '../../../../services/storage';
import { Timestamp } from '../../../../services/time';
import { getPathname, removeTrailingSlash } from '../../../../services/url';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import * as tracking from '../../../../tracking';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { HeatmapEmptyState } from '../../components/HeatmapEmptyState';
import {
  SelectorsDense,
  useStandardSelectorStateFromUrl
} from '../../components/Selectors';
import { newSideNavProps, sideNavProps } from '../../services/detailsSideNav';
import {
  Heatmap,
  HeatmapAnnotationData,
  HeatmapDeviceOptions,
  IHeatmapAnnotationDimension,
  prepareHeatmapData,
  prepareHeatmapDataOld,
  useHeatmapAnalyticsData
} from './components/Heatmap';
import { Recreator, RecreatorUnsafe } from './components/Recreator';
import {
  useTimeframeWithRevisions,
  TimeframePickerWithRevisions
} from '../../../../components/analytics_v2/Timeframe/revisions';
import { ISOTimeRange } from '../../../../domainTypes/analytics_v2';

const LOADER_HEIGHT = 500;
const SCALE_SINGLE = 0.7;

const LoaderLabel = styled('div')`
  margin-top: ${(p) => p.theme.spacing(1)}px;
  color: ${(p) => p.theme.palette.text.secondary};
`;

type Props = {
  url: string;
};

type WrappedHeatmapProps = {
  space: ISpace;
  url: string;
  timeframe: Timeframe;
  revision: IPageRevision;
  latestRevision: IPageRevision;
  dimension: IHeatmapAnnotationDimension;
  setDimension: (dimension: IHeatmapAnnotationDimension) => void;
  scale: number;
  device: HeatmapDeviceOptions;
  setDevice: (device: HeatmapDeviceOptions) => void;
};

type WrappedHeatmapClickhouseProps = Omit<WrappedHeatmapProps, 'timeframe'> & {
  timeRange: ISOTimeRange;
};

type WrappedHeatmapWithDataProps = {
  space: ISpace;
  annotationData: HeatmapAnnotationData[];
  metadata: Doc<IPageScreenshot>;
  device: HeatmapDeviceOptions;
  setDevice: (device: HeatmapDeviceOptions) => void;
  setDimension: (dimension: IHeatmapAnnotationDimension) => void;
  dimension: IHeatmapAnnotationDimension;
  scale: number;
  isLatestRevision: boolean;
  minNextRevisionDate: Timestamp;
};

const Container = styled('div')`
  display: flex;
  justify-content: center;
`;

const ProgressContainer = styled('div')`
  display: grid;
  margin-top: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: space-between;
`;

const CardGrid = styled(CardContent)`
  text-align: center;
`;

const CreationInProgress: React.FC<{ d: IPageScreenshot }> = ({ d }) => {
  let activeStep = null;
  if (d.status === 'PENDING') {
    activeStep = 0;
  }
  if (d.status === 'PENDING_LOADED') {
    activeStep = 1;
  }
  if (d.status === 'PENDING_LINKS_FOUND') {
    activeStep = 2;
  }

  return (
    <Centered>
      <Paper style={{ width: 400, height: 500 }}>
        <LinearProgress variant="indeterminate" />
        <CardContent>
          <ProgressContainer>
            <div style={{ textAlign: 'center', marginBottom: '36px' }}>
              <img
                src="/images/empty-states/heatmaps-2.svg"
                width="200"
                alt=""
              />
            </div>
            <div style={{ margin: '0 auto' }}>
              <div>
                <ProgressStepper
                  activeStep={activeStep}
                  steps={[
                    'Visiting your page',
                    'Analyzing your links',
                    'Building your heatmap'
                  ]}
                />
              </div>
            </div>
          </ProgressContainer>
        </CardContent>
      </Paper>
    </Centered>
  );
};

const HeatmapErrorContainer = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '600px',
  margin: `0 auto`
}));

const HeatmapError = ({
  metadata,
  isLatestRevision
}: {
  metadata: Doc<IPageScreenshot>;
  isLatestRevision: boolean;
}) => {
  const { status, hasError } = metadata.data;

  if (!hasError) {
    return null;
  }

  if (!isLatestRevision) {
    return (
      <HeatmapErrorContainer>
        <Card>
          <CardGrid>
            <img
              src={'/images/blank_canvas.svg'}
              alt=""
              style={{ maxWidth: 250, margin: '0 auto 24px' }}
            />
            {status === 'NO_SCRIPT' ? (
              <Typography variant="body1" paragraph>
                We couldn't find Affilimate's tracking snippet on this page.
                <br />
                <br />
                Since this isn't the most recent version of the page, it's not
                possible to take this screenshot retroactively.
              </Typography>
            ) : (
              <Typography variant="body1" paragraph>
                Something went wrong with this screenshot!
                <br />
                <br />
                Since this isn't the most recent version of the page, it's not
                possible to take this screenshot retroactively.
              </Typography>
            )}
          </CardGrid>
        </Card>
        <Typography
          variant="caption"
          color="textSecondary"
          component="p"
          style={{ marginTop: '35px' }}
        >
          Need some help? &nbsp;
          <ButtonBase
            style={{ position: 'relative', top: '-2px' }}
            onClick={() => {
              Beacon('chat');
            }}
          >
            Chat with us
          </ButtonBase>
        </Typography>
      </HeatmapErrorContainer>
    );
  }

  return (
    <HeatmapErrorContainer>
      <Card>
        <CardGrid>
          <div>
            {status === 'NO_SCRIPT' ? (
              <>
                <Typography variant="h6" paragraph style={{ fontWeight: 700 }}>
                  Snippet not detected on this page
                </Typography>
                <Typography variant="body1" paragraph>
                  Affilimate uses its own tracking snippet to assist in
                  generating heatmaps. This means we couldn't generate a heatmap
                  for this page.
                </Typography>
                <Typography variant="body1" paragraph>
                  <HelpIcon
                    articleId={ARTICLES.content.heatmapNotRendering}
                    color="blue"
                  >
                    See recommended solutions
                  </HelpIcon>
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  We were not able to generate a heatmap for this page.
                </Typography>
                <Typography variant="body1" paragraph>
                  <HelpIcon
                    articleId={ARTICLES.content.heatmapNotRendering}
                    color="blue"
                  >
                    See recommended solutions
                  </HelpIcon>
                </Typography>
              </>
            )}
            <br />
            <RecreatorUnsafe d={metadata} />
          </div>
        </CardGrid>
        <Typography
          variant="caption"
          color="textSecondary"
          component="p"
          style={{ textAlign: 'center', margin: '12px auto' }}
        >
          Follow the recommended solutions, then press Try again.
        </Typography>
      </Card>
    </HeatmapErrorContainer>
  );
};

const WrappedHeatmapWithData: React.FC<WrappedHeatmapWithDataProps> = ({
  space,
  annotationData,
  metadata,
  scale,
  device,
  setDevice,
  dimension,
  setDimension,
  isLatestRevision,
  minNextRevisionDate
}) => {
  const screenshotDetails =
    metadata.data.byDevice[device === 'all' ? 'desktop' : device];
  const [value, loading, error] = useDownloadUrlsWithCustomMetadata<{
    placeholder: string;
    height: string;
  }>(screenshotDetails ? screenshotDetails.storagePaths : EMPTY_ARR);

  if (isScreenshotPending(metadata.data)) {
    return <CreationInProgress d={metadata.data} />;
  }

  if (!screenshotDetails || loading) {
    // This happens when we want to like at device data,
    // for which we have no info collected in the past./
    // E.g. mobile views were introduced only in August 2019 -
    // all data collected before that doesn't have it.
    // TODO - Add some info around this.
    return (
      <Loader
        height={LOADER_HEIGHT}
        label={<LoaderLabel>Loading your page data...</LoaderLabel>}
      />
    );
  }

  if (!value || !value.length) {
    return <HeatmapEmptyState />;
  }

  if (metadata.data.hasError) {
    return (
      <HeatmapError metadata={metadata} isLatestRevision={isLatestRevision} />
    );
  }

  if (error) {
    console.error(error.serverResponse);
    return <HeatmapEmptyState />;
  }

  const imageData = value.map<IImageInfo>((v) => ({
    src: v.url,
    placeholder: v.metadata.customMetadata.placeholder,
    height: Number(v.metadata.customMetadata.height)
  }));

  return (
    <div>
      <Container>
        <Heatmap
          space={space}
          scale={scale}
          annotationData={annotationData}
          screenshot={metadata}
          dimension={dimension}
          setDimension={setDimension}
          device={device}
          setDevice={setDevice}
          image={{
            alt: metadata.data.url,
            data: imageData,
            dimensions: screenshotDetails.dimensions
          }}
          minNextRevisionDate={minNextRevisionDate}
        />
      </Container>
      <Recreator d={metadata} />
    </div>
  );
};

export const NoData: React.FC<{
  onRequest: () => Promise<Doc<IPageScreenshot>>;
  url: string;
  isLatestRevision: boolean;
}> = ({ onRequest, url, isLatestRevision }) => (
  <Centered height={500}>
    <Card>
      <CardContent>
        <img
          src={'/images/empty-states/no_data.svg'}
          alt=""
          style={{ maxWidth: 300 }}
        />
        <Centered>
          {isLatestRevision ? (
            <p>No heatmap data available yet.</p>
          ) : (
            <p>We unforuntunately have no heatmap data for this revision.</p>
          )}
        </Centered>
        {isLatestRevision && (
          <Centered>
            <ButtonWithPromise
              onClick={() => {
                tracking.sendEvent({
                  category: tracking.toAppCategory(),
                  action: 'Click',
                  label: 'Request data'
                });
                return onRequest()
                  .then(() => {
                    tracking.sendEvent({
                      category: tracking.toAppCategory(),
                      action: 'Request screenshot',
                      label: url
                    });
                  })
                  .catch(() => {
                    tracking.sendEvent({
                      category: tracking.toAppCategory(),
                      action: 'Request screenshot (error)',
                      label: url
                    });
                  });
              }}
              variant="contained"
              color="primary"
              pending="Creating..."
            >
              Request data
            </ButtonWithPromise>
          </Centered>
        )}
      </CardContent>
    </Card>
  </Centered>
);

const WrappedHeatmapClickhouseAndPg: React.FC<WrappedHeatmapClickhouseProps> = ({
  scale,
  space,
  url,
  timeRange,
  dimension,
  setDimension,
  revision,
  latestRevision,
  device,
  setDevice
}) => {
  const isLatestRevision = revision === latestRevision;
  const currentUser = useCurrentUser();

  const spaceId = space.id;
  const [heatmapData, loadingHeatmapData] = useHeatmapAnalyticsData(
    spaceId,
    url,
    timeRange,
    device
  );

  const [metadata, loadingMetadata, error] = useScreenshotMetadata(
    space.id,
    url,
    revision
  );

  // metadata === undefined && error === undefined works around the slightly erroneous
  // state reporting by useCollection
  const loading =
    loadingHeatmapData &&
    loadingMetadata &&
    metadata === undefined &&
    error === undefined;

  const annotationData = useMemo(
    () =>
      metadata && heatmapData
        ? prepareHeatmapData(
            metadata.data.byDevice[device === 'all' ? 'desktop' : device]
              ?.links || EMPTY_ARR,
            heatmapData.links,
            heatmapData.rows
          )
        : null,
    [metadata, device, heatmapData]
  );

  if (error) {
    console.log(error);
    return null;
  }

  if (!loading && metadata === null) {
    return (
      <NoData
        url={url}
        onRequest={() =>
          createScreenshotDoc(
            currentUser.space.id,
            url,
            currentUser.id,
            revision.lastModifiedAt
          )
        }
        isLatestRevision={isLatestRevision}
      />
    );
  }

  return (
    <>
      {annotationData && metadata ? (
        <WrappedHeatmapWithData
          space={space}
          annotationData={annotationData}
          scale={scale}
          device={device}
          setDevice={setDevice}
          dimension={dimension}
          setDimension={setDimension}
          metadata={metadata}
          isLatestRevision={isLatestRevision}
          minNextRevisionDate={latestRevision.lastModifiedAt}
        />
      ) : (
        <Loader
          height={LOADER_HEIGHT}
          label={<LoaderLabel>Loading metadata...</LoaderLabel>}
        />
      )}
    </>
  );
};

const WrappedHeatmapPg: React.FC<WrappedHeatmapProps> = ({
  scale,
  space,
  url,
  timeframe,
  dimension,
  setDimension,
  revision,
  latestRevision,
  device,
  setDevice
}) => {
  const isLatestRevision = revision === latestRevision;
  const currentUser = useCurrentUser();
  const currency = useSpaceCurrency();
  //useLoadingValueTimer('Sales (Heatmap)', sales);

  // TODO change how product retrieval works
  // We don't want to load all products anymore.
  // The next hook will just return counts and earnings per productId. This collection
  // of productIds we can then resolve in a next step.
  //prepareHeatmapDataOld This can even be done fully asynchronously, as all we need from the product is the product name.
  // All other information is not relevant on this particular page.
  // There's even a case to be made to store product names in a much lighter version of the product
  // object, which ONLY contains a name to make access as fast as possible.
  // This solution could also apply to other pages - e.g. the Transactions page - where we ALSO
  // only need the product name in addition to the rest of the data.
  const productsLv = useProductsWithCountsAndSalesOnPageWithLazyProductRetrieval(
    url,
    timeframe,
    true,
    currency
  );
  useLoadingValueTimer('lazy products', productsLv);
  const [products, loadingProducts] = productsLv;

  const [metadata, loadingMetadata, error] = useScreenshotMetadata(
    space.id,
    url,
    revision
  );

  // metadata === undefined && error === undefined works around the slightly erroneous
  // state reporting by useCollection
  const loading =
    loadingProducts &&
    loadingMetadata &&
    metadata === undefined &&
    error === undefined;

  const annotationData = useMemo(
    () =>
      metadata && products
        ? prepareHeatmapDataOld(
            metadata.data.byDevice[device === 'all' ? 'desktop' : device]
              ?.links || EMPTY_ARR,
            keyBy(products, (p) => p.id)
          )
        : EMPTY_ARR,
    [metadata, device, products]
  );

  if (error) {
    console.log(error);
    return null;
  }

  if (!loading && metadata === null) {
    return (
      <NoData
        url={url}
        onRequest={() =>
          createScreenshotDoc(
            currentUser.space.id,
            url,
            currentUser.id,
            revision.lastModifiedAt
          )
        }
        isLatestRevision={isLatestRevision}
      />
    );
  }

  return (
    <>
      {loading && (
        <Loader
          height={LOADER_HEIGHT}
          label={<LoaderLabel>Loading metadata...</LoaderLabel>}
        />
      )}
      {products && metadata && (
        <WrappedHeatmapWithData
          space={space}
          annotationData={annotationData}
          scale={scale}
          device={device}
          setDevice={setDevice}
          dimension={dimension}
          setDimension={setDimension}
          metadata={metadata}
          isLatestRevision={isLatestRevision}
          minNextRevisionDate={latestRevision.lastModifiedAt}
        />
      )}
    </>
  );
};

interface BodyProps {
  url: string;
  device: HeatmapDeviceOptions;
  setDevice: (device: HeatmapDeviceOptions) => void;
  dimension: IHeatmapAnnotationDimension;
  setDimension: (dimension: IHeatmapAnnotationDimension) => void;
}

const PageContentDetailsHeatmapBodyPG: React.FC<BodyProps> = ({
  url,
  setDevice,
  device,
  setDimension,
  dimension
}) => {
  const { space } = useCurrentUser();
  const [pageMetadata, loading, error] = usePage(space.id, url);
  const { value, onChange, options } = useStandardSelectorStateFromUrl(
    space,
    pageMetadata ? pageMetadata.data.revisions : []
  );

  return (
    <>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <SelectorsDense value={value} onChange={onChange} options={options} />
        </PageToolbarSection>
      </PageToolbar>
      {error ? (
        <HeatmapEmptyState />
      ) : loading ? (
        <Loader height={LOADER_HEIGHT} />
      ) : pageMetadata ? (
        pageMetadata.data.sitemapError === 'NOT_FOUND' ? (
          <AlertBox variant="error">
            We were unable to find this page in your sitemap and are therefore
            not able to provide heatmap data.{' '}
            <HelpIcon
              articleId={ARTICLES.content.heatmapNotRendering}
              color="blue"
            >
              See recommended solutions
            </HelpIcon>
          </AlertBox>
        ) : pageMetadata.data.sitemapError === 'NO_LAST_MODIFIED' ? (
          <AlertBox variant="error">
            The sitemap entry for this page doesn't contain its last
            modification date. We're therefore not able to provide heatmap data.{' '}
            <HelpIcon
              articleId={ARTICLES.content.heatmapNotRendering}
              color="blue"
            >
              See recommended solutions
            </HelpIcon>
          </AlertBox>
        ) : value.revision ? (
          <WrappedHeatmapPg
            scale={SCALE_SINGLE}
            space={space}
            url={url}
            device={device}
            setDevice={setDevice}
            timeframe={value.timeframe}
            dimension={dimension}
            setDimension={setDimension}
            revision={value.revision}
            latestRevision={
              getLatestRevision(pageMetadata.data.revisions) || value.revision
            }
          />
        ) : (
          <HeatmapEmptyState />
        )
      ) : (
        <Loader height={LOADER_HEIGHT} />
      )}
    </>
  );
};

const PageContentDetailsHeatmapBody: React.FC<BodyProps> = ({
  url,
  setDevice,
  device,
  dimension,
  setDimension
}) => {
  const { space } = useCurrentUser();
  const [pageMetadata, loading, error] = usePage(space.id, url);

  const { ranges, pickerProps } = useTimeframeWithRevisions(
    pageMetadata ? pageMetadata.data.revisions : []
  );

  const { selectedRevision } = pickerProps;

  return (
    <>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframePickerWithRevisions {...pickerProps} />
        </PageToolbarSection>
      </PageToolbar>
      {error ? (
        <HeatmapEmptyState />
      ) : loading ? (
        <Loader height={LOADER_HEIGHT} />
      ) : pageMetadata ? (
        pageMetadata.data.sitemapError === 'NOT_FOUND' ? (
          <AlertBox variant="error">
            We were unable to find this page in your sitemap and are therefore
            not able to provide heatmap data.{' '}
            <HelpIcon
              articleId={ARTICLES.content.heatmapNotRendering}
              color="blue"
            >
              See recommended solutions
            </HelpIcon>
          </AlertBox>
        ) : pageMetadata.data.sitemapError === 'NO_LAST_MODIFIED' ? (
          <AlertBox variant="error">
            The sitemap entry for this page doesn't contain its last
            modification date. We're therefore not able to provide heatmap data.{' '}
            <HelpIcon
              articleId={ARTICLES.content.heatmapNotRendering}
              color="blue"
            >
              See recommended solutions
            </HelpIcon>
          </AlertBox>
        ) : selectedRevision ? (
          <WrappedHeatmapClickhouseAndPg
            scale={SCALE_SINGLE}
            space={space}
            url={url}
            device={device}
            setDevice={setDevice}
            timeRange={ranges.range}
            dimension={dimension}
            setDimension={setDimension}
            revision={selectedRevision}
            latestRevision={
              getLatestRevision(pageMetadata.data.revisions) || selectedRevision
            }
          />
        ) : (
          <HeatmapEmptyState />
        )
      ) : (
        <Loader height={LOADER_HEIGHT} />
      )}
    </>
  );
};

export const PageContentDetailsHeatmap: React.FC<Props> = ({ url: rawUrl }) => {
  const [experimental] = useExperimentalContext();
  const url = removeTrailingSlash(rawUrl);

  const { ROUTES } = useRoutes();
  const sideNav = experimental
    ? sideNavProps(ROUTES, url)
    : newSideNavProps(ROUTES, url);
  const [dimension, setDimension] = useState<IHeatmapAnnotationDimension>('c');
  const [device, setDevice] = useTypedStringQueryParam<HeatmapDeviceOptions>(
    'device',
    'all'
  );
  useTrackMixpanelView('view_heatmap', { url });

  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return (
    <>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      <PageBody sideNav={sideNav} noTopPadding>
        {experimental ? (
          <PageContentDetailsHeatmapBodyPG
            url={url}
            device={device}
            setDevice={setDevice}
            dimension={dimension}
            setDimension={setDimension}
          />
        ) : (
          <PageContentDetailsHeatmapBody
            url={url}
            device={device}
            setDevice={setDevice}
            dimension={dimension}
            setDimension={setDimension}
          />
        )}
      </PageBody>
    </>
  );
};
