import moment from 'moment-timezone';
import { useMemo } from 'react';
import {
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import {
  LoadingValue,
  useMappedLoadingValue
} from '../../../../../../services/db';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import { groupBy, mapValues } from 'lodash';
import { ReportMetric } from './report-metric';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { useFilters } from './filters';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';
import { useDomains } from '../../../../../../services/domains';
import { groupColumnTransformers } from '../../../../../../services/referrers-query';

export type Granularity = Pick<
  NonNullable<AnalyticsQuery['interval']>,
  'unit' | 'value'
>;

// TODO: ideally interval should be visible in component as part of query
export const timeRangeToGranularity = ({
  start,
  end
}: ISOTimeRange): Granularity => {
  const timeframeLengthInDays = moment(end).diff(moment(start), 'day');
  if (timeframeLengthInDays <= 2) {
    return {
      unit: 'hour',
      value: 1
    };
  }
  return {
    unit: 'day',
    value: 1
  };
};

export type ReferrerGroupTotals = Record<
  ReferrerGroup,
  AnalyticsResponseRowWithComparison['data'][ReportMetric]
>;

export const useReferrerGroupTimeseries = (
  metric: ReportMetric
): LoadingValue<AnalyticsResponse> => {
  const { space, tz } = useCurrentUser();
  const { range } = useTimeframe();
  const filters = useFilters();
  const allDomains = useDomains();
  const query = useMemo<AnalyticsQuery>(() => {
    const granularity = timeRangeToGranularity(range);
    return {
      select: [metric],
      range,
      filters,
      interval: {
        ...granularity,
        tz
      },
      orderBy: [
        { field: 'interval', direction: 'ASC' },
        { field: metric, direction: 'DESC' }
      ],
      groupBy: ['referrer_entry_origin'],
      columnTransformers: groupColumnTransformers(allDomains)
    };
  }, [allDomains, metric, range, filters, tz]);

  const [data, loading, error] = useAnalyticsQueryV2(space.id, query);
  const hasStaleData = data && data.q.select[0] !== metric;
  if (hasStaleData) {
    return [undefined, true, error];
  }
  return [data, loading, error];
};

export const useReferrerGroupTotals = (
  metric: ReportMetric
): LoadingValue<ReferrerGroupTotals> => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const filters = useFilters();
  const allDomains = useDomains();

  const query = useMemo<AnalyticsQuery>(
    () => ({
      select: [metric],
      range,
      compare,
      filters,
      orderBy: [{ field: metric, direction: 'DESC' }],
      groupBy: ['referrer_entry_origin'],
      columnTransformers: groupColumnTransformers(allDomains)
    }),
    [allDomains, compare, metric, range, filters]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) =>
      mapValues(
        groupBy(data.rows, (row) => row.group.referrer_entry_origin),
        (values) => {
          const data = values[0].data;
          return data[metric];
        }
      ) as ReferrerGroupTotals
  );
};

export const useReferrerGroupRows = (
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const filters = useFilters();
  const allDomains = useDomains();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: metrics,
      range,
      compare,
      groupBy: ['referrer_entry_origin'],
      orderBy: [orderBy],
      filters,
      columnTransformers: groupColumnTransformers(allDomains)
    };
  }, [allDomains, compare, filters, metrics, orderBy, range]);

  return useAnalyticsQueryV2(space.id, query);
};

export const toReferrerGroupTitle = (group: ReferrerGroup): string => {
  switch (group) {
    case 'google-discover':
      // NOTE: this is long, and it's used in TotalNumbers and stretches the container ...
      return 'Google Discover';
    case 'internal':
      return 'Internal';
    case 'other':
      return 'Other';
    case 'search':
      return 'Search';
    case 'social':
      return 'Social';
    case 'direct':
      return 'Direct';
  }
};
