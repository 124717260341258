import { Button, Hidden } from '@material-ui/core';
import {
  compact,
  groupBy,
  keyBy,
  mapValues,
  partition,
  sortBy,
  zip
} from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AddAdditionalDomainModal } from '../../../../components/AddDomainModal';
import { toTimebasedCounters } from '../../../../components/Charts/TrafficBiaxialChart';
import { UNKNOWN } from '../../../../components/GroupableList';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SearchInput } from '../../../../components/SearchInput';
import {
  TimeframePickerDense,
  useStandardOptions
} from '../../../../components/TimeframePicker/LegacyPickers';
import { Hint } from '../../../../components/Typography';
import {
  DAY_FORMAT,
  EMPTY_COUNTER,
  ICounter,
  IDailyCounter,
  isSameTimeframe,
  mergeCounter,
  safeMergeCounter,
  Timeframe
} from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import { IPageWithCountsAndTrends } from '../../../../domainTypes/page';
import {
  addOneEarningToAnother,
  EarningsArgs,
  EarningsArgsGroupedInTimeframeAsTimeseries,
  EarningsArgsTopPagesForOriginInTimeFrame,
  EarningsRespGroupedInTimeframe,
  EarningsRespGroupedInTimeframeAsTimeseries,
  EarningsRespTopPagesForOriginInTimeFrame,
  EARNING_MINIMAL_FIELD_SET,
  EMPTY_DAILY_EARNING,
  EMPTY_EARNING,
  IDailyEarning,
  IEarning,
  IEarningMinimalField,
  SalesFilterArgs,
  toDailyEarningFromMinimal,
  toEarningFromMinimal
} from '../../../../domainTypes/performance';
import { DEFAULT_CURRENCY } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useRoutes, useStringQueryParam } from '../../../../routes';
import {
  allTime,
  getComparisonTimeframe,
  toComparableTimeframe,
  useAnalyticsDataByOrigin,
  useAnalyticsDataByOriginAsTimeseries
} from '../../../../services/analytics';
import {
  useCurrentUser,
  useCurrentUserScopes,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import {
  combineLoadingValues,
  LoadingObject,
  LoadingValue,
  mapLoadingObject,
  useAsLoadingObject,
  useMappedLoadingValue
} from '../../../../services/db';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import {
  getDomainName,
  usePageAnalyticsWithCountsAndTrends
} from '../../../../services/pages';
import { pluralize } from '../../../../services/pluralize';
import {
  useEarnings,
  useEarningsSingle
} from '../../../../services/sales/earnings';
import { createScan } from '../../../../services/scan';
import { addDomain } from '../../../../services/space';
import { timeframeToMs } from '../../../../services/time';
import { removeTrailingSlash, withoutProtocol } from '../../../../services/url';
import { DomainSummary, TotalSummary } from '../../components/DomainSummary';
import { DashboardOverviewV2 } from '../OverviewV2';

import { useLegacyTimeframe } from '../../../../hooks/timeframe';

const CenteredContainer = styled('div')`
  margin-top: ${(p) => p.theme.spacing(3)}px;
  display: flex;
  justify-content: center;
`;

const ALL = 'ALL';
const OTHER = 'OTHER';

type EarningsByDomain = { [domain: string]: IEarning };

type EarningsData = {
  yesterday: EarningsByDomain;
  monthToDate: EarningsByDomain;
  lastMonth: EarningsByDomain;
  // yearToDate: EarningsByDomain - disabled, too slow
  tf: EarningsByDomain;
  tfToCompare: EarningsByDomain | null;
};

const useEarningsData = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean,
  fields?: IEarningMinimalField[]
): LoadingValue<EarningsData> => {
  const queries: EarningsArgs[] = useMemo(() => {
    const { tz } = timeframe;
    const n = moment.tz(tz).startOf('d');
    const yesterday: EarningsArgs = {
      type: 'groupedInTimeframe',
      d: {
        currency,
        groupBy: ['origin'],
        dates: timeframeToMs({
          start: n.clone().subtract(1, 'd').format(DAY_FORMAT),
          end: n.format(DAY_FORMAT),
          tz
        })
      }
    };
    const monthToDate: EarningsArgs = {
      type: 'groupedInTimeframe',
      d: {
        currency,
        groupBy: ['origin'],
        dates: timeframeToMs({
          start: n.clone().startOf('month').format(DAY_FORMAT),
          end: n.format(DAY_FORMAT),
          tz
        })
      }
    };

    const lastMonth: EarningsArgs = {
      type: 'groupedInTimeframe',
      d: {
        currency,
        groupBy: ['origin'],
        dates: timeframeToMs({
          start: n
            .clone()
            .subtract(1, 'month')
            .startOf('month')
            .format(DAY_FORMAT),
          end: n.clone().subtract(1, 'month').endOf('month').format(DAY_FORMAT),
          tz
        })
      }
    };

    const total: EarningsArgs = {
      type: 'groupedInTimeframe',
      d: {
        currency,
        groupBy: ['origin'],
        dates: timeframeToMs(timeframe)
      }
    };
    const comparedTotal: EarningsArgs | null = compare
      ? {
          type: 'groupedInTimeframe',
          d: {
            currency,
            groupBy: ['origin'],
            dates: timeframeToMs(getComparisonTimeframe(timeframe))
          }
        }
      : null;

    return compact([
      yesterday,
      monthToDate,
      lastMonth,
      total,
      comparedTotal
    ]).map((q) => {
      if (fields) {
        const args: EarningsArgs = {
          ...q,
          d: {
            ...q.d,
            fields
          }
        };
        return args;
      }
      return q;
    });
  }, [timeframe, compare, currency, fields]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    ({
      res: [yesterday, monthToDate, lastMonth, tf, tfToCompare = null],
      time
    }) => {
      console.log('useEarningsData', time, queries);
      const mapResp = (r: EarningsRespGroupedInTimeframe) =>
        mapValues(
          keyBy(r.d, (x) => {
            const k = `${x.group['origin'] || UNKNOWN}`;
            return k === UNKNOWN ? OTHER : k;
          }),
          (v) => toEarningFromMinimal(v.d)
        );

      const d = {
        yesterday: mapResp(yesterday),
        monthToDate: mapResp(monthToDate),
        lastMonth: mapResp(lastMonth),
        tf: mapResp(tf),
        tfToCompare: tfToCompare ? mapResp(tfToCompare) : null
      };
      return d;
    }
  );
};

const useTopContentTrendsByEarningsCommisionsOnly = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  origins: string[]
) => {
  const queries: EarningsArgs[] = useMemo(() => {
    return origins.map<EarningsArgsTopPagesForOriginInTimeFrame>((origin) => {
      return {
        type: 'topPagesForOriginInTimeframe',
        d: {
          tf: timeframe,
          origin,
          limit: 10,
          compare: true,
          currency,
          fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_ONLY
        }
      };
    });
  }, [timeframe, origins, currency]);
  return useMappedLoadingValue(
    useEarnings<EarningsRespTopPagesForOriginInTimeFrame[]>(
      spaceId,
      queries,
      currency
    ),
    ({ time, res: ds }) => {
      const data = origins.reduce<{
        [origin: string]: {
          [pageUrl: string]: {
            curr: IEarning;
            prev: IEarning | null;
          };
        };
      }>((m, o, i) => {
        m[o] = mapValues(ds[i]?.d || {}, ({ curr, prev }) => ({
          curr: toEarningFromMinimal(curr),
          prev: prev ? toEarningFromMinimal(prev) : null
        }));
        return m;
      }, {});
      console.log('top pages', time, data);
      return data;
    }
  );
};

const useEarningsAsTimeseriesForNetworks = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  [origin: string]: {
    [partnerKey: string]: IDailyEarning[];
  };
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['origin', 'partner_key'],
        currency,
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_ONLY,
        ...q
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    ({ time, res: data }) => {
      console.log('timeseries', time, data);
      const byOrigin = groupBy(data.d, (x) => {
        const k = `${x.group['origin'] || UNKNOWN}`;
        return k === UNKNOWN ? OTHER : k;
      });
      return mapValues(byOrigin, (xs) => {
        const byPartnerKey = keyBy(xs, (x) => {
          const k = `${x.group['partner_key'] || UNKNOWN}`;
          return k === UNKNOWN ? OTHER : k;
        });
        return mapValues(byPartnerKey, (x) => {
          return x.ds.map(toDailyEarningFromMinimal);
        });
      });
    }
  );
};

const DomainSummaryContainer = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

// Fulfills the old interface by now - this can be vastly simplified
// and return only the data that is truly necessary
const useContentTrendsByClicks = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  return useMappedLoadingValue(
    usePageAnalyticsWithCountsAndTrends(spaceId, timeframe, compare),
    (xs) => {
      return Object.values(xs).map<IPageWithCountsAndTrends>((x) => {
        const url = x.url.startsWith('http') ? x.url : `https://${x.url}`;
        const domain = getDomainName(url); // getDomainName fails otherwise
        return {
          href: url,
          domain,
          counts: x.counts,
          tagIds: []
        };
      });
    }
  );
};

const getDomainPermutations = (protocollessDomain: string) => {
  if (!protocollessDomain) {
    return [];
  }
  if (protocollessDomain.startsWith('www.')) {
    return [protocollessDomain, protocollessDomain.replace('www.', '')];
  }
  // check if it's a subdomained url - no permutation needed in this case
  if (protocollessDomain.match(/.+?\..+?\..+?$/)) {
    return [protocollessDomain];
  }
  return [protocollessDomain, `www.${protocollessDomain}`];
};

export const PageDashboardOverviewPg = () => {
  const { ROUTES, goTo } = useRoutes();
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canViewDomains = scopes.has('domains.view');
  const canAddDomains = scopes.has('domains.create');

  const { options } = useStandardOptions();
  const [timeframe, setTimeframe] = useLegacyTimeframe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeframeToCompare = useMemo(() => toComparableTimeframe(timeframe), [
    timeframe.start,
    timeframe.end,
    timeframe.tz
  ]);
  const [search, setSearch] = useStringQueryParam('q');
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);

  const compare = !isSameTimeframe(allTime(), timeframe);
  const currency = space.config.currency || DEFAULT_CURRENCY;

  const activeOrigins = useMemo(() => {
    return space.domains
      .filter((d) => d.active && d.verified)
      .map((d) => removeTrailingSlash(d.url))
      .map(withoutProtocol);
  }, [space.domains]);

  const trafficTimeseries = useAsLoadingObject(
    useMappedLoadingValue(
      // useCountsInTimeframeByProtocollessDomain(space.id, comparableTimeframe),
      useAnalyticsDataByOriginAsTimeseries(space.id, timeframe),
      (v) => {
        const allContainer: { [tk: string]: IDailyCounter } = {};
        Object.values(v).forEach((dailyEarnings) => {
          dailyEarnings.forEach((de) => {
            allContainer[de.timeKey] = {
              ...safeMergeCounter(allContainer[de.timeKey], de),
              timeKey: de.timeKey
            };
          });
        });
        v[ALL] = sortBy(Object.values(allContainer), (de) => de.timeKey);
        return mapValues(v, (ds) => toTimebasedCounters(ds));
      }
    )
  );
  const trafficSum = useAsLoadingObject(
    useMappedLoadingValue(
      combineLoadingValues(
        useAnalyticsDataByOrigin(space.id, timeframe),
        useAnalyticsDataByOrigin(space.id, timeframeToCompare)
      ),
      ([curr, prev]) => {
        const res: {
          [protocollessOrigin: string]: {
            prev: ICounter;
            curr: ICounter;
          };
        } = {};
        Object.entries(curr).forEach(([origin, counter]) => {
          const protocollessOrigin = withoutProtocol(origin);
          const c = (res[protocollessOrigin] = res[protocollessOrigin] || {
            prev: EMPTY_COUNTER(),
            curr: EMPTY_COUNTER()
          });
          c.curr = counter;
        });
        Object.entries(prev).forEach(([origin, counter]) => {
          const protocollessOrigin = withoutProtocol(origin);
          const c = (res[protocollessOrigin] = res[protocollessOrigin] || {
            prev: EMPTY_COUNTER(),
            curr: EMPTY_COUNTER()
          });
          c.prev = counter;
        });

        res[ALL] = {
          prev: Object.values(res).reduce(
            (m, x) => mergeCounter(m, x.prev),
            EMPTY_COUNTER()
          ),
          curr: Object.values(res).reduce(
            (m, x) => mergeCounter(m, x.curr),
            EMPTY_COUNTER()
          )
        };

        return res;
      }
    )
  );

  const [
    earningsDataD,
    earningsDataLoading,
    earningsDataError
  ] = useEarningsData(
    space.id,
    timeframe,
    currency,
    compare,
    EARNING_MINIMAL_FIELD_SET.COMMISSIONS_AND_TX_COUNT
  );

  const earningsData = useMemo<{
    yesterday: LoadingObject<EarningsByDomain>;
    monthToDate: LoadingObject<EarningsByDomain>;
    lastMonth: LoadingObject<EarningsByDomain>;
    // yearToDate: LoadingObject<EarningsByDomain>; disabled -- too slow
    tf: LoadingObject<EarningsByDomain>;
    tfToCompare: LoadingObject<EarningsByDomain | null>;
  }>(() => {
    const groupActiveOrigins = (e: EarningsByDomain): EarningsByDomain => {
      if (!e) {
        return e;
      }
      // make a copy from which we remove all known data - the rest will be collected to become OTHER
      const copy = { ...e };
      const res: EarningsByDomain = {};
      activeOrigins.forEach((origin) => {
        const perms = getDomainPermutations(origin);
        const allEarnings = compact(perms.map((x) => copy[x]));
        perms.forEach((x) => delete copy[x]);
        if (allEarnings.length === 1) {
          res[origin] = allEarnings[0];
        } else {
          const merged = allEarnings.reduce(
            addOneEarningToAnother,
            EMPTY_EARNING(currency)
          );
          res[origin] = merged;
        }
      });
      const rest = Object.values(copy);
      if (rest.length) {
        res[OTHER] = rest.reduce(
          addOneEarningToAnother,
          EMPTY_EARNING(currency)
        );
      }
      res[ALL] = Object.values(res).reduce(
        addOneEarningToAnother,
        EMPTY_EARNING(currency)
      );
      return res;
    };
    const [d, loading, error] = [
      earningsDataD,
      earningsDataLoading,
      earningsDataError
    ];
    return {
      tf: {
        d: d ? groupActiveOrigins(d.tf) : undefined,
        loading,
        error
      },
      tfToCompare: {
        d: d
          ? d.tfToCompare
            ? groupActiveOrigins(d.tfToCompare)
            : null
          : undefined,
        loading,
        error
      },
      yesterday: {
        d: d ? groupActiveOrigins(d.yesterday) : undefined,
        loading,
        error
      },
      monthToDate: {
        d: d ? groupActiveOrigins(d.monthToDate) : undefined,
        loading,
        error
      },
      lastMonth: {
        d: d ? groupActiveOrigins(d.lastMonth) : undefined,
        loading,
        error
      }
      // Disabled, too slow
      // yearToDate: {
      //   d: d ? groupActiveOrigins(d.yearToDate) : undefined,
      //   loading,
      //   error
      // }
    };
  }, [
    earningsDataD,
    earningsDataLoading,
    earningsDataError,
    activeOrigins,
    currency
  ]);

  const timeSeriesForNetworksQuery = useMemo(() => {
    return { dates: timeframeToMs(timeframe) };
  }, [timeframe]);
  const timeseriesForNetworksUngrouped = useAsLoadingObject(
    useEarningsAsTimeseriesForNetworks(
      space.id,
      timeSeriesForNetworksQuery,
      currency
    )
  );

  const timeseriesForNetworks = useMemo(() => {
    if (!timeseriesForNetworksUngrouped) {
      return timeseriesForNetworksUngrouped;
    }
    const ungrouped: typeof timeseriesForNetworksUngrouped.d = {
      ...timeseriesForNetworksUngrouped.d
    };
    const regrouped: {
      [origin: string]: {
        [partnerKey: string]: IDailyEarning[][];
      };
    } = {};

    const addSeriesToRegrouped = (
      origin: string,
      xs: {
        [partnerKey: string]: IDailyEarning[];
      }
    ) => {
      const originContainer = (regrouped[origin] = regrouped[origin] || {});
      Object.entries(xs).forEach(([pk, series]) => {
        const pkContainer = (originContainer[pk] = originContainer[pk] || []);
        pkContainer.push(series);
      });
    };

    activeOrigins.forEach((origin) => {
      const perms = getDomainPermutations(origin);
      perms.forEach((p) => {
        const x = ungrouped[p];
        if (!x) {
          return;
        }
        addSeriesToRegrouped(origin, x);
        delete ungrouped[p];
      });
    });

    Object.values(ungrouped).forEach((g) => {
      addSeriesToRegrouped(OTHER, g);
    });

    const newD = mapValues(regrouped, (g) => {
      return mapValues(g, (x) => {
        // can be safely zipped as at this point we know all inner elements are of the same length
        if (x.length === 1) {
          // no need to create new objects
          return x[0];
        }
        return zip(...x).map((els) => {
          const elsWithoutUndefineds = compact(els);
          return elsWithoutUndefineds.reduce(
            addOneEarningToAnother,
            EMPTY_DAILY_EARNING(currency, elsWithoutUndefineds[0].timeKey)
          );
        });
      });
    });

    const allContainer: {
      [network: string]: {
        [timekey: string]: IDailyEarning;
      };
    } = {};
    Object.values(newD).forEach((byNetwork) => {
      Object.entries(byNetwork).forEach(([network, dailyEarnings]) => {
        const networkContainer = (allContainer[network] =
          allContainer[network] || {});
        dailyEarnings.forEach((de) => {
          const prev = (networkContainer[de.timeKey] =
            networkContainer[de.timeKey] ||
            EMPTY_DAILY_EARNING(currency, de.timeKey));
          addOneEarningToAnother(prev, de);
        });
      });
    });
    newD[ALL] = mapValues(allContainer, (byTimekey) =>
      sortBy(Object.values(byTimekey), (de) => de.timeKey)
    );

    return {
      ...timeseriesForNetworksUngrouped,
      d: newD
    };
  }, [timeseriesForNetworksUngrouped, activeOrigins, currency]);

  const contentTrendsByEarnings = useAsLoadingObject(
    useTopContentTrendsByEarningsCommisionsOnly(
      space.id,
      timeframe,
      currency,
      activeOrigins
    )
  );

  const contentTrendsByClicks = useAsLoadingObject(
    useMappedLoadingValue(
      useContentTrendsByClicks(space.id, timeframe, compare),
      (ps) => groupBy(ps, (p) => p.domain)
    )
  );

  const filteredDomains = search
    ? space.domains.filter((d) => d.url.indexOf(search) !== -1)
    : space.domains;
  const [activeDomains, inactiveDomains] = partition(
    filteredDomains,
    (d) => d.active
  );

  return (
    <PageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection spacing="wide" flex={2}>
          {canAddDomains && (
            <Hidden xsDown>
              <Button variant="contained" color="primary" onClick={openDialog}>
                Add website
              </Button>
            </Hidden>
          )}
          <Hidden smDown>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Find website"
              width={300}
              size="small"
            />
          </Hidden>
          <Hidden mdUp>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Find website"
              width={150}
              size="small"
            />
          </Hidden>
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
        </PageToolbarSection>
      </PageToolbar>

      {canViewDomains && !search && (
        <DomainSummaryContainer>
          <TotalSummary
            spaceId={space.id}
            sales={{
              tf: mapLoadingObject(earningsData.tf, (x) => x[ALL]),
              tfToCompare: mapLoadingObject(earningsData.tfToCompare, (x) =>
                x ? x[ALL] : null
              ),
              yesterday: mapLoadingObject(
                earningsData.yesterday,
                (x) => x[ALL]
              ),
              monthToDate: mapLoadingObject(
                earningsData.monthToDate,
                (x) => x[ALL]
              ),
              lastMonth: mapLoadingObject(earningsData.lastMonth, (x) => x[ALL])
              // Disabled - too slow
              // yearToDate: mapLoadingObject(
              //   earningsData.yearToDate,
              //   (x) => x[ALL]
              // )
            }}
            timeseriesForNetworks={mapLoadingObject(
              timeseriesForNetworks,
              (x) => x[ALL]
            )}
            trafficTimeseries={{
              d: trafficTimeseries.d
                ? trafficTimeseries.d[ALL] || EMPTY_ARR
                : undefined,
              loading: trafficTimeseries.loading,
              error: trafficTimeseries.error
            }}
            trafficSum={{
              d: trafficSum.d
                ? trafficSum.d[ALL] || {
                    curr: EMPTY_COUNTER(),
                    prev: EMPTY_COUNTER()
                  }
                : undefined,
              loading: trafficSum.loading,
              error: trafficSum.error
            }}
            timeframe={timeframe}
            currency={currency}
          />
        </DomainSummaryContainer>
      )}
      {canViewDomains &&
        activeDomains.map((d) => {
          const domain = withoutProtocol(d.url);
          return (
            <DomainSummaryContainer key={d.url}>
              <DomainSummary
                spaceId={space.id}
                domain={d}
                sales={{
                  tf: mapLoadingObject(earningsData.tf, (x) => x[domain]),
                  tfToCompare: mapLoadingObject(earningsData.tfToCompare, (x) =>
                    x ? x[domain] : null
                  ),
                  yesterday: mapLoadingObject(
                    earningsData.yesterday,
                    (x) => x[domain]
                  ),
                  monthToDate: mapLoadingObject(
                    earningsData.monthToDate,
                    (x) => x[domain]
                  ),
                  lastMonth: mapLoadingObject(
                    earningsData.lastMonth,
                    (x) => x[domain]
                  )
                  // Dsisabled - too slow
                  // yearToDate: mapLoadingObject(
                  //   earningsData.yearToDate,
                  //   (x) => x[domain]
                  // )
                }}
                contentTrends={{
                  byEarnings: mapLoadingObject(
                    contentTrendsByEarnings,
                    (x) => x[domain]
                  ),
                  byClicks: mapLoadingObject(
                    contentTrendsByClicks,
                    (x) => x[domain]
                  )
                }}
                timeseriesForNetworks={mapLoadingObject(
                  timeseriesForNetworks,
                  (x) => x[domain]
                )}
                trafficTimeseries={{
                  d: trafficTimeseries.d
                    ? trafficTimeseries.d[domain] || EMPTY_ARR
                    : undefined,
                  loading: trafficTimeseries.loading,
                  error: trafficTimeseries.error
                }}
                trafficSum={{
                  d: trafficSum.d
                    ? trafficSum.d[domain] || {
                        curr: EMPTY_COUNTER(),
                        prev: EMPTY_COUNTER()
                      }
                    : undefined,
                  loading: trafficSum.loading,
                  error: trafficSum.error
                }}
                timeframe={timeframe}
                currency={currency}
              />
            </DomainSummaryContainer>
          );
        })}

      {!!inactiveDomains.length && canViewDomains && (
        <CenteredContainer>
          <Hint>
            {pluralize(
              'additional inactive domain',
              inactiveDomains.length,
              true
            )}
            . <Link to={ROUTES.settings.general.url()}>Open Settings</Link> if
            you wish to re-activate them.
          </Hint>
        </CenteredContainer>
      )}

      <AddAdditionalDomainModal
        open={dialogOpen}
        onClose={closeDialog}
        onVerifyDomain={(domain) => addDomain(space.id, domain)}
        onScan={(ds) =>
          createScan(ds, 'DOMAIN').then((doc) =>
            goTo(ROUTES.links.scans.details.url(doc.id))
          )
        }
      />
    </PageBody>
  );
};

export const PageDashboardOverview = () => {
  useTrackMixpanelView('view_dashboard');
  const [canView] = useHasCurrentUserRequiredScopes(['dashboard.view']);
  const [experimental] = useExperimentalContext();

  return (
    <>
      <Helmet>
        <title>Affilimate | Dashboard</title>
      </Helmet>
      {canView ? (
        experimental ? (
          <PageDashboardOverviewPg />
        ) : (
          <DashboardOverviewV2 />
        )
      ) : (
        <NoPermissions />
      )}
    </>
  );
};
