import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { styled } from '../../../../../emotion';
import { useBooleanQueryParam, useRoutes } from '../../../../../routes';
import { useAdminOrImpersonatorClaim } from '../../../../../services/auth';
import { HelpScoutArticlePreview } from '../service';
import { DraftStatus, UnpublishedStatus } from './ArticleStatus';
import { Link } from 'react-router-dom';

export const ArticleBody = styled('article')`
  p,
  ul,
  li {
    font-size: 16px;
    line-height: 30px;

    code {
      padding: 6px;
    }
  }

  img {
    max-width: 100%;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    border: 1px solid #eee;
  }

  table {
    width: 100%;
    code,
    pre {
      font-size: 12px;
    }
  }

  code,
  pre {
    background-color: #eee;
    overflow: scroll;
    color: #000;
    font-size: 14px;
    padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing(1)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius / 2}px;
    font-family: Menlo, Consolas, Courier;
  }

  blockquote {
    border-left: 3px solid ${(p) => p.theme.palette.grey[500]};
    color: 3px solid ${(p) => p.theme.palette.grey[500]};
    padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing(2)}px;
    font-style: italic;
    margin: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: ${(p) => p.theme.spacing(4)}px;
  }

  a {
    color: ${(p) => p.theme.palette.primary.main};
    text-decoration: underline;
  }

  hr {
    margin: ${(p) => p.theme.spacing(3)}px 0 ${(p) => p.theme.spacing(5)}px;
    border: 1px solid #eee;
  }

  table thead {
    font-weight: 700;
  }

  table td {
    border-bottom: 1px solid #eee;
    padding: 0.5em 1em;
    font-size: 14px;
    line-height: 24px;
  }

  .steps li {
    list-style: none;
    counter-increment: steps-counter;
    position: relative;
    padding-top: 0.4em;
    padding-bottom: 1em;
  }

  .steps li:before {
    content: counter(steps-counter);
    position: absolute;
    top: 6px;
    left: -40px;
    background-color: ${(p) => p.theme.palette.primary.main};
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }

  .video.widescreen {
    padding-bottom: 56.25%;
  }

  .video {
    position: relative;
    height: 0;
    padding: 0 0 67.5%;
    overflow: hidden;
    margin-bottom: 1.5em;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .callout-red {
    text-align: left;
    border-left: 5px solid ${(p) => p.theme.palette.error.main};
    margin: ${(p) => p.theme.spacing(2)}px 0;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    background-color: #fff1f0;
    font-family: ${(p) => p.theme.typography.fontFamily};
    font-size: ${(p) => p.theme.typography.body2.fontSize};
    line-height: ${(p) => p.theme.typography.body2.lineHeight};
  }

  .callout-blue {
    text-align: left;
    border-left: 5px solid ${(p) => p.theme.palette.primary.main};
    margin: ${(p) => p.theme.spacing(2)}px 0;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    background-color: #e6f7ff;
    font-family: ${(p) => p.theme.typography.fontFamily};
    font-size: ${(p) => p.theme.typography.body2.fontSize};
    line-height: ${(p) => p.theme.typography.body2.lineHeight};
  }

  .callout-yellow {
    text-align: left;
    border-left: 5px solid ${(p) => p.theme.palette.warning.main};
    margin: ${(p) => p.theme.spacing(2)}px 0;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    background-color: #fffbe6;
    font-family: ${(p) => p.theme.typography.fontFamily};
    font-size: ${(p) => p.theme.typography.body2.fontSize};
    line-height: ${(p) => p.theme.typography.body2.lineHeight};
  }
`;

const ArticleFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.palette.grey[500]};
  margin-top: 100px;

  a {
    color: ${(p) => p.theme.palette.primary.main};
    border-bottom: 1px solid;
  }
`;

export const Article = ({ article }: { article: HelpScoutArticlePreview }) => {
  const { ROUTES } = useRoutes();
  const [showEditorOptions] = useAdminOrImpersonatorClaim();
  const [includeDrafts] = useBooleanQueryParam('drafts', false);
  const lastUpdated = new Date(article.lastPublishedAt).toLocaleDateString(
    'en-US'
  );
  const text = useMemo(() => {
    const updatedDocsUrls = article.text.replaceAll(
      'https://affilimate.com/docs/',
      '/docs/'
    );

    const updatedInternal = updatedDocsUrls.replace(
      'https://app.affilimate.com',
      '/'
    );

    return parse(updatedInternal, {
      replace: (node: any) => {
        if (
          node.type === 'tag' &&
          node.name === 'a' &&
          node.attribs.href.startsWith('/')
        ) {
          // Replace anchor tag with a <Link> component
          const linkChildren = Array.isArray(node.children)
            ? node.children
            : [node.children];

          const children = linkChildren.map((child: any) => {
            // Check if the child is an object with a "data" property
            if (
              typeof child === 'object' &&
              child !== null &&
              'data' in child
            ) {
              return child.data || null;
            }

            return child;
          });

          if (node.attribs.href.indexOf('/docs/') === 0) {
            const slug = node.attribs.href.replace('/docs/', '');
            return (
              <Link to={ROUTES.docs.knowledgeBase.url(slug)}>{children}</Link>
            );
          }

          return <Link to={node.attribs.href}>{children}</Link>;
        }
        return node;
      }
    });
  }, [article.text, ROUTES]);

  return (
    <>
      <h1>
        {article.name}
        {showEditorOptions && article.hasDraft && includeDrafts && (
          <DraftStatus />
        )}
        {showEditorOptions && article.status === 'notpublished' && (
          <UnpublishedStatus />
        )}
      </h1>
      <ArticleBody>{text}</ArticleBody>
      <ArticleFooter>
        <p>
          Have questions? Contact us at{' '}
          <a href="mailto:support@affilimate.com">support@affilimate.com</a>{' '}
          anytime.
        </p>
        <p>Last Updated {lastUpdated}</p>
      </ArticleFooter>
    </>
  );
};
