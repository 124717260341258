import React, { useMemo } from 'react';
import {
  SideNavProps,
  PageBody,
  DISTANCE_TO_TOP_BAR
} from '../../../../../layout/PageBody';
import { useBooleanQueryParam, useRoutes } from '../../../../../routes';
import { HelpScoutArticlePreview } from '../service';
import { ArticleList } from './ArticleList';
import { styled } from '../../../../../emotion';
import { useAdminOrImpersonatorClaim } from '../../../../../services/auth';
import { Button } from '@material-ui/core';
import { CopyButton } from '../../../../../components/CopyButton';
import { ArrowUpRight, Copy, Eye, EyeOff } from 'react-feather';

type Props = {
  noTopPadding?: boolean;
  relatedArticles?: HelpScoutArticlePreview[];
  selectedArticle?: HelpScoutArticlePreview;
};

const RightNavWrapper = styled('div')`
  top: ${(p) => p.theme.spacing(7 + DISTANCE_TO_TOP_BAR)}px;
  position: fixed;
  padding-left: ${(p) => p.theme.spacing(2)}px;
  padding-right: ${(p) => p.theme.spacing(2)}px;
`;

const ArticleActions = styled('div')`
  display: grid;
  max-width: 200px;
`;

const RightNav = ({
  selectedArticle,
  relatedArticles
}: {
  selectedArticle: HelpScoutArticlePreview;
  relatedArticles?: HelpScoutArticlePreview[];
}) => {
  const [showEditorOptions] = useAdminOrImpersonatorClaim();
  const [includeDrafts, setIncludeDrafts] = useBooleanQueryParam(
    'drafts',
    false
  );

  return (
    <div>
      <RightNavWrapper>
        {selectedArticle && showEditorOptions && (
          <ArticleActions>
            <h3>Available actions</h3>
            <CopyButton
              variant="outlined"
              size="small"
              style={{ marginBottom: '6px' }}
              label={
                <>
                  <Copy size={14} /> &nbsp; Copy link
                </>
              }
              text={window.location.origin + window.location.pathname}
            />
            {selectedArticle.hasDraft && !includeDrafts && (
              <Button
                variant="outlined"
                size="small"
                style={{ marginBottom: '6px' }}
                color="secondary"
                onClick={() => {
                  setIncludeDrafts(true);
                }}
              >
                <Eye size={14} /> &nbsp; View draft
              </Button>
            )}
            {includeDrafts && (
              <Button
                variant="outlined"
                size="small"
                style={{ marginBottom: '6px' }}
                onClick={() => {
                  setIncludeDrafts(false);
                }}
              >
                <EyeOff size={14} /> &nbsp; Hide draft
              </Button>
            )}
            <Button
              variant="outlined"
              size="small"
              href={`https://secure.helpscout.net/docs/${selectedArticle.collectionId}/article/${selectedArticle.id}/`}
              target="_blank"
              style={{ marginBottom: '6px' }}
            >
              Edit article &nbsp; <ArrowUpRight size={14} />
            </Button>
          </ArticleActions>
        )}
        {relatedArticles && (
          <div>
            <h3>Read related articles</h3>
            <ArticleList columns={1} articles={relatedArticles} />
          </div>
        )}
      </RightNavWrapper>
    </div>
  );
};

export const KnowledgeBasePageBody: React.FC<Props> = ({
  noTopPadding,
  relatedArticles,
  selectedArticle,
  children
}) => {
  const { ROUTES } = useRoutes();
  const sideNav: SideNavProps = useMemo(() => {
    return {
      groups: [
        {
          label: 'Knowledge base',
          items: [
            {
              label: 'Getting Started',
              path: ROUTES.docs.knowledgeBase.url('getting-started')
            },
            {
              label: 'Affiliate Dashboard',
              path: ROUTES.docs.knowledgeBase.url('affiliate-dashboard')
            },
            {
              label: 'Integrations',
              path: ROUTES.docs.knowledgeBase.url('integrations')
            },
            {
              label: 'Link Management',
              path: ROUTES.docs.knowledgeBase.url('link-management')
            },
            {
              label: 'Content Analytics',
              path: ROUTES.docs.knowledgeBase.url('content-analytics')
            },
            {
              label: 'Offsite Analytics',
              path: ROUTES.docs.knowledgeBase.url('offsite-analytics')
            },
            {
              label: 'Tools',
              path: ROUTES.docs.knowledgeBase.url('tools')
            },
            {
              label: 'API',
              path: ROUTES.docs.knowledgeBase.url('api')
            },
            {
              label: 'Account',
              path: ROUTES.docs.knowledgeBase.url('account')
            }
          ]
        },
        {
          label: 'API',
          items: [
            {
              label: 'Overview',
              path: ROUTES.docs.knowledgeBase.url('api-overview')
            },
            {
              label: 'Client API',
              path: ROUTES.docs.knowledgeBase.url('client-api')
            },
            {
              label: 'Publisher API',
              path: ROUTES.docs.api.url()
            }
          ]
        }
      ]
    };
  }, [ROUTES.docs.knowledgeBase, ROUTES.docs.api]);

  return (
    <>
      <PageBody
        sideNav={sideNav}
        noTopPadding={noTopPadding}
        rightNav={
          selectedArticle ? (
            <RightNav
              selectedArticle={selectedArticle}
              relatedArticles={relatedArticles}
            />
          ) : window.location.pathname.startsWith('/api') ? null : (
            <div />
          )
        }
      >
        {children}
      </PageBody>
    </>
  );
};
