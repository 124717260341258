import { useCurrentUser } from '../../services/currentUser';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import {
  getCompareMoments,
  getTimeframeMoments,
  toMoments
} from '../../hooks/timeframe/toMoments';
import {
  CalendarDate,
  toCalendarDate as dateTimeToCalendarDate,
  ZonedDateTime
} from '@internationalized/date';
import { isEqual, isNil } from 'lodash';
import { DateRangeInput } from './DateRangeInput';
import { DateRangePicker } from './DateRangePicker';
import { IComparisonOption, ITimeframeOption } from './service/options';
import {
  TimeframeComparisonDefinition,
  TimeframeDefinition,
  TimeframeRangeDefinition
} from '../../domainTypes/timeframe';
import {
  endOfDay,
  rangeToISOStrings,
  rangeToMoments,
  toCalendarDate,
  toEndOfDay,
  toStartOfDay,
  toZonedDateTime
} from './service/conversion';
import {
  CalendarDateRange,
  clampDateRange,
  DateTimeRange
} from './service/range';
import { useMaxDateTime, useMinDateTime } from './service/bounds';
import { useFeatureEnabled } from '../../services/features';
import { Granularity } from './GranularitySwitch';

const TIME_SETTINGS_LOCAL_STORAGE_KEY =
  'affilimate_timeframe_picker_granularity';

const timeframeToMoments = (state: TimeframeState, tz: string) => {
  if (state.kind === 'range') {
    return rangeToMoments(state, tz);
  }
  return getTimeframeMoments(state.preset, tz);
};

interface Labels {
  rangeName?: string;
  minDateName?: string;
  maxDateName?: string;
}

const validateRange = (
  range: DateTimeRange,
  min: ZonedDateTime,
  max: ZonedDateTime,
  labels: Labels = {}
): string | undefined => {
  const {
    rangeName = 'Range',
    minDateName = min.toDate().toLocaleString(),
    maxDateName = max.toDate().toLocaleString()
  } = labels;
  if (range.start.compare(range.end) > 0) {
    return `Start date must be before end date`;
  }
  if (range.start.compare(min) < 0) {
    return `${rangeName} can't start before ${minDateName}`;
  }
  if (range.end.compare(max) > 0) {
    return `${rangeName} can't end after ${maxDateName}`;
  }
  return undefined;
};

const validateTimeframe = (
  state: TimeframeState,
  min: ZonedDateTime,
  max: ZonedDateTime,
  labels?: Labels
): string | undefined => {
  if (state.kind !== 'range') return undefined;
  return validateRange(state, min, max, labels);
};

const validateComparison = (
  state: ComparisonState,
  min: ZonedDateTime,
  max: ZonedDateTime,
  labels?: Labels
): string | undefined => {
  if (state.kind !== 'range') return undefined;
  return validateRange(state, min, max, labels);
};

const timeframeToDateTimeRange = (
  timeframe: TimeframeState,
  tz: string
): DateTimeRange => {
  if (timeframe.kind === 'range') {
    return {
      start: timeframe.start,
      end: timeframe.end
    };
  }
  const { end, start } = getTimeframeMoments(timeframe.preset, tz);
  return {
    start: toZonedDateTime(start),
    end: toZonedDateTime(end)
  };
};

const comparisonToDateTimeRange = (
  state: TimeframePickerMenuInternalState,
  minDateTime: ZonedDateTime,
  maxDateTime: ZonedDateTime,
  tz: string
): DateTimeRange | null => {
  const { comparison, timeframe } = state;
  if (isNil(comparison)) return null;
  if (comparison.kind === 'range') {
    return {
      start: comparison.start,
      end: comparison.end
    };
  }
  if (!isNil(validateTimeframe(timeframe, minDateTime, maxDateTime))) {
    return null;
  }
  const compare = getCompareMoments(
    comparison.option,
    timeframeToMoments(timeframe, tz)
  );
  if (isNil(compare)) return null;
  return {
    start: toZonedDateTime(compare.start),
    end: toZonedDateTime(compare.end)
  };
};

type TimeframeState =
  | {
      kind: 'preset';
      preset: TimeframeRangeDefinition;
    }
  | {
      kind: 'range';
      start: ZonedDateTime;
      end: ZonedDateTime;
    };

type ComparisonState =
  | {
      kind: 'preset';
      option: TimeframeComparisonDefinition;
    }
  | {
      kind: 'range';
      start: ZonedDateTime;
      end: ZonedDateTime;
    };

type TimeframePickerMenuInternalState = {
  timeframe: TimeframeState;
  timeframeFocus: CalendarDate;
  comparison: ComparisonState;
  comparisonFocus?: CalendarDate;
  granularity: Granularity;
};

const toTimeframeDefinition = (
  state: TimeframePickerMenuInternalState,
  tz: string
): TimeframeDefinition => {
  const range: TimeframeRangeDefinition =
    state.timeframe.kind === 'preset'
      ? state.timeframe.preset
      : {
          kind: 'custom',
          ...rangeToISOStrings(state.timeframe, tz)
        };
  const comparison: TimeframeComparisonDefinition =
    state.comparison.kind === 'preset'
      ? state.comparison.option
      : {
          kind: 'custom',
          ...rangeToISOStrings(state.comparison, tz)
        };
  return {
    range,
    comparison
  };
};

const requiresMinuteGranularity = (
  definition: TimeframeRangeDefinition
): boolean =>
  definition.kind === 'period' && definition.value.type === 'latest';

export interface TimeframePickerMenuState {
  timeframeInputProps: ComponentProps<typeof DateRangeInput>;
  timeframePickerProps: ComponentProps<typeof DateRangePicker>;
  timeframePresetSelectProps: {
    value?: TimeframeRangeDefinition;
    onChange: (option: TimeframeRangeDefinition) => void;
  };

  comparisonInputProps: ComponentProps<typeof DateRangeInput>;
  comparisonPickerProps: ComponentProps<typeof DateRangePicker>;
  comparisonPresetSelectProps: {
    value?: TimeframeComparisonDefinition;
    onChange: (option: TimeframeComparisonDefinition) => void;
  };

  isMenuValid: boolean;
  timeframeDefinition: TimeframeDefinition;
}

export const useTimePickerMenuState = (
  definition: TimeframeDefinition,
  timeframePresets: ITimeframeOption[],
  comparisonPresets: IComparisonOption[]
): TimeframePickerMenuState => {
  const { tz } = useCurrentUser();
  const hasRealtimeFeature = useFeatureEnabled('REFERRER_REPORTS_V1');
  const minDateTime = useMinDateTime();
  const maxDateTime = useMaxDateTime();
  const minDate = useMemo(() => dateTimeToCalendarDate(minDateTime), [
    minDateTime
  ]);
  const maxDate = useMemo(() => dateTimeToCalendarDate(maxDateTime), [
    maxDateTime
  ]);

  const [state, setState] = useState<TimeframePickerMenuInternalState>(() => {
    const { start, end, compare } = toMoments(definition, tz);
    const getGranularity = (): Granularity => {
      if (!hasRealtimeFeature) return 'day';
      if (requiresMinuteGranularity(definition.range)) {
        return 'minute';
      }
      const stored = localStorage.getItem(
        TIME_SETTINGS_LOCAL_STORAGE_KEY
      ) as Granularity;
      return stored ? (stored as Granularity) : 'day';
    };

    const getTimeframeState = (): TimeframeState => {
      if (definition.range.kind === 'period') {
        return {
          kind: 'preset',
          preset: definition.range
        };
      }
      if (
        timeframePresets.some((option) =>
          isEqual(option.value, definition.range)
        )
      ) {
        return {
          kind: 'preset',
          preset: definition.range
        };
      }
      return {
        kind: 'range',
        start: toZonedDateTime(start),
        end: toZonedDateTime(end)
      };
    };

    const getComparisonState = (): ComparisonState => {
      if (definition.comparison.kind === 'previous') {
        return {
          kind: 'preset',
          option: definition.comparison
        };
      }
      if (
        comparisonPresets.some((option) =>
          isEqual(option.value, definition.comparison)
        )
      ) {
        return {
          kind: 'preset',
          option: definition.comparison
        };
      }
      return {
        kind: 'range',
        start: toZonedDateTime(compare!.start),
        end: toZonedDateTime(compare!.end)
      };
    };

    const timeframeFocus = toCalendarDate(start);
    const comparisonFocus = toCalendarDate(compare?.start);
    return {
      timeframe: getTimeframeState(),
      comparison: getComparisonState(),
      timeframeFocus,
      comparisonFocus,
      granularity: getGranularity()
    };
  });

  const adjustFocus = useCallback(
    (
      state: TimeframePickerMenuInternalState,
      skipTimeframeFocus = false
    ): TimeframePickerMenuInternalState => {
      const { end } = timeframeToDateTimeRange(state.timeframe, tz);
      const timeframeFocus = dateTimeToCalendarDate(end);
      const comparisonRange = comparisonToDateTimeRange(
        state,
        minDateTime,
        maxDateTime,
        tz
      );
      const comparisonFocus = comparisonRange
        ? dateTimeToCalendarDate(comparisonRange.end)
        : undefined;

      return {
        ...state,
        timeframeFocus: skipTimeframeFocus
          ? state.timeframeFocus
          : timeframeFocus,
        comparisonFocus
      };
    },
    [maxDateTime, minDateTime, tz]
  );

  return useMemo(() => {
    const selectedTimeframeOption: TimeframeRangeDefinition | undefined =
      state.timeframe.kind === 'preset' ? state.timeframe.preset : undefined;

    const selectTimeframeOption = (definition: TimeframeRangeDefinition) => {
      setState((state) => {
        const timeframe: TimeframeState = {
          kind: 'preset',
          preset: definition
        };

        return adjustFocus({
          ...state,
          timeframe,
          granularity: requiresMinuteGranularity(definition)
            ? 'minute'
            : state.granularity
        });
      });
    };

    const timeframeDateTimeRange = timeframeToDateTimeRange(
      state.timeframe,
      tz
    );
    const onTimeframeDateRangeChange = (range: CalendarDateRange | null) => {
      if (range !== null) {
        setState((state) => {
          const timeframe: TimeframeState = {
            kind: 'range',
            start: toStartOfDay(range.start, tz),
            end: toEndOfDay(range.end, tz)
          };

          return {
            ...state,
            timeframe
          };
        });
      }
    };

    const onTimeframeDateTimeRangeChange = (range: DateTimeRange | null) => {
      if (range !== null) {
        setState((state) => {
          const end =
            state.granularity === 'minute' ? range.end : endOfDay(range.end);
          const timeframe: TimeframeState = {
            kind: 'range',
            start: range.start,
            end
          };

          return adjustFocus({
            ...state,
            timeframe
          });
        });
      }
    };

    const timeframeValidation = validateTimeframe(
      state.timeframe,
      minDateTime,
      maxDateTime,
      {
        rangeName: 'Timeframe'
      }
    );

    const isTimeframeValid = isNil(timeframeValidation);
    const timeframePickerValue = isTimeframeValid
      ? clampDateRange(
          {
            start: dateTimeToCalendarDate(timeframeDateTimeRange.start),
            end: dateTimeToCalendarDate(timeframeDateTimeRange.end)
          },
          minDate,
          maxDate
        )
      : null;

    const timeframeFocus = state.timeframeFocus;
    const onTimeframeFocusChange = (date: CalendarDate) => {
      setState((state) => ({ ...state, timeframeFocus: date }));
    };

    const selectedComparisonOption =
      state.comparison?.kind === 'preset' ? state.comparison.option : undefined;
    const selectComparisonOption = (
      definition: TimeframeComparisonDefinition
    ) => {
      setState((state) => {
        const comparison: ComparisonState = {
          kind: 'preset',
          option: definition
        };

        return adjustFocus(
          {
            ...state,
            comparison,
            comparisonFocus
          },
          true
        );
      });
    };

    const comparisonRangeValue = comparisonToDateTimeRange(
      state,
      minDateTime,
      maxDateTime,
      tz
    );
    const onComparisonDateRangeChange = (range: CalendarDateRange | null) => {
      if (range !== null) {
        setState((state) => {
          const comparison: ComparisonState = {
            kind: 'range',
            start: toStartOfDay(range.start, tz),
            end: toEndOfDay(range.end, tz)
          };

          return {
            ...state,
            comparison
          };
        });
      }
    };

    const onComparisonDateTimeRangeChange = (range: DateTimeRange | null) => {
      if (range !== null) {
        setState((state) => {
          const end =
            state.granularity === 'minute' ? range.end : endOfDay(range.end);
          const comparison: ComparisonState = {
            kind: 'range',
            start: range.start,
            end
          };

          return adjustFocus(
            {
              ...state,
              comparison
            },
            true
          );
        });
      }
    };

    const comparisonValidation = validateComparison(
      state.comparison,
      minDateTime,
      maxDateTime,
      {
        rangeName: 'Comparison'
      }
    );
    const isComparisonValid = isNil(comparisonValidation);

    const comparisonFocus = state.comparisonFocus;
    const onComparisonFocusChange = (date: CalendarDate) => {
      setState((state) => ({ ...state, comparisonFocus: date }));
    };

    const setGranularity = (value: Granularity) => {
      setState((state) => ({ ...state, granularity: value }));
      localStorage.setItem(TIME_SETTINGS_LOCAL_STORAGE_KEY, value);
    };

    const timeframeDefinition = toTimeframeDefinition(state, tz);

    const timeframeInputProps: ComponentProps<typeof DateRangeInput> = {
      value: timeframeDateTimeRange,
      onChange: onTimeframeDateTimeRangeChange,
      minValue: minDateTime,
      maxValue: maxDateTime,
      errorMessage: timeframeValidation,
      granularity: state.granularity,
      onGranularityChange: setGranularity,
      hideGranularitySwitch: !hasRealtimeFeature
    };

    const timeframePickerProps: ComponentProps<typeof DateRangePicker> = {
      value: timeframePickerValue,
      onChange: onTimeframeDateRangeChange,
      focusedValue: timeframeFocus,
      onFocusChange: onTimeframeFocusChange,
      minValue: minDate,
      maxValue: maxDate
    };

    const comparisonInputProps: ComponentProps<typeof DateRangeInput> = {
      value: comparisonRangeValue,
      onChange: onComparisonDateTimeRangeChange,
      minValue: minDateTime,
      maxValue: maxDateTime,
      errorMessage: comparisonValidation,
      granularity: state.granularity,
      onGranularityChange: setGranularity,
      hideGranularitySwitch: !hasRealtimeFeature
    };

    const comparisonPickerProps: ComponentProps<typeof DateRangePicker> = {
      value:
        comparisonRangeValue &&
        clampDateRange(
          {
            start: dateTimeToCalendarDate(comparisonRangeValue.start),
            end: dateTimeToCalendarDate(comparisonRangeValue.end)
          },
          minDate,
          maxDate
        ),
      onChange: onComparisonDateRangeChange,
      focusedValue: comparisonFocus,
      onFocusChange: onComparisonFocusChange,
      minValue: minDate,
      maxValue: maxDate
    };

    return {
      timeframeInputProps: timeframeInputProps,
      timeframePickerProps: timeframePickerProps,
      timeframePresetSelectProps: {
        value: selectedTimeframeOption,
        onChange: selectTimeframeOption
      },
      comparisonInputProps: comparisonInputProps,
      comparisonPickerProps: comparisonPickerProps,
      comparisonPresetSelectProps: {
        value: selectedComparisonOption,
        onChange: selectComparisonOption
      },
      timeframeDefinition,
      isMenuValid: isTimeframeValid && isComparisonValid
    };
  }, [
    adjustFocus,
    hasRealtimeFeature,
    maxDate,
    maxDateTime,
    minDate,
    minDateTime,
    state,
    tz
  ]);
};
