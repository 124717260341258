import { Button, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { css } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';
import { Dimension } from '../filters';
import { DimensionDescription, DimensionTitle } from './Dimension';

type FilterMenuCompoundComponent = React.FC & {
  Header: React.FC<HeaderProps>;
  ModeSelector: <T extends string>(
    props: ModeSelectorProps<T>
  ) => React.ReactElement;
  Body: React.FC;
  Footer: React.FC<FooterProps>;
  SaveButton: React.FC<SaveButtonProps>;
};

const FILTER_MENU_WIDTH = 400;

export const FilterMenu: FilterMenuCompoundComponent = ({ children }) => {
  return (
    <div className={css(() => ({ maxWidth: FILTER_MENU_WIDTH }))}>
      {children}
    </div>
  );
};

interface HeaderProps {
  dimension: Dimension;
}

const Header: React.FC<HeaderProps> = ({ dimension, children }) => (
  <div className={css((t) => ({ padding: t.spacing(2) }))}>
    <DimensionTitle dimension={dimension}>{children}</DimensionTitle>
  </div>
);

export const BASIC_MODES = [
  {
    value: 'oneOf',
    label: 'one of'
  }
];

interface ModeSelectorProps<T extends string> {
  modes: Array<{ label: string; value: T }>;
  mode: T;
  setMode: (m: T) => void;
}

const ModeSelector = <T extends string>({
  modes,
  mode,
  setMode
}: ModeSelectorProps<T>) => (
  <Select
    value={mode}
    onChange={(e) => setMode(e.target.value as T)}
    variant="standard"
    disableUnderline
    className={css((t) => ({
      fontSize: t.typography.h6.fontSize
    }))}
  >
    {modes.map((m) => (
      <MenuItem key={m.value} value={m.value}>
        {m.label}
      </MenuItem>
    ))}
  </Select>
);

const Body: React.FC = ({ children }) => {
  return (
    <div>
      {children}
      <ReactResizeDetector
        handleHeight
        onResize={() => {
          window.dispatchEvent(new CustomEvent('resize'));
        }}
      />
    </div>
  );
};

interface FooterProps {
  definition: FilterDefinition;
}

const Footer: React.FC<FooterProps> = ({ definition, children }) => {
  return (
    <FlexContainer
      direction="column"
      spacing={2}
      className={css((t) => ({
        padding: t.spacing(2),
        backgroundColor: t.palette.grey['100']
      }))}
    >
      <DimensionDescription definition={definition} />
      {children}
    </FlexContainer>
  );
};

interface SaveButtonProps {
  onSave: () => void;
  disabled: boolean;
  label: string;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onSave, disabled, label }) => (
  <Button
    onClick={onSave}
    variant="contained"
    color="primary"
    disabled={disabled}
    fullWidth
  >
    {label}
  </Button>
);

FilterMenu.Footer = Footer;
FilterMenu.Body = Body;
FilterMenu.ModeSelector = ModeSelector;
FilterMenu.Header = Header;
FilterMenu.SaveButton = SaveButton;
