import {
  formatChartCurrency,
  formatChartNumber
} from '../../components/Charts/Util';
import { CurrencyCode } from '../../domainTypes/currency';
import { formatDate } from '../time';
import moment from 'moment-timezone';

// TODO: It should be equal to SelectableField in the end!
export const metrics = [
  'c',
  'ctr',
  'p',
  'v',
  's',
  'view_ratio',
  'commission_sum_net',
  'commission_count_net',
  'gmv_sum_net',
  'epc_net',
  'rpm_net',
  'aov_net',
  'avg_rate_net',
  'order_count_net',
  'order_count_gross',
  'quantity_net',
  'page_last_modified',
  'count_uniq_page_url',
  'count_uniq_link_occ'
] as const;

export type Metric = typeof metrics[number];

export const isMetric = (m: string): m is Metric =>
  metrics.includes(m as Metric);

export const formatMetric = (
  value: number,
  metric: Metric,
  currency: CurrencyCode,
  opts: {
    verbose?: boolean;
  } = {}
): string => {
  switch (metric) {
    case 'count_uniq_link_occ':
      return formatChartNumber(value);
    case 'count_uniq_page_url':
      return formatChartNumber(value);
    case 'page_last_modified':
      // NOTE: basic formatter!
      return formatDate(moment.unix(value), 'YYYY-MM-DD');
    case 'aov_net':
      return formatChartCurrency(value, currency, { excludeCents: false });
    case 'avg_rate_net':
      return formatChartNumber(value);
    case 'order_count_net':
      return formatChartNumber(value);
    case 'commission_count_net':
      return formatChartNumber(value);
    case 'order_count_gross':
      return formatChartNumber(value);
    case 'quantity_net':
      return formatChartNumber(value);
    case 'epc_net':
      return formatChartCurrency(value, currency, { excludeCents: false });
    case 'rpm_net':
      return formatChartCurrency(value, currency, { excludeCents: false });
    case 'v':
      return formatChartNumber(value);
    case 'ctr':
      return formatChartNumber(value);
    case 'view_ratio':
      return formatChartNumber(value);
    case 'p':
      return formatChartNumber(value);
    case 'c':
      return formatChartNumber(value);
    case 's':
      return formatChartNumber(value);
    case 'commission_sum_net':
      return formatChartCurrency(value, currency, {
        excludeCents: !opts.verbose
      });
    case 'gmv_sum_net':
      return formatChartCurrency(value, currency, {
        excludeCents: !opts.verbose
      });
  }
};

export const metricTitle = (m: Metric): string => {
  switch (m) {
    case 'count_uniq_link_occ':
      return 'Links';
    case 'count_uniq_page_url':
      return 'Pages';
    case 'page_last_modified':
      return 'Updated';
    case 'aov_net':
      return 'AOV';
    case 'avg_rate_net':
      return 'Eff Rate';
    case 'commission_count_net':
      return 'Line items';
    case 'order_count_net':
      return 'Orders';
    case 'order_count_gross':
      return 'Orders gross';
    case 'quantity_net':
      return 'Items sold';
    case 'epc_net':
      return 'EPC';
    case 'rpm_net':
      return 'RPM';
    case 'v':
      return 'Impressions';
    case 'ctr':
      return 'CTR';
    case 'view_ratio':
      return 'Visibility';
    case 'p':
      return 'Pageviews';
    case 'c':
      return 'Clicks';
    case 's':
      return 'Served';
    case 'gmv_sum_net':
      return 'Sales volume';
    case 'commission_sum_net':
      return 'Earnings';
  }
};

export const metricName = (m: Metric): string => {
  switch (m) {
    case 'count_uniq_link_occ':
      return 'links';
    case 'count_uniq_page_url':
      return 'pages';
    case 'page_last_modified':
      return 'updated';
    case 'aov_net':
      return 'AOV';
    case 'avg_rate_net':
      return 'eff Rate';
    case 'commission_count_net':
      return 'line items';
    case 'order_count_net':
      return 'orders';
    case 'order_count_gross':
      return 'orders gross';
    case 'quantity_net':
      return 'items sold';
    case 'epc_net':
      return 'EPC';
    case 'rpm_net':
      return 'RPM';
    case 'v':
      return 'impressions';
    case 'ctr':
      return 'CTR';
    case 'view_ratio':
      return 'visibility';
    case 'p':
      return 'pageviews';
    case 'c':
      return 'clicks';
    case 's':
      return 'served';
    case 'gmv_sum_net':
      return 'sales volume';
    case 'commission_sum_net':
      return 'earnings';
  }
};
