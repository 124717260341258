import { InputBase, MenuItem, Select } from '@material-ui/core';
import moment, { Duration } from 'moment-timezone';
import React, { useMemo } from 'react';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useNumberQueryParam } from '../../../../../routes';
import { ONE_MINUTE } from '../../../../../services/time';
import { TimeframeDefinition } from '../../../../../domainTypes/timeframe';

const usePeriodSelectorState = () => {
  return useNumberQueryParam('period', 48);
};

const periodInHours = [
  1,
  6,
  12,
  24,
  48,
  /* TODO: remove this option when we get realtime sales data */ 24 * 7
];

const options = periodInHours.map((p) => (
  <MenuItem key={p} value={p}>
    {p === 168 ? 'Latest week' : p === 1 ? 'Latest hour' : `Latest ${p} hours`}
  </MenuItem>
));

export const PeriodSelector: React.FC = () => {
  const [period, setPeriod] = usePeriodSelectorState();
  return (
    <Select
      value={period}
      onChange={(e) => {
        setPeriod(e.target.value as number);
      }}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {options}
    </Select>
  );
};

export const usePeriodRange = (endDate: number): AnalyticsQuery['range'] => {
  const [period] = usePeriodSelectorState();
  return useMemo<ISOTimeRange>(() => {
    const end = new Date(endDate).toISOString();
    const start = new Date(endDate - period * 60 * ONE_MINUTE).toISOString();
    return {
      start,
      end
    };
  }, [period, endDate]);
};

export const comparePeriodRange = (range: ISOTimeRange): ISOTimeRange => {
  const start = moment(range.start);
  const end = moment(range.end);
  const diff = end.diff(start, 'h');
  return {
    start: start.subtract(diff, 'h').toISOString(),
    end: end.subtract(diff, 'h').toISOString()
  };
};

const periodToDuration = (period: number): Duration => {
  if (period < 24) {
    return moment.duration(period, 'hours');
  }
  return moment.duration(period / 24, 'days');
};

export const usePeriodRangeToTimeframe = (): TimeframeDefinition => {
  const [period] = usePeriodSelectorState();
  const duration = periodToDuration(period);
  return {
    range: {
      kind: 'period',
      value: {
        type: 'latest',
        duration: duration.toISOString()
      }
    },
    comparison: { kind: 'previous' }
  };
};
