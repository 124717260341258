import { Timeframe } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import {
  EARNING_MINIMAL_FIELD_SET,
  EarningsArgsGroupedInTimeframeAsTimeseries,
  EarningsArgsInTimeframe,
  EarningsRespGroupedInTimeframeAsTimeseries,
  EarningsRespInTimeframe,
  IDailyEarning,
  toDailyEarningFromMinimal,
  toEarningFromMinimal
} from '../../../../domainTypes/performance';
import { useMemo } from 'react';
import { timeframeToMs } from '../../../../services/time';
import { toComparableTimeframe } from '../../../../services/analytics';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import {
  useEarnings,
  useEarningsSingle
} from '../../../../services/sales/earnings';

const SALE_DATE_COLUMN = 'sale_date';

export const useTotalEarnings = (
  spaceId: string,
  url: string,
  timeframe: Timeframe,
  currency: CurrencyCode
) => {
  const queries: [
    EarningsArgsInTimeframe,
    EarningsArgsInTimeframe
  ] = useMemo(() => {
    return [
      {
        type: 'inTimeframe',
        d: {
          currency,
          page_url: [url],
          dates: {
            ...timeframeToMs(toComparableTimeframe(timeframe)),
            column: SALE_DATE_COLUMN
          }
        }
      },
      {
        type: 'inTimeframe',
        d: {
          currency,
          page_url: [url],
          dates: { ...timeframeToMs(timeframe), column: SALE_DATE_COLUMN }
        }
      }
    ];
  }, [url, timeframe, currency]);

  return useMappedLoadingValue(
    useEarnings<[EarningsRespInTimeframe, EarningsRespInTimeframe]>(
      spaceId,
      queries,
      currency
    ),
    (r) => {
      console.log('useTotalEarnings', r);
      const [prev, curr] = r.res.map((x) => x.d);
      return {
        prev: toEarningFromMinimal(prev),
        curr: toEarningFromMinimal(curr)
      };
    }
  );
};

export type EarningsByAdvertiser = Record<
  string,
  {
    advertiserName: string;
    partnerKey: string;
    earnings: IDailyEarning[];
  }
>;

export const useEarningsByAdvertiserTimeseries = (
  spaceId: string,
  url: string,
  timeframe: Timeframe,
  currency: CurrencyCode
): LoadingValue<EarningsByAdvertiser> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['advertiser_name', 'partner_key'],
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_VOLUME_AND_TX_COUNT,
        page_url: [url],
        dates: { ...timeframeToMs(timeframe), column: SALE_DATE_COLUMN },
        currency
      }
    };
  }, [url, timeframe, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      return r.res.d.reduce((acc, row) => {
        const advertiserName: string = row.group['advertiser_name'];
        const partnerKey: string = row.group['partner_key'];

        return {
          ...acc,
          [`${advertiserName}---${partnerKey}`]: {
            advertiserName,
            partnerKey,
            earnings: row.ds.map(toDailyEarningFromMinimal)
          }
        };
      }, {} as EarningsByAdvertiser);
    }
  );
};
