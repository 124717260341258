import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { ArticleList } from './ArticleList';
import { capitalize } from 'lodash/fp';
import { HelpScoutArticlePreview } from '../service';
import { styled } from '../../../../../emotion';
import { useAdminOrImpersonatorClaim } from '../../../../../services/auth';
import { useBooleanQueryParam, useRoutes } from '../../../../../routes';
import { Eye, EyeOff } from 'react-feather';

const PlayButton = styled('span')`
  display: block;
  position: absolute;
  width: 60px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  background-color: white;

  &:after {
    content: '';
    position: absolute;
    left: 26px;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid black;
  }
`;

const QuickstartVideo = styled('div')`
  position: relative;
  background-color: ${(p) => p.theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;

  img {
    max-width: 200px;
    opacity: 0.4;
    display: block;
  }
`;

const QuickstartCard = styled(Link)`
  display: grid;
  grid-template-columns: 300px 1fr;
  background: #ffffff;
  border: 1px solid #f5f4f4;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: transform 0.2s;
  margin-top: ${(p) => p.theme.spacing(3)}px;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;

  .inner {
    padding: ${(p) => p.theme.spacing(4)}px;
  }

  h3 {
    font-size: 18px;
  }

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
    transform: scale(1.02);
  }
`;

const ArticleActions = styled('div')`
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  margin-top: ${(p) => p.theme.spacing(3)}px;
`;

export const CategoryList = ({
  slug,
  groupNames,
  category,
  groups
}: {
  slug?: string;
  groupNames: string[];
  category: { name: string; description: string };
  groups: { [key: string]: HelpScoutArticlePreview[] };
}) => {
  const { ROUTES } = useRoutes();
  const [showEditorOptions] = useAdminOrImpersonatorClaim();
  const [includeDrafts, setIncludeDrafts] = useBooleanQueryParam(
    'drafts',
    false
  );
  return (
    <>
      {showEditorOptions && (
        <ArticleActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setIncludeDrafts(!includeDrafts);
            }}
          >
            {includeDrafts ? <EyeOff size={14} /> : <Eye size={14} />} &nbsp;{' '}
            {includeDrafts ? 'Hide' : 'View'} unpublished
          </Button>
        </ArticleActions>
      )}
      {slug === 'getting-started' && (
        <QuickstartCard to={ROUTES.docs.knowledgeBase.url('quickstart-guide')}>
          <QuickstartVideo>
            <img
              src="https://affilimate.com/images/help-center/graphics/transactions.png"
              alt="Quickstart video"
            />
            <PlayButton />
          </QuickstartVideo>
          <div className="inner">
            <h2>Quick start guide</h2>
            <Typography variant="body1" component="p">
              Wondering where to start? Use this quick start guide to set up
              your tracking and run your first analysis of your affiliate
              performance.
            </Typography>
          </div>
        </QuickstartCard>
      )}
      <Typography
        variant="h4"
        component="h1"
        style={{
          fontWeight: 'bold',
          marginTop: '18px',
          marginBottom: '6px'
        }}
      >
        {category.name}
      </Typography>
      <Typography variant="body1" color="textSecondary" component="p">
        {category.description}
      </Typography>
      {groupNames.map((name) => (
        <React.Fragment key={name}>
          <h2>{capitalize(name)}</h2>
          <ArticleList columns={2} articles={groups[name]} />
        </React.Fragment>
      ))}
    </>
  );
};
