import { Routes } from '../../../domainTypes/routes';
import { SideNavHowTo, SideNavProps } from '../../../layout/PageBody';

export const sideNavProps = (
  routes: Routes,
  url: string,
  howTo?: SideNavHowTo
) => {
  const sideNav: SideNavProps = {
    groups: [
      {
        label: 'Content',
        items: [
          {
            label: 'Trends',
            path: routes.content.details.trends.url(url)
          },
          {
            label: 'Heatmap',
            path: routes.content.details.heatmap.url(url)
          },
          {
            label: 'Link Performance',
            path: routes.content.details.links.url(url)
          },
          {
            label: 'Revisions',
            path: routes.content.details.revisions.url(url)
          },
          {
            label: 'Audience',
            path: routes.content.details.audience.url(url)
          }
        ]
      }
    ],
    back: {
      path: routes.content.overview_v2.url()
    },
    howTo
  };

  return sideNav;
};

/*
  NOTE:
    New version of Content Details has all exports inlined in views.
    Previous function should be removed after moving to V2
 */

export const newSideNavProps = (
  routes: Routes,
  url: string,
  howTo?: SideNavHowTo
) => {
  const sideNav: SideNavProps = {
    groups: [
      {
        label: 'Content',
        items: [
          {
            label: 'Trends',
            path: routes.content.details.trends.url(url)
          },
          {
            label: 'Heatmap',
            path: routes.content.details.heatmap.url(url)
          },
          {
            label: 'Link Performance',
            path: routes.content.details.links.url(url)
          },
          {
            label: 'Revisions',
            path: routes.content.details.revisions.url(url)
          },
          {
            label: 'Audience',
            path: routes.content.details.audience.url(url),
            badge: 'NEW'
          }
        ]
      }
    ],
    back: {
      path: routes.content.overview_v2.url()
    },
    howTo
  };

  return sideNav;
};
