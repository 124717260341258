import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ChevronDown, Columns as IconColumns } from 'react-feather/dist';
import { MultiSelector } from '../../MultiSelector';
import { IColumn } from '../Column';

export interface ColumnSelectorProps<Key extends string> {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;
  columns: Array<
    | IColumn<any, Key, any>
    | { label: string; columns: Array<IColumn<any, Key, any>> }
  >;
  short?: boolean;
}

export const ColumnSelector = <Key extends string>({
  value,
  onChange,
  columns
}: ColumnSelectorProps<Key>) => {
  const options = useMemo(
    () =>
      columns.map((c) => {
        if ('columns' in c) {
          return {
            label: c.label,
            options: c.columns.map((c) => ({
              label: c.alternateHead ? c.alternateHead() : c.head(),
              value: c.key
            }))
          };
        }
        return {
          label: c.alternateHead ? c.alternateHead() : c.head(),
          value: c.key
        };
      }),
    [columns]
  );
  const columnsCount = useMemo(
    () => columns.flatMap((c) => ('columns' in c ? c.columns : c)).length,
    [columns]
  );
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      options={options}
      legend={`Columns (${value.size} of ${columnsCount} selected)`}
      allowSearch
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <IconColumns size={18} /> Columns{' '}
        <strong>
          {value.size} of {columnsCount}
        </strong>
        <ChevronDown size={18} />
      </Typography>
    </MultiSelector>
  );
};
