import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../../../components/NoPermissions';
import { useAdminOrImpersonatorClaim } from '../../../../../../services/auth';
import { useFeatureEnabled } from '../../../../../../services/features';
import { ContentPageBody } from '../../../../components/ContentPageBody';
import { OriginsTable } from '../components/OriginsTable';
import { TopBar } from '../components/TopBar';
import { TotalNumbers } from '../components/TotalNumbers';
import { TotalsBar } from '../components/TotalsBar';
import { ContentWrapper } from '../layout';
import {
  toReferrerGroupTitle,
  useReferrerGroupTimeseries,
  useReferrerGroupTotals
} from '../services/referrer-groups';
import { useRoutes } from '../../../../../../routes';
import { useTrackMixpanelView } from '../../../../../../services/mixpanel';
import { OriginsTimeseriesChart } from '../components/OriginsTimeseriesChart';
import { useReportMetric } from '../services/report-metric';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';

interface ReferrerGroupDetailsProps {
  groupId: ReferrerGroup;
}

const Body: React.FC<{ group: ReferrerGroup }> = ({ group }) => {
  const [metric] = useReportMetric();
  const groupTotals = useReferrerGroupTotals(metric);
  const timeseries = useReferrerGroupTimeseries(metric);
  const [adminOrImpersonator] = useAdminOrImpersonatorClaim();
  const canView = useFeatureEnabled('REFERRER_REPORTS_V1');

  if (!canView && !adminOrImpersonator) {
    return <NoPermissions />;
  }

  return (
    <ContentWrapper>
      <TotalsBar data={groupTotals} />
      <TotalNumbers totals={groupTotals} active={group} />
      <OriginsTimeseriesChart data={timeseries} group={group} />
      <OriginsTable group={group} />
    </ContentWrapper>
  );
};

export const ReferrerGroupDetails: React.FC<ReferrerGroupDetailsProps> = ({
  groupId
}) => {
  const { ROUTES } = useRoutes();
  useTrackMixpanelView('view_referrers', { section: groupId });
  return (
    <>
      <Helmet>
        <title>Referrers | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        <TopBar
          breadcrumbs={[
            {
              title: 'Referrer groups',
              to: ROUTES.content.referrers.overview.url()
            },
            { title: `${toReferrerGroupTitle(groupId)}` }
          ]}
        />
        <Body group={groupId} />
      </ContentPageBody>
    </>
  );
};
