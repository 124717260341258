import React, { useRef } from 'react';
import {
  FilterDefinition,
  FiltersDefinition
} from '../../../../domainTypes/filters';
import { css } from '../../../../emotion';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { Dimension } from '../filters';
import { And } from './And';
import { FilterControl } from './FilterControl';
import { FilterPlaceholder } from './FilterPlaceholder';
import { ISOTimeRange } from '../../../../domainTypes/analytics_v2';
import { FilterDrawerRenderContext } from './types';

interface FilterListProps {
  filters: FiltersDefinition;
  range: ISOTimeRange;
  orderBy: Metric;
  placeHolderDimension: Dimension | null;
  onChange: (def: FilterDefinition) => void;
  onDelete: (dimension: Dimension) => void;
  addFilter: (d: FilterDefinition) => void;
  clearPlaceholder: () => void;
  renderContext?: FilterDrawerRenderContext;
}

export const FilterList: React.FC<FilterListProps> = ({
  filters,
  range,
  orderBy,
  placeHolderDimension,
  onDelete,
  onChange,
  clearPlaceholder,
  addFilter,
  renderContext
}) => {
  const placeHolderRef = useRef<HTMLDivElement>(null);
  return (
    <>
      {filters.map((definition, i) => {
        const isFirst = i === 0;
        // Convert fragment into div if we want to avoid orphans
        return (
          <React.Fragment key={definition.k}>
            {isFirst ? null : <And />}
            <FilterControl
              // key={definition.k} NOTE: we need a key that changes with whole definition, JSON.stringify?
              definition={definition}
              onChange={onChange}
              onDelete={onDelete}
              orderBy={orderBy}
              range={range}
              renderContext={renderContext}
            />
          </React.Fragment>
        );
      })}
      <div
        ref={placeHolderRef}
        className={css((t) => ({
          alignItems: 'center',
          display: 'flex',
          columnGap: t.spacing(2)
        }))}
      >
        {placeHolderDimension && (
          <FilterPlaceholder
            anchorRef={placeHolderRef}
            addFilter={addFilter}
            clear={clearPlaceholder}
            dimension={placeHolderDimension}
            orderBy={orderBy}
            range={range}
            renderContext={renderContext}
          />
        )}
      </div>
    </>
  );
};
