import moment, { Moment } from 'moment-timezone';
import {
  TimeframeComparisonDefinition,
  TimeframeDefinition,
  TimeframeRangeDefinition
} from '../../domainTypes/timeframe';

export const getPreviousMoments = (range: { start: Moment; end: Moment }) => {
  // TODO: check this hack!
  const normalizedEnd =
    range.end.get('ms') === 999
      ? range.end.clone().add(1, 'millisecond')
      : range.end;
  const start = range.start;
  const duration = normalizedEnd.diff(start);
  return {
    start: range.start.clone().subtract(duration),
    end: range.end.clone().subtract(duration)
  };
};

export const getCompareMoments = (
  definition: TimeframeComparisonDefinition,
  range: { start: Moment; end: Moment }
) => {
  switch (definition.kind) {
    case 'disabled':
      return undefined;
    case 'custom':
      return {
        start: moment(definition.start),
        end: moment(definition.end)
      };
    case 'timeshift':
      return {
        start: range.start
          .clone()
          .subtract(moment.duration(definition.duration)),
        end: range.end.clone().subtract(moment.duration(definition.duration))
      };
    case 'previous': {
      return getPreviousMoments(range);
    }
  }
};

export const getTimeframeMoments = (
  definition: TimeframeRangeDefinition,
  tz: string
) => {
  switch (definition.kind) {
    case 'custom':
      return {
        start: moment(definition.start).tz(tz),
        end: moment(definition.end).tz(tz)
      };
    case 'period': {
      const now = moment().tz(tz);
      switch (definition.value.type) {
        case 'last': {
          const ref = now.clone().startOf('day');
          return {
            end: ref.clone().subtract(1, 'millisecond'),
            start: ref.clone().subtract(definition.value.duration)
          };
        }
        case 'current':
          return {
            start: now.clone().startOf(definition.value.unit),
            end: now.clone().startOf('day').subtract(1, 'millisecond') // end of yesterday
          };
        case 'previous': {
          const ref = now.clone().subtract(1, definition.value.unit);
          return {
            start: ref.clone().startOf(definition.value.unit),
            end: ref.clone().endOf(definition.value.unit)
          };
        }
        case 'latest':
          return {
            start: now
              .clone()
              .subtract(moment.duration(definition.value.duration)),
            end: now.clone()
          };
      }
    }
  }
};

export const toMoments = (definition: TimeframeDefinition, tz: string) => {
  const { start, end } = getTimeframeMoments(definition.range, tz);
  const compare = getCompareMoments(definition.comparison, { start, end });
  return { start, end, compare };
};
