import { Paper, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { DownloadCloud } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  AdvertisersTable,
  COLUMNS,
  SORTERS
} from '../../../../components/AdvertisersTable';
import { AlertBox } from '../../../../components/AlertBox';
import { AdvertiserChartCard } from '../../../../components/Charts/AdvertiserChartCard';
import { EarningsChartCard } from '../../../../components/Charts/EarningsChartCard';
import { ChartMode } from '../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { GraphMode } from '../../../../components/Charts/EarningsChartCard/GraphModeSelector';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SearchInput } from '../../../../components/SearchInput';
import {
  SiteSelector,
  UNATTRIBUTED
} from '../../../../components/SiteSelector';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  TimeframePickerDense,
  useStandardOptions
} from '../../../../components/TimeframePicker/LegacyPickers';
import { isSameTimeframe } from '../../../../domainTypes/analytics';
import { EMPTY_ARR } from '../../../../domainTypes/emptyConstants';
import {
  SALE_STATUSES,
  SALE_TYPES,
  SALE_TYPES_WITHOUT_BONUS,
  SalesFilterArgs,
  SaleStatus,
  SaleType
} from '../../../../domainTypes/performance';
import { css, styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import {
  queryParamToList,
  setToQueryParam,
  useNullableStringSetQueryParam,
  useQueryParam,
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../routes';
import { useEarningsPerAdvertiser } from '../../../../services/advertisers';
import { allTime } from '../../../../services/analytics';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { useAdvertiserTimeseriesData } from '../../../../services/sales/earnings';
import { getActiveDomainUrls } from '../../../../services/space';
import {
  msToTimeframe,
  timeframeToMs,
  toMoment
} from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { AdvertisersExportButton } from '../../components/AdvertisersExportButton';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { SalesFilter } from '../../components/SalesFilter';
import { useKnownAdvertisers, useKnownPartners } from '../../services/hooks';
import { PagePerformanceAdvertisersInnerV2 } from './pages/AdvertiserOverview';

import { useLegacyTimeframe } from '../../../../hooks/timeframe';

const HEIGHT = 700;

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1.75fr 3fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  min-height: 500px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const DEFAULT_COLUMNS = COLUMNS.map((c) => c.key).filter(
  (c) =>
    ['orderCountGross', 'lostCount', 'avgCommissionNetPercent'].indexOf(c) ===
    -1
);

const PagePerformanceAdvertisersInner = () => {
  const { ROUTES, changeQuery } = useRoutes();
  const { space } = useCurrentUser();
  const { id: spaceId } = space;
  const { options } = useStandardOptions();
  const allSites = useMemo(() => new Set(getActiveDomainUrls(space, true)), [
    space
  ]);
  const [search, setSearch] = useStringQueryParam('q');
  const [sites, setSites] = useQueryParam(
    'site',
    (p) =>
      new Set(
        p
          ? queryParamToList(p)
          : [...getActiveDomainUrls(space, true), UNATTRIBUTED]
      ),
    (ss) =>
      ss.size === getActiveDomainUrls(space, true).length + 1 // including UNATTRIBUTED
        ? undefined
        : setToQueryParam(ss)
  );
  const [timeframe, setTimeframe] = useLegacyTimeframe();
  const compare = !isSameTimeframe(allTime(), timeframe);
  const currency = useSpaceCurrency();
  const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
    'graph_mode',
    'advertiser'
  );

  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );

  const [
    selectedPartners,
    setSelectedPartners,
    toPartnerQueryParam
  ] = useNullableStringSetQueryParam('partners');

  const [
    selectedAdvertisers,
    setSelectedAdvertisers,
    toAdvertiserQueryParam
  ] = useNullableStringSetQueryParam('advertisers', '---');

  const [
    selectedStatuses,
    setSelectedStatuses,
    toStatusQueryParam
  ] = useQueryParam(
    'status',
    (p) => new Set(p ? queryParamToList<SaleStatus>(p) : SALE_STATUSES),
    (ss) => (ss.size === SALE_STATUSES.length ? undefined : setToQueryParam(ss))
  );

  const [selectedTypes, setSelectedTypes, toTypeQueryParam] = useQueryParam(
    'sale_type',
    (p) =>
      new Set(p ? queryParamToList<SaleType>(p) : SALE_TYPES_WITHOUT_BONUS),
    (ss) =>
      ss.size === SALE_TYPES_WITHOUT_BONUS.length
        ? undefined
        : setToQueryParam(ss)
  );

  const q: SalesFilterArgs = useMemo(() => {
    const filters: SalesFilterArgs = { dates: timeframeToMs(timeframe) };
    if (selectedStatuses.size !== SALE_STATUSES.length) {
      filters.sale_status = [...selectedStatuses];
    }
    if (selectedTypes.size !== SALE_TYPES.length) {
      filters.sale_type = [...selectedTypes];
    }
    if (allSites.size + 1 !== sites.size) {
      filters.origin = [...sites];
    }
    if (selectedPartners && selectedPartners.size) {
      filters.partner_key = [...selectedPartners];
    }
    if (selectedAdvertisers && selectedAdvertisers.size) {
      filters.advertiser_name = [...selectedAdvertisers];
    }
    return filters;
  }, [
    timeframe,
    sites,
    allSites,
    selectedPartners,
    selectedStatuses,
    selectedTypes,
    selectedAdvertisers
  ]);

  const [partners] = useKnownPartners(space.id, q);
  const [advertisers] = useKnownAdvertisers(space.id, q);

  const [d, loadingTimeseries] = useAdvertiserTimeseriesData(
    spaceId,
    q,
    currency,
    search
  );
  const [earnings, loadingEarnings, error] = useEarningsPerAdvertiser(
    spaceId,
    timeframe,
    compare,
    currency,
    q
  );

  /*
  const [clickData, loadingClickData] = useMappedLoadingValue(
    usePromise<AdvertiserWithData[]>(async () => {
      console.log('checking if should call endpoint');
      if (!earnings || !earnings.length || loadingEarnings) {
        return [];
      }
      console.log('calling endpoint', earnings, loadingEarnings);
      const ads = earnings.map((e) => ({
        name: e.advertiserName,
        id: e.advertiserId,
        partnerKey: e.partner.key
      }));
      return getDataFromAdvertisers({
        spaceId: space.id,
        timeframe,
        advertisers: ads
      });
    }, [earnings, loadingEarnings, space.id, timeframe]),
    (d) => {
      console.log('promise resolved', d);
      return groupBy(d, (r) => `${r.partnerKey}---${r.name}`);
    }
  );

  console.log('click data', clickData, 'loading click data', loadingClickData);
 */

  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const [columnNames, setColumns] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );

  // Use this to show a message to new users about their product
  // analytics data
  const user = useCurrentUser();

  const [canExportAdvertisers] = useHasCurrentUserRequiredScopes([
    'reports.advertisers.export'
  ]);

  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;

  const filteredEarnings = useMemo(() => {
    return earnings
      ? earnings.filter((e) =>
          search ? e.advertiserName.toLowerCase().includes(search) : true
        )
      : null;
  }, [earnings, search]);

  if (error) {
    return (
      <Paper style={{ height: '100%' }}>
        <Centered height={HEIGHT}>Something went wrong.</Centered>
      </Paper>
    );
  }

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Advertisers
          </Typography>
          <SiteSelector
            sites={getActiveDomainUrls(space, true)}
            value={sites}
            onChange={setSites}
            includeUnattributed
          />
          <ColumnSelector
            value={columnNames}
            onChange={setColumns}
            columns={COLUMNS}
          />
          <SalesFilter
            partners={{
              partners: partners || EMPTY_ARR,
              value: selectedPartners,
              onChange: setSelectedPartners
            }}
            advertisers={{
              advertisers: advertisers || EMPTY_ARR,
              value: selectedAdvertisers,
              onChange: setSelectedAdvertisers
            }}
            statuses={{
              value: selectedStatuses,
              onChange: setSelectedStatuses
            }}
            types={{
              value: selectedTypes,
              onChange: setSelectedTypes
            }}
            onReset={() => {
              changeQuery({
                ...toStatusQueryParam(new Set(SALE_STATUSES)),
                ...toTypeQueryParam(new Set(SALE_TYPES)),
                ...toPartnerQueryParam(null),
                ...toAdvertiserQueryParam(null)
              });
            }}
          />
        </PageToolbarSection>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="Search by advertiser"
          />
          <TimeframePickerDense
            value={timeframe}
            onChange={setTimeframe}
            options={options}
          />
          {canExportAdvertisers && (
            <Tooltip title="Export current view" placement="top">
              <AdvertisersExportButton
                columns={[...columnNames]}
                currency={currency}
                timeframe={timeframe}
                disabled={loadingEarnings || !filteredEarnings}
                rows={filteredEarnings!}
                variant="text"
                className={css((t) => ({
                  minWidth: 0,
                  marginLeft: t.spacing(1)
                }))}
              >
                <DownloadCloud size={16} />
              </AdvertisersExportButton>
            </Tooltip>
          )}
        </PageToolbarSection>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <div>
        <Grid>
          <AdvertiserChartCard
            data={
              filteredEarnings
                ? filteredEarnings.map((e) => ({
                    advertiserName: e.advertiserName,
                    partnerKey: e.partner.key,
                    earnings: e.curr
                  }))
                : []
            }
            loading={loadingEarnings}
          />
          <EarningsChartCard
            data={d ? d.data : undefined}
            loading={loadingTimeseries}
            currency={currency}
            graphMode={graphMode}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
            timeframe={msToTimeframe(d ? d.dates : q.dates)}
          />
        </Grid>
        {loadingEarnings || !filteredEarnings ? (
          <Paper>
            <Loader height={HEIGHT} />
          </Paper>
        ) : (
          <>
            <AdvertisersTable
              data={filteredEarnings}
              columns={columnNames}
              sortDirection={direction}
              sorter={sorter}
              setSort={setSort}
              compare={true}
              stickyHeadOffset={DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT}
              rowToHref={(p) => {
                return ROUTES.performanceNew.advertisers.details.url({
                  advertisers: p.advertiserName,
                  partners: p.partner.key
                });
              }}
            />
          </>
        )}
      </div>
    </PerformancePageBody>
  );
};

export const PagePerformanceAdvertisers = () => {
  useTrackMixpanelView('view_advertisers');
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.advertisers.view'
  ]);
  const [experimental] = useExperimentalContext();

  return (
    <>
      <Helmet>
        <title>Advertisers | Affilimate</title>
      </Helmet>
      {canView ? (
        experimental ? (
          <PagePerformanceAdvertisersInner />
        ) : (
          <PagePerformanceAdvertisersInnerV2 />
        )
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
