import {
  LoadingValue,
  store,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../db';
import { FS } from '../../versions';
import { generateToDocFn } from '../../domainTypes/document';
import {
  CustomDimensionName,
  ICustomDimensionDefinition,
  ICustomDimensionDetailsDoc,
  ICustomDimensionsDoc,
  isClickDimension,
  LinkGeneratorOption,
  LinkGeneratorSettings
} from '../../domainTypes/customDimensions';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../firecache/documentListener';
import { useCurrentUser } from '../currentUser';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';

export type DimensionsSettings = Partial<
  Record<CustomDimensionName, DimensionSettings>
>;

export type DimensionSettings = ICustomDimensionDefinition;

const toCustomDimensionsDoc = generateToDocFn<ICustomDimensionsDoc>();

export const getCustomDimensionsStore = (spaceId: string) =>
  store().collection(FS.customDimensions).doc(spaceId);

export const defaultCustomDimensionsDoc = (
  spaceId: string
): ICustomDimensionsDoc => ({
  dimensions: {},
  spaceId
});

const customDimensionsStore = createDocumentListenerGetter(
  (spaceId) => store().collection(FS.customDimensions).doc(spaceId),
  toCustomDimensionsDoc,
  defaultCustomDimensionsDoc
);

export const useCustomDimensionsDoc = (spaceId: string) =>
  useDocumentListener(customDimensionsStore(spaceId));

export const useCustomDimensions = (): LoadingValue<DimensionsSettings> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useCustomDimensionsDoc(space.id),
    (doc) => (doc || null)?.data.dimensions ?? {}
  );
};

const toCustomDimensionDetailsDoc = generateToDocFn<
  ICustomDimensionDetailsDoc
>();

const customDimensionDetailsStore = createDocumentListenerGetter(
  (docId: string) => store().collection(FS.customDimensionDetails).doc(docId),
  toCustomDimensionDetailsDoc
);

export const getCustomDimensionsDetailsStore = (
  spaceId: string,
  slot: CustomDimensionName
) =>
  store()
    .collection(FS.customDimensionDetails)
    .doc(detailsDocId(spaceId, slot));

function detailsDocId(spaceId: string, slot: CustomDimensionName) {
  return `${spaceId}_${slot}`;
}

export const useCustomDimensionDetailsDoc = (
  spaceId: string,
  slot: CustomDimensionName
) =>
  useDocumentListener(customDimensionDetailsStore(detailsDocId(spaceId, slot)));

export const useCustomDimension = (
  slot: CustomDimensionName
): LoadingValue<{
  name?: string;
  description?: string;
  linkGeneratorSettings?: LinkGeneratorSettings;
  options?: LinkGeneratorOption[];
}> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useCombineLoadingValues(
      useCustomDimensions(),
      useCustomDimensionDetailsDoc(space.id, slot)
    ),
    ([dimensions, details]) => {
      const settings = dimensions[slot];
      const options = details ? details.data.options : [];

      return {
        ...settings,
        options
      };
    }
  );
};

const detailsStore = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store()
        .collection(FS.customDimensionDetails)
        .where('spaceId', '==', spaceId),
      toCustomDimensionDetailsDoc
    )
);

const useCustomDimensionsDetails = (spaceId: string) =>
  useCollectionListener(detailsStore(spaceId));

export const useCustomDimensionsOptions = () => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useCombineLoadingValues(
      useCustomDimensions(),
      useCustomDimensionsDetails(space.id)
    ),
    ([dimensions, detailsDocs]) => {
      const details = detailsDocs.map((doc) => doc.data);
      return Object.entries(dimensions).flatMap(([slot, settings]) => {
        if (!isClickDimension(slot)) return [];
        if (settings?.linkGeneratorSettings.type === 'disabled') return [];
        const dimensionDetails = details.find((d) => d.slot === slot);
        return [
          {
            slot,
            ...settings,
            options: dimensionDetails?.options ?? []
          }
        ];
      });
    }
  );
};
