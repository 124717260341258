import { compact, keyBy, mapValues } from 'lodash';
import { useMemo } from 'react';
import { UNKNOWN } from '../../components/GroupableList';
import {
  EMPTY_COUNT_WITH_TREND,
  EMPTY_TREND_COUNTER,
  ICounterWithTrend,
  ICountWithTrend,
  IShortCounter,
  PageAnalyticsQuery,
  PageAnalyticsResponseSum,
  PageAnalyticsResponseTimeseries,
  recalculateTrend,
  Timeframe
} from '../../domainTypes/analytics';
import { CurrencyCode } from '../../domainTypes/currency';
import { Doc } from '../../domainTypes/document';
import {
  IPageWithCountsAndTrends,
  IPageWithCountsAndTrendsAndSales
} from '../../domainTypes/page';
import {
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  EMPTY_EARNING,
  ITrackedConvertedSale,
  toEarningFromMinimal
} from '../../domainTypes/performance';
import { useTagsPerPage } from '../../features/Content/pages/Tags/service';
import { useSalesInTimeframeBySpaceId } from '../../features/PerformanceNew/services/sale';
import { usePromise } from '../../hooks/usePromise';
import { timeframeToMomentRange, timeframeToMs } from '../../services/time';
import {
  getTrend,
  multiplyTimeframe,
  toComparableTimeframe,
  usePageCountsInTimeFrame
} from '../analytics';
import { toChecksum } from '../checksum';
import {
  combineLoadingValues,
  LoadingValue,
  LoadingValueExtended,
  useCombineLoadingValues3,
  useMappedLoadingValue
} from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { useEarnings } from '../sales/earnings';
import { withoutProtocol } from '../url';

export const getPathName = (href: string) => {
  const m = href.match(/.+\/\/.+?(\/.+)/);
  return m ? m[1] : '/';
};

export const getDomainName = (href: string) => {
  try {
    const url = new URL(href);
    return url.hostname;
  } catch (err) {
    return '';
  }
};

const toPageObjects = (countersByHref: {
  [href: string]: ICounterWithTrend;
}) => {
  const result: IPageWithCountsAndTrends[] = [];
  for (const href in countersByHref) {
    try {
      result.push({
        href,
        domain: new URL(href).hostname,
        counts: countersByHref[href]
      });
    } catch {
      console.log('Invalid URL', href);
      // Ignore the invalid URL
    }
  }
  return result;
};

export const usePagesWithCountsInTimeframe = (
  timeframe: Timeframe,
  compare: boolean
) => {
  return useMappedLoadingValue(
    usePageCountsInTimeFrame(timeframe, compare),
    toPageObjects
  );
};

export type ISalesCounter = {
  rpm: ICountWithTrend;
  sales: ICountWithTrend;
  salesCount: ICountWithTrend;
  orderCount: ICountWithTrend;
  saleValue: ICountWithTrend;
};

export type IPagesWithRpm = {
  [href: string]: ISalesCounter;
};

const groupAndCalculateSales = (
  sales: Doc<ITrackedConvertedSale>[],
  pages: IPageWithCountsAndTrends[],
  timeframe: Timeframe
): IPagesWithRpm => {
  const moments = timeframeToMomentRange(timeframe);

  const aggregatedSales = sales.reduce<IPagesWithRpm>((memo, sale) => {
    if (!sale.data.click && !sale.data.pageUrl) {
      return memo;
    }

    const href = sale.data.click
      ? sale.data.click.href
      : sale.data.pageUrl || '';

    const current = memo[href] || {
      rpm: EMPTY_COUNT_WITH_TREND(),
      sales: EMPTY_COUNT_WITH_TREND(),
      salesCount: EMPTY_COUNT_WITH_TREND(),
      orderCount: EMPTY_COUNT_WITH_TREND(),
      saleValue: EMPTY_COUNT_WITH_TREND()
    };

    if (sale.data.sale.status === 'Cancelled') {
      return memo;
    }

    const inCurrentTimeframe = sale.data.dates.sale.isSameOrAfter(
      moments.start
    );

    const attr = inCurrentTimeframe ? 'count' : 'lastCount';

    // Subtract sales count
    const multiplier = sale.data.sale.status === 'Refunded' ? -1 : 1;

    const salesValue = {
      [attr]:
        current.sales[attr] + multiplier * sale.data.sale.amount.commission
    };

    // Means, it's possible for an item to make negative sales during
    // a given timeframe. Which seems fair to me if the page generates refunds.
    const countValue = current.salesCount[attr] + multiplier;
    const salesCountValue = { [attr]: countValue };
    const orderCountValue = { [attr]: current.orderCount[attr] };
    const saleValue = { [attr]: current.saleValue[attr] };

    memo[href] = {
      rpm: {
        count: 0,
        lastCount: 0,
        trend: 0
      },
      sales: {
        ...current.sales,
        ...salesValue
      },
      salesCount: {
        ...current.salesCount,
        ...salesCountValue
      },
      orderCount: {
        ...current.orderCount,
        ...orderCountValue
      },
      saleValue: {
        ...current.saleValue,
        ...saleValue
      }
    };
    return memo;
  }, {});

  return Object.keys(aggregatedSales).reduce<IPagesWithRpm>((memo, href) => {
    if (!pages) {
      return memo;
    }

    const page = pages.find((p) => p.href === href);

    if (!page) {
      return memo;
    }

    const salesForPage = aggregatedSales[href];

    if (!salesForPage) {
      return {
        ...memo,
        [href]: {
          rpm: EMPTY_COUNT_WITH_TREND(),
          sales: EMPTY_COUNT_WITH_TREND(),
          salesCount: EMPTY_COUNT_WITH_TREND(),
          orderCount: EMPTY_COUNT_WITH_TREND(),
          saleValue: EMPTY_COUNT_WITH_TREND()
        }
      };
    }

    const currentRpm =
      (aggregatedSales[href].sales.count / page.counts.pageViews.count) * 1000;

    const prevRpm =
      (aggregatedSales[href].sales.lastCount /
        page.counts.pageViews.lastCount) *
      1000;

    const p = aggregatedSales[href];

    memo[href] = {
      ...p,
      sales: recalculateTrend(p.sales),
      salesCount: recalculateTrend(p.salesCount),
      orderCount: recalculateTrend(p.orderCount),
      saleValue: recalculateTrend(p.saleValue),
      rpm: {
        count: currentRpm,
        lastCount: prevRpm,
        trend: getTrend(prevRpm, currentRpm)
      }
    };

    return memo;
  }, {});
};

/**
 * @deprecated
 */
const useSalesByPageInTimeframe = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean
): LoadingValue<IPagesWithRpm> => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return useMappedLoadingValue(
    combineLoadingValues(
      useSalesInTimeframeBySpaceId(spaceId, tf, currency),
      usePagesWithCountsInTimeframe(timeframe, compare)
    ),
    ([convertedSales, pages]) =>
      groupAndCalculateSales(convertedSales, pages, timeframe)
  );
};

const useEarningsByPageInTimeframe = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean
) => {
  const { start, end, tz } = timeframe;
  const queries = useMemo<EarningsArgsGroupedInTimeframe[]>(() => {
    const tf: Timeframe = { start, end, tz };
    return compact([
      {
        type: 'groupedInTimeframe',
        d: { groupBy: ['page_url'], dates: timeframeToMs(tf), currency }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          groupBy: ['page_url'],
          dates: timeframeToMs(toComparableTimeframe(tf)),
          currency
        }
      }
    ]);
  }, [start, end, tz, compare, currency]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      console.log('useEarningsByPageInTimeframe', r);
      const curr = r.res[0];
      const prev: EarningsRespGroupedInTimeframe | undefined = r.res[1];
      const prevByPageUrl = keyBy(
        prev?.d || [],
        (k) => (k.group['page_url'] as string) || UNKNOWN
      );
      const currByPageUrl = keyBy(
        curr.d,
        (k) => (k.group['page_url'] as string) || UNKNOWN
      );
      return mapValues(currByPageUrl, (x, k) => {
        const p = prevByPageUrl[k]?.d;
        return {
          prev: p ? toEarningFromMinimal(p) : EMPTY_EARNING(currency),
          curr: toEarningFromMinimal(x.d)
        };
      });
    }
  );
};

const PAGE_ANALYTICS_CACHE: {
  [spaceId: string]: {
    [checksum: string]: Promise<any>;
  };
} = {};

const getPageAnalyticsCacheForSpace = (spaceId: string) => {
  return (PAGE_ANALYTICS_CACHE[spaceId] = PAGE_ANALYTICS_CACHE[spaceId] || {});
};

const getPageAnalyticsFnName = (v: 1 | 2) => {
  return v === 1
    ? 'analytics-getPageAnalytics'
    : 'analytics_v2-getPageAnalytics';
};

type GetPageAnalyticsOptions = {
  v: 1 | 2;
  noCache?: boolean;
};
export const getPageAnalytics = (
  spaceId: string,
  q: Omit<PageAnalyticsQuery, 'asTimeseries'>,
  opts: GetPageAnalyticsOptions = { v: 1, noCache: false }
): Promise<PageAnalyticsResponseSum> => {
  const fullQ: PageAnalyticsQuery = {
    ...q,
    asTimeseries: false
  };
  const req = () =>
    callFirebaseFunction<
      PageAnalyticsResponseSum,
      { spaceId: string; q: PageAnalyticsQuery }
    >(getPageAnalyticsFnName(opts.v), {
      spaceId,
      q: fullQ
    });

  if (opts.noCache) {
    return req();
  }
  const checksum = toChecksum(fullQ);
  const cache = getPageAnalyticsCacheForSpace(spaceId);
  return (cache[checksum] = cache[checksum] || req());
};

export const getPageAnalyticsAsTimeseries = (
  spaceId: string,
  q: Omit<PageAnalyticsQuery, 'asTimeseries'>,
  opts: GetPageAnalyticsOptions = { v: 1, noCache: false }
): Promise<PageAnalyticsResponseTimeseries> => {
  const fullQ: PageAnalyticsQuery = {
    ...q,
    asTimeseries: true
  };
  const req = () =>
    callFirebaseFunction<
      PageAnalyticsResponseTimeseries,
      { spaceId: string; q: PageAnalyticsQuery }
    >(getPageAnalyticsFnName(opts.v), {
      spaceId,
      q: fullQ
    });
  if (opts.noCache) {
    return req();
  }
  const checksum = toChecksum(fullQ);
  const cache = getPageAnalyticsCacheForSpace(spaceId);
  return (cache[checksum] = cache[checksum] || req());
};

const getPageAnalyticsAsCountMap = async (
  spaceId: string,
  timeframe: Timeframe,
  filters?: Omit<PageAnalyticsQuery, 'asTimeseries' | 'tf'>
): Promise<
  {
    url: string;
    counts: IShortCounter;
  }[]
> => {
  return getPageAnalytics(spaceId, {
    tf: timeframe,
    groupBy: 'url',
    ...(filters || {})
  }).then((r) => {
    const res = r.d.map(([url, p, s, v, c]) => {
      return {
        url,
        counts: {
          p: p,
          s: s,
          v: v,
          c: c
        }
      };
    });
    console.log('getPageAnalytics', r, { timeframe, aggregated: res });
    return res;
  });
};

export const usePageAnalyticsWithCountsAndTrends = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean,
  filters?: Omit<PageAnalyticsQuery, 'asTimeseries' | 'tf'>
): LoadingValueExtended<{
  [url: string]: {
    url: string;
    counts: ICounterWithTrend;
  };
}> => {
  const { start, end, tz } = timeframe;

  return usePromise(async () => {
    const tf = { start, end, tz };
    const res = await Promise.all(
      compact([
        getPageAnalyticsAsCountMap(spaceId, tf, filters),
        compare &&
          getPageAnalyticsAsCountMap(
            spaceId,
            toComparableTimeframe(tf),
            filters
          )
      ])
    );
    const [curr, prev = []] = res;
    const currByUrl = keyBy(curr, (x) => x.url);
    const prevByUrl = keyBy(prev, (x) => x.url);
    const allUrls = [
      ...new Set([...Object.keys(currByUrl), ...Object.keys(prevByUrl)])
    ];

    const dataByUrl = allUrls.reduce<{
      [url: string]: {
        url: string;
        counts: ICounterWithTrend;
      };
    }>((m, url) => {
      const u = `https://${url}`;
      const c = currByUrl[url];
      const p = prevByUrl[url];
      m[u] = {
        url: u,
        counts: {
          pageViews: {
            count: c?.counts.p || 0,
            lastCount: p?.counts.p || 0,
            trend: getTrend(p?.counts.p || 0, c?.counts.p || 0)
          },
          served: {
            count: c?.counts.s || 0,
            lastCount: p?.counts.s || 0,
            trend: getTrend(p?.counts.s || 0, c?.counts.s || 0)
          },
          viewed: {
            count: c?.counts.v || 0,
            lastCount: p?.counts.v || 0,
            trend: getTrend(p?.counts.v || 0, c?.counts.v || 0)
          },
          clicked: {
            count: c?.counts.c || 0,
            lastCount: p?.counts.c || 0,
            trend: getTrend(p?.counts.c || 0, c?.counts.c || 0)
          }
        }
      };
      return m;
    }, {});
    console.log('usePageAnalyticsWithCountsAndTrends', dataByUrl);
    return dataByUrl;
  }, [spaceId, start, end, tz, compare, filters]);
};

export const usePagesWithCountsAndSalesInTimeframePgOnly = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean
): LoadingValue<IPageWithCountsAndTrendsAndSales[]> => {
  return useMappedLoadingValue(
    useCombineLoadingValues3(
      usePageAnalyticsWithCountsAndTrends(spaceId, timeframe, compare),
      useEarningsByPageInTimeframe(spaceId, timeframe, currency, compare),
      useTagsPerPage(spaceId)
    ),
    ([analytics, earnings, tagsPerPage]) => {
      const allUrls = [
        ...new Set([...Object.keys(analytics), ...Object.keys(earnings)])
      ];
      return allUrls
        .filter((url) => url !== UNKNOWN)
        .map<IPageWithCountsAndTrendsAndSales>((url) => {
          const counts = analytics[url]?.counts || EMPTY_TREND_COUNTER();
          const p: IPageWithCountsAndTrends = {
            href: url,
            domain: getDomainName(url), // protocoll or not?
            counts
          };

          const tagIds = tagsPerPage[withoutProtocol(p.href)] || [];

          const e = earnings[p.href];
          if (!e) {
            return Object.assign({}, p, {
              sales: EMPTY_COUNT_WITH_TREND(),
              salesCount: EMPTY_COUNT_WITH_TREND(),
              rpm: EMPTY_COUNT_WITH_TREND(),
              orderCount: EMPTY_COUNT_WITH_TREND(),
              saleValue: EMPTY_COUNT_WITH_TREND(),
              tagIds
            });
          }
          const sales: ICountWithTrend = {
            count: e.curr.total,
            lastCount: e.prev.total,
            trend: getTrend(e.prev.total, e.curr.total)
          };
          const salesCount: ICountWithTrend = {
            count: e.curr.totalCount,
            lastCount: e.prev.totalCount,
            trend: getTrend(e.prev.totalCount, e.curr.totalCount)
          };
          const orderCount: ICountWithTrend = {
            count: e.curr.orderCount.total,
            lastCount: e.prev.orderCount.total,
            trend: getTrend(e.prev.orderCount.total, e.curr.orderCount.total)
          };
          const saleValue: ICountWithTrend = {
            count: e.curr.saleValue.total,
            lastCount: e.prev.saleValue.total,
            trend: getTrend(e.prev.saleValue.total, e.curr.saleValue.total)
          };

          const rpmCurr = (sales.count / p.counts.pageViews.count) * 1000;
          const rpmPrev =
            (sales.lastCount / p.counts.pageViews.lastCount) * 1000;
          const rpm: ICountWithTrend = {
            count: rpmCurr,
            lastCount: rpmPrev,
            trend: getTrend(rpmPrev, rpmCurr)
          };
          return Object.assign({}, p, {
            sales,
            salesCount,
            orderCount,
            saleValue,
            rpm,
            tagIds
          });
        });
    }
  );
};

export const usePagesWithCountsAndSalesInTimeframeOld = (
  spaceId: string,
  timeframe: Timeframe,
  currency: CurrencyCode,
  compare: boolean
): LoadingValue<IPageWithCountsAndTrendsAndSales[]> => {
  return useMappedLoadingValue(
    combineLoadingValues(
      useSalesByPageInTimeframe(spaceId, timeframe, currency, compare),
      usePagesWithCountsInTimeframe(timeframe, compare)
    ),
    ([sales, pages]) => {
      return pages.map((p) => {
        const s = sales[p.href];
        return {
          ...p,
          sales: s ? s.sales : EMPTY_COUNT_WITH_TREND(),
          salesCount: s ? s.salesCount : EMPTY_COUNT_WITH_TREND(),
          orderCount: s ? s.orderCount : EMPTY_COUNT_WITH_TREND(),
          saleValue: s ? s.saleValue : EMPTY_COUNT_WITH_TREND(),
          tagIds: []
        };
      });
    }
  );
};
