import {
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography
} from '@material-ui/core';

import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import { compact, uniq } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Edit, Minus, RefreshCw, UploadCloud } from 'react-feather';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../components/AdditionalActionsMenu';
import { AlertBox } from '../../../../components/AlertBox';
import { ConnectionId } from '../../../../components/ConnectionId';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { PartnerLogoWithName } from '../../../../components/PartnerLogo';
import { ScheduleFormSimple } from '../../../../components/Schedule/SimpleForm';
import { Doc } from '../../../../domainTypes/document';
import { IPartner } from '../../../../domainTypes/partners';

import {
  IReportingStats,
  toReportingStatsId
} from '../../../../domainTypes/reporting';
import { SalesApiFetchSchedule } from '../../../../domainTypes/schedule';
import { ISecretWithTs } from '../../../../domainTypes/secret';
import {
  IAutoLabelConfig,
  PARTNERS_WITH_TRACKING
} from '../../../../domainTypes/tracking';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { saveAutoLabelConfig } from '../../../../services/autoLabelConfig';
import { Beacon, DOCS_LIST } from '../../../../services/beacon';
import {
  useCurrentUserScopes,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { updateOrCreateSchedule } from '../../../../services/schedules/helper';
import {
  SIMPLE_DATE,
  WEEKDAYS,
  formatDatePrecise,
  formatSmartRelativeDate,
  getTzAbbr,
  toMoment
} from '../../../../services/time';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { ApiSettings } from '../..//components/ApiSettings';
import { ImportDialog } from '../../components/ImportDialog';
import { SmartLabelSwitch } from '../../components/SmartLabelSwitch';
import {
  ArchiveIntegrationDialog,
  isArchivedIntegration,
  toggleIntegrationStatus
} from './ArchiveIntegrationDialog';
import { ImportHistoryDialog } from './ImportHistoryDialog';

import {
  IApiReportHandler,
  ReportHandler
} from '../../services/handlers/types';
import { scheduleImportJobs } from '../../../../services/sales/sales';

const ImportButton = ({
  children,
  preselectedPartnerKey
}: {
  children: (args: { openDialog: () => void }) => React.ReactNode;
  preselectedPartnerKey?: string;
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const currency = useSpaceCurrency();

  return (
    <span>
      {children({ openDialog })}
      <ImportDialog
        preselectedPartnerKey={preselectedPartnerKey}
        open={dialogOpen}
        onClose={closeDialog}
        currency={currency}
      />
    </span>
  );
};

const ColorCodedImportTime = styled<'span', { timeAgo: number }>('span')`
  color: ${(p) =>
    p.timeAgo >= -24
      ? p.theme.palette.success.main
      : p.theme.palette.warning.main};
`;

const RefreshLastApiImportButton = ({
  handler,
  secret
}: {
  handler: IApiReportHandler;
  secret: Doc<ISecretWithTs>;
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFetchAndSave = async () => {
    setLoading(true);
    const start = moment().subtract(7, 'days');
    const end = moment();

    try {
      setLoading(true);
      await scheduleImportJobs({
        spaceId: secret.data.spaceId,
        instanceId: secret.data.instanceId,
        handlerName: handler.configName,
        range: { start, end }
      })
        .then(() => {
          enqueueSnackbar(
            'Import scheduled successfully! Wait a few minutes then refresh to see updates.',
            {
              variant: 'success'
            }
          );
        })
        .catch(() => {
          enqueueSnackbar(
            'Error occurred scheduling import! Please try again and contact Support if needed.',
            {
              variant: 'error'
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(true);
      throw err;
    }
  };

  return loading ? (
    <CircularProgress
      size={14}
      style={{
        marginLeft: '9px',
        position: 'relative',
        top: '1px',
        color: '#777'
      }}
    />
  ) : (
    <Tooltip
      placement="top"
      title={loading ? false : 'Schedule data re-pull for last 7 days'}
    >
      <TableButton
        disabled={loading}
        onClick={() => {
          onFetchAndSave();
        }}
      >
        <RefreshCw size={14} />
      </TableButton>
    </Tooltip>
  );
};

const LastImport = ({
  handler,
  stats,
  secret,
  archivedOnly
}: {
  handler: ReportHandler;
  autoRunSchedule: Doc<SalesApiFetchSchedule> | null;
  stats: IReportingStats;
  secret: Doc<ISecretWithTs> | null;
  archivedOnly?: boolean;
}) => {
  const type = handler.type;
  const scopes = useCurrentUserScopes();
  const canTriggerPull = scopes.has('integrations.trigger_pull');
  const canUploadReports = scopes.has('integrations.upload_report');

  if (archivedOnly) {
    return <Minus size={16} color="#DDD" />;
  }

  if (type === 'AFFILIATE_PROGRAM') {
    return <span>Instant</span>;
  }

  if (!stats.lastRun || (type === 'API' && secret === null)) {
    return <span>Never</span>;
  }

  const timeAgo = toMoment(stats.lastRun.ts).diff(moment(), 'hours');

  return (
    <div>
      <Tooltip
        placement="top"
        title={formatDatePrecise(stats.lastRun.ts, 'llll')}
      >
        <ColorCodedImportTime timeAgo={timeAgo}>
          {formatSmartRelativeDate(stats.lastRun.ts, SIMPLE_DATE)}
        </ColorCodedImportTime>
      </Tooltip>
      {type === 'API' && secret && canTriggerPull && (
        <RefreshLastApiImportButton
          handler={handler as IApiReportHandler}
          secret={secret}
        />
      )}
      {type !== 'API' && canUploadReports && (
        <Tooltip placement="top" title="Click to upload fresh reports">
          <ImportButton preselectedPartnerKey={handler.partnerKey}>
            {({ openDialog }) => (
              <TableButton onClick={openDialog}>
                <UploadCloud size={16} />
              </TableButton>
            )}
          </ImportButton>
        </Tooltip>
      )}
    </div>
  );
};

const ApiForm = ({
  handler,
  secret,
  loading,
  onSuccess
}: {
  handler: IApiReportHandler;
  secret: void | Doc<ISecretWithTs>;
  loading: boolean;
  onSuccess?: () => any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  if (loading || !secret) {
    return <Loader height={100} />;
  }

  const allowCancel = !!secret.data.createdAt;

  return (
    <ApiSettings
      handler={handler}
      secret={secret}
      allowRun
      allowCancel={allowCancel}
      onSuccess={() => {
        enqueueSnackbar('API settings updated!', {
          variant: 'success'
        });
        if (onSuccess) {
          onSuccess();
        }
      }}
      onError={() =>
        enqueueSnackbar(
          'Credentials invalid, please check you entered them correctly.',
          { variant: 'error' }
        )
      }
    />
  );
};

const Cell = styled<'div', { head?: boolean }>('div')`
  ${(p) => (p.head ? `color: ${p.theme.palette.text.hint};` : '')}
  ${(p) =>
    p.head
      ? `font-size: ${p.theme.custom.fontSize.s}px;`
      : ''}
  min-height: 25px;
`;

const TableButton = styled(ButtonBase)`
  position: relative;
  top: -1px;
  margin-left: 9px !important;
  color: ${(p) => p.theme.palette.grey[500]} !important;
`;

const LAlign = styled(Cell)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CAlign = styled(Cell)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RAlign = styled(Cell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ImportScheduleColumn = ({
  handler,
  secret,
  stats,
  partner,
  autoRunSchedule,
  archivedOnly
}: {
  handler: ReportHandler;
  secret: Doc<ISecretWithTs> | null;
  stats: IReportingStats;
  partner: IPartner;
  autoRunSchedule: Doc<SalesApiFetchSchedule> | null;
  archivedOnly?: boolean;
}) => {
  const { dialogOpen, setDialogOpen } = useDialogState();
  const [canEditIntegrations] = useHasCurrentUserRequiredScopes([
    'integrations.edit'
  ]);
  const [canCreateIntegrations] = useHasCurrentUserRequiredScopes([
    'integrations.create'
  ]);
  if (archivedOnly) {
    return <Minus size={16} color="#DDD" />;
  }

  if (handler.type !== 'API') {
    return <Minus size={16} color="#DDD" />;
  }

  const setupRequired = handler.type === 'API' && stats.lastRun === null;

  if (setupRequired && !canCreateIntegrations) {
    return <Minus size={16} color="#DDD" />;
  }

  if (setupRequired) {
    return (
      <>
        <ScheduleDisabled>Setup now</ScheduleDisabled>
        <ImportButton preselectedPartnerKey={handler.partnerKey}>
          {({ openDialog }) => (
            <TableButton onClick={openDialog}>
              <UploadCloud size={16} />
            </TableButton>
          )}
        </ImportButton>
      </>
    );
  }

  return (
    <>
      <ScheduleToText handler={handler} autoRunSchedule={autoRunSchedule} />
      {canEditIntegrations && (
        <Tooltip placement="top" title="Change when your reports are pulled">
          <TableButton
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <Edit size={16} />
          </TableButton>
        </Tooltip>
      )}
      <ImportScheduleDialog
        dialogProps={{
          open: dialogOpen,
          maxWidth: 'sm',
          onClose: () => {
            setDialogOpen(false);
          }
        }}
        partner={partner}
        autoRunSchedule={autoRunSchedule}
      />
    </>
  );
};

const ImportScheduleDialog = ({
  dialogProps,
  partner,
  autoRunSchedule
}: {
  dialogProps: DialogProps;
  partner: IPartner;
  autoRunSchedule: Doc<SalesApiFetchSchedule> | null;
}) => {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Set your import schedule</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ marginBottom: '24px' }}
        >
          Choose when Affilimate should pull your transaction reports for all
          connections to <strong>{partner.name}</strong>:
        </Typography>
        {autoRunSchedule && (
          <ScheduleFormSimple
            label={`Enable all integrations with ${partner.name}`}
            doc={autoRunSchedule}
            onChange={(schedule) =>
              updateOrCreateSchedule(schedule.id, schedule.data)
            }
            readOnlyType={true}
          />
        )}
        <AlertBox variant="pending" style={{ marginTop: '24px' }}>
          If you're re-enabling an integration that used to be disabled, don't
          forget import your latest transactions by pressing the{' '}
          <RefreshCw
            size={14}
            style={{ margin: '0 3px', position: 'relative', top: '2px' }}
          />{' '}
          button next to its <strong>Last Import</strong> date.
        </AlertBox>
      </DialogContent>
      <DialogActions>
        {dialogProps.onClose !== undefined && (
          <Button
            variant="contained"
            color="default"
            onClick={(e) => dialogProps.onClose!(e, 'backdropClick')}
          >
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const SmartLabelSwitchWrapper = ({
  smartLabels,
  partner,
  canToggleSmartLabels,
  onChangeSmartLabels,
  archivedOnly
}: {
  smartLabels: boolean;
  partner: IPartner;
  canToggleSmartLabels: boolean;
  onChangeSmartLabels: (nextValue: boolean) => void;
  archivedOnly?: boolean;
}) => {
  if (archivedOnly) {
    return <Minus size={16} color="#DDD" />;
  }
  return !!PARTNERS_WITH_TRACKING[partner.key] ? (
    <SmartLabelSwitch
      value={smartLabels}
      partnerKey={partner.key}
      disabled={!canToggleSmartLabels}
      onChange={onChangeSmartLabels}
    />
  ) : (
    <Minus size={16} color="#DDD" />
  );
};

export const HandlerListRow = ({
  h,
  partner,
  stats,
  secret,
  smartLabels,
  onChangeSmartLabels,
  autoRunSchedule,
  extensionActive,
  spaceId,
  archivedOnly
}: {
  h: ReportHandler;
  partner: IPartner;
  stats: IReportingStats;
  spaceId: string;
  secret: Doc<ISecretWithTs> | null; // null for those handlers which don't have secrets
  smartLabels: boolean;
  onChangeSmartLabels: (nextValue: boolean) => void;
  autoRunSchedule: Doc<SalesApiFetchSchedule> | null;
  extensionActive: boolean;
  archivedOnly?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const integrationId = useMemo(() => {
    return h.type === 'API' && secret
      ? secret.data.instanceId
      : toReportingStatsId(stats.spaceId, stats.partnerKey);
  }, [h.type, secret, stats.spaceId, stats.partnerKey]);
  const [canToggleSmartLabels] = useHasCurrentUserRequiredScopes([
    'integrations.toggle_smart_labels'
  ]);
  const [canEditIntegrations] = useHasCurrentUserRequiredScopes([
    'integrations.edit'
  ]);
  const [canSeeTransactions] = useHasCurrentUserRequiredScopes([
    'reports.transactions.view'
  ]);

  const openHelpArticle = (handler: ReportHandler) => {
    const docConfig = DOCS_LIST.find(
      (d) => d.partnerKey === handler.partnerKey
    );
    if (!docConfig) {
      return;
    }

    Beacon('article', docConfig.docId);
  };

  const archivingOption = () => {
    if (!canEditIntegrations || !secret) {
      return null;
    }
    return isArchivedIntegration(secret.data.status)
      ? {
          key: 'archiveIntegration',
          label: 'Unarchive',
          onClick: async () => {
            await toggleIntegrationStatus({
              spaceId,
              integrationId,
              status: 'archived'
            });
            return enqueueSnackbar('Integration unarchived!', {
              variant: 'success'
            });
          }
        }
      : {
          key: 'archiveIntegration',
          label: 'Archive',
          dialog: ({ onClose }: { onClose: any }) => (
            <ArchiveIntegrationDialog
              onClose={onClose}
              integrationId={integrationId}
              status={secret.data.status}
            />
          )
        };
  };

  const additionalActionsMenuOptions: AdditionActionsMenuOption<{
    secret: null | Doc<ISecretWithTs>;
  }>[] = compact([
    h.type === 'API' &&
      secret &&
      canEditIntegrations && {
        key: 'apiSettings',
        label: 'Edit API Settings',
        dialog: ({ data }) => (
          <div style={{ maxWidth: '600px' }}>
            <DialogTitle>
              Update this connection to {partner.name} &nbsp; &nbsp;
              <HelpIcon onClick={() => openHelpArticle(h)} color="blue">
                Show instructions
              </HelpIcon>
            </DialogTitle>
            <DialogContent>
              <ApiForm
                handler={h}
                secret={data?.secret || undefined}
                loading={false}
              />
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                style={{ marginTop: '24px' }}
              >
                To add another integration with {partner.name}, use the blue
                Import button in the left menu and click "Add another
                connection".
              </Typography>
            </DialogContent>
          </div>
        )
      },
    canSeeTransactions && {
      key: 'pastImports',
      label: 'Show import history',
      dialog: ({ onClose }: { onClose: any }) => (
        <ImportHistoryDialog onClose={onClose} integrationId={integrationId} />
      )
    },
    archivingOption()
  ]);

  return (
    <>
      <LAlign>
        <PartnerLogoWithName
          partner={partner}
          additionalName={
            secret?.data.nickname
              ? secret.data.nickname
              : h.additionalName
              ? h.additionalName(partner, secret)
              : null
          }
        />
      </LAlign>
      <RAlign>
        {integrationId !== null ? (
          <ConnectionId>{integrationId}</ConnectionId>
        ) : (
          ''
        )}
      </RAlign>
      <LAlign>
        {h.type === 'API'
          ? 'API'
          : h.type === 'AFFILIATE_PROGRAM'
          ? 'Automatic'
          : extensionActive
          ? 'Browser Extension'
          : 'File Upload'}
      </LAlign>
      <LAlign>
        <ImportScheduleColumn
          handler={h}
          secret={secret}
          stats={stats}
          partner={partner}
          autoRunSchedule={autoRunSchedule}
          archivedOnly={archivedOnly}
        />
      </LAlign>
      <CAlign>
        <SmartLabelSwitchWrapper
          smartLabels={smartLabels}
          partner={partner}
          canToggleSmartLabels={canToggleSmartLabels}
          onChangeSmartLabels={onChangeSmartLabels}
          archivedOnly={archivedOnly}
        />
      </CAlign>
      <LAlign>
        <LastImport
          handler={h}
          secret={secret}
          stats={stats}
          autoRunSchedule={autoRunSchedule}
          archivedOnly={archivedOnly}
        />
      </LAlign>
      <RAlign>
        {h.type !== 'AFFILIATE_PROGRAM' &&
          (canSeeTransactions || canEditIntegrations) && (
            <AdditionalActionsMenu
              options={additionalActionsMenuOptions}
              data={{ secret }}
            />
          )}
      </RAlign>
    </>
  );
};

export const changeAutoLabelConfig = (
  doc: Doc<IAutoLabelConfig>,
  partnerKey: string,
  enabled: boolean
) => {
  const nextItems = enabled
    ? uniq([...doc.data.partners, partnerKey])
    : doc.data.partners.filter((p) => p !== partnerKey);
  return saveAutoLabelConfig({
    ...doc,
    data: {
      ...doc.data,
      partners: nextItems
    }
  });
};

const ScheduleDisabled = styled('div')`
  font-style: italic;
  opacity: 0.5;
`;

const ScheduleToText = ({
  autoRunSchedule,
  handler
}: {
  autoRunSchedule: Doc<SalesApiFetchSchedule> | null;
  handler: ReportHandler;
}) => {
  if (!autoRunSchedule) {
    return <ScheduleDisabled>Setup required</ScheduleDisabled>;
  }

  if (!autoRunSchedule.data.active) {
    return <ScheduleDisabled>Disabled</ScheduleDisabled>;
  }

  const f = autoRunSchedule.data.frequency;

  if (f.type === 'NONE') {
    return <ScheduleDisabled>None</ScheduleDisabled>;
  }

  if (f.type === 'DAILY') {
    return (
      <div>
        Daily at {f.hour === 0 ? `midnight` : `${f.hour}:00`} {getTzAbbr(f.tz)}
      </div>
    );
  } else if (f.type === 'WEEKLY') {
    return (
      <div>
        Weekly on {WEEKDAYS[f.weekDay]} at {f.hour}:00 {getTzAbbr(f.tz)}
      </div>
    );
  } else {
    return <div>None</div>;
  }
};
