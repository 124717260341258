import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Info } from 'react-feather';
import { Hint, SubHeading } from '../../../components/Typography';
import { styled } from '../../../emotion';

export const SummaryHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const SummaryHeaderLeft = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  > div:first-of-type {
    margin-right: ${(p) => p.theme.spacing(2)}px;
  }

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const SubHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

export const SummaryGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${(p) => p.theme.spacing(6)}px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
    grid-gap: ${(p) => p.theme.spacing(6)}px;
  }
`;

const GridItemBody = styled('div')`
  height: calc(100% - 34px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const SummaryGridItem: React.FC<{
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  info?: string;
  style?: React.CSSProperties;
}> = ({ heading, subheading, children, style, info }) => {
  return (
    <div style={style}>
      <SubHeader>
        <SubHeading>
          {heading}

          {info && (
            <Tooltip title={info} placement="top">
              <span
                style={{ marginLeft: '5px', position: 'relative', top: '2px' }}
              >
                <Info size={13} />
              </span>
            </Tooltip>
          )}
        </SubHeading>
        {subheading ? <Hint>{subheading}</Hint> : null}
      </SubHeader>
      <GridItemBody>{children}</GridItemBody>
    </div>
  );
};
