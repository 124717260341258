import { flatten, maxBy, minBy } from 'lodash';
import moment from 'moment-timezone';
import { ParseConfig } from 'papaparse';
import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { CurrencyCode } from '../../../domainTypes/currency';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  IReport,
  IReportPreview,
  IReportPreviewMaybeAsync,
  ISale,
  ITrackedSale
} from '../../../domainTypes/performance';
import {
  CLOUD_STORAGE_BUCKET,
  IReportingPreviewParams,
  ReportingApiResult,
  toReportingStatsId
} from '../../../domainTypes/reporting';
import { ISpace } from '../../../domainTypes/space';
import { usePromise } from '../../../hooks/usePromise';
import { compress } from '../../../services/compression';
import { getCompressor } from '../../../services/compressionAsync';
import {
  batchSet,
  refreshTimestamp,
  store,
  useMappedLoadingValue
} from '../../../services/db';
import {
  readCsvRows,
  readFileAsText,
  readXlsxRows
} from '../../../services/file';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { flushSalesCacheForSpace } from '../../../services/sales/cache';
import {
  getFileContent,
  ICloudStorageFile,
  storeAllFiles
} from '../../../services/storage';
import { now } from '../../../services/time';
import { CF, FS } from '../../../versions';
import { CSV_REPORT_HANDLERS, XLSX_REPORT_HANDLERS } from './handlers';
import { FileReportParseResult, IFileReportHandler } from './handlers/types';
import { convertTrackedSales, refreshSaleTimestamps } from './sale';

const getFileContents = async (
  file: File,
  options?: ParseConfig
): Promise<{ rows: any[][]; fileType: 'csv' | 'xlsx' | null }> => {
  if (file.name.match(/csv$/)) {
    return { rows: await readCsvRows(file, options || {}), fileType: 'csv' };
  }
  if (file.name.match(/xlsx$/)) {
    return { rows: await readXlsxRows(file), fileType: 'xlsx' };
  }
  return { rows: [], fileType: null };
};

export const parseFile = async (
  space: ISpace,
  file: File
): Promise<FileReportParseResult> => {
  try {
    const reportId = shortid();

    if (file.type === null) {
      return {
        file,
        fileType: null,
        report: null,
        errorType: 'FILE_TYPE_UNKNOWN'
      };
    }

    let p: IFileReportHandler<string> | IFileReportHandler<string[]>;

    if (file.name.match(/csv$/)) {
      const textFromFile = await readFileAsText(file);
      console.log(textFromFile);
      const csvHandlers = CSV_REPORT_HANDLERS.filter((h) => h.type === 'CSV');
      p = csvHandlers.find((h) =>
        h.parser.matches(textFromFile as string, h.parser.csvHeaders)
      )!;
    } else if (file.name.match(/xlsx$/)) {
      const content = await getFileContents(file);
      const xlsxHandlers = XLSX_REPORT_HANDLERS.filter(
        (h) => h.type === 'XLSX'
      );
      p = xlsxHandlers.find((h) =>
        h.parser.matches(content.rows, h.parser.csvHeaders)
      )!;
    } else {
      return {
        file,
        fileType: null,
        report: null,
        errorType: 'UNKNOWN_REPORT'
      };
    }

    const content = await getFileContents(file, p.parser.parseOptions);
    const { rows, fileType } = content;
    const sales = await p.parser.processRows(rows, {
      space,
      now: moment(),
      partnerKey: p.partnerKey,
      integrationId: toReportingStatsId(space.id, p.partnerKey),
      reportId
    });

    const earliest = minBy(sales, (s) => s.saleDate.toMillis());
    const latest = maxBy(sales, (s) => s.saleDate.toMillis());

    return {
      file,
      fileType,
      report: {
        spaceId: space.id,
        reportId,
        partnerKey: p.partnerKey,
        start: earliest ? earliest.saleDate : null,
        end: latest ? latest.saleDate : null,
        sales
      }
    };
  } catch (err) {
    console.error('Cannot read report', err);
    return {
      file,
      fileType: null,
      report: null,
      errorType: 'UNKNOWN',
      errorMsg: err
    };
  }
};

const getSalesFromCloudStorage = async (storagePath: string) => {
  const content = await getFileContent(storagePath);
  const compressor = getCompressor();
  const decompressedSales = await compressor.decompress(content);
  return (decompressedSales as ISale[]).map(refreshSaleTimestamps);
};

const collectSaleEvents = <T extends { sales: ISale[] }>(
  containers: T[]
): ISale[] => {
  return flatten(containers.map((c) => c.sales));
};
export const reportsToTrackedSalesEvents = async (
  spaceId: string,
  userId: string,
  reports: IReportPreview[],
  tz: string
) => {
  const sales = collectSaleEvents(reports);
  const createdAt = now();

  // The connection to clicke events should happen
  // on the server when the report is uploaded...
  const trackedSales: ITrackedSale[] = sales.map((s) => {
    return {
      spaceId,
      queryDate: s.saleDate,
      device: s.device || 'unknown',
      pageUrl: s.referrerUrl || null,
      origin: s.origin,
      sale: s,
      click: null,
      createdAt,
      createdBy: userId,
      channelId: '',
      saleType: s.saleType || 'unknown'
    };
  });

  return trackedSales;
};

const maybeAsyncReportPreviewToSyncReportPreview = async (
  r: IReportPreviewMaybeAsync
): Promise<IReportPreview> => {
  const sales = r.sales || (await getSalesFromCloudStorage(r.salesStoragePath));
  return {
    ...r,
    sales: sales
  };
};

export const useTrackedSalesEventsForAsyncReports = (
  spaceId: string,
  userId: string,
  maybeAsyncReports: IReportPreviewMaybeAsync[],
  tz: string,
  currency: CurrencyCode
) => {
  return usePromise(
    () =>
      Promise.all(
        maybeAsyncReports.map(maybeAsyncReportPreviewToSyncReportPreview)
      ).then((reports) =>
        reportsToTrackedSalesEvents(
          spaceId,
          userId,
          reports,
          tz
        ).then((sales) => convertTrackedSales(sales, currency, tz))
      ),
    [spaceId, maybeAsyncReports.map((r) => r.reportId).join('-'), tz]
  );
};

export const useTrackedSalesEventsForReports = (
  spaceId: string,
  userId: string,
  reports: IReportPreview[],
  tz: string,
  currency: CurrencyCode
) => {
  return usePromise(
    () =>
      reportsToTrackedSalesEvents(spaceId, userId, reports, tz).then((sales) =>
        convertTrackedSales(sales, currency, tz)
      ),
    [spaceId, reports.map((r) => r.reportId).join('-'), tz]
  );
};

const saveResults = async (
  data: {
    sourceFile: ICloudStorageFile;
    report: Doc<IReport>;
    salesFile: ICloudStorageFile;
  }[]
) => {
  const reports = data.map((d) => d.report);
  const files = flatten(data.map((d) => [d.sourceFile, d.salesFile]));

  await storeAllFiles(files);
  await batchSet(FS.reports, reports);

  return data;
};

const toSourceStoragePath = (
  spaceId: string,
  partnerKey: string,
  reportId: string,
  extension: string
) => {
  return `${CLOUD_STORAGE_BUCKET}/report_${spaceId}_${partnerKey}_${reportId}.${extension}`;
};

const toSalesStoragePath = (
  spaceId: string,
  partnerKey: string,
  reportId: string,
  extension: string
) => {
  return `${CLOUD_STORAGE_BUCKET}/report_${spaceId}_${partnerKey}_${reportId}_sales.${extension}`;
};

const getContentType = (f: FileReportParseResult['fileType']) => {
  if (f === 'csv') {
    return 'text/csv';
  }
  if (f === 'xlsx') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  return 'text/plain';
};

export const saveParseResults = async (
  results: FileReportParseResult[],
  createdBy: string
) => {
  const createdAt = now();
  const data: {
    report: Doc<IReport>;
    sourceFile: ICloudStorageFile;
    salesFile: ICloudStorageFile;
  }[] = [];
  for (const r of results) {
    if (!r.report) {
      continue;
    }
    const { spaceId, reportId, partnerKey, start, end, sales } = r.report;
    if (!start || !end) {
      continue;
    }

    const sourceStoragePath = toSourceStoragePath(
      spaceId,
      reportId,
      partnerKey,
      r.fileType || 'txt'
    );
    const sourceFile: ICloudStorageFile = {
      name: sourceStoragePath,
      data: r.file,
      contentType: getContentType(r.fileType),
      metadata: {
        spaceId,
        reportId,
        originalFileName: r.file.name,
        type: 'FILE'
      }
    };
    const compressor = getCompressor();
    const compressedSales = await compressor.compress(sales);

    const salesStoragePath = toSalesStoragePath(
      spaceId,
      partnerKey,
      reportId,
      'txt'
    );
    const salesFile: ICloudStorageFile = {
      name: salesStoragePath,
      data: new Blob([compressedSales], {
        type: 'text/plain'
      }),
      contentType: 'text/plain',
      metadata: { spaceId, reportId }
    };

    const report: Doc<IReport> = {
      id: reportId,
      collection: FS.reports,
      data: {
        spaceId,
        partnerKey,
        start,
        end,
        source: {
          type: 'FILE',
          storagePath: sourceStoragePath
        },
        createdAt,
        createdBy,
        salesCount: sales.length,
        salesStoragePath,
        integrationId: toReportingStatsId(spaceId, partnerKey),
        sales: null,
        v: 1
      }
    };

    data.push({ report, sourceFile, salesFile });
  }
  return await saveResults(data);
};

export const saveApiResult = async (
  r: ReportingApiResult,
  createdBy: string
) => {
  if (r.errorType || !r.report) {
    console.log('Tried to save an api result with errors!');
    return Promise.reject();
  }

  const { spaceId, reportId, partnerKey, start, end, sales } = r.report;
  if (!start || !end) {
    console.log('Report is missing dates', r.report);
    return Promise.reject();
  }

  const sourceStoragePath = toSourceStoragePath(
    spaceId,
    partnerKey,
    reportId,
    'txt'
  );

  const sourceFile: ICloudStorageFile = {
    name: sourceStoragePath,
    data: new Blob([JSON.stringify(r.response, null, 2)], {
      type: 'text/plain'
    }),
    contentType: 'text/plain',
    metadata: { spaceId, reportId, originalUrl: r.url, type: 'API' }
  };

  const salesStoragePath = toSalesStoragePath(
    spaceId,
    partnerKey,
    reportId,
    'txt'
  );
  const salesFile: ICloudStorageFile = {
    name: salesStoragePath,
    data: new Blob([compress(sales)], {
      type: 'text/plain'
    }),
    contentType: 'text/plain',
    metadata: { spaceId, reportId }
  };

  const report: Doc<IReport> = {
    id: reportId,
    collection: FS.reports,
    data: {
      spaceId,
      partnerKey,
      start,
      end,
      source: {
        type: 'API',
        url: r.url,
        storagePath: sourceStoragePath
      },
      createdAt: now(),
      createdBy,
      salesCount: sales.length,
      salesStoragePath,
      integrationId: r.integrationId,
      sales: null,
      v: 1
    }
  };

  const data = { sourceFile, report, salesFile };
  await saveResults([data]);
  return data;
};

export const toReportDoc = generateToDocFn<IReport>((d) => {
  if (!d.integrationId) {
    d.integrationId = toReportingStatsId(d.spaceId, d.partnerKey);
  }
  return d;
});

export const getReportsQuery = (spaceId: string) =>
  store().collection(FS.reports).where('spaceId', '==', spaceId);

export const useReports = (spaceId: string, limit = 15) => {
  return useMappedLoadingValue(
    useCollection(
      getReportsQuery(spaceId).orderBy('createdAt', 'desc').limit(limit)
    ),
    (s) => s.docs.map(toReportDoc)
  );
};

const hasAnyReportsListener = createCollectionListenerStore(
  (spaceId) => new CollectionListener<any>(getReportsQuery(spaceId).limit(1))
);

export const useHasAnyReports = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollectionListener(hasAnyReportsListener(spaceId)),
    (docs) => docs.length > 0
  );
};

export const getApiPreview = (params: IReportingPreviewParams) => {
  return callFirebaseFunction<ReportingApiResult, IReportingPreviewParams>(
    CF.reporting.preview,
    params
  ).then<ReportingApiResult>((result) => {
    const { report } = result;
    if (!report) {
      return result;
    }
    return {
      ...result,
      report: {
        ...report,
        start: refreshTimestamp(report.start),
        end: refreshTimestamp(report.end),
        sales: report.sales.map(refreshSaleTimestamps)
      }
    };
  });
};

export const triggerApiHandler = async (params: IReportingPreviewParams) => {
  await callFirebaseFunction<void, IReportingPreviewParams>(
    CF.reporting.triggerApiHandler,
    params
  );
  flushSalesCacheForSpace(params.spaceId);
};

export const deleteReports = async (reports: Doc<IReport>[]) => {
  await Promise.all(
    reports.map((d) =>
      callFirebaseFunction('reporting-deleteReport', { reportId: d.id })
    )
  );
  const spaceIds = reports.map((r) => r.data.spaceId);
  spaceIds.forEach(flushSalesCacheForSpace);
};
