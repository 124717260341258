import React, { ReactNode } from 'react';
import {
  Line,
  LineChart,
  TickFormatterFunction,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { CustomTooltip } from '../../../../../../components/Charts/CustomTooltip';
import { WithShape } from '../../../../../../components/Charts/Util';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { formatMetric } from '../../../../../../services/analyticsV2/metrics';
import { TrafficMetric } from '../../service';
import { ChartData } from './chart-data';
import { CHART_HEIGHT } from './index';
import {
  Channel,
  channelColor,
  channelLabel,
  getChannel
} from '../../../../../../services/channels/channels';

interface ChartProps {
  data: Array<ChartData>;
  channels: Channel[];
  labelFormatter: (
    label: TooltipProps['label'],
    metric: TrafficMetric
  ) => ReactNode;
  xAxisTickFormatter: TickFormatterFunction;
  currency: CurrencyCode;
  metric: TrafficMetric;
}

export const realtimeTrafficChart = ({
  labelFormatter,
  data,
  xAxisTickFormatter,
  channels,
  currency,
  metric
}: ChartProps) => (
  <LineChart
    width={730}
    height={CHART_HEIGHT}
    data={data}
    syncId="realtimeChartId"
  >
    <Tooltip
      cursor={false}
      content={<CustomTooltip />}
      formatter={(value, id) => {
        const channel = getChannel(id, channels);
        return [
          formatMetric(value as number, metric, currency),
          <WithShape color={channelColor(channel)} small>
            {channelLabel(channel)}
          </WithShape>
        ];
      }}
      labelFormatter={(label) => labelFormatter(label, metric)}
    />
    <XAxis
      dataKey="timestamp"
      minTickGap={120}
      tickLine={false}
      tickFormatter={xAxisTickFormatter}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(0, 8)',
        fontSize: 14
      }}
      textAnchor="end"
      tickSize={0}
      stroke="cwBBB"
    />
    <YAxis
      yAxisId="traffic"
      tickLine={false}
      tickFormatter={(d) => formatMetric(d, metric, currency)}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(8, 0)',
        fontSize: 14
      }}
      tickSize={0}
      orientation="right"
      stroke="cwBBB"
    />
    {channels.map((channel) => (
      <Line
        key={channel.id}
        name={channel.id}
        type="monotone"
        dataKey={(d) => d.traffic[channel.id]}
        yAxisId="traffic"
        dot={false}
        stroke={channelColor(channel)}
        strokeWidth={2.5}
        isAnimationActive={false}
      />
    ))}
  </LineChart>
);
